const { default: axiosClientDownLoad } = require("./axiosClientDownLoad");

const ExportExcelApi = {
    getTimKiemThongKeBanDo: (params) => {
        const url = '/exportexcel/gettimkiemthongkebando';
        return axiosClientDownLoad.get(url, { params });
    },
    getTaiKhoan: (params) => {
        const url = '/exportexcel/gettaikhoan';
        return axiosClientDownLoad.get(url, { params });
    },
    getDienTichTrongTrot: (params) => {
        const url = '/exportexcel/getDienTichTrongTrot';
        return axiosClientDownLoad.get(url, { params });
    },
    getSanLuongNongSan: (params) => {
        const url = '/exportexcel/getSanLuongNongSan';
        return axiosClientDownLoad.get(url, { params });
    },
    getGiaNongSan: (params) => {
        const url = '/exportexcel/getGiaNongSan';
        return axiosClientDownLoad.get(url, { params });
    },
    getThoiTiet: (params) => {
        const url = '/exportexcel/getThoiTiet';
        return axiosClientDownLoad.get(url, { params });
    },
    getSauBenh: (params) => {
        const url = '/exportexcel/getSauBenh';
        return axiosClientDownLoad.get(url, { params });
    },
    getHienTrangSuDungDat: (params) => {
        const url = '/exportexcel/getHienTrangSuDungDat';
        return axiosClientDownLoad.get(url, { params });
    },
    getDienTichSx: (params) =>{
        const url = '/exportexcel/getdientichsx';
        return axiosClientDownLoad.get(url, { params });
    },
    getAnhHuongSauBenh: (params) =>{
        const url = '/exportexcel/getanhhuongsaubenh';
        return axiosClientDownLoad.get(url, { params });
    },
    getDonViCungCap: (params) =>{
        const url = '/exportexcel/getdonvicungcap';
        return axiosClientDownLoad.get(url, { params });
    },
    getThuocBvThucVat: (params) =>{
        const url = '/exportexcel/getthuocbvthucvat';
        return axiosClientDownLoad.get(url, { params });
    }
}
export default ExportExcelApi;