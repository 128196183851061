import { Button, Modal } from 'react-bootstrap';
import React, { useEffect, useState } from 'react'
import NumberFormat from 'react-number-format';
import BanDoPublicApi from '../../api/BanDoPublicApi';
import ThongBao from '../../core/ThongBao';
import { Loading } from '../../shared/loading/Loading';
import stylelocal from "./TraCuu.module.css"
import { TrieuChungSauBenh } from '../tracuusaubenh/trieuchungsaubenh'

export const ModalTraCuu = (props) => {
    const [thongTin, setThongTin] = useState({});
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [timKiem, setTimKiem] = useState({});

    useEffect(() => {
        if (props.show == true) {
            if (props.x) {
                const params = {
                    x: props.x,
                    y: props.y,
                    tableName: 'BDVUNGDUAN'
                }
                setLoading(true);
                BanDoPublicApi.getDetail(params).then((res) => {
                    if(res?.ten_huyen == null){
                        ThongBao.prinWarningMessage('Không có thông tin.');
                        setLoading(false);
                        props.handleClose();
                    }
                    else{
                        setTimKiem({ IdCayTrong: res.htsd , IdQuanHuyen: res.mahuyen, IdXaPhuong: res.id_xa, Thang: 0});
                        setThongTin(res);
                        setShow(true);
                        setLoading(false);
                    }
                    
                });
            }
            else {
                ThongBao.prinWarningMessage('Bạn chưa chọn khoảnh đất.');
                props.handleClose();
            }
        }
    }, [props.show]);

    const handleClose = () => {
        setShow(false);
        props.handleClose();
    };

    return (
        <Modal show={show} onHide={handleClose} animation={false} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Tra cứu sâu bệnh.</Modal.Title>
            </Modal.Header>
            <Modal.Body className={stylelocal.modaltinhphanbon}>
                <form className="user">
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label text-right">Tỉnh</label>
                        <div className="col-sm-4">
                            <input name="tentinh" type="text" className="form-control" placeholder="Tỉnh" value='Hòa Bình' readOnly />
                        </div>
                        <label className="col-sm-2 col-form-label text-right">Huyện</label>
                        <div className="col-sm-4">
                            <input name="tenhuyen" type="text" className="form-control" placeholder="Huyện" value={thongTin?.ten_huyen} readOnly />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label text-right">Xã</label>
                        <div className="col-sm-4">
                            <input name="tenxa" type="text" className="form-control" placeholder="Xã" value={thongTin?.texxa_vn} readOnly />
                        </div>
                        <label className="col-sm-2 col-form-label text-right">Hiện trạng</label>
                        <div className="col-sm-4">
                            <input name="tenxa" type="text" className="form-control" placeholder="Xã" value={thongTin?.caytrong} readOnly />
                        </div>
                    </div>
                </form>
                <TrieuChungSauBenh timKiem={timKiem}></TrieuChungSauBenh>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" size="sm" onClick={handleClose}><i className="fas fa-sign-out-alt"></i> Đóng</Button>
            </Modal.Footer>
            <Loading loading={loading}></Loading>
        </Modal>
    )
}
