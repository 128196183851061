import React, { useState, useEffect } from 'react'
import NhomQuyenApi from '../../../api/NhomQuyenApi';
import { Modal, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Pagination from 'react-bootstrap-4-pagination';
import ThongBao from '../../../core/ThongBao';
import Authen from '../../../core/Authen';

export const NhomQuyen = () => {
    const [listNhomQuyens, setNhomQuyen] = useState([]);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const { register, handleSubmit, reset } = useForm();
    const [filter, setFilter] = useState('');

    const [roleAdd, setRoleAdd] = useState(false);
    const [roleEdit, setRoleEdit] = useState(false);
    const [roleDel, setRoleDel] = useState(false);

    let pageIndex = 1;
    let pageSize = 20;
    const [paginationConfig, setPaging] = useState({
        totalPages: 1,
        currentPage: 1,
        showMax: 5,
        size: "sm",
        threeDots: true,
        prevNext: true,
        onClick: function (page) {
            pageIndex = page;
            loadNhomQuyen();
        }
    });

    const loadNhomQuyen = async () => {
        try {
            const params = {
                pageIndex: pageIndex,
                pageSize: pageSize,
                keyword: filter
            }
            const response = await NhomQuyenApi.getPaging(params);
            response.Items.forEach(item => {
                item.Checked = false;
            });
            paginationConfig.totalPages = 1;
            if (response.TotalRow > 0) {
                paginationConfig.totalPages = Math.ceil(response.TotalRow / pageSize);
            }
            paginationConfig.currentPage = pageIndex;
            setPaging(paginationConfig);
            setNhomQuyen(response.Items);
        } catch (err) {
        }
    }

    useEffect(() => {
        loadNhomQuyen();
        setRoleAdd(Authen.checkQuyen('NHOMQUYEN_CREATE'));
        setRoleEdit(Authen.checkQuyen('NHOMQUYEN_UPDATE'));
        setRoleDel(Authen.checkQuyen('NHOMQUYEN_DELETE'));
    }, []);

    const timKiem = () => {
        loadNhomQuyen();
    }

    function onChangeFilter(e) {
        setFilter(e.target.value)
    }

    const saveData = (data) => {
        if (data.Id === "") {
            NhomQuyenApi.post(data).then(() => {
                setShow(false);
                loadNhomQuyen();
            })
        }
        else {
            NhomQuyenApi.put(data).then(() => {
                setShow(false);
                loadNhomQuyen();
            })
        }
    }

    const clickRow = (id) => {
        listNhomQuyens.forEach(item => {
            if (item.Id === id) {
                item.Checked = !item.Checked;
            }
        });
        setNhomQuyen([...listNhomQuyens]);
    }

    const handleShowAdd = () => {
        reset({
            Id: "",
            Name: null,
            Description: null
        }, [reset]);
        setShow(true)
    };

    const handleShowEdit = () => {
        let index = listNhomQuyens.findIndex(x => x.Checked);
        if (index !== -1) {
            NhomQuyenApi.getDetail(listNhomQuyens[index].Id).then((response) => {
                reset({
                    Id: response.Id,
                    Name: response.Name,
                    Description: response.Description
                }, [reset])
                setShow(true);
            });
        }
    }

    const handleShowDel = () => {
        let index = listNhomQuyens.findIndex(x => x.Checked);
        if (index !== -1) {
            ThongBao.printConfirmationDialog("Bạn có muốn xóa bản ghi được chọn không?", () => {
                NhomQuyenApi.delete(listNhomQuyens[index].Id).then((res) => {
                    if (res.Message == "") {
                        ThongBao.printSuccessMessage("Xóa bản ghi thành công!");
                        loadNhomQuyen();
                    }
                    else {
                        ThongBao.prinWarningMessage(res.Message);
                    }
                })
            })
        }
    }

    const checkBoHandler = () => {

    }

    return (
        <div className="card shadow mb-1">
            <div className="card-header py-2 row">
                <div className="col-md-4">
                    <h6 className="m-0 font-weight-bold text-primary">Nhóm quyền</h6>
                </div>
                <div className="input-group col-md-4">
                    <input type="text" className="form-control" placeholder="Tìm kiếm" value={filter} onChange={onChangeFilter} />
                    <div className="input-group-append">
                        <button className="btn btn-info" type="button" onClick={timKiem}><i className='fas fa-search'></i> Tìm kiếm</button>
                    </div>
                </div>
                <div className="col-md-4 bd-button text-right">
                    {roleAdd === true ? <Button variant="success" size="sm" onClick={handleShowAdd}><i className='fas fa-plus'></i>&nbsp;Thêm</Button> : ''}
                    {roleEdit === true ? <Button variant="primary" size="sm" onClick={handleShowEdit}><i className='far fa-edit'></i>&nbsp;Sửa</Button> : ''}
                    {roleDel === true ? <Button variant="danger" size="sm" onClick={handleShowDel}><i className='fas fa-trash-alt'></i>&nbsp;Xóa</Button> : ''}
                </div>
            </div>
            <div className="card-body">
                {/* <div className="table-responsive"> */}
                <div>
                    <table className="table table-bordered" width="100%">
                        <thead>
                            <tr>
                                <th className="col-checkbox"></th>
                                <th>Mã nhóm</th>
                                <th>Tên nhóm</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                listNhomQuyens.map((item, key) => {
                                    return (
                                        <tr onClick={() => clickRow(item.Id)} key={key}>
                                            <td className="col-checkbox">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" checked={item.Checked} onChange={checkBoHandler} />
                                                </div>
                                            </td>
                                            <td>{item.Name}</td>
                                            <td>{item.Description}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <div className="col-md-12 text-right">
                    <Pagination {...paginationConfig} />
                </div>
            </div>

            <Modal show={show} onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Nhóm quyền</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="user" onSubmit={handleSubmit(saveData)}>
                        <input name="Id" hidden={true} ref={register}></input>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Mã nhóm
                                <span className="text-danger"> *</span>
                            </label>
                            <div className="col-sm-9">
                                <input name="Name" type="text" className="form-control" placeholder="Mã nhóm quyền" ref={register({ required: true })} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Tên nhóm
                                <span className="text-danger"> *</span>
                            </label>
                            <div className="col-sm-9">
                                <input name="Description" type="text" className="form-control" placeholder="Tên nhóm" ref={register({ required: true })} />
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" size="sm" onClick={handleSubmit(saveData)}><i className='fas fa-save'></i>&nbsp;Lưu</Button>
                    <Button variant="danger" size="sm" onClick={handleClose}><i className="fas fa-sign-out-alt"></i>&nbsp;Hủy</Button>
                </Modal.Footer>
            </Modal>

        </div>

    )
}
