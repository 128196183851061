import { Button, Modal } from 'react-bootstrap';
import React, { useEffect, useState } from 'react'
import NumberFormat from 'react-number-format';
import BanDoPublicApi from '../../api/BanDoPublicApi';
import ThongBao from '../../core/ThongBao';
import { Loading } from '../../shared/loading/Loading';
import Select from 'react-select';
import SauBenhApi from '../../api/SauBenhApi';
import LichSuChanDoanApi from '../../api/LichSuChanDoanApi';

export const DuDoanDichHai = (props) => {
    const [thongTin, setThongTin] = useState({});
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [giaiDoan, setGiaiDoan] = useState({});
    const [optionGiaiDoans, setOptionGiaiDoan] = useState([{ value: 0, label: 'Chọn giai đoạn' }]);
    const [listSauBenh, setListSauBenh] = useState([]);
    const [sauBenh, setSauBenh] = useState('');
    const [soDiemNhiem, setSoDiemNhiem] = useState();
    const [matDo, setMatDo] = useState();
    const [dienTich, setDienTich] = useState();
    const [ ketQua, setKetQua] = useState({});

    const [optionSauBenhs, setOptionSauBenh] = useState([{ value: 0, label: 'Chọn sâu bệnh' }]);

    useEffect(() => {
        if (props.show == true) {
            if (props.x) {
                const params = {
                    x: props.x,
                    y: props.y,
                    tableName: 'BDVUNGDUAN'
                }
                setLoading(true);
                BanDoPublicApi.getDetail(params).then((res) => {
                    if (res?.ten_huyen == null) {
                        ThongBao.prinWarningMessage('Không có thông tin.');
                        setLoading(false);
                        props.handleClose();
                    }
                    else {
                        setThongTin(res);
                        loadSauBenh(res.htsd);
                        setDienTich(res.dientich);
                        setShow(true);
                        setLoading(false);
                    }

                });
            }
            else {
                ThongBao.prinWarningMessage('Bạn chưa chọn khoảnh đất.');
                props.handleClose();
            }
        }
    }, [props.show]);

    const loadSauBenh = async (maCayTrong) => {
        const resPonse = await SauBenhApi.getAll();
        if (!resPonse.length == false) {
            let list = [];
            resPonse.map(x => {
                if (x.MaCayTrong === maCayTrong) {
                    list.push({ value: x.Id, label: x.TenSauBenh, idCayTrong: x.IdCayTrong });
                }
            });
            setOptionSauBenh(list);
        }
    }

    const loadGiaiDoan = async (idSauBenh) => {
        const res = await SauBenhApi.getGiaiDoanSinhTruongBySauBenh(idSauBenh);
        let list = [];
        res.forEach(item => {
            list.push({value: item.Id, label: item.Ten, donVi: item.DonVi, tieuChuan: item.TieuChuan});
        });
        if(list.length > 0){
            setGiaiDoan(list[0]);
        }
        else{
            setGiaiDoan(null);
        }
        setOptionGiaiDoan(list);
    }

    const handleClose = () => {
        setShow(false);
        props.handleClose();
    };

    const onChangeSelect = (optionSelected) => {
        setSauBenh(optionSelected);
        loadGiaiDoan(optionSelected.value);

    }

    const onChangeSelectGiaiDoan = (optionSelected) => {
        setGiaiDoan(optionSelected);
    }

    const onchangeSoDiemNhiem = (e) => {
        const sodiem = parseInt(e.target.value);
        if (sodiem < 1) {
            ThongBao.printErrorMessage(`Giá trị nhập thấp hơn mức năng suất tối thiểu (1 điểm)`);
            setSoDiemNhiem(1);
            return;
        }
        if (sodiem > 10) {
            ThongBao.printErrorMessage(`Giá trị nhập vượt quá mức năng suất tối đa (10 điểm)`);
            setSoDiemNhiem(10);
            return;
        }
        setSoDiemNhiem(sodiem);
    }

    const onchangeDienTich = (e) => {
        const dt = parseFloat(e.target.value);
        setDienTich(dt);

    }

    const onchangeMatDo = (e) => {
        const md = parseFloat(e.target.value);
        setMatDo(md);
    }



    const chuanDoan = () => {
        if(matDo == null || soDiemNhiem == null || giaiDoan == null){
            ThongBao.printErrorMessage(`Bạn chưa điền đủ các thông tin cần thiết.`);
            return;
        }
        let mucDoNhiem = 'Không nhiễm';
        if(matDo >= giaiDoan.tieuChuan * 0.5 && matDo <= giaiDoan.tieuChuan){
            mucDoNhiem = 'Nhiễm nhẹ';
        }
        if(matDo > giaiDoan.tieuChuan && matDo <= giaiDoan.tieuChuan * 2){
            mucDoNhiem = 'Nhiễm trung bình';
        }
        if(matDo > giaiDoan.tieuChuan * 2){
            mucDoNhiem = "Nhiễm nặng";
        }
        setKetQua({
            MucDoNhiem: mucDoNhiem,
            DienTichNhiem: soDiemNhiem/10*dienTich
        });
        const data = {
            IdCayTrong: sauBenh.idCayTrong,
            IdSauBenh: sauBenh.value,
            IdGiaiDoan: giaiDoan.value,
            MatDo: matDo,
            MucDoNhiem: mucDoNhiem,
            ToaDoX: props.x,
            ToaDoY: props.y
        }
        LichSuChanDoanApi.add(data);
    }

    return (
        <Modal show={show} onHide={handleClose} animation={false} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Chẩn đoán dịch hại.</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form className="user">
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label text-right">Tỉnh</label>
                        <div className="col-sm-4">
                            <input name="tentinh" type="text" className="form-control" placeholder="Tỉnh" value='Hòa Bình' readOnly />
                        </div>
                        <label className="col-sm-2 col-form-label text-right">Huyện</label>
                        <div className="col-sm-4">
                            <input name="tenhuyen" type="text" className="form-control" placeholder="Huyện" value={thongTin?.ten_huyen} readOnly />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label text-right">Xã</label>
                        <div className="col-sm-4">
                            <input name="tenxa" type="text" className="form-control" placeholder="Xã" value={thongTin?.texxa_vn} readOnly />
                        </div>
                        <label className="col-sm-2 col-form-label text-right">Cây trồng</label>
                        <div className="col-sm-4">
                            <input name="tenxa" type="text" className="form-control" placeholder="Xã" value={thongTin?.caytrong} readOnly />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label text-right">Sâu bệnh</label>
                        <div className="col-md-4 col-sm-3 col-xl-4">
                            <Select options={optionSauBenhs} onChange={onChangeSelect} value={sauBenh} placeholder="Chọn sâu bệnh" theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                                height: 30,
                                spacing: {
                                    controlHeight: 30,
                                    menuGutter: 1,
                                    baseUnit: 2,
                                }
                            })} />
                        </div>
                        <label className="col-sm-2 col-form-label text-right">Giai đoạn</label>
                        <div className="col-sm-4">
                            <Select options={optionGiaiDoans} onChange={onChangeSelectGiaiDoan} placeholder="Chọn giai đoạn" value={giaiDoan} theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                                height: 30,
                                spacing: {
                                    controlHeight: 30,
                                    menuGutter: 1,
                                    baseUnit: 2,
                                }
                            })} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label text-right">Mật độ</label>
                        <div className="col-sm-2">
                            <NumberFormat className="form-control" value={matDo} displayType={'input'} thousandSeparator={true} decimalScale={2} onChange={onchangeMatDo}></NumberFormat>
                        </div>
                        <label className="col-sm-2 col-form-label">{giaiDoan?.donVi}</label>
                        <label className="col-sm-2 col-form-label text-right">Số điểm nhiễm</label>
                        <div className="col-sm-4">
                            {/* <NumberFormat className="form-control" displayType={'input'} thousandSeparator={true} decimalScale={2}></NumberFormat> */}
                            <input name="sodiem" type="number" className="form-control kieu-so" placeholder="Số điểm" value={soDiemNhiem} onChange={onchangeSoDiemNhiem} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label text-right">Tổng diện tích(ha)</label>
                        <div className="col-sm-4">
                            <NumberFormat className="form-control" value={dienTich} displayType={'input'} thousandSeparator={true} decimalScale={2} onChange={onchangeDienTich}></NumberFormat>
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-sm-12 text-right">
                        <button type="button" className="btn btn-success btn-sm" onClick={chuanDoan}>Chẩn đoán</button>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label text-right">Mức độ nhiễm</label>
                        <div className="col-sm-4">
                            <input name="tenxa" type="text" className="form-control" placeholder="Không nhiễm" value={ketQua?.MucDoNhiem} readOnly />
                        </div>
                        <label className="col-sm-2 col-form-label text-right">Diện tích nhiễm(ha)</label>
                        <div className="col-sm-4">
                            <NumberFormat className="form-control" value={ketQua?.DienTichNhiem} displayType={'input'} thousandSeparator={true} decimalScale={2} readOnly></NumberFormat>
                        </div>
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" size="sm" onClick={handleClose}><i className="fas fa-sign-out-alt"></i> Đóng</Button>
            </Modal.Footer>
            <Loading loading={loading}></Loading>
        </Modal>
    )
}
