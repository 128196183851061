const { default: axiosClient } = require("./axiosClient");

const UploadFileApi = {
    // getPaging: (params) => {
    //     const url = '/upload/paging';
    //     return axiosClient.get(url, {params});
    // },

    // getDetail: (id) =>{
    //     const url = '/upload/getbyid/' + id;
    //     return axiosClient.get(url);
    // },

    post: (data,idFolder) =>{
        const url = '/upload/addtaptin?idfolder='+idFolder;
        return axiosClient.post(url, data);
    }

    // put: (data) => {
    //     const url = '/upload/edit';
    //     return axiosClient.put(url, data);
    // },

    // delete: (id) =>{
    //     const url = '/upload/delete/' + id;
    //     return axiosClient.delete(url);
    // },
    // getAll: (params) =>{
    //     const url = 'upload/getall';
    //     return axiosClient.get(url, {params});
    // }
}

export default UploadFileApi;