import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom';
import axiosClient from '../../api/axiosClient';


export const Sidebar = () => {
    const [listFunctions, setFunction] = useState([]);
    const loadFunction = async () => {
        let response = await axiosClient.get('/function/functions-view');
        setFunction(response);
    }

    useEffect(() => {
        loadFunction();
    }, [])

    return (
        <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
            <a className="sidebar-brand d-flex align-items-center justify-content-center" href="#">
                <div className="sidebar-brand-icon">
                    <img className="logo" src="/img/logo.png"></img>
                </div>
                <div className="sidebar-brand-text mx-3">HỆ THỐNG</div>
            </a>
            <hr className="sidebar-divider my-0" />
            <li className="nav-item">
                <NavLink to="/main/home" className="nav-link collapsed">
                    <i className="fas fa-home" />
                    <span>Trang chủ</span>
                </NavLink>
            </li>
            <hr className="sidebar-divider" />
            {
                listFunctions.filter(x => x.ParentId === null).map((item, key) => {
                    let listChil = listFunctions.filter(x => x.ParentId === item.Id);
                    if (listChil.length === 0) {
                        return (
                            <>
                                <li className="nav-item">
                                    <NavLink to={item.Url} className="nav-link collapsed">
                                        <i className={item.CssClass} />
                                        <span>{item.Name}</span>
                                    </NavLink>
                                </li>
                                <hr className="sidebar-divider"/>
                            </>
                        )
                    }
                    return (
                        <>
                            <li className="nav-item">
                                <a className="nav-link collapsed" href={item.Url} data-toggle="collapse" data-target={'#menu' + key} aria-expanded="true" aria-controls={'menu' + key}>
                                    <i className={item.CssClass} />
                                    <span>{item.Name}</span>
                                </a>
                                <div id={'menu' + key} className="collapse" aria-labelledby="headingUtilities" data-parent="#accordionSidebar">
                                    <div className="bg-white py-2 collapse-inner rounded">
                                        <h6 className="collapse-header">{item.Name}</h6>
                                        {
                                            listChil.map((child, index) => {
                                                return (
                                                    <NavLink to={child.Url} className="collapse-item" key={index}>{child.Name}</NavLink>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </li>
                            <hr className="sidebar-divider" />
                        </>
                    )
                })
            }
        </ul>
    )
}
