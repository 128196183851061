const { default: axiosClient } = require("./axiosClient");

const NhatKyDangNhapApi = {
    getPaging: (params) => {
        const url = '/nhatkydangnhap/paging';
        return axiosClient.get(url, {params});
    },
    delete: (id) =>{
        const url = '/nhatkydangnhap/delete/' + id;
        return axiosClient.delete(url);
    }
}
  
export default NhatKyDangNhapApi;