import axiosClient from './axiosClient';

const ImportExcelSauBenhHaiApi = {

    saveFile: (params) => {
        const url = '/ImportExcelSauBenhHai/savefile';
        return axiosClient.post(url, params);
    },
}

export default ImportExcelSauBenhHaiApi;