import React, { useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import HoiDapApi from '../../../api/HoiDapApi';
import ThongBao from '../../../core/ThongBao';
import 'react-tabs/style/react-tabs.css';
import '../../../index.css'

export const TraLoi = (props) => {
    var apiUrl = process.env.REACT_APP_URL;
    var noImage = "/uploadfiles/website/no_image-100x100.jpg";
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
        props.closeModal();
    }
    const { register, handleSubmit, reset, errors } = useForm();
    const [srcImage, setSrcImage] = useState(noImage);
    const [currentItem, setCurrentItem] = useState({});

    const [idHoiDap, setIdHoiDap] = useState();
    const [listTraLoi, setListTraLoi] = useState([]);

    useEffect(() => {
        if (props.show === true) {
            setShow(true);
            setIdHoiDap(props.idHoiDap);
            loadHoiDaps(props.idHoiDap);
            var item = {
                Id: 0,
                NoiDung: ''
            };
            reset(item, [reset]);
        }
    }, [props.show]);

    const loadHoiDaps = async (id) => {
        try {
            const response = await HoiDapApi.getByCha(id);
            setCurrentItem(response);
            setListTraLoi(response.TraLois);
        } catch (err) {
        }
    }

    const saveData = (data) => {
        data.IdCayTrong = currentItem.IdCayTrong;
        data.IdCha = currentItem.Id;
        data.Status = true;
        if (data.Id === "0" || data.Id == null) {
            data.Id = 0;
            HoiDapApi.post(data).then(() => {
                loadHoiDaps(currentItem.Id);
                var item = {
                    Id: 0,
                    NoiDung: ''
                };
                reset(item, [reset]);
            })
        }
        else {
            HoiDapApi.put(data).then(() => {
                setShow(false);
                loadHoiDaps();
            })
        }
    }

    const handleShowEdit = (data) => {
        reset(data, [reset]);
    }

    const handleShowDel = (data) => {
        ThongBao.printConfirmationDialog("Bạn có đồng ý xóa dữ liệu đã chọn hay không?", () => {
            let lstId = [];
            lstId.push(data.Id);
            HoiDapApi.delete(lstId).then(() => {
                loadHoiDaps(currentItem.Id);
            });
        });
    }

    const fontLarge_Style = {
        fontSize: 'large'
    }

    const traLoi_Style = {
        backgroundColor: 'ghostwhite',
        padding: '20px',
        maxHeight: '250px',
        overflow: 'scroll'
    }

    const button_Style = {
        padding: '.25rem .25rem',
        lineHeight: '1',
        fontSize: '.875rem',
        borderRadius: '2rem',
        marginLeft: '.5rem'
    }

    const thumb_Style = {
        maxHeight: '100px',
        maxWidth: '100%',
        padding: '2px',
        border: '1px solid gainsboro'
    }

    return (
        <>
            <Modal show={show} onHide={handleClose} animation={false} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Trả lời</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group row">
                        <div className={currentItem.HinhAnhs != null?'col-sm-9':'col-sm-12'}>
                            <div className="form-group ">
                                <span className="thumbnail" >
                                    <img name="HinhAnhs" ref={register} src={apiUrl + srcImage} />
                                </span>
                                <span>
                                    <span className="col-sm-3 col-form-label text-primary font-weight-bold">{currentItem.FullName == null ? currentItem.HoTen : currentItem.FullName}</span>
                                </span>
                                <span>
                                    <span className="col-sm-3 col-form-label">{currentItem.TenCayTrong}</span>
                                </span>
                            </div>
                            <div className="form-group">
                                <div  >
                                    <span className="col-sm-3 col-form-label " style={fontLarge_Style}>{currentItem.ChuDe} </span>
                                </div>
                                <div >
                                    <span className="col-sm-3 col-form-label">{currentItem.NoiDung} </span>
                                </div>
                            </div>
                        </div>
                        {currentItem.HinhAnhs != null &&
                            <div className="col-sm-3">
                                <img name="HinhAnhs" style={thumb_Style} ref={register} src={apiUrl + currentItem.HinhAnhs} />
                            </div>
                        }
                    </div>
                    <hr></hr>
                    <div style={traLoi_Style}>
                        {listTraLoi.length == 0 &&
                            <>
                                <div className="form-group">
                                    Chưa có câu trả lời nào.
                            </div>
                            </>
                        }
                        {listTraLoi.length > 0 &&
                            listTraLoi.map((item, key) => {
                                return (
                                    <>
                                        <div className="form-group row">
                                            <div className="col-sm-9">
                                                <span className="thumbnail" >
                                                    <img name="HinhAnhs" ref={register} src={apiUrl + srcImage} />
                                                </span>
                                                <span>
                                                    <span className="col-sm-3 col-form-label text-primary font-weight-bold">{item.FullName == null ? item.HoTen : item.FullName}</span>
                                                </span>
                                            </div>
                                            <div className="col-sm-3 text-right">
                                                {item.FullName != null &&
                                                    <Button variant="secondary" style={button_Style} onClick={() => handleShowEdit(item)}><i className='fas fa-edit'></i></Button>
                                                }
                                                <Button variant="secondary" style={button_Style} onClick={() => handleShowDel(item)}><i className='fas fa-trash-alt'></i></Button>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div >
                                                <span className="col-sm-3 col-form-label">{item.NoiDung} </span>
                                            </div>
                                        </div>
                                        <hr></hr>
                                    </>
                                )
                            })
                        }
                    </div>
                    <hr></hr>
                    <form className="user" onSubmit={handleSubmit(saveData)}>
                        <input name="Id" hidden={true} ref={register} ></input>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Trả lời.
                                <span className="text-danger">*</span>
                            </label>
                            <div className="col-sm-9">
                                <textarea name="NoiDung" className="form-control textrea-size" rows="4" placeholder="Nhập nội dung" ref={register({ required: true })}  ></textarea>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" size="sm" onClick={handleSubmit(saveData)}>
                        Lưu</Button>
                    <Button variant="danger" size="sm" onClick={handleClose}>
                        Hủy</Button>
                </Modal.Footer>
            </Modal>



        </>
    )
}
