import jwt_decode from "jwt-decode"
import axiosClient from '../api/axiosClient';

const Authen = {
    logout: () => {
        const url = '/Account/logout';
        return axiosClient.post(url);
    },

    getUser: () => {
        let token = localStorage.getItem(process.env.REACT_APP_TOKEN);
        if (token != null) {
            let nv = jwt_decode(token);
            return {
                Id: nv.Id,
                UserName: nv.userName,
                FullName: nv.fullName,
                Email: nv.Email,
                Avatar: nv.avatar,
                maQuanHuyen:nv.maQuanHuyen,
                tatCaQuanHuyen:nv.tatCaQuanHuyen,
                idQuanHuyen: nv.idQuanHuyen
            };
        }
        return {};
    },

    checkAccess: () => {
        let token = localStorage.getItem(process.env.REACT_APP_TOKEN);
        if (token != null) {

            return true;
        }
        return false;
    },
    checkQuyen: (func) =>{
        const token = localStorage.getItem(process.env.REACT_APP_TOKEN);
        if(token != null){
            const nv = jwt_decode(token);
            const type = typeof nv.roles;
            if(type === "string"){
                if(nv.roles === "Admin"){
                    return true;
                }
            }
            else{
                if( nv.roles.findIndex(x=>x.Name==="Admin") !== -1)
                {
                    return true;
                };
            }
            var permissions = [];
            permissions = nv.permissions//JSON.parse(nv.permissions);
            if( permissions.findIndex(x=>x.Role === func) !== -1)
            {
                return true;
            }
            else
            {
                return false;
            }
        }
    }

}
export default Authen;