import axiosClient from './axiosClient';

const SlideApi = {

    createSlide: (params) => {
        const url = '/Slide/create';
        return axiosClient.post(url, params);

    },
    getDetail: (id) =>{
        const url = '/Slide/getbyid/' + id;
        return axiosClient.get(url);
    },

    updateSlide: (params) => {
        const url = '/Slide/update';
        return axiosClient.put(url, params);
    },

    DeleteSlide: (Id) => {
        const url = '/Slide/delete/' + Id;
        return axiosClient.delete(url);
    },

    getSlide: (params) => {
        const url = '/Slide/paging';
        return axiosClient.get(url, { params });
    },

    getAll: (params) => {
        const url = '/Slide/getall';
        return axiosClient.get(url, { params });
    }

}

export default SlideApi;