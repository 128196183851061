import React, { useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import ThuocBaoVeThucVatApi from '../../../api/ThuocBaoVeThucVatApi';
import Select from 'react-select';
import Pagination from 'react-bootstrap-4-pagination';
import ThongBao from '../../../core/ThongBao';
import Authen from '../../../core/Authen';
import Ultility from '../../../core/Ultility';
import { QuanLyTapTin } from '../../../shared/quanlytaptin/QuanLyTapTin';
import * as XLSX from 'xlsx';
import CayTrongApi from '../../../api/CayTrongApi';

export const ThuocBaoVeThucVat = () => {
    var apiUrl = process.env.REACT_APP_URL;
    var noImage = "/uploadfiles/website/no_image-100x100.jpg";
    const [srcImage, setSrcImage] = useState(noImage);
    const [listThuocBaoVeThucVat, setThuocBaoVeThucVat] = useState([]);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const { register, handleSubmit, reset, errors } = useForm();
    const [filter, setFilter] = useState('');
    const [roleAdd, setRoleAdd] = useState(false);
    const [roleEdit, setRoleEdit] = useState(false);
    const [roleDel, setRoleDel] = useState(false);
    const [checkedRow, setCheckedRow] = useState(false);
    const [viewModal, setViewModal] = useState(false);
    const [selectedCay, setSelectedCay] = useState();
    const [danhMucCay, setDanhMucCay] = useState([]);
    let pageIndex = 1;
    let pageSize = 20;
    const [paginationConfig, setPaging] = useState({
        totalPages: 1,
        currentPage: 1,
        showMax: 5,
        size: "sm",
        threeDots: true,
        prevNext: true,
        onClick: function (page) {
            pageIndex = page;
            loadThuocBaoVeThucVats();
        }
    });

    var theme = {
        borderRadius: 0,
        height: 30,
        spacing: {
            controlHeight: 30,
            menuGutter: 1,
            baseUnit: 2,
        }
    }

    function loadDanhMucCay() {
        CayTrongApi.getAll().then((response) => {
            let list = [];
            list.push({ value: null, label: 'Chọn cây trồng...' });
            response.forEach(item => {
                list.push({ value: item.Id, label: item.TenCayTrong });
            })
            setDanhMucCay(list);
        })
    }

    const loadThuocBaoVeThucVats = async () => {
        try {
            const params = {
                pageIndex: pageIndex,
                pageSize: pageSize,
                filter: filter
            }
            const response = await ThuocBaoVeThucVatApi.getPaging(params);
            response.Items.forEach(item => {
                item.Checked = false;
            });
            paginationConfig.totalPages = response.Items.length > 0 ? Math.ceil(response.TotalRow / pageSize) : 1;
            paginationConfig.currentPage = pageIndex;
            paginationConfig.onClick = function (page) {
                pageIndex = page;
                loadThuocBaoVeThucVats();
            }
            setPaging(paginationConfig);
            setThuocBaoVeThucVat(response.Items);
            setCheckedRow(false);
        } catch (err) {
        }
    }

    useEffect(() => {
        loadThuocBaoVeThucVats();
        loadDanhMucCay();
        setRoleAdd(Authen.checkQuyen('THUOCBAOVETHUCVAT_CREATE'));
        setRoleEdit(Authen.checkQuyen('THUOCBAOVETHUCVAT_UPDATE'));
        setRoleDel(Authen.checkQuyen('THUOCBAOVETHUCVAT_DELETE'));
    }, []);

    const saveData = (data) => {
        data.HinhAnh = srcImage;
        data.IdCayTrong = selectedCay == null? null: selectedCay.value;
        if (data.Id === "0") {
            ThuocBaoVeThucVatApi.post(data).then(() => {
                setShow(false);
                loadThuocBaoVeThucVats();
            })
        }
        else {
            ThuocBaoVeThucVatApi.put(data).then(() => {
                setShow(false);
                loadThuocBaoVeThucVats();
            })
        }
    }

    const handleShowAdd = () => {
        reset({
            Id: 0,
            Ten: "",
            IdCayTrong: null,
            Status: true
        }, [reset]);
        setSelectedCay(null);
        setSrcImage(noImage);
        setShow(true)
    };

    const handleShowEdit = () => {
        let index = listThuocBaoVeThucVat.findIndex(x => x.Checked);
        if (index !== -1) {
            ThuocBaoVeThucVatApi.getDetail(listThuocBaoVeThucVat[index].id).then((response) => {
                const index = danhMucCay.findIndex(x=>x.value == response.IdCayTrong);
                if(index != -1){
                    setSelectedCay(danhMucCay[index]);
                }
                else{
                    setSelectedCay(null);
                }
                reset(response, [reset]);
                setSrcImage(response.HinhAnh);
                setShow(true);
            });
        }
    }

    const handleShowDel = () => {
        ThongBao.printConfirmationDialog("Bạn có đồng ý xóa dữ liệu đã chọn hay không?", () => {
            let lstChecked = listThuocBaoVeThucVat.filter(x => x.Checked);
            if (lstChecked.length > 0) {
                let lstId = [];
                lstChecked.forEach(element => {
                    lstId.push(element.id);
                });
                ThuocBaoVeThucVatApi.delete(lstId).then(() => {
                    loadThuocBaoVeThucVats();
                })
            }
        });
    }

    const timKiem = () => {
        loadThuocBaoVeThucVats();
    }

    function onChangeFilter(e) {
        setFilter(e.target.value)
    }

    const clickRow = (id) => {
        listThuocBaoVeThucVat.forEach(item => {
            item.Checked = false;
            if (item.id === id) {
                item.Checked = true;
            }
        });
        if (listThuocBaoVeThucVat.findIndex(x => x.Checked == true) >= 0) {
            setCheckedRow(true);
        }
        else {
            setCheckedRow(false);
        }
        setThuocBaoVeThucVat([...listThuocBaoVeThucVat]);
    }

    const checkBoHandler = () => {

    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            loadThuocBaoVeThucVats();
        }
    }

    const openModalImage = () => {
        setViewModal(true);
    }

    const closeModalImage = () => {
        setViewModal(false);
    }

    const onFileSelected = (event) => {
        setSrcImage(event);
    }
    const onChangeDanhMucCay = (e) => {
        setSelectedCay(e);
    }

    return (
        <div className="card shadow mb-1">
            <div className="card-header py-2 row">
                <div className="col-md-4">
                    <h6 className="m-0 font-weight-bold text-primary">Thuốc bảo vệ thực vật</h6>
                </div>
                <div className="input-group col-md-4">
                    <input type="text" className="form-control" placeholder="Tìm kiếm" value={filter} onChange={onChangeFilter} onKeyPress={handleKeyPress} />
                    <div className="input-group-append">
                        <button className="btn btn-info" type="button" onClick={timKiem}><i className='fas fa-search'></i> Tìm kiếm</button>
                    </div>
                </div>
                <div className="col-md-4 bd-button text-right">
                    {roleAdd == true ? <Button variant="success" size="sm" onClick={handleShowAdd}><i className='fas fa-plus'></i> Thêm</Button> : ''}
                    {roleEdit == true ? <Button variant="primary" size="sm" onClick={handleShowEdit} disabled={!checkedRow}><i className='far fa-edit'></i> Sửa</Button> : ''}
                    {roleDel == true ? <Button variant="danger" size="sm" onClick={handleShowDel} disabled={!checkedRow}><i className='fas fa-trash-alt'></i> Xóa</Button> : ''}
                </div>
            </div>
            <div className="card-body">
                <div className="import-body table-import import-scroll">
                    <table className="table table-bordered" width="100%">
                        <thead>
                            <tr>
                                <th className="col-checkbox"></th>
                                <th>Hình ảnh</th>
                                <th>Mã thuốc</th>
                                <th>Tên thuốc</th>
                                <th>Cây trồng</th>
                                <th>Mô tả</th>
                                <th>Ghi chú</th>
                                <th>Trạng thái</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                listThuocBaoVeThucVat.map((item, key) => {
                                    return (
                                        <tr onClick={() => clickRow(item.id)} key={key}>
                                            <td className="col-checkbox">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" checked={item.Checked} onChange={checkBoHandler} />
                                                </div>
                                            </td>
                                            <td className="col-img"><img src={apiUrl + item.hinhanh}></img></td>
                                            <td>{item.ma}</td>
                                            <td>{item.ten}</td>
                                            <td>{item.caytrong}</td>
                                            <td>{item.mota}</td>
                                            <td>{item.ghichu}</td>
                                            <td className="text-center">
                                                {item.status == true &&
                                                    <span className="label label-success cls-status-label">Kích hoạt</span>
                                                }
                                                {item.status == false &&
                                                    <span className="label label-danger cls-status-label">Khóa</span>
                                                }
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <div className="col-md-12 text-right">
                    <Pagination {...paginationConfig} />
                </div>
            </div>

            <Modal show={show} onHide={handleClose} animation={false} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Thuốc bảo vệ thực vật</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="user" onSubmit={handleSubmit(saveData)}>
                        <input name="Id" hidden={true} ref={register}></input>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Tên thuốc
                                <span className="text-danger"> *</span>
                            </label>
                            <div className="col-sm-9">
                                <input name="Ten" type="text" className="form-control" placeholder="Tên thuốc" ref={register({ required: true })} />
                                {errors.Ten && <span className="text-danger">Hãy nhập dữ liệu</span>}
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Mã thuốc</label>
                            <div className="col-sm-9">
                                <input name="Ma" type="text" className="form-control" placeholder="Mã thuốc" ref={register} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Cây trồng
                            </label>
                            <div className="col-sm-9">
                                <Select
                                    className="select"
                                    name="IdCayTrong"
                                    placeholder="Chọn cây trồng"
                                    value={selectedCay}
                                    onChange={onChangeDanhMucCay}
                                    options={danhMucCay}
                                    ref={register}
                                    theme={theme}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Mô tả</label>
                            <div className="col-sm-9">
                                <textarea name="MoTa" className="form-control textrea-size" rows="3" placeholder="Nhập mô tả" ref={register}  ></textarea>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Ghi chú</label>
                            <div className="col-sm-9">
                                <input name="GhiChu" type="text" className="form-control" placeholder="Ghi chú" ref={register} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Hình ảnh</label>
                            <div className="col-sm-9">
                                <div className="thumbnail" >
                                    <img name="Image" ref={register} src={apiUrl + srcImage} onClick={openModalImage} />
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label"></label>
                            <div className="col-sm-9">
                                <label className="custom-checkbox checkbox-style" >
                                    <input className="form-check-input" name="Status" type="checkbox" ref={register} />
                                    <span className="helping-el"></span> Kích hoạt
                                </label>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" size="sm" onClick={handleSubmit(saveData)}>
                        Lưu</Button>
                    <Button variant="danger" size="sm" onClick={handleClose}>
                        Hủy</Button>
                </Modal.Footer>
            </Modal>
            <QuanLyTapTin viewModal={viewModal} closeModal={closeModalImage} onFileSelected={(e) => onFileSelected(e)}></QuanLyTapTin>
        </div>

    )
}
