import React, { useState, useEffect } from 'react'
import Pagination from 'react-bootstrap-4-pagination';
import CayTrongApi from '../../../api/CayTrongApi';
import ThuocBaoVeThucVatApi from '../../../api/ThuocBaoVeThucVatApi';
import Select from 'react-select';
import ExportExcelApi from '../../../api/ExportExcelApi';

export const ThongKeThuocBaoVeThucVat = (props) => {
    var apiUrl = process.env.REACT_APP_URL;
    var noImage = "/uploadfiles/website/no_image-100x100.jpg";

    const [listThuocBaoVeThucVats, setListThuocBaoVeThucVat] = useState([]);
    const [timListCayTrong, setTimListCayTrong] = useState([]);
    const [timCayTrong, setTimCayTrong] = useState({ value: 0, label: 'Chọn cây trồng...' });
    const [search, setSearch] = useState('');

    let pageIndex = 1;
    let pageSize = 20;

    const [paginationConfig, setPaging] = useState({
        totalPages: 1,
        currentPage: 1,
        showMax: 5,
        size: "sm",
        threeDots: true,
        prevNext: true,
        onClick: function (page) {
            pageIndex = page;
            loadThuocBaoVeThucVat();
        }
    });

    useEffect(() => {
        loadCayTrong();
        loadThuocBaoVeThucVat();
    }, []);

    const loadCayTrong = async () => {
        await CayTrongApi.getAll().then((cay) => {
            let list = [];
            list.push({ value: 0, label: 'Chọn cây trồng...' });
            cay.forEach(item => {
                list.push({ value: item.Id, label: item.TenCayTrong, ma: item.MaCayTrong });
            });
            setTimListCayTrong(list);
        })
    }

    const loadThuocBaoVeThucVat = async () => {
        try {
            const params = {
                filter: search,
                pageIndex: pageIndex,
                idCayTrong:timCayTrong?.value,
                pageSize: pageSize
            }
            const response = await ThuocBaoVeThucVatApi.getThongKe(params);
            paginationConfig.totalPages = 1;
            if (response.Items.length > 0) {
                paginationConfig.totalPages = Math.ceil(response.TotalRow / pageSize);
            }
            paginationConfig.currentPage = pageIndex;
            setPaging(paginationConfig);
            setListThuocBaoVeThucVat(response.Items);
        } catch (err) {
        }
    }

    const ExportExcel = async () => {
        try {
            const params = {
                filter: search,
                pageIndex: pageIndex,
                idCayTrong:timCayTrong?.value,
                pageSize: pageSize
            }
            ExportExcelApi.getThuocBvThucVat(params).then((res) => {
                const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                
                a.download = 'download.xlsx';
                a.click();
            }, () => {
                
            });
        } catch (err) {
        }
    }

    const exportExcel = () =>{
        ExportExcel();
    }

    const timKiem = () => {
        loadThuocBaoVeThucVat();
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            loadThuocBaoVeThucVat();
        }
    }

    const setFilter = (e) => {
        setSearch(e.target.value)
    }

    const onChangeTimCayTrong = (e) => {
        setTimCayTrong(e);
    }

    return (

        <div className="card shadow mb-1">
            {
                props.showName == true ? <div className="row">
                    <div className="col-md-12">
                        <div className="breadcrumb-container">
                            <a href="/" className="breadcrumb-dis">Trang chủ / </a>
                            <a href="/public/thuoc-bao-ve-thuc-vat" className="breadcrumb-active"> Thuốc bảo vệ thực vật</a>
                            <div className="breadcrumb-line">
                            </div>
                        </div>
                    </div>
                </div> : ''
            }
            <div className="card-header row h-solieu">
                <div className="col-md-3">
                    <Select placeholder="Cây trồng"
                        onChange={onChangeTimCayTrong} options={timListCayTrong} value={timCayTrong} theme={theme => ({
                            ...theme,
                            borderRadius: 0,
                            height: 30,
                            spacing: {
                                controlHeight: 30,
                                menuGutter: 1,
                                baseUnit: 2,
                            }
                        })} />
                </div>
                <div className="col-md-3">
                    <div className="input-group">
                        <input type="text" className="form-control" placeholder="Tìm kiếm" value={search} onChange={setFilter} onKeyPress={handleKeyPress} />
                        <div className="input-group-append">
                            <button className="btn btn-info btn-sm" type="button" onClick={timKiem} ><i className='fas fa-search'></i></button>
                            <button className="btn btn-primary btn-sm" type="button" onClick={exportExcel} ><i className='fas fa-file-excel'></i> Excel</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card-body">
                <div id="div-table">
                    <table className="table table-bordered" width="100%">
                        <thead>
                            <tr>
                                <th>Cây trồng</th>
                                <th>Ảnh</th>
                                <th>Tên</th>
                                <th>Mô tả</th>
                                <th>Ghi chú</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                listThuocBaoVeThucVats.map((item, key) => {
                                    return (
                                        <tr key={key}>
                                            <td style={{width: '120px'}}>{item.TenCayTrong}</td>
                                            <td>
                                                <img src={apiUrl + (item.HinhAnh == null ? noImage: item.HinhAnh)} style={{ maxHeight: '60px', maxWidth: '60px'}}></img>
                                            </td>
                                            <td>{item.Ten}</td>
                                            <td>{item.MoTa}</td>
                                            <td>{item.GhiChu}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <div className="col-md-12 text-right">
                    <Pagination {...paginationConfig} />
                </div>
            </div>
        </div>
    )


}