import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import QuanHuyenApi from '../../api/QuanHuyenApi';


export const SelectXa = (props) => {

    const [listXas, setXa] = useState([]);
    const [selectedOption, setSelectedOption] = useState();

    useEffect(() => {
        if (props.maHuyen == null) {
            setXa([]);
        }
        else {
            loadXa();
        }
    }, [props.maHuyen]);
    useEffect(() => {
        if (listXas.length > 0) {
            const index = listXas.findIndex(x => x.value == props.maXa);
            if (index != -1) {
                setSelectedOption(listXas[index]);
                props.onChangeXaProps(listXas[index]);
            }
        }
    }, [props.maXa])

    const loadXa = () => {
        let params = {
            maHuyen: props.maHuyen
        }
        QuanHuyenApi.getXaByHuyen(params).then((response) => {
            let list = [];
            response.forEach(item => {
                list.push({ value: item.maXa, label: item.tenXa });
                if (props.maXa == item.maXa) {
                    setSelectedOption({ value: item.maXa, label: item.tenXa });
                    props.onChangeXaProps({ value: item.maXa, label: item.tenXa });
                }
            });
            setXa(list);
        })
    }

    const onChangeXa = (e) => {
        setSelectedOption(e);
        props.onChangeXaProps(e);
    }

    return (
        <Select
            className="select"
            name="IdQuanHuyen"
            placeholder="Chọn huyện"
            value={selectedOption}
            onChange={onChangeXa}
            options={listXas}
            theme={theme => ({
                ...theme,
                borderRadius: 0,
                height: 30,
                spacing: {
                    controlHeight: 30,
                    menuGutter: 1,
                    baseUnit: 2,
                }
            })}
        />
    )
}
