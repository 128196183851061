import React, { useEffect, useState } from 'react'
import stylelocal from "./TimKiemNangCao.module.css"
import Select from 'react-select';
import ExportExcelApi from '../../api/ExportExcelApi';
import { Loading } from '../loading/Loading';
import { TimKiemVungDuAn } from './VungDuAn';
import { TimKiemVungLua } from './VungLua';
import { TimKiemVungCaq } from './VungCaq';
import { TimKiemVungRau } from './VungRau';
import { TimKiemBaoVeThucVat } from './BaoVeThucVat';
export const TimKiemNangCao = (props) => {
    const [listLopBanDos, setListLopBanDo] = useState([]);
    const [selectedOption, setSelectedOption] = useState({});
    const [loading, setLoading] = useState(false);
    const [queryHuyen, setQueryHuyen] = useState('');
    const width = 350;
    const [xPosition, setX] = React.useState(-width);
    const [filterXa, setFilterXa] = useState('');
    const toggleMenu = () => {
        if (xPosition < -5) {
            setX(-5);
        } else {
            setX(-width);
        }
    };

    useEffect(() => {
        if (props.show > 0) {
            toggleMenu();
        }
    }, [props.show]);

    useEffect(() => {
        let list = [];
        props.listLopBanDos.forEach(item => {
            if (item.Type > 1 && item.Checked && item.Ma !== 'BDHANHCHINH' && item.ParentId !== 'BDHANHCHINH') {
                list.push({ value: item.Ma, label: item.Name });
            }
        });
        if (list.length > 0) {
            if (selectedOption.value == null) {
                setSelectedOption(list[0]);
            }
            else {
                if (list.findIndex(x => x.value === selectedOption.value) === -1) {
                    setSelectedOption(list[0]);
                }
            }
        }
        else {
            setSelectedOption({});
        }
        setListLopBanDo(list);
    }, [props.listLopBanDos])

    useEffect(() => {

        let query = '';
        let queryXa = '';
        if (props.listHuyens?.length > 0 && props.listHuyens !== null) {
            queryXa = `${props.listHuyens.map(x => x.Ma).join(',')}`;
            query = `mahuyen IN (${props.listHuyens.map(x => `'${x.Ma}'`).join(',')})`;

        } else { queryXa = '' }

        setQueryHuyen(query);
        setFilterXa(queryXa);
    }, [props.listHuyens])


    const onChangeLopBanDo = (e) => {
        setSelectedOption(e);
    }

    const onTimKiem = (sql) => {
        props.onTimKiemNangCao({ Ma: selectedOption.value, Sql: sql });
    }

    const onExportExcel = (sql) => {
        const ma = selectedOption.value;
        // const queryHuyen = `"maHuyen" IN (${props.listHuyens.map(x => x.Ma).join(',')})`;
        // sql = sql === '' ? queryHuyen : `${sql} AND ${queryHuyen}`;
        let params = {
            sql: sql,
            ma: ma
        }
        // console.log(sql);
        setLoading(true);
        ExportExcelApi.getTimKiemThongKeBanDo(params).then((res) => {
            const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            setLoading(false);
            a.download = 'download.xlsx';
            a.click();
        }, () => {
            setLoading(false);
        });
    }

    const timKiemLoading = (e) => {
        setLoading(e);
    }

    const timKiemGetChiTiet = (e) => {
        props.loadThongTinById(e);
    }


    return (
        <React.Fragment>
            <div
                className={stylelocal.sidebar}
                style={{
                    transform: `translatex(${xPosition}px)`,
                    width: width,
                }}
            >
                {
                    xPosition === -5 ? <button
                        onClick={() => toggleMenu()}
                        className={stylelocal.togglemenu}
                        style={{
                            transform: `translate(${344}px)`
                        }}
                    >
                        <i className="fas fa-chevron-left" />
                    </button>
                        : ''
                }
                <div className={stylelocal.sidecontent}>
                    <div className="row">
                        <div className="col-md-12">
                            <h5 className={stylelocal.tieude}>TÌM KIẾM NÂNG CAO</h5>
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-md-12">
                            <Select
                                className="select"
                                name="idLopBanDo"
                                placeholder="Lớp bản đồ"
                                value={selectedOption}
                                onChange={onChangeLopBanDo}
                                options={listLopBanDos}
                                theme={theme => ({
                                    ...theme,
                                    borderRadius: 0,
                                    height: 30,
                                    spacing: {
                                        controlHeight: 30,
                                        menuGutter: 1,
                                        baseUnit: 2,
                                    }
                                })}
                                styles={{ container: (base) => ({ ...base, zIndex: 500 }) }}
                            />
                        </div>
                    </div>
                    {
                        selectedOption.value === 'BDVUNGDUAN' ? <TimKiemVungDuAn timKiem={(e) => onTimKiem(e)} exportExcel={(e) => onExportExcel(e)}
                        timKiemLoading={(e) => timKiemLoading(e)} timKiemGetChiTiet={(e) => timKiemGetChiTiet(e)} queryHuyen={queryHuyen} filterXa={filterXa}></TimKiemVungDuAn> : ''
                    }
                     {
                        selectedOption.value === 'BDCAYLUA' ? <TimKiemVungLua timKiem={(e) => onTimKiem(e)} exportExcel={(e) => onExportExcel(e)}
                        timKiemLoading={(e) => timKiemLoading(e)} timKiemGetChiTiet={(e) => timKiemGetChiTiet(e)} queryHuyen={queryHuyen} filterXa={filterXa}></TimKiemVungLua> : ''
                    }
                     {
                        selectedOption.value === 'BDCAYRAU' ? <TimKiemVungRau timKiem={(e) => onTimKiem(e)} exportExcel={(e) => onExportExcel(e)}
                        timKiemLoading={(e) => timKiemLoading(e)} timKiemGetChiTiet={(e) => timKiemGetChiTiet(e)} queryHuyen={queryHuyen} filterXa={filterXa}></TimKiemVungRau> : ''
                    }
                    {
                        selectedOption.value === 'BDCAYCAQ' ? <TimKiemVungCaq timKiem={(e) => onTimKiem(e)} exportExcel={(e) => onExportExcel(e)}
                        timKiemLoading={(e) => timKiemLoading(e)} timKiemGetChiTiet={(e) => timKiemGetChiTiet(e)} queryHuyen={queryHuyen} filterXa={filterXa}></TimKiemVungCaq> : ''
                    }
                     {
                        selectedOption.value === 'BDBAOVETHUCVAT' ? <TimKiemBaoVeThucVat timKiem={(e) => onTimKiem(e)} exportExcel={(e) => onExportExcel(e)}
                        timKiemLoading={(e) => timKiemLoading(e)} timKiemGetChiTiet={(e) => timKiemGetChiTiet(e)} queryHuyen={queryHuyen} filterXa={filterXa}></TimKiemBaoVeThucVat> : ''
                    }
                    {

                        selectedOption.value === undefined ? <h5 className="text-center">Bạn chưa chọn lớp bản đồ</h5> : ''
                    }
                </div>
            </div>
            <Loading loading={loading}></Loading>
        </React.Fragment>
    )
}
