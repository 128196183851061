import React, { useState, useEffect } from 'react';
import SauBenhApi from '../../api/SauBenhApi';

export default function SauBenhChiTiet(props) {
    var apiUrl = process.env.REACT_APP_API_URL.replace('/api', '');
    const [sauBenh, setSauBenh] = useState({});
    const [trieuChung, setTrieuChung] = useState([]);

    const loadSauBenh = async (id) => {
        const res = await SauBenhApi.timKiemSauBenhChiTiet(id);
        let lst = {};
        if (res != undefined) {
            lst = res;
        }
        setSauBenh(lst);
        setTrieuChung(lst.TrieuChungs);
    }
    useEffect(() => {
        loadSauBenh(props.match.params.IdSauBenh);
    }, [props.match.params.IdSauBenh]);

    return (
        <div>
            {sauBenh.length == 0 ? '' :
                <>
                    <div className="col-md-12">
                        <h5>{sauBenh.TenSauBenh}</h5>
                    </div>
                    <div className="col-md-12">
                        Nhiệt độ : {sauBenh.NhietDoMin} - {sauBenh.NhietDoMax}
                    </div>
                    <div className="col-md-12">
                        Các tháng trong năm: {sauBenh.ThoiGian}
                    </div>
                    <div className="sau-benh-chi-tiet">
                        {sauBenh.HinhAnh != null ? <img src={apiUrl + sauBenh.HinhAnh}></img> : ''}
                    </div>
                    <div className="col-md-12">
                        {sauBenh.MoTa}
                    </div>
                    <div className="col-md-12 mt-2" style={{ fontWeight: 'bold' }}>
                        Các giai đoạn phát triển:
                            </div>
                    {
                        !trieuChung.length == false ? trieuChung.map((x, key) => {
                            return (
                                <div key={key}>
                                    <div className="col-md-12 ml-2" style={{ fontWeight: 'bold' }}>
                                        {x.TenGiaiDoan + ':'}
                                    </div>
                                    <div className="col-md-12 ml-2 sau-benh-chi-tiet-hinh-anh-trieu-chung">
                                        <div className="text-center">
                                            {x.HinhAnhTrieuChung == null ? '' :
                                                x.HinhAnhTrieuChung.split(',').map((x, keyImage) => {
                                                    return (
                                                        x == null || x == '' ? '' : <img src={apiUrl + x} key={keyImage}></img>
                                                    )
                                                })
                                            }
                                        </div>

                                    </div>
                                    <div className="col-md-12 ml-2">
                                        {' - ' + x.MoTaTrieuChung}
                                    </div>
                                </div>
                            )
                        }) : ''
                    }
                    <div className="col-md-12 mt-2" style={{ fontWeight: 'bold' }}>
                        Các giai đoạn phát triển:
                    </div>
                    <div className="col-md-12 ml-2">
                        {' - ' +sauBenh.BienPhapCanhTac}
                    </div>
                    <div className="col-md-12 mt-2" style={{ fontWeight: 'bold' }}>
                        Các giai đoạn phòng trừ:
                    </div>
                    <div className="col-md-12 ml-2 mb-2">
                        {' - ' +sauBenh.BienPhapPhongTru}
                    </div>
                </>
            }
        </div >
    )
}
