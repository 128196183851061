import React, { useState, useEffect } from 'react'
import { Fade, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import TrieuChungApi from '../../../api/TrieuChungApi';
import '../../../css/apppublic.css';
import '../../../css/reactconfirm.css';
import '../../../css/reactselect.css';
import Pagination from 'react-bootstrap-4-pagination';
import Authen from '../../../core/Authen';
import { Loading } from '../../../shared/loading/Loading';
import ThongBao from '../../../core/ThongBao';
import { QuanLyTapTin } from '../../../shared/quanlytaptin/QuanLyTapTin';
import SauBenhApi from '../../../api/SauBenhApi';
import GiaiDoanPhatTrienApi from '../../../api/GiaiDoanPhatTrienApi';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


export const DacDiemTrieuChung = () => {

    const { handleSubmit, reset, register, errors } = useForm();

    var apiUrl = process.env.REACT_APP_API_URL.replace('/api', '');
    var noImage = "/uploadfiles/website/no_image-100x100.jpg";

    const [listTrieuChung, setListTrieuChung] = useState([]);
    const [sauBenh, setSauBenh] = useState('');
    const [giaiDoan, setGiaiDoan] = useState('');
    const [show, setShow] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isXem, setIsXem] = useState(false);
    const [search, setSearch] = useState('');
    const [roleAdd, setRoleAdd] = useState(false);
    const [roleEdit, setRoleEdit] = useState(false);
    const [roleDel, setRoleDel] = useState(false);
    const [loading, setLoading] = useState(false);
    let pageIndex = 1, pageSize = 20;
    const [disButtonEdit, setDisButtonEdit] = useState(true);
    const [viewModal, setViewModal] = useState(false);
    const [srcImage, setSrcImage] = useState(noImage);
    const [loai, setLoai] = useState({});
    const [dacDiem, setDacDiem] = useState({});
    const [moTa, setMoTa] = useState('');
    const [listSauBenh, setListSauBenh] = useState([]);

    const [optionSauBenhs, setOptionSauBenh] = useState([{ value: 0, label: 'Chọn sâu bệnh' }]);
    const [optionGiaiDoans, setOptionGiaiDoan] = useState([{ value: 0, label: 'Chọn giai đoạn' }]);

    const optionLoais = [
        { value: true, label: 'Đặc điểm sinh học' },
        { value: false, label: 'Đặc điểm triệu chứng' },
    ];

    const [paginationConfig, setPaginationConfig] = useState({
        totalPages: 1,
        currentPage: 1,
        showMax: 5,
        size: 'sm',
        threeDots: true,
        prevNext: true,
        onClick: function (page) {
            if (page != null) {
                pageIndex = page;
                loadTrieuChung();
            }
        }
    });

    const closeModalImage = () => {
        setViewModal(false);
    }

    const openModalImage = () => {
        setViewModal(true);
    }

    const onFileSelected = (event) => {
        setSrcImage(event);
    }

    const onChangeSelect = (optionSelected) => {
        setSauBenh(optionSelected);
    }


    const onChangeSelectGiaiDoan = (optionSelected) => {
        setGiaiDoan(optionSelected);
        if (!listSauBenh.length == false) {
            let lstSau = listSauBenh.filter(x => x.IdGiaiDoan === optionSelected.value);
            let opt = [{ value: 0, label: 'Chọn sâu bệnh' }]
            lstSau.forEach(x => {
                opt.push({ 'value': x.Id, 'label': x.TenSauBenh });
            });
            setOptionSauBenh(opt);
            setSauBenh({ value: 0, label: 'Chọn sâu bệnh' });
        }

    }

    const loadTrieuChung = async () => {
        try {
            const params = {
                filter: search,
                pageIndex: pageIndex,
                pageSize: pageSize
            }
            const response = await TrieuChungApi.getPaging(params);
            response.Items.forEach((x) => {
                x.Checked = false
            });
            /* setDisButtonEdit(true); */
            setListTrieuChung(response.Items);
            paginationConfig.totalPages = Math.ceil(response.TotalRow == 0 ? 1 : response.TotalRow / pageSize);
            paginationConfig.currentPage = pageIndex;
            setPaginationConfig(paginationConfig);
        }
        catch (err) { }
    }

    const rowClick = (id) => {
        listTrieuChung.forEach(element => {
            if (element.Id === id) {
                if (element.Checked === true) {
                    element.Checked = false;
                }
                else {
                    element.Checked = true;
                    setIsEdit(true);
                    setDisButtonEdit(false);
                }
            }
            else {
                element.Checked = false;
            }
        });

        if (listTrieuChung.filter(element => element.Checked === true).length == 0) {
            setIsEdit(false);
            setDisButtonEdit(true);
        };

        setIsEdit(true);
        setListTrieuChung([...listTrieuChung]);
    }

    const handleClose = () => {
        setShow(false);
    }

    const handleShowAdd = () => {
        reset({
            Id: 0,
            Status: true
        }, [reset]);
        setDacDiem({
            Id: 0,
            Status: true
        });
        setSrcImage(noImage);
        setIsEdit(false);
        setIsXem(false);
        setMoTa('');
        setSauBenh({ value: 0, label: 'Chọn sâu bệnh' });
        setGiaiDoan({ value: 0, label: 'Chọn giai đoạn' });
        setLoai({ value: true, label: 'Đặc điểm sinh học' });
        setShow(true);
    }

    const handleShowEdit = () => {
        setIsEdit(true);
        setIsXem(false);
        let index = listTrieuChung.findIndex(x => x.Checked);
        if (index !== -1) {
            TrieuChungApi.getDetail(listTrieuChung[index].Id).then((response) => {
                reset(
                    {
                        Id: response.Id,
                        TenTrieuChung: response.TenTrieuChung,
                        TenKhoaHoc: response.TenKhoaHoc,
                        GhiChu: response.GhiChu,
                        Status: response.Status,
                        ThuTu: response.ThuTu,
                        DonViTinh: response.DonViTinh,
                        TieuChuan: response.TieuChuan
                    }
                    , [reset]);
                setDacDiem(response);
                setSrcImage(response.HinhAnh);
                setSauBenh(optionSauBenhs.filter(x => x.value == response.IdSauBenh).length == 0 ? { value: 0, label: 'Chọn sâu bệnh' } : optionSauBenhs.filter(x => x.value == response.IdSauBenh)[0]);
                setGiaiDoan(optionGiaiDoans.filter(x => x.value == response.IdGiaiDoan).length == 0 ? { value: 0, label: 'Chọn giai đoạn' } : optionGiaiDoans.filter(x => x.value == response.IdGiaiDoan)[0]);
                setLoai(response.Loai == undefined ? { value: true, label: 'Đặc điểm sinh học' } : optionLoais.filter(x => x.value == response.Loai)[0]);
                setMoTa(response.MoTa);
                setIsXem(false);
                setShow(true);
            });
        }
    }

    const handleShowData = () => {
        setIsXem(true);
        let index = listTrieuChung.findIndex(x => x.Checked);
        if (index !== -1) {
            TrieuChungApi.getDetail(listTrieuChung[index].Id).then((response) => {
                reset(reset(
                    {
                        Id: response.Id,
                        TenTrieuChung: response.TenTrieuChung,
                        TenKhoaHoc: response.TenKhoaHoc,
                        GhiChu: response.GhiChu,
                        Status: response.Status,
                        ThuTu: response.ThuTu,
                        DonViTinh: response.DonViTinh,
                        TieuChuan: response.TieuChuan
                    }
                    , [reset]), [reset]);
                setDacDiem(response);
                setSrcImage(response.HinhAnh);
                setSauBenh(optionSauBenhs.filter(x => x.value == response.IdSauBenh).length == 0 ? { value: 0, label: 'Chọn sâu bệnh' } : optionSauBenhs.filter(x => x.value == response.IdSauBenh)[0]);
                setGiaiDoan(optionGiaiDoans.filter(x => x.value == response.IdGiaiDoan).length == 0 ? { value: 0, label: 'Chọn giai đoạn' } : optionGiaiDoans.filter(x => x.value == response.IdGiaiDoan)[0]);
                setLoai(response.Loai == undefined ? { value: true, label: 'Đặc điểm sinh học' } : optionLoais.filter(x => x.value == response.Loai)[0]);
                setMoTa(response.MoTa);
                setShow(true);
            });
        }
    }



    const onSubmit = async (data) => {
        try {
            var fielPath = '';

            dacDiem.HinhAnh = srcImage;
            dacDiem.IdSauBenh = sauBenh.value;
            dacDiem.IdGiaiDoan = giaiDoan.value;
            dacDiem.MoTa = moTa;
            if (!isEdit) {
                console.log(dacDiem);
                const res = await TrieuChungApi.post(dacDiem);
            }
            else {
                console.log(dacDiem);
                const res = await TrieuChungApi.put(dacDiem);
            }
            setShow(false);
            loadTrieuChung();
        }
        catch (ex) {
            console.log(ex.message);
        }
    }

    const loadSauBenh = async () => {
        const resPonse = await SauBenhApi.getAll();
        setListSauBenh(resPonse);
        if (!resPonse.length == false) {
            resPonse.map(x => {
                optionSauBenhs.push({ 'value': x.Id, 'label': x.TenSauBenh });
            })
        }
    }

    const loadGiaiDoan = async () => {
        const rePonse = await GiaiDoanPhatTrienApi.getAll();
        if (!rePonse.length == false) {
            rePonse.map(x => {
                optionGiaiDoans.push({ 'value': x.Id, 'label': x.TenGiaiDoan });
            })
        }
    }

    useEffect(() => {
        loadTrieuChung();
        loadSauBenh();
        loadGiaiDoan();
        setRoleAdd(Authen.checkQuyen('DACDIEMTRIEUCHUNG_CREATE'));
        setRoleEdit(Authen.checkQuyen('DACDIEMTRIEUCHUNG_UPDATE'));
        setRoleDel(Authen.checkQuyen('DACDIEMTRIEUCHUNG_DELETE'));
    }, []);

    const setFilter = (e) => {
        setSearch(e.target.value);
    }


    const alertDell = async () => {
        let lstChecked = listTrieuChung.filter(x => x.Checked);
        if (lstChecked.length > 0) {
            ThongBao.printConfirmationDialog("Bạn có đồng ý xóa dữ liệu đã chọn hay không?", () => {
                TrieuChungApi.delete(lstChecked[0].Id).then(() => {
                    loadTrieuChung();
                })
            });
        }
    }

    const Timkiem = () => {
        loadTrieuChung();
    }

    const onChangeSelectLoai = (optionSelected) => {
        setLoai(optionSelected)
    }

    const onChangeMoTa = (event, editor) => {
        const data = editor.getData();
        setMoTa(data);
    }

    const changeTab = () => {
        reset(
            {
                Id: dacDiem.Id,
                TenTrieuChung: dacDiem.TenTrieuChung,
                TenKhoaHoc: dacDiem.TenKhoaHoc,
                GhiChu: dacDiem.GhiChu,
                Status: dacDiem.Status,
                ThuTu: dacDiem.ThuTu,
                DonViTinh: dacDiem.DonViTinh,
                TieuChuan: dacDiem.TieuChuan
            }, [reset]);
    }

    return (
        <div className="card shadow mb-1">
            <div className="card-header py-2">
                <div className="row ml-2">
                    <div className="col-md-2">
                        <h6 className="m-0 font-weight-bold text-primary">Đặc điểm triệu chứng</h6>
                    </div>
                    <div className="col-md-4">
                        <div className="input-group">
                            <input type="text" className="form-control" placeholder="Tìm kiếm" value={search} onChange={setFilter} />
                            <div className="input-group-append">
                                <button className="btn btn-info btn-sm" type="button" onClick={Timkiem} ><i className='fas fa-search'></i> Tìm kiếm</button>
                            </div>
                        </div>
                    </div>
                    <div className="fluid-container col-md-6 text-right">
                        {roleAdd == true ? <button type="button" className="btn btn-success btn-sm ml-1" onClick={handleShowAdd}><i className='fas fa-plus'></i> Thêm</button> : ''}
                        {roleEdit == true ? <button type="button" className="btn btn-info btn-sm ml-1" disabled={disButtonEdit} onClick={handleShowEdit}><i className='far fa-edit'></i> Sửa</button> : ''}
                        {roleDel == true ? <button type="button" className="btn btn-danger btn-sm ml-1" disabled={disButtonEdit} onClick={alertDell} ><i className='fas fa-trash-alt'></i> Xóa</button> : ''}
                        <button type="button" className="btn btn-primary btn-sm ml-1 mr-2" disabled={disButtonEdit} onClick={handleShowData}><i className='far fa-eye'></i> Xem</button>
                    </div>
                </div>
            </div>

            <div className="card-body">
                <div className="table-import import-scroll">
                    <table className="table table-bordered" width="100%">
                        <thead>
                            <tr>
                                <th><input name="Active" type="Checkbox"></input></th>
                                <th>Hình ảnh</th>
                                <th>Tên triệu chứng</th>
                                <th>Mô tả</th>
                                <th>Tiêu chuẩn</th>
                                <th>Đơn vị tính</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                listTrieuChung.map((item, key) => {
                                    return (
                                        <tr className="SelectRow" key={key} onClick={() => rowClick(item.Id)}>
                                            <td className="text-center">
                                                <input type="Checkbox" checked={item.Checked} onChange={e => item.checked = e.target.value}></input>
                                            </td>
                                            <td className="col-img">
                                                <img className="img-profile rounded-circle" src={apiUrl + item.HinhAnh} />
                                            </td>
                                            <td>{item.TenTrieuChung}</td>
                                            <td>{item.MoTa}</td>
                                            <td>{item.TieuChuan}</td>
                                            <td>{item.DonViTinh}</td>
                                            <td className="text-center">{item.Status ? <span className="label label-success cls-status-label">Kích hoạt</span> : <span className="label label-danger cls-status-label">Khóa</span>}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <div className="col-md-12 text-right">
                    <Pagination {...paginationConfig} />
                </div>
            </div>

            <Modal dialogclassName="modalTK" show={show} onHide={handleClose} backdrop="static" keyboard={false} animation={false} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Đặc điểm triệu chứng</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container-fluid">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Tabs>
                                <TabList>
                                    <Tab onClick={changeTab}>Thông tin cơ bản</Tab>
                                    <Tab onClick={changeTab}>Mô tả</Tab>
                                </TabList>
                                <TabPanel>
                                    <div className="form-group row" hidden={true}>
                                        <div className="col-md-2 col-sm-6 col-xl-2 col-form-label  nhanLH">
                                            <label className="nhanTK">ID <code>*</code></label>
                                        </div>
                                        <div className="col-md-10 col-sm-6 col-xl-10">
                                            <input className="form-control" name="Id" type="Text" ref={register()} onChange={(e) => dacDiem.Id = e.target.value}></input>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <div className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">
                                            Tên triệu chứng <code>*</code>
                                        </div>
                                        <div className="col-md-10 col-sm-6 col-xl-10">
                                            <input className="form-control" name="TenTrieuChung" type="Text" ref={register({ required: "Tên triệu chứng không được trống" })}
                                                onChange={(e) => dacDiem.TenTrieuChung = e.target.value}></input>
                                            <span className="eRor">{errors.TenTrieuChung && errors.TenTrieuChung.message}</span>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <div className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">
                                            Hình ảnh
                                </div>
                                        <div className="col-md-10 col-sm-6 col-xl-10 thumbnail">
                                            <img name="HinhAnh" ref={register} src={apiUrl + srcImage} onClick={openModalImage} />
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <div className="col-md-2 col-sm-3 col-xl-2 col-form-label nhanLH">
                                            Giai đoạn
                                </div>
                                        <div className="col-md-4 col-sm-3 col-xl-4">
                                            <Select options={optionGiaiDoans} onChange={onChangeSelectGiaiDoan} value={giaiDoan} theme={theme => ({
                                                ...theme,
                                                borderRadius: 0,
                                                height: 30,
                                                spacing: {
                                                    controlHeight: 30,
                                                    menuGutter: 1,
                                                    baseUnit: 2,
                                                }
                                            })} />
                                        </div>
                                        <div className="col-md-2 col-sm-3 col-xl-2 col-form-label nhanLH">
                                            Sâu bệnh
                                        </div>
                                        <div className="col-md-4 col-sm-3 col-xl-4">
                                            <Select options={optionSauBenhs} onChange={onChangeSelect} value={sauBenh} theme={theme => ({
                                                ...theme,
                                                borderRadius: 0,
                                                height: 30,
                                                spacing: {
                                                    controlHeight: 30,
                                                    menuGutter: 1,
                                                    baseUnit: 2,
                                                }
                                            })} />
                                        </div>

                                    </div>
                                    <div className="form-group row mb-1">
                                        <div className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">
                                            Ghi chú
                                </div>
                                        <div className="col-md-10 col-sm-6 col-xl-10">
                                            <textarea className="form-control textrea-size" rows="3" placeholder="Nhập ghi chú" name="GhiChu" type="Text" ref={register()} onChange={(e) => dacDiem.GhiChu = e.target.value}></textarea>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <div className="col-md-2 col-sm-3 col-xl-2 col-form-label nhanLH">
                                            Loại
                                        </div>
                                        <div className="col-md-4 col-sm-3 col-xl-4 text-left mt-2">
                                            <Select options={optionLoais} onChange={onChangeSelectLoai} value={loai}
                                                theme={theme => ({
                                                    ...theme,
                                                    borderRadius: 0,
                                                    height: 30,
                                                    spacing: {
                                                        controlHeight: 30,
                                                        menuGutter: 1,
                                                        baseUnit: 2,
                                                    }
                                                })} />
                                        </div>
                                        <div className="col-md-2 col-sm-3 col-xl-2 col-form-label nhanLH">
                                            Thứ tự
                                        </div>
                                        <div className="col-md-4 col-sm-3 col-xl-4 text-left mt-2">
                                            <input className="form-control" name="ThuTu" type="Number" ref={register()} onChange={(e) => dacDiem.ThuTu = e.target.value}
                                            ></input>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <div className="col-md-2 col-sm-3 col-xl-2 col-form-label nhanLH">
                                            Tiêu chuẩn
                                        </div>
                                        <div className="col-md-4 col-sm-3 col-xl-4 text-left mt-2">
                                            <input className="form-control" name="TieuChuan" type="number" ref={register()} onChange={(e) => dacDiem.TieuChuan = e.target.value}
                                            ></input>
                                        </div>
                                        <div className="col-md-2 col-sm-3 col-xl-2 col-form-label nhanLH">
                                            Đơn vị tính
                                        </div>
                                        <div className="col-md-4 col-sm-3 col-xl-4 text-left mt-2">
                                            <input className="form-control" name="DonViTinh" type="text" ref={register()} onChange={(e) => dacDiem.DonViTinh = e.target.value}
                                            ></input>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <div className="col-md-2 col-sm-3 col-xl-2 col-form-label nhanLH">
                                            Trạng thái
                                </div>
                                        <div className="col-md-4 col-sm-3 col-xl-4 text-left mt-2">
                                            <input name="Status" type="checkbox" ref={register()} onChange={(e) => dacDiem.Status = e.target.value}></input>
                                        </div>

                                    </div>
                                </TabPanel>
                                <TabPanel>
                                    <div className="form-group row">
                                        <label className="col-sm-2 col-form-label"></label>
                                        <div className="col-sm-10">
                                            <CKEditor editor={ClassicEditor} data={moTa} onChange={(event, editor) => onChangeMoTa(event, editor)} />
                                        </div>
                                    </div>
                                </TabPanel>
                            </Tabs>
                        </form>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <a href="#" type="button" className="btn btn-info btn-sm ml-1" onClick={handleSubmit(onSubmit)} hidden={isXem}><i className='fas fa-save'></i> Lưu</a>
                    <a href="#" type="button" className="btn btn-danger btn-sm ml-1" onClick={handleClose} disabled><i className="fas fa-sign-out-alt"></i> Hủy</a>
                </Modal.Footer>
            </Modal>

            <QuanLyTapTin viewModal={viewModal} closeModal={closeModalImage} onFileSelected={(e) => onFileSelected(e)}></QuanLyTapTin>
            <Loading loading={loading}></Loading>
        </div>
    )
}

