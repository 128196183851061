import React, { useState } from 'react'
import { Header } from './header'
import { Footer } from './footer'
import { TrangChu } from './trangchu'
import { BaiViet } from '../src/public/baiviet/baiviet'
import { Trang } from '../src/public/website/trang'
import { Switch, Route, Redirect } from "react-router-dom";
import { TrangBaiViet } from './public/baiviet/trangbaiviet';
import { HoiDap } from './public/hoidap/hoidap';
import { TraLoi } from './public/hoidap/traloi';
import { LienHe } from './public/lienhe/LienHe';
import SauBenhHome from './public/saubenh/sau-benh-home'

import { TraCuuSauBenh } from './public/tracuusaubenh/tracuusaubenh'
import { DienTichSanXuatPublic } from './public/solieu/dientichsanxuat/dientichsanxuat'
import { ThongKeAnhHuongSauBenh } from './public/solieu/anhhuongsaubenh/thongkeanhhuongsaubenh'
import { ThongKeThuocBaoVeThucVat } from './public/solieu/thuocbaovethucvat/thongkethuocbaovethucvat'
import { DangNhap } from './public/dangnhap/dangnhap'
import { ThongKeDonViCungCap } from './public/solieu/donvicungcap/DonViCungCap'


export const DefaultPage = () => {
    const [lienHe, setLienHe] = useState(null);
    const changedLienHe = (e) => {
        setLienHe(e);
    }
    return (
        <>
            <Header changedLienHe={changedLienHe}></Header>
            <main role="main" className="bd-content">
                <div className="container">
                    <Switch>
                        <Route path="/" exact>
                            <Redirect to="/public/index" ></Redirect>
                        </Route>
                        <Route path="/public" exact>
                            <Redirect to="/public/index"></Redirect>
                        </Route>
                        <Route path="/index" >
                            <TrangChu></TrangChu>
                        </Route>
                        <Route path="/public/danh-muc/:Alias/:IdDanhMuc" component={BaiViet} >
                        </Route>
                        <Route path="/public/bai-viet/:Alias/:IdBaiViet" component={TrangBaiViet} >
                        </Route>
                        <Route path="/public/dien-tich-san-xuat">
                            <DienTichSanXuatPublic showName={true}></DienTichSanXuatPublic>
                        </Route>
                        <Route path="/public/anh-huong-sau-benh">
                            <ThongKeAnhHuongSauBenh showName={true}></ThongKeAnhHuongSauBenh>
                        </Route>
                        <Route path="/public/don-vi-cung-cap">
                            <ThongKeDonViCungCap showName={true}></ThongKeDonViCungCap>
                        </Route>
                        <Route path="/public/thuoc-bao-ve-thuc-vat">
                            <ThongKeThuocBaoVeThucVat showName={true}></ThongKeThuocBaoVeThucVat>
                        </Route>
                        <Route path="/public/trang/:alias" component={Trang} >
                        </Route>
                        <Route path="/public/sau-benh">
                            <SauBenhHome></SauBenhHome>
                        </Route>
                        <Route path="/public/hoi-dap/:IdCauHoi">
                            <TraLoi show={true}></TraLoi>
                        </Route>
                        <Route path="/public/hoi-dap">
                            <HoiDap show={true}></HoiDap>
                        </Route>
                        <Route path="/public/lien-he">
                            <LienHe></LienHe>
                        </Route>
                        <Route path="/public/tra-cuu-sau-benh">
                            <TraCuuSauBenh ></TraCuuSauBenh>
                        </Route>
                        <Route path="/public/dang-nhap">
                            <DangNhap ></DangNhap>
                        </Route>
                        <Route path="*">
                            <TrangChu></TrangChu>
                        </Route>
                    </Switch>
                    <Footer lienHe={lienHe}></Footer>
                </div>
            </main>
        </>
    )
}
