import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import QuanHuyenApi from '../../api/QuanHuyenApi';


export const SelectHuyen = (props) => {

    const [listQuanHuyens, setQuanHuyen] = useState([]);
    const [selectedOption, setSelectedOption] = useState();

    useEffect(() => {
        loadQuanHuyen();
    }, []);

    useEffect(() => {
        if(listQuanHuyens.length > 0){
            const index = listQuanHuyens.findIndex(x=>x.ma == props.maHuyen);
            if(index != -1){
                setSelectedOption(listQuanHuyens[index]);
                props.onChangeHuyenProps(listQuanHuyens[index]);
            }
        }
    }, [props.maHuyen])

    const loadQuanHuyen = () => {
        QuanHuyenApi.getAll().then((response) => {
            let list = [];
            response.forEach(item => {
                list.push({ value: item.Ma, label: item.Ten, ma: item.Ma });
                if(props.maHuyen == item.Ma){
                    setSelectedOption({ value: item.Ma, label: item.Ten, ma: item.Ma });
                    props.onChangeHuyenProps({ value: item.Ma, label: item.Ten, ma: item.Ma });
                }
            });
            setQuanHuyen(list);
        })
    }

    const onChangeHuyen = (e) => {
        setSelectedOption(e);
        props.onChangeHuyenProps(e);
    }

    return (
        <Select
            className="select"
            name="IdQuanHuyen"
            placeholder="Chọn huyện"
            value={selectedOption}
            onChange={onChangeHuyen}
            options={listQuanHuyens}
            theme={theme => ({
                ...theme,
                borderRadius: 0,
                height: 30,
                spacing: {
                    controlHeight: 30,
                    menuGutter: 1,
                    baseUnit: 2,
                }
            })}
            styles={{ container: (base) => ({ ...base, zIndex: 500 }) }}
        />
    )
}
