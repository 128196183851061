import axiosClient from './axiosClient';


const GiaiDoanPhatTrienApi=
{
    getAll: (params) =>{
        const url = '/giaidoanphattrien/getall';
        return axiosClient.get(url, {params});
    },

    getPaging: (params)=>
    {
        const url= '/giaidoanphattrien/paging';
        return axiosClient.get(url,{params});
    },
    getDetail: (id) =>
    {
        const url= '/giaidoanphattrien/getbyid/' + id;
        return axiosClient.get(url);
    },
    getById: (id) =>
    {
        const url= '/giaidoanphattrien/getbyid/' + id;
        return axiosClient.get(url);
    },
    post: (data) =>{
        const url = '/giaidoanphattrien/add';
        return axiosClient.post(url, data);
    },

    put: (data) => {
        const url = '/giaidoanphattrien/edit';
        return axiosClient.put(url, data);
    },

    delete: (id) =>{
        const url = '/giaidoanphattrien/delete/' + id;
        return axiosClient.delete(url);
    }
    
}

export default GiaiDoanPhatTrienApi;