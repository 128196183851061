import React, { useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import CayTrongApi from '../../../api/CayTrongApi';
import Select from 'react-select';
import Pagination from 'react-bootstrap-4-pagination';
import ThongBao from '../../../core/ThongBao';
import { QuanLyTapTin } from '../../../shared/quanlytaptin/QuanLyTapTin';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import Authen from '../../../core/Authen';
import Ultility from '../../../core/Ultility';
import { param, post } from 'jquery';
import PostApi from '../../../api/PostApi';
import { now } from 'moment';

export const CayTrong = () => {
    var apiUrl = process.env.REACT_APP_URL;
    var noImage = "/uploadfiles/website/no_image-100x100.jpg";

    const [listCayTrongs, setListCayTrongs] = useState([]);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const { register, handleSubmit, reset, errors } = useForm();
    const [filter, setFilter] = useState('');
    const [srcImage, setSrcImage] = useState(noImage);

    const [viewModal, setViewModal] = useState(false);
    const [cayTrongModal, setCayTrongModal] = useState({ CayTrong });

    const [roleAdd, setRoleAdd] = useState(false);
    const [roleEdit, setRoleEdit] = useState(false);
    const [roleDel, setRoleDel] = useState(false);

    const [isEdit, setIsEdit] = useState(false);
    const [isXem, setIsXem] = useState(false);

    let pageIndex = 1;
    let pageSize = 20;

    const [paginationConfig, setPaging] = useState({
        totalPages: 1,
        currentPage: 1,
        showMax: 5,
        size: "sm",
        threeDots: true,
        prevNext: true,
        onClick: function (page) {
            pageIndex = page;
            loadCayTrong();
        }
    });

    useEffect(() => {
        loadCayTrong();
        setRoleAdd(Authen.checkQuyen('CAYTRONG_CREATE'));
        setRoleEdit(Authen.checkQuyen('CAYTRONG_UPDATE'));
        setRoleDel(Authen.checkQuyen('CAYTRONG_DELETE'));
    }, []);

    const loadCayTrong = async () => {
        try {
            const params = {
                pageIndex: pageIndex,
                pageSize: pageSize,
                filter: filter,
                idCha: 0
            }
            const response = await CayTrongApi.getPaging(params);
            response.Items.forEach(item => {
                item.Checked = false;
            });
            paginationConfig.totalPages = 1;
            if (response.Items.length > 0) {
                paginationConfig.totalPages = Math.ceil(response.TotalRow / pageSize);
            }
            paginationConfig.currentPage = pageIndex;
            setPaging(paginationConfig);
            setListCayTrongs(response.Items);
            setIsEdit(false);
        } catch (err) {
        }
    }

    const timKiem = () => {
        loadCayTrong();
    }

    function onChangeFilter(e) {
        setFilter(e.target.value)
    }

    const saveData = (data) => {
        try {
            var userLogin = Authen.getUser().UserName;
            cayTrongModal.HinhAnh = srcImage;
            cayTrongModal.IdCha = 0;
            if (cayTrongModal.Id === 0 || cayTrongModal.Id == null) {
                cayTrongModal.Id = 0;
                cayTrongModal.CreatedDate = new Date();
                cayTrongModal.CreatedBy = userLogin;
                CayTrongApi.post(cayTrongModal).then(() => {
                    setShow(false);
                    loadCayTrong();
                })
            }
            else {
                cayTrongModal.UpdatedDate = new Date();
                cayTrongModal.UpdatedBy = userLogin;
                CayTrongApi.put(cayTrongModal).then(() => {
                    setShow(false);
                    loadCayTrong();
                })
            }
            setIsEdit(false);
        }
        catch (ex) {
            console.log(ex);
        }
    }

    const clickRow = (id) => {
        listCayTrongs.forEach(element => {
            if (element.Id === id) {
                element.Checked = !element.Checked;
            }
        })
        let index = listCayTrongs.findIndex(x => x.Checked);
        if (index >= 0) {
            setIsEdit(true);
        }
        else {
            setIsEdit(false);
        }
        setListCayTrongs([...listCayTrongs]);
    }

    const checkBoHandler = () => {

    }

    const handleShowAdd = () => {
        reset({
            Id: 0,
            TenCayTrong: "",
            Status: true
        }, [reset]);
        setCayTrongModal({
            Id: 0,
            TenCayTrong: "",
            Status: true
        });
        setIsXem(false);
        setShow(true)
        setSrcImage(noImage);
    };

    const handleShowEdit = () => {
        let index = listCayTrongs.findIndex(x => x.Checked);
        if (index !== -1) {
            CayTrongApi.getDetail(listCayTrongs[index].Id).then((response) => {
                reset({
                    Id: response.ID,
                    TenCayTrong: response.TenCayTrong,
                    MoTa: response.MoTa,
                    HinhAnh: response.MoTa,
                    IdCha: response.IdCha,
                    GhiChu: response.GhiChu,
                    Delete: response.Delete,
                    CreatedDate: response.CreatedDate,
                    CreatedBy: response.CreatedBy,
                    UpdatedDate: response.UpdatedDate,
                    UpdatedBy: response.UpdatedBy,
                    Status: response.Status
                }, [reset]);
                setCayTrongModal(response);
                setIsXem(false);
                setShow(true);
                setSrcImage(response.HinhAnh);
            });
        }
    }

    const handleShowDel = () => {
        ThongBao.printConfirmationDialog("Bạn có đồng ý xóa dữ liệu đã chọn hay không?", () => {
            let lstChecked = listCayTrongs.filter(x => x.Checked);
            if (lstChecked.length > 0) {
                let lstId = [];
                lstChecked.forEach(element => {
                    lstId.push(element.Id);
                });
                CayTrongApi.delete(lstId).then(() => {
                    loadCayTrong();
                })
                setIsEdit(false);
            }
        });
    }
    const handleShowData = () => {
        let index = listCayTrongs.findIndex(x => x.Checked);
        if (index !== - 1) {
            CayTrongApi.getDetail(listCayTrongs[index].Id).then((response) => {
                reset({
                    Id: response.ID,
                    TenCayTrong: response.TenCayTrong,
                    MoTa: response.MoTa,
                    HinhAnh: response.MoTa,
                    IdCha: response.IdCha,
                    GhiChu: response.GhiChu,
                    Delete: response.Delete,
                    CreatedDate: response.CreatedDate,
                    CreatedBy: response.CreatedBy,
                    UpdatedDate: response.UpdatedDate,
                    UpdatedBy: response.UpdatedBy,
                    Status: response.Status
                }, [reset]);
                setCayTrongModal(response);
                setShow(true);
                setIsXem(true);
                setSrcImage(response.HinhAnh);
            })
        }
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            loadCayTrong();
        }
    }

    const openModalImage = () => {
        setViewModal(true);
    }


    const closeModalImage = () => {
        setViewModal(false);
    }

    const onFileSelected = (event) => {
        setSrcImage(event);
    }

    const changeTab = () => {
        reset({
            Id: cayTrongModal.ID,
            TenCayTrong: cayTrongModal.TenCayTrong,
            MoTa: cayTrongModal.MoTa,
            HinhAnh: cayTrongModal.MoTa,
            IdCha: cayTrongModal.IdCha,
            GhiChu: cayTrongModal.GhiChu,
            Delete: cayTrongModal.Delete,
            CreatedDate: cayTrongModal.CreatedDate,
            CreatedBy: cayTrongModal.CreatedBy,
            UpdatedDate: cayTrongModal.UpdatedDate,
            UpdatedBy: cayTrongModal.UpdatedBy,
            Status: cayTrongModal.Status
        }, [reset])
    }

    const onChangeName = (e) => {
        let str = "";
        cayTrongModal.TenCayTrong = e.target.value
    }

    return (
        <div className="card shadow mb-1">
            <div className="card-header py-2 row">
                <div className="col-md-4">
                    <h6 className="m-0 font-weight-bold text-primary">Cây Trồng</h6>
                </div>
                <div className="input-group col-md-4">
                    <input type="text" className="form-control" placeholder="Tìm kiếm" value={filter} onChange={onChangeFilter} onKeyPress={handleKeyPress} />
                    <div className="input-group-append">
                        <button className="btn btn-info" type="button" onClick={timKiem}><i className='fas fa-search'></i> Tìm kiếm</button>
                    </div>
                </div>
                <div className="col-md-4 bd-button text-right">
                    {roleAdd == true ? <Button variant="success" size="sm" onClick={handleShowAdd}><i className='fas fa-plus'></i> Thêm</Button> : ''}
                    {roleEdit == true ? <Button variant="primary" size="sm" onClick={handleShowEdit} disabled={!isEdit}>
                        <i className='far fa-edit'></i> Sửa</Button> : ''}
                    {roleDel == true ? <Button variant="danger" size="sm" onClick={handleShowDel} disabled={!isEdit}>
                        <i className='fas fa-trash-alt' ></i> Xóa</Button> : ''}
                    <button type="button" className="btn btn-primary btn-sm ml-1 mr-2" disabled={!isEdit} onClick={handleShowData}>
                        <i className='far fa-eye'></i> Xem </button>
                </div>
            </div>
            <div className="card-body">
                <div className="">
                    <table className="table table-bordered" width="100%">
                        <thead>
                            <tr>
                                <th className="col-checkbox"></th>
                                <th>Tên cây trồng</th>
                                <th>Mô tả</th>
                                <th>Ghi chú</th>
                                <th>Hình ảnh</th>
                                <th className="col-trangthai">Trạng thái</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                listCayTrongs.map((item, key) => {
                                    return (
                                        <tr onClick={() => clickRow(item.Id)} key={key}>
                                            <td className="col-checkbox">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" checked={item.Checked}
                                                        onChange={checkBoHandler} />
                                                </div>
                                            </td>
                                            <td>{item.TenCayTrong}</td>
                                            <td>{item.MoTa}</td>
                                            <td>{item.GhiChu}</td>
                                            <td className="col-img"><img src={apiUrl + item.HinhAnh}></img></td>
                                            <td className="text-center">
                                                {item.Status == true &&
                                                    <span className="label label-success cls-status-label">Kích hoạt</span>
                                                }
                                                {item.Status == false &&
                                                    <span className="label label-danger cls-status-label">Khóa</span>
                                                }

                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <div className="col-md-12 text-right">
                    <Pagination {...paginationConfig} />
                </div>
            </div>

            <Modal show={show} onHide={handleClose} animation={false} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Chi tiết cây trồng</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="user" onSubmit={handleSubmit(saveData)}>
                        <input name="Id" hidden={true} ref={register} onChange={e => cayTrongModal.Id = e.target.value} ></input>
                        <div className="form-group row">
                            <label className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">
                                Tên cây trồng
                                        <span className="text-danger"> *</span>
                            </label>
                            <div className="col-sm-10">
                                <input name="TenCayTrong" type="text" className="form-control" placeholder="Tên cây trồng"
                                    ref={register({ required: true })} onChange={onChangeName} />
                                {errors.TenCayTrong && <span className="text-danger">Hãy nhập tên cây trồng</span>}
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">Mô tả</label>
                            <div className="col-sm-10">
                                <textarea name="MoTa" className="form-control textrea-size" rows="2" placeholder="Nhập mô tả" ref={register}
                                    onChange={e => cayTrongModal.MoTa = e.target.value} ></textarea>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">Hình ảnh</label>
                            <div className="col-sm-10">
                                <div className="thumbnail" >
                                    <img name="HinhAnh" ref={register} src={apiUrl + srcImage} onClick={openModalImage} />
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">Ghi chú</label>
                            <div className="col-sm-10">
                                <textarea name="GhiChu" className="form-control textrea-size" rows="2" placeholder="Nhập ghi chú"
                                    ref={register} onChange={e => cayTrongModal.GhiChu = e.target.value} ></textarea>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH"></label>
                            <div className="col-sm-10">
                                <label className="custom-checkbox checkbox-style" >
                                    <input className="form-check-input" name="Status" type="checkbox" ref={register}
                                        onChange={e => cayTrongModal.Status = e.target.checked} />
                                    <span className="helping-el"></span> Kích hoạt
                                    </label>
                            </div>
                        </div>

                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" size="sm" onClick={handleSubmit(saveData)} hidden={isXem}>
                        Lưu</Button>
                    <Button variant="danger" size="sm" onClick={handleClose}>
                        Hủy</Button>
                </Modal.Footer>
            </Modal>
            {
                viewModal == true ? <QuanLyTapTin viewModal={viewModal} closeModal={closeModalImage} onFileSelected={(e) => onFileSelected(e)}></QuanLyTapTin> : ''
            }
        </div>
    )

}