import React, { useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import MuaVuApi from '../../../api/MuaVuApi';
import Select from 'react-select';
import Pagination from 'react-bootstrap-4-pagination';
import ThongBao from '../../../core/ThongBao';
import Authen from '../../../core/Authen';
import Ultility from '../../../core/Ultility';

export const MuaVu = () => {
    const [listMuaVu, setMuaVu] = useState([]);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const { register, handleSubmit, reset, errors } = useForm();
    const [filter, setFilter] = useState('');
    const [roleAdd, setRoleAdd] = useState(false);
    const [roleEdit, setRoleEdit] = useState(false);
    const [roleDel, setRoleDel] = useState(false);
    const [checkedRow, setCheckedRow] = useState(false);
    let pageIndex = 1;
    let pageSize = 20;
    const [paginationConfig, setPaging] = useState({
        totalPages: 1,
        currentPage: 1,
        showMax: 5,
        size: "sm",
        threeDots: true,
        prevNext: true,
        onClick: function (page) {
            pageIndex = page;
            loadMuaVus();
        }
    });

    const loadMuaVus = async () => {
        try {
            const params = {
                pageIndex: pageIndex,
                pageSize: pageSize,
                filter: filter
            }
            const response = await MuaVuApi.getPaging(params);
            response.Items.forEach(item => {
                item.Checked = false;
            });
            paginationConfig.totalPages = response.Items.length > 0 ? Math.ceil(response.TotalRow / pageSize) : 1;
            paginationConfig.currentPage = pageIndex;
            paginationConfig.onClick = function (page) {
                pageIndex = page;
                loadMuaVus();
            }
            setPaging(paginationConfig);
            setMuaVu(response.Items);
            setCheckedRow(false);
        } catch (err) {
        }
    }

    useEffect(() => {
        loadMuaVus();
        setRoleAdd(Authen.checkQuyen('MUAVU_CREATE'));
        setRoleEdit(Authen.checkQuyen('MUAVU_UPDATE'));
        setRoleDel(Authen.checkQuyen('MUAVU_DELETE'));
    }, []);

    const saveData = (data) => {
        if (data.Id === "0") {
            MuaVuApi.post(data).then(() => {
                setShow(false);
                loadMuaVus();
            })
        }
        else {
            MuaVuApi.put(data).then(() => {
                setShow(false);
                loadMuaVus();
            })
        }
    }

    const handleShowAdd = () => {
        reset({
            Id: 0,
            Ten: "",
            Status: true
        }, [reset]);
        setShow(true)
    };

    const handleShowEdit = () => {
        let index = listMuaVu.findIndex(x => x.Checked);
        if (index !== -1) {
            MuaVuApi.getDetail(listMuaVu[index].Id).then((response) => {
                reset(response, [reset]);
                setShow(true);
            });
        }
    }

    const handleShowDel = () => {
        ThongBao.printConfirmationDialog("Bạn có đồng ý xóa dữ liệu đã chọn hay không?", () => {
            let lstChecked = listMuaVu.filter(x => x.Checked);
            if (lstChecked.length > 0) {
                let lstId = [];
                lstChecked.forEach(element => {
                    lstId.push(element.Id);
                });
                MuaVuApi.delete(lstId).then(() => {
                    loadMuaVus();
                })
            }
        });
    }

    const timKiem = () => {
        loadMuaVus();
    }

    function onChangeFilter(e) {
        setFilter(e.target.value)
    }

    const clickRow = (id) => {
        listMuaVu.forEach(item => {
            if (item.Id === id) {
                item.Checked = !item.Checked;
            }
        });
        if (listMuaVu.findIndex(x => x.Checked == true) >= 0) {
            setCheckedRow(true);
        }
        else {
            setCheckedRow(false);
        }
        setMuaVu([...listMuaVu]);
    }

    const checkBoHandler = () => {

    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            loadMuaVus();
        }
    }

    return (
        <div className="card shadow mb-1">
            <div className="card-header py-2 row">
                <div className="col-md-4">
                    <h6 className="m-0 font-weight-bold text-primary">Mùa vụ</h6>
                </div>
                <div className="input-group col-md-4">
                    <input type="text" className="form-control" placeholder="Tìm kiếm" value={filter} onChange={onChangeFilter} onKeyPress={handleKeyPress} />
                    <div className="input-group-append">
                        <button className="btn btn-info" type="button" onClick={timKiem}><i className='fas fa-search'></i> Tìm kiếm</button>
                    </div>
                </div>
                <div className="col-md-4 bd-button text-right">
                    {roleAdd == true ? <Button variant="success" size="sm" onClick={handleShowAdd}><i className='fas fa-plus'></i> Thêm</Button> : ''}
                    {roleEdit == true ? <Button variant="primary" size="sm" onClick={handleShowEdit} disabled={!checkedRow}><i className='far fa-edit'></i> Sửa</Button> : ''}
                    {roleDel == true ? <Button variant="danger" size="sm" onClick={handleShowDel} disabled={!checkedRow}><i className='fas fa-trash-alt'></i> Xóa</Button> : ''}
                </div>
            </div>
            <div className="card-body table-solieu">
                <div className="table-responsive">
                    <table className="table table-bordered" width="100%">
                        <thead>
                            <tr>
                                <th className="col-checkbox"></th>
                                <th>Tên mùa vụ</th>
                                <th>Thời gian</th>
                                <th>Mô tả</th>
                                <th>Ghi chú</th>
                                <th>Trạng thái</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                listMuaVu.map((item, key) => {
                                    return (
                                        <tr onClick={() => clickRow(item.Id)} key={key}>
                                            <td className="col-checkbox">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" checked={item.Checked} onChange={checkBoHandler} />
                                                </div>
                                            </td>
                                            <td>{item.Ten}</td>
                                            <td>{item.ThoiGian}</td>
                                            <td>{item.MoTa}</td>
                                            <td>{item.GhiChu}</td>
                                            <td className="text-center">
                                                {item.Status == true &&
                                                    <span className="label label-success cls-status-label">Kích hoạt</span>
                                                }
                                                {item.Status == false &&
                                                    <span className="label label-danger cls-status-label">Khóa</span>
                                                }
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <div className="col-md-12 text-right">
                    <Pagination {...paginationConfig} />
                </div>
            </div>

            <Modal show={show} onHide={handleClose} animation={false} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Mùa vụ</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="user" onSubmit={handleSubmit(saveData)}>
                        <input name="Id" hidden={true} ref={register}></input>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Tên mùa vụ
                                <span className="text-danger"> *</span>
                            </label>
                            <div className="col-sm-9">
                                <input name="Ten" type="text" className="form-control" placeholder="Tên mùa vụ" ref={register({ required: true })} />
                                {errors.Ten && <span className="text-danger">Hãy nhập dữ liệu</span>}
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Thời gian</label>
                            <div className="col-sm-9">
                                <input name="ThoiGian" type="text" className="form-control" placeholder="Thời gian" ref={register} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Mô tả</label>
                            <div className="col-sm-9">
                                <textarea name="MoTa" className="form-control textrea-size" rows="8" placeholder="Nhập mô tả" ref={register}  ></textarea>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Ghi chú</label>
                            <div className="col-sm-9">
                                <textarea name="GhiChu" className="form-control textrea-size" rows="3" placeholder="Nhập ghi chú" ref={register}  ></textarea>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label"></label>
                            <div className="col-sm-9">
                                <label className="custom-checkbox checkbox-style" >
                                    <input className="form-check-input" name="Status" type="checkbox" ref={register} />
                                    <span className="helping-el"></span> Kích hoạt
                                </label>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" size="sm" onClick={handleSubmit(saveData)}>
                        Lưu</Button>
                    <Button variant="danger" size="sm" onClick={handleClose}>
                        Hủy</Button>
                </Modal.Footer>
            </Modal>
        </div>

    )
}
