import axiosClient from './axiosClient';

const LienHeApi = {
    updateLienHe:(params) => {
        const url = '/LienHe/edit';
        return axiosClient.put(url, params);
    },

    getDetail: (id) =>{
        const url = '/LienHe/getbyid/' + id;
        return axiosClient.get(url);
    },

    getLienHe:(params) => {
        const url = '/LienHe/paging';
        return axiosClient.get(url, {params});
    }
}

export default LienHeApi;