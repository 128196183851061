import React, { useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import GopYApi from '../../../api/GopYApi';
import Select from 'react-select';
import Pagination from 'react-bootstrap-4-pagination';
import ThongBao from '../../../core/ThongBao';
import Authen from '../../../core/Authen';
import Ultility from '../../../core/Ultility';
import moment from 'moment'

export const GopY = () => {
    const [listGopY, setGopY] = useState([]);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const { register, handleSubmit, reset, errors } = useForm();
    const [filter, setFilter] = useState('');
    const [selectedOption, setSelectedOption] = useState();
    const [roleAdd, setRoleAdd] = useState(false);
    const [roleEdit, setRoleEdit] = useState(false);
    const [roleDel, setRoleDel] = useState(false);

    let pageIndex = 1;
    let pageSize = 20;
    const [paginationConfig, setPaging] = useState({
        totalPages: 1,
        currentPage: 1,
        showMax: 5,
        size: "sm",
        threeDots: true,
        prevNext: true,
        onClick: function (page) {
            pageIndex = page;
            loadGopY();
        }
    });

    const loadGopY = async () => {
        try {
            const params = {
                pageIndex: pageIndex,
                pageSize: pageSize,
                filter: filter
            }
            const response = await GopYApi.getPaging(params);
            response.Items.forEach(item => {
                item.Checked = false;
            });
            if (response.Items.length > 0) {
                paginationConfig.totalPages = Math.ceil(response.TotalRow / pageSize);
            }
            else {
                paginationConfig.totalPages = 1;
            }
            paginationConfig.currentPage = pageIndex;
            setPaging(paginationConfig);
            setGopY(response.Items);
        } catch (err) {
        }
    }

    useEffect(() => {
        loadGopY();
        setRoleEdit(true);
        setRoleAdd(true);
        setRoleDel(true);
        setRoleAdd(Authen.checkQuyen('GOPY_CREATE'));
        setRoleEdit(Authen.checkQuyen('GOPY_UPDATE'));
        setRoleDel(Authen.checkQuyen('GOPY_DELETE'));
    }, []);

    const timKiem = () => {
        loadGopY();
    }

    function onChangeFilter(e) {
        setFilter(e.target.value)
    }

    const saveData = (data) => {
        if (selectedOption) {
            data.ParentId = selectedOption.value;
        }
        else {
            data.ParentId = null;
        }
        if (data.Id === "0") {
            GopYApi.post(data).then(() => {
                setShow(false);
                loadGopY();
            })
        }
        else {
            GopYApi.put(data).then(() => {
                setShow(false);
                loadGopY();
            })
        }
    }

    const clickRow = (id) => {
        listGopY.forEach(item => {
            if (item.IdGopY === id) {
                item.Checked = !item.Checked;
            }
        });
        setGopY([...listGopY]);
    }

    const handleShowAdd = () => {
        reset({
            IdGopY: 0,
            TieuDe: ""
        }, [reset]);
        setShow(true)
    };

    const handleShowEdit = () => {
        let index = listGopY.findIndex(x => x.Checked);
        if (index !== -1) {
            GopYApi.getDetail(listGopY[index].IdGopY).then((response) => {
                reset({
                    IdGopY: response.IdGopY,
                    HoTen: response.HoTen,
                    DienThoai: response.DienThoai,
                    Email: response.Email,
                    TieuDe: response.TieuDe,
                    CreatedDate: response.CreatedDate,
                    NoiDung: response.NoiDung
                }, [reset]);
                setShow(true);
            });
        }
    }

    const handleShowDel = () => {
        ThongBao.printConfirmationDialog("Bạn có đồng ý xóa dữ liệu đã chọn hay không?", () => {
            let lstChecked = listGopY.filter(x => x.Checked);
            if (lstChecked.length > 0) {
                let lstId = [];
                lstChecked.forEach(element => {
                    lstId.push(element.IdGopY);
                });
                GopYApi.delete(lstId).then(() => {
                    loadGopY();
                })
            }
        });
    }

    const checkBoHandler = () => {

    }

    const onChangeDanhMucCha = (e) => {
        setSelectedOption(e);
        // props.onChangeHuyenProps(e);
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            loadGopY();
        }
    }

    return (
        <div className="card shadow mb-1">
            <div className="card-header py-2 row">
                <div className="col-md-4">
                    <h6 className="m-0 font-weight-bold text-primary">Góp ý</h6>
                </div>
                <div className="input-group col-md-4">
                    <input type="text" className="form-control" placeholder="Tìm kiếm" value={filter} onChange={onChangeFilter} onKeyPress={handleKeyPress} />
                    <div className="input-group-append">
                        <button className="btn btn-info" type="button" onClick={timKiem}>Tìm kiếm</button>
                    </div>
                </div>
                <div className="col-md-4 bd-button text-right">
                    {/* {roleAdd == true ? <Button variant="success" size="sm" onClick={handleShowAdd}>Thêm</Button> : ''} */}
                    {roleEdit == true ? <Button variant="primary" size="sm" onClick={handleShowEdit}>Xem</Button> : ''}
                    {roleDel == true ? <Button variant="danger" size="sm" onClick={handleShowDel}>Xóa</Button> : ''}
                </div>
            </div>
            <div className="card-body">
                <div className="table-responsive">
                    <table className="table table-bordered" width="100%">
                        <thead>
                            <tr>
                                <th className="col-checkbox"></th>
                                <th>Họ tên</th>
                                <th>Điện thoại</th>
                                <th>Email</th>
                                <th>Tiêu đề</th>
                                <th>Ngày lập</th>
                                <td>Nội dung</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                listGopY.map((item, key) => {
                                    return (
                                        <tr onClick={() => clickRow(item.IdGopY)} key={key}>
                                            <td className="col-checkbox">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" checked={item.Checked} onChange={checkBoHandler} />
                                                </div>
                                            </td>
                                            <td>{item.HoTen}</td>
                                            <td>{item.DienThoai}</td>
                                            <td>{item.Email}</td>
                                            <td>{item.TieuDe}</td>
                                            <td>{moment(item.CreatedDate).format("DD/MM/yyyy HH:mm")}</td>
                                            <td>{item.NoiDung}</td>
                                            {/* <td className="text-center">
                                                {item.Status == true &&
                                                    <span className="label label-success cls-status-label">Kích hoạt</span>
                                                }
                                                {item.Status == false &&
                                                    <span className="label label-danger cls-status-label">Khóa</span>
                                                }
                                            </td> */}
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <div className="col-md-12 text-right">
                    <Pagination {...paginationConfig} />
                </div>
            </div>

            <Modal show={show} onHide={handleClose} animation={false} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Góp ý</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="user" onSubmit={handleSubmit(saveData)}>
                        <input name="IdGopY" hidden={true} ref={register}></input>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label text-right">Tiêu đề <span className="text-danger">*</span></label>
                            <div className="col-sm-10">
                                <input name="TieuDe" type="text" className="form-control" placeholder="Nhập tiêu đề" ref={register({ required: true })} />
                                {errors.TieuDe && <span className="text-danger">Hãy nhập dữ liệu</span>}
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label text-right">Họ và tên <span className="text-danger">*</span></label>
                            <div className="col-sm-10">
                                <input name="HoTen" type="text" className="form-control" placeholder="Nhập họ và tên" ref={register({ required: true })} />
                                {errors.HoTen && <span className="text-danger">Hãy nhập dữ liệu</span>}
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label text-right">Điện thoại</label>
                            <div className="col-sm-4">
                                <input name="DienThoai" type="text" className="form-control" placeholder="Nhập điện thoại" ref={register} />
                            </div>
                            <label className="col-sm-2 col-form-label text-right">Email <span className="text-danger">*</span></label>
                            <div className="col-sm-4">
                                <input name="Email" type="text" className="form-control" placeholder="Nhập email" ref={register({
                                    required: true,
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: "Sai định dạng email"
                                    }
                                })} />
                                <span className="text-danger">{errors.Email && errors.Email.message}</span>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label text-right">Nội dung <span className="text-danger">*</span></label>
                            <div className="col-sm-10">
                                <textarea rows="5" name="NoiDung" type="text" className="form-control" placeholder="Nhập nội dung" ref={register({ required: true })} />
                                {errors.NoiDung && <span className="text-danger">Hãy nhập dữ liệu</span>}
                            </div>
                        </div>

                    </form>
                </Modal.Body>
                <Modal.Footer>
                    {/* <Button variant="success" size="sm" onClick={handleSubmit(saveData)}>
                        Lưu</Button> */}
                    <Button variant="danger" size="sm" onClick={handleClose}>
                        Hủy</Button>
                </Modal.Footer>
            </Modal>
        </div>

    )
}
