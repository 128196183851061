import React, { useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import ThoiTietApi from '../../../api/ThoiTietApi';
import Select from 'react-select';
import Pagination from 'react-bootstrap-4-pagination';
import ThongBao from '../../../core/ThongBao';
import Authen from '../../../core/Authen';
import Ultility from '../../../core/Ultility';
import moment from 'moment'


import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";

export const ThoiTiet = () => {
    const [listThoiTiet, setThoiTiet] = useState([]);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const { register, handleSubmit, reset, errors } = useForm();
    const [filter, setFilter] = useState('');
    const [roleAdd, setRoleAdd] = useState(false);
    const [roleEdit, setRoleEdit] = useState(false);
    const [roleDel, setRoleDel] = useState(false);
    const [checkedRow, setCheckedRow] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    let pageIndex = 1;
    let pageSize = 20;
    const [paginationConfig, setPaging] = useState({
        totalPages: 1,
        currentPage: 1,
        showMax: 5,
        size: "sm",
        threeDots: true,
        prevNext: true,
        onClick: function (page) {
            pageIndex = page;
            loadThoiTiets();
        }
    });

    const loadThoiTiets = async () => {
        try {
            const params = {
                pageIndex: pageIndex,
                pageSize: pageSize,
                filter: filter
            }
            const response = await ThoiTietApi.getPaging(params);
            response.Items.forEach(item => {
                item.Checked = false;
            });
            paginationConfig.totalPages = response.Items.length > 0 ? Math.ceil(response.TotalRow / pageSize) : 1;
            paginationConfig.currentPage = pageIndex;
            paginationConfig.onClick = function (page) {
                pageIndex = page;
                loadThoiTiets();
            }
            setPaging(paginationConfig);
            setThoiTiet(response.Items);
            setCheckedRow(false);
        } catch (err) {
        }
    }

    useEffect(() => {
        loadThoiTiets();
        setRoleAdd(Authen.checkQuyen('THOITIET_CREATE'));
        setRoleEdit(Authen.checkQuyen('THOITIET_UPDATE'));
        setRoleDel(Authen.checkQuyen('THOITIET_DELETE'));
    }, []);

    const saveData = (data) => {
        debugger;
        data.Ngay=moment(startDate).format("DD/MM/YYYY");
        if (data.Id === "0") {
            ThoiTietApi.post(data).then(() => {
                setShow(false);
                loadThoiTiets();
            })
        }
        else {
            ThoiTietApi.put(data).then(() => {
                setShow(false);
                loadThoiTiets();
            })
        }
    }

    const handleShowAdd = () => {
        reset({
            Id: 0,
            Ten: "",
            Status: true
        }, [reset]);
        setShow(true)
    };

    const handleShowEdit = () => {
        let index = listThoiTiet.findIndex(x => x.Checked);
        if (index !== -1) {
            ThoiTietApi.getDetail(listThoiTiet[index].Id).then((response) => {
                reset(response, [reset]);
                debugger;
                var d=moment(response.Ngay);

                setStartDate(moment(response.Ngay));
                setShow(true);
            });
        }
    }

    const handleShowDel = () => {
        ThongBao.printConfirmationDialog("Bạn có đồng ý xóa dữ liệu đã chọn hay không?", () => {
            let lstChecked = listThoiTiet.filter(x => x.Checked);
            if (lstChecked.length > 0) {
                let lstId = [];
                lstChecked.forEach(element => {
                    lstId.push(element.Id);
                });
                ThoiTietApi.delete(lstId).then(() => {
                    loadThoiTiets();
                })
            }
        });
    }

    const timKiem = () => {
        loadThoiTiets();
    }

    function onChangeFilter(e) {
        setFilter(e.target.value)
    }

    const clickRow = (id) => {
        listThoiTiet.forEach(item => {
            if (item.Id === id) {
                item.Checked = !item.Checked;
            }
        });
        if (listThoiTiet.findIndex(x => x.Checked == true) >= 0) {
            setCheckedRow(true);
        }
        else {
            setCheckedRow(false);
        }
        setThoiTiet([...listThoiTiet]);
    }

    const checkBoHandler = () => {

    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            loadThoiTiets();
        }
    }
    const Ngay_ValueChange=(event)=>{
        setStartDate(event._d);
    }
    
    return (
        <div className="card shadow mb-1">
            <div className="card-header py-2 row">
                <div className="col-md-4">
                    <h6 className="m-0 font-weight-bold text-primary">Thời tiết</h6>
                </div>
                <div className="input-group col-md-4">
                    <input type="text" className="form-control" placeholder="Tìm kiếm" value={filter} onChange={onChangeFilter} onKeyPress={handleKeyPress} />
                    <div className="input-group-append">
                        <button className="btn btn-info" type="button" onClick={timKiem}><i className='fas fa-search'></i> Tìm kiếm</button>
                    </div>
                </div>
                <div className="col-md-4 bd-button text-right">
                    {roleAdd == true ? <Button variant="success" size="sm" onClick={handleShowAdd}><i className='fas fa-plus'></i> Thêm</Button> : ''}
                    {roleEdit == true ? <Button variant="primary" size="sm" onClick={handleShowEdit} disabled={!checkedRow}><i className='far fa-edit'></i> Sửa</Button> : ''}
                    {roleDel == true ? <Button variant="danger" size="sm" onClick={handleShowDel} disabled={!checkedRow}><i className='fas fa-trash-alt'></i> Xóa</Button> : ''}
                </div>
            </div>
            <div className="card-body">
                <div className="table-responsive">
                    <table className="table table-bordered" width="100%">
                        <thead>
                            <tr>
                                <th className="col-checkbox"></th>
                                <th>Ngày</th>
                                <th>Nhiệt độ</th>
                                <th>Độ ẩm</th>
                                <th>Nắng</th>
                                <th>Mưa</th>
                                <th>Trạng thái</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                listThoiTiet.map((item, key) => {
                                    return (
                                        <tr onClick={() => clickRow(item.Id)} key={key}>
                                            <td className="col-checkbox">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" checked={item.Checked} onChange={checkBoHandler} />
                                                </div>
                                            </td>
                                            <td>{moment(item.Ngay).format("DD/MM/YYYY")}</td>
                                            <td>{item.NhietDo}</td>
                                            <td>{item.DoAm}</td>
                                            <td>{item.Nang}</td>
                                            <td>{item.Mua}</td>
                                            <td className="text-center">
                                                {item.Status == true &&
                                                    <span className="label label-success cls-status-label">Kích hoạt</span>
                                                }
                                                {item.Status == false &&
                                                    <span className="label label-danger cls-status-label">Khóa</span>
                                                }
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <div className="col-md-12 text-right">
                    <Pagination {...paginationConfig} />
                </div>
            </div>

            <Modal show={show} onHide={handleClose} animation={false} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Thời tiết</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="user" onSubmit={handleSubmit(saveData)}>
                        <input name="Id" hidden={true} ref={register}></input>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Ngày
                                <span className="text-danger"> *</span>
                            </label>
                            <div className="col-sm-9">
                                <Datetime name="Ngay" initialValue={startDate} timeFormat={false} dateFormat="DD/MM/YYYY" closeOnSelect={true} onChange={(e) => Ngay_ValueChange(e)} ref={register} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Nhiệt độ</label>
                            <div className="col-sm-9">
                                <input name="NhietDo" type="text" className="form-control" placeholder="Nhiệt độ" ref={register} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Độ ẩm</label>
                            <div className="col-sm-9">
                                <input name="DoAm" type="text" className="form-control" placeholder="Độ ẩm" ref={register} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Nắng</label>
                            <div className="col-sm-9">
                                <input name="Nang" type="text" className="form-control" placeholder="Nắng" ref={register} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Mưa</label>
                            <div className="col-sm-9">
                                <input name="Mua" type="text" className="form-control" placeholder="Mưa" ref={register} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Ghi chú</label>
                            <div className="col-sm-9">
                                <textarea name="GhiChu" className="form-control textrea-size" rows="3" placeholder="Nhập ghi chú" ref={register}  ></textarea>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label"></label>
                            <div className="col-sm-9">
                                <label className="custom-checkbox checkbox-style" >
                                    <input className="form-check-input" name="Status" type="checkbox" ref={register} />
                                    <span className="helping-el"></span> Kích hoạt
                                </label>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" size="sm" onClick={handleSubmit(saveData)}>
                        Lưu</Button>
                    <Button variant="danger" size="sm" onClick={handleClose}>
                        Hủy</Button>
                </Modal.Footer>
            </Modal>
        </div>

    )
}
