import axiosClient from './axiosClient';
const QuanHuyenApi = {
    getAll: (param) => {
        const url = '/quanhuyen/getall';
        return axiosClient.get(url)
    },
    getAllByAuth: (param) => {
        const url = '/quanhuyen/getallbyauth';
        return axiosClient.get(url)
    },
    getXaByHuyen: (params) => {
        const url = '/quanhuyen/getxabyhuyen';
        return axiosClient.get(url,{params})
    }
}
export default QuanHuyenApi;