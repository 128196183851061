const { default: axiosClient } = require("./axiosClient");

const LichSuChanDoanApi = {
    getPaging: (params) => {
        const url = '/lichsuchandoan/paging';
        return axiosClient.get(url, {params});
    },
    delete: (id) =>{
        const url = '/lichsuchandoan/delete/' + id;
        return axiosClient.delete(url);
    },
    add: (data) =>{
        const url = '/lichsuchandoan/add';
        return axiosClient.post(url, data);
    },
}
  
export default LichSuChanDoanApi;