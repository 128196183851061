import React from 'react'
import { Button } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import { Tabs, Tab } from 'react-bootstrap';
import { useState } from 'react';
import NumberFormat from 'react-number-format';
import Pagination from 'react-bootstrap-4-pagination';
import VungDuAnApi from '../../api/VungDuAnApi';
import { SelectXaMulti } from '../selecthuyenmulti/SelectXaMulti';

export const TimKiemVungCaq = (props) => {
    const { register, handleSubmit } = useForm();
    const [listKetQuas, setKetQua] = useState([]);
    const [tongRow, setTongRow] = useState(-1);
    const [tongDienTich, setTongDienTich] = useState(0);
    const [listDats, setListDat] = useState([]);
    const [listFilters, setListFilter] = useState([]);
    let query = '';
    let pageIndex = 1;
    const timKiem = (data) => {
        query = getSQL(data);
        props.timKiem(query);
        loadDuLieu();
    }
    const getSQL = (data) => {
        let sql = `htsd = 'CAQ' `;
        if (listFilters !== null && listFilters?.length > 0) {
            sql = sql.length > 0 ? sql + ' AND ' : '';
            sql = `"ma_px" in  ('${listFilters.map(x => x.value).join(',').replace(/,/g, "','")}')`
        }
        // if (listFilters !== null && listFilters?.length > 0) {
        //     sql = `"maXa" in  (${listFilters.map(x => x.value).join(',')})`
        // }
        // if (listDats != null && listDats?.length > 0) {
        //     sql = sql.length > 0 ? sql + ' AND ' : '';
        //     sql = sql + `"khDatTCVN" in  ('${listDats.map(x => x.KhDat).join(',').replace(/,/g, "','")}')`
        // }
        if (data.DienTichMin !== "") {
            sql = sql.length > 0 ? sql + ' AND ' : '';
            sql = sql + `"dientichbd" ${data.DienTichDieuKienMin} ${data.DienTichMin}`
        }
        if (data.DienTichMax !== "") {
            sql = sql.length > 0 ? sql + ' AND ' : '';
            sql = sql + `"dientichbd" ${data.DienTichDieuKienMax} ${data.DienTichMax}`
        }

        if (sql !== '') {
            sql = props.queryHuyen + ' AND ' + sql
        }
        else {
            sql = props.queryHuyen;
        }
        return sql;
    }

    const exportExcel = (data) => {
        const sql = getSQL(data);
        props.exportExcel(sql);
    }

    const clickTab = (k) => {
    }

    const onChangeXa = (e) => {
        setListFilter(e);
    }
    const getKhDat = (listDats) => {
        setListDat(listDats)
    }

    const loadDuLieu = () => {
        props.timKiemLoading(true);
        const params = {
            pageIndex: pageIndex,
            sql: query
        }
        VungDuAnApi.getPaging(params).then((res) => {
            setKetQua(res.Items);
            paginationConfig.totalPages = (Math.ceil(res.TotalRow / 20) === 0 ? 1 : Math.ceil(res.TotalRow / 20));
            paginationConfig.currentPage = pageIndex;
            setTongRow(res.TotalRow);
            setTongDienTich(res.TongDienTich);
            paginationConfig.onClick = function (page) {
                pageIndex = page;
                loadDuLieu();
            }
            setPaging(paginationConfig);
            props.timKiemLoading(false);
        }, (err) => {
            props.timKiemLoading(false);
        })
    }

    const clickRow = (id) => {
        props.timKiemGetChiTiet({
            id: id,
            tableName: 'BDCAYCAQ'
        })
    }

    const [paginationConfig, setPaging] = useState({
        totalPages: 1,
        currentPage: 1,
        showMax: 5,
        size: "sm",
        threeDots: true,
        prevNext: true,
        onClick: function (page) {
            pageIndex = page;
            loadDuLieu();
        }
    });

    return (
        <Tabs defaultActiveKey="timkiem" onSelect={(k) => clickTab(k)}>
            <Tab eventKey="timkiem" title="Tìm kiếm">
                <form className="frmtimkiem">

                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label text-right">Tên xã</label>
                        <div className="col-sm-10">
                            <SelectXaMulti filterXa={props.filterXa} onChangeXaProps={(e) => onChangeXa(e)} ></SelectXaMulti>
                        </div>
                    </div>
                    <div className="form-group row tkdientich">
                        <div className="col-sm-3 col-3">
                            <input name="DienTichMin" type="number" className="form-control" placeholder="ha" ref={register} />
                        </div>
                        <div className="col-sm-2 col-2 select-dt">
                            <select class="form-control" name="DienTichDieuKienMin" ref={register}>
                                <option value=">">&#60;</option>
                                <option value=">=">&#60;&#61;</option>
                            </select>
                        </div>
                        <label className="col-sm-2 col-2 col-form-label text-center">Diện tích</label>
                        <div className="col-sm-2 col-2 select-dt">
                            <select class="form-control" name="DienTichDieuKienMax" ref={register}>
                                <option value="<">&#60;</option>
                                <option value="<=">&#60;&#61;</option>
                            </select>
                        </div>
                        <div className="col-sm-3 col-3">
                            <input name="DienTichMax" type="number" className="form-control" placeholder="ha" ref={register} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-sm-12 text-right">
                            <Button variant="info mr-1" size="sm" onClick={handleSubmit(exportExcel)}><i className="fas fa-file-excel"></i> Excel</Button>
                            <Button variant="success" size="sm" onClick={handleSubmit(timKiem)}><i className="fas fa-search"></i> Tìm kiếm</Button>
                        </div>
                    </div>

                    {
                        tongRow > -1 ?
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label text-right">Kết quả:</label>
                                <div className="col-sm-8">
                                    <h5 className="ket-qua-tim-kiem"><NumberFormat value={tongRow} displayType={'text'} thousandSeparator={true} decimalScale={2}></NumberFormat> khoảnh đất</h5>
                                </div>
                                <label className="col-sm-4 col-form-label text-right">Tổng diện tích:</label>
                                <div className="col-sm-8">
                                    <h5 className="ket-qua-tim-kiem"><NumberFormat value={tongDienTich} displayType={'text'} thousandSeparator={true} decimalScale={2}></NumberFormat> ha</h5>
                                </div>
                            </div> : ''
                    }
                </form>
            </Tab>
            <Tab eventKey="ketqua" title="Kết quả">
                {
                    <>
                        <table className="table table-bordered tb-chu-giai" width="100%">
                            <thead>
                                <tr>
                                    <th>Huyện</th>
                                    <th>Xã</th>
                                    <th>Vùng cây trồng</th>
                                    <th>Diện tích</th>

                                </tr>
                            </thead>
                            <tbody>
                                {
                                    listKetQuas.length === 0 ? <tr>
                                        <td colSpan="100%">Không có bản ghi được tìm thấy</td>
                                    </tr> :
                                        listKetQuas.map((item, key) => {
                                            
                                            return (
                                                <tr key={key} onClick={() => clickRow(item.gid)}>
                                                    <td>{item.text_huyen}</td>
                                                    <td>{item.text_xavn}</td>
                                                    <td>{item.htsd}</td>
                                                    <td>
                                                        <NumberFormat value={item.dientichbd} displayType={'text'} thousandSeparator={true} decimalScale={2}></NumberFormat>
                                                    </td>

                                                </tr>
                                            )
                                        })
                                }
                            </tbody>
                        </table>
                        <Pagination {...paginationConfig} />
                    </>
                }
            </Tab>
        </Tabs>


    )
}
