import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Tabs, Tab } from 'react-bootstrap';
import stylelocal from "./SidebarLayer.module.css"
import { VungDuAn } from '../right-info/VungDuAn';
import { CayAnQua } from '../right-info/CayAnQua';
import { CayLua } from '../right-info/CayLua';
import { CayRau } from '../right-info/CayRau';
import { Xa } from '../right-info/Xa';
import { Huyen } from '../right-info/Huyen';
import { BaoVeThucVat } from '../right-info/BaoVeThucVat';

export const SidebarLayer = (props) => {
    const [key, setKey] = useState('');
    const [showTab, setShowTab] = useState(false);
    const [thongTinVungDuAn, setThongTinVungDuAn] = useState({});
    const [thongTinCayLua, setThongCayLua] = useState({});
    const [thongTinCayRau, setThongTinCayRau] = useState({});
    const [thongTinCayAnQua, setThongTinCayAnQua] = useState({});
    const [thongTinXa, setThongTinXa] = useState({});
    const [thongTinHuyen, setThongTinHuyen] = useState({});
    const [thongTinBaoVeThucVat, setThongTinBaoVeThucVat] = useState({});
    var width = window.innerWidth - props.width;
    if (window.innerWidth < 768) {
        width = window.innerWidth - 10;
    }
    const [xPosition, setX] = React.useState(width);
    const toggleMenu = () => {
        if (xPosition < width) {
            setX(width);
        } else {
            setX(width - 290);
        }
    };
    useLayoutEffect(() => {
        function updateSize() {
            width = window.innerWidth - props.width;
            setX(width)
        }
        window.addEventListener('resize', updateSize);
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    useEffect(() => {
        if (props.x > 0) {
            setKey(props.tabSelect);
        }
    }, [props.x]);
    useEffect(() => {
        const show = props.listLopBanDos.findIndex(x => x.Checked && x.ParentId !== 'BANDONEN' && x.ParentId !== 'BANDOVETINH' && (x.ParentId !== 'BDHANHCHINH' || x.Ma === 'BDHANHCHINHHUYEN') && x.LayerName != null) !== -1;
        setShowTab(show);
    }, [props.listLopBanDos]);
    useEffect(() => {
        const tableName = props.listLopBanDos.filter(x => x.Checked && x.ParentId !== 'BANDONEN' && x.ParentId !== 'BANDOVETINH' && (x.ParentId !== 'BDHANHCHINH' || x.Ma === 'BDHANHCHINHHUYEN') && x.LayerName != null);
        let k = props.tabSelect;
        if (tableName.length > 0 && k === '') {
            k = tableName[0].Ma;
        }
        switch (k) {
            case 'BDBAOVETHUCVAT':
                props.thongTin.tableName = "BDBAOVETHUCVAT";
                setThongTinBaoVeThucVat(props.thongTin);
                break;
            case 'BDRANHGIOIHUYEN':
                props.thongTin.tableName = "BDRANHGIOIHUYEN";
                setThongTinHuyen(props.thongTin);
                break;
            case 'BDRANHGIOIXA':
                props.thongTin.tableName = "BDRANHGIOIXA";
                setThongTinXa(props.thongTin);
                break;
            case 'BDCAYCAQ':
                props.thongTin.tableName = "BDCAYCAQ";
                setThongTinCayAnQua(props.thongTin);
                break;
            case 'BDCAYLUA':
                props.thongTin.tableName = "BDCAYLUA";
                setThongCayLua(props.thongTin);
                break;
            case 'BDCAYRAU':
                props.thongTin.tableName = "BDCAYRAU";
                setThongTinCayRau(props.thongTin);
                break;
            case 'BDVUNGDUAN':
                props.thongTin.tableName = "BDVUNGDUAN";
                setThongTinVungDuAn(props.thongTin);
                break;
        }
    }, [props.thongTin])
    useEffect(() => {
        setKey(props.tabSelect);
    }, [props.tabSelect])

    useEffect(() => {
        if (props.show > 0 && xPosition > width - 290) {
            toggleMenu();
        }
    }, [props.show]);

    const clickTab = async (k) => {
        setKey(k)
        props.updateTableSelect(k);
    }
    const adminstyle = {
        height: 'calc(100vh - 100px)'
    }

    const showEditThongTin = (e) => {
        props.showEditThongTin(e);
    }


    return (
        <React.Fragment>
            <div
                className={stylelocal.sidebar}
                style={{
                    transform: `translatex(${xPosition}px)`,
                    width: width,

                }}
            >
                <button
                    onClick={() => toggleMenu()}
                    className={stylelocal.togglemenu}
                    style={{
                        transform: `translate(${-25}px, 0px)`
                    }}
                >
                    {
                        <div className={stylelocal.outer}>
                            <div className={stylelocal.inner + ' ' + stylelocal.rotate}>THÔNG TIN</div>
                        </div>
                    }
                </button>
                <div className={stylelocal.sidecontent} style={props.admin ? adminstyle : null}>
                    {
                        showTab === true ? <Tabs
                            id="controlled-tab-example"
                            activeKey={key}
                            onSelect={(k) => clickTab(k)}
                        >
                            {
                                props.listLopBanDos.filter(x => (x.Type > 1 || x.Ma == 'BDRANHGIOIHUYEN' || x.Ma == 'BDRANHGIOIXA') && x.Checked).map((item, index) => {
                                    switch (item.Ma) {
                                        case 'BDRANHGIOIHUYEN':
                                            return (
                                                <Tab eventKey={item.Ma} title="Huyện" key={index}>
                                                    <Huyen thongTin={thongTinHuyen} showEditThongTin={(e) => { showEditThongTin(thongTinHuyen) }} admin={props.admin} />
                                                </Tab>
                                            )
                                        case 'BDRANHGIOIXA':
                                            return (
                                                <Tab eventKey={item.Ma} title="Xã" key={index}>
                                                    <Xa thongTin={thongTinXa} showEditThongTin={(e) => { showEditThongTin(thongTinXa) }} admin={props.admin} />
                                                </Tab>
                                            )
                                        case 'BDVUNGDUAN':
                                            return (
                                                <Tab eventKey={item.Ma} title="Phân bố cây trồng" key={index}>
                                                    <VungDuAn thongTin={thongTinVungDuAn} showEditThongTin={(e) => { showEditThongTin(thongTinVungDuAn) }} admin={props.admin} />
                                                </Tab>
                                            )
                                        case 'BDCAYCAQ':
                                            return (
                                                <Tab eventKey={item.Ma} title="Cây ăn quả" key={index}>
                                                    <CayAnQua thongTin={thongTinCayAnQua} showEditThongTin={(e) => { showEditThongTin(thongTinCayAnQua) }} admin={props.admin} />
                                                </Tab>
                                            )
                                        case 'BDCAYLUA':
                                            return (
                                                <Tab eventKey={item.Ma} title="Cây lúa" key={index}>
                                                    <CayLua thongTin={thongTinCayLua} showEditThongTin={(e) => { showEditThongTin(thongTinCayLua) }} admin={props.admin} />
                                                </Tab>
                                            )
                                        case 'BDCAYRAU':
                                            return (
                                                <Tab eventKey={item.Ma} title="Cây rau" key={index}>
                                                    <CayRau thongTin={thongTinCayRau} showEditThongTin={(e) => { showEditThongTin(thongTinCayRau) }} admin={props.admin} />
                                                </Tab>
                                            )
                                        case 'BDBAOVETHUCVAT':
                                            return (
                                                <Tab eventKey={item.Ma} title="Bảo vệ thực vật" key={index}>
                                                    <BaoVeThucVat thongTin={thongTinBaoVeThucVat} showEditThongTin={(e) => { showEditThongTin(thongTinBaoVeThucVat) }} admin={props.admin} />
                                                </Tab>
                                            )
                                    }
                                })
                            }

                        </Tabs> : <h5 className="text-center">Bạn chưa chọn bản đồ</h5>
                    }
                </div>
            </div>
        </React.Fragment>
    )
}
