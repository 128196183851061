import React, { useState, useEffect } from 'react'
import NhatKyDangNhapApi from '../../../api/NhatKyDangNhapApi';
import Pagination from 'react-bootstrap-4-pagination';
import ThongBao from '../../../core/ThongBao';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import Authen from '../../../core/Authen';

export const NhatKyDangNhap = () => {
    const [listNhatKyDangNhaps, setNhatKyDangNhap] = useState([]);
    const [filter, setFilter] = useState('');
    const [isEdit, setIsEdit] = useState(false);
    var [tuNgay, setTuNgay] = useState(new Date());
    var [denNgay, setDenNgay] = useState(new Date());
    const [roleAdd, setRoleAdd] = useState(false);
    const [roleEdit, setRoleEdit] = useState(false);
    const [roleDel, setRoleDel] = useState(false);

    let pageIndex = 1;
    let pageSize = 20;
    const [paginationConfig, setPaging] = useState({
        totalPages: 1,
        currentPage: 1,
        showMax: 5,
        size: "sm",
        threeDots: true,
        prevNext: true,
        onClick: function (page) {
            pageIndex = page;
            loadNhatKyDangNhap();
        }
    });

    const loadNhatKyDangNhap = async () => {
        try {
            const params = {
                pageIndex: pageIndex,
                pageSize: pageSize,
                keyword: filter,
                tuNgay: moment(tuNgay).format('DD/MM/YYYY'),
                denNgay: moment(denNgay).format('DD/MM/YYYY')
            }
            const response = await NhatKyDangNhapApi.getPaging(params);
            response.Items.forEach(item => {
                item.Checked = false;
            });
            paginationConfig.totalPages = Math.ceil(response.TotalRow / pageSize);
            if (response.TotalRow == 0) {
                paginationConfig.totalPages = 1;
            }
            paginationConfig.currentPage = pageIndex;
            setPaging(paginationConfig);
            setNhatKyDangNhap(response.Items);
        } catch (err) {
        }
    }

    useEffect(() => {
        loadNhatKyDangNhap();
        setRoleAdd(Authen.checkQuyen('NHATKYDANGNHAP_CREATE'));
        setRoleEdit(Authen.checkQuyen('NHATKYDANGNHAP_UPDATE'));
        setRoleDel(Authen.checkQuyen('NHATKYDANGNHAP_DELETE'));
    }, []);

    const timKiem = () => {
        loadNhatKyDangNhap();
    }

    function onChangeFilter(e) {
        setFilter(e.target.value)
    }

    const handleShowDel = () => {
        let index = listNhatKyDangNhaps.findIndex(x => x.Checked);
        if (index !== -1) {
            ThongBao.printConfirmationDialog("Bạn có muốn xóa bản ghi này không?", () => {
                NhatKyDangNhapApi.delete(listNhatKyDangNhaps[index].Id).then(() => {
                    ThongBao.printSuccessMessage("Xóa thành công!");
                    loadNhatKyDangNhap();
                })
            });
        }
    }

    const clickRow = (id) => {
        listNhatKyDangNhaps.forEach(item => {
            if (item.Id === id) {
                item.Checked = !item.Checked;
            }
        });
        setNhatKyDangNhap([...listNhatKyDangNhaps]);
        let list = listNhatKyDangNhaps.filter(x => x.Checked);
        if (list.length !== 0) {
            setIsEdit(true);
        }
        else
        {
            setIsEdit(false);
        }
    }

    const handleCallback = (start, end, label) => {
        tuNgay = start;
        denNgay = end;
        setTuNgay(tuNgay);
        setDenNgay(denNgay);
        loadNhatKyDangNhap();
    }

    return (
        <div className="card shadow mb-1">
            <div className="card-header py-2 row">
                <div className="col-md-3">
                    <h6 className="m-0 font-weight-bold text-primary">Nhật ký đăng nhập</h6>
                </div>
                <div className="col-md-2">
                    <DateRangePicker onCallback={handleCallback}
                        initialSettings={{ startDate: tuNgay, endDate: denNgay, locale: { format: 'DD/MM/YYYY'} }}
                    >
                        <input type="text" className="form-control" />
                    </DateRangePicker>
                </div>
                <div className="input-group col-md-4">
                    <input type="text" className="form-control" placeholder="Tìm kiếm" value={filter} onChange={onChangeFilter} />
                    <div className="input-group-append">
                        <button className="btn btn-info" type="button" onClick={timKiem}><i className='fas fa-search'></i> Tìm kiếm</button>
                    </div>
                </div>
                <div className="col-md-3 bd-button text-right">
                {roleDel == true?<button type="button" className="btn btn-danger btn-sm ml-1" disabled={!isEdit} onClick={handleShowDel} ><i className='fas fa-trash-alt'></i> Xóa</button>:''}
                </div>
            </div>
            <div className="card-body">
                <div className="">
                    <table className="table table-bordered" width="100%">
                        <thead>
                            <tr>
                                <th className="col-checkbox"></th>
                                <th>Tài khoản</th>
                                <th>Nội dung</th>
                                <th>Thời gian</th>
                                <th>Địa chỉ Ip</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                listNhatKyDangNhaps.map((item, key) => {
                                    return (
                                        <tr key={key} onClick={() => clickRow(item.Id)}>
                                            <td className="col-checkbox">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" checked={item.Checked} readOnly />
                                                </div>
                                            </td>
                                            <td>{item.TenDangNhap}</td>
                                            <td>{item.NoiDung}</td>
                                            <td>{item.Ngay}</td>
                                            <td>{item.Ip}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <div className="col-md-12 text-right">
                    <Pagination {...paginationConfig} />
                </div>
            </div>
        </div>

    )
}
