import { Button } from 'react-bootstrap';
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import HuongDanApi from '../../../api/HuongDanApi';
import CKEditor from 'ckeditor4-react';
import { useHistory, useParams } from 'react-router-dom';

export const HuongDanAddEdit = () => {
    const { register, handleSubmit, reset, errors } = useForm();
    const [content, setContent] = useState('');
    var { id } = useParams();
    let history = useHistory();
    useEffect(() => {
        if (id !== "0") {
            HuongDanApi.getDetail(id).then((response) => {
                reset({
                    IdHuongDan: response.IdHuongDan,
                    TieuDe: response.TieuDe,
                    MoTa: response.MoTa,
                    NoiDung: response.NoiDung,
                    ThuTu: response.ThuTu,
                    Status: response.Status,
                    TapTin: response.TapTin,
                    HienThiCongKhai: response.HienThiCongKhai
                }, [reset]);
                setTimeout(() => {
                    setContent(response.NoiDung);
                }, 50);
            });
        }
        else {
            reset({
                IdHuongDan: 0,
                Status: true
            }, [reset]);
            setContent('');
            console.log('OK');
        }
    }, [id])

    const layDuLieu = (e) => {
        setContent(e.editor.getData());
    }

    const saveData = (data) => {
        data.NoiDung = content;
        if (data.ThuTu == "") {
            data.ThuTu = 0;
        }
        if (data.IdHuongDan === "0") {
            HuongDanApi.post(data).then(() => {
                history.push("/main/tienich/huongdan");
            })
        }
        else {
            HuongDanApi.put(data).then(() => {
                history.push("/main/tienich/huongdan");
            })
        }
    }

    const handleClose = () => {
        history.push("/main/tienich/huongdan");
    }

    return (
        <div className="card shadow mb-1 frm-huong-dan">
            <form>
                <div className="card-header py-2 row">
                    <div className="col-md-6">
                        <h6 className="m-0 font-weight-bold text-primary">Hướng dẫn</h6>
                    </div>
                    <div className="col-md-6 bd-button text-right">
                        <Button variant="success" size="sm" onClick={handleSubmit(saveData)}>
                            Lưu</Button>
                        <Button variant="danger" size="sm" onClick={handleClose}>
                            Hủy</Button>
                    </div>
                </div>
                <div className="card-body">
                    <input name="IdHuongDan" hidden={true} ref={register}></input>
                    <div className="form-group row">
                        <label className="col-sm-3 col-form-label">Tiêu đề
                                <span className="text-danger"> *</span>
                        </label>
                        <div className="col-sm-9">
                            <input name="TieuDe" type="text" className="form-control" placeholder="Tiêu đề" ref={register({ required: true })} />
                            {errors.TieuDe && <span className="text-danger">Hãy nhập dữ liệu</span>}
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-3 col-form-label">Thứ tự</label>
                        <div className="col-sm-3">
                            <input name="ThuTu" type="number" className="form-control" placeholder="Thứ tự" ref={register} />
                        </div>
                        <div className="col-sm-3">
                            <label className="custom-checkbox checkbox-style" >
                                <input className="form-check-input" name="HienThiCongKhai" type="checkbox" ref={register} />
                                <span className="helping-el"></span> Hiển thị công khai
                                </label>
                        </div>
                        <div className="col-sm-3">
                            <label className="custom-checkbox checkbox-style" >
                                <input className="form-check-input" name="Status" type="checkbox" ref={register} />
                                <span className="helping-el"></span> Kích hoạt
                                </label>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-3 col-form-label">Mô tả</label>
                        <div className="col-sm-9">
                            <input name="MoTa" type="text" className="form-control" placeholder="Mô tả" ref={register} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-3 col-form-label">Nội dung</label>
                        <div className="col-sm-9">
                            {/* <CKEditor editor={ClassicEditor} data={content} onChange={(event, editor) => setContent(editor.getData())} /> */}
                            <CKEditor data={content} onChange={layDuLieu}
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}
