const { default: axiosClient } = require("./axiosClient");

const LuotTruyCapApi = {

    post: (data) =>{
        const url = '/luottruycap/add';
        return axiosClient.post(url, data);
    },
    getAll: (params) =>{
        const url = '/luottruycap/getall';
        return axiosClient.get(url, {params});
    }
}

export default LuotTruyCapApi;