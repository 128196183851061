import React, { useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import CayTrongApi from '../../../api/CayTrongApi';
import GiaiDoanPhatTrienApi from '../../../api/GiaiDoanPhatTrienApi';
import Select from 'react-select';
import Pagination from 'react-bootstrap-4-pagination';
import ThongBao from '../../../core/ThongBao';
import { QuanLyTapTin } from '../../../shared/quanlytaptin/QuanLyTapTin';
import 'react-tabs/style/react-tabs.css';
import Authen from '../../../core/Authen';

export const GiaiDoanPhatTrien = () => {
    var noImage = "/uploadfiles/website/no_image-100x100.jpg";

    //const [listCayTrongs, setListCayTrongs] = useState([]);
    const [listGiaiDoans,setListGiaiDoans]=useState([]);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const { register, handleSubmit, reset, errors } = useForm();
    const [filter, setFilter] = useState('');

    const [viewModal, setViewModal] = useState(false);
    const [giaiDoanModal, setGiaiDoanModal] = useState({GiaiDoanPhatTrien})

    const [roleAdd, setRoleAdd] = useState(false);
    const [roleEdit, setRoleEdit] = useState(false);
    const [roleDel, setRoleDel] = useState(false);

    const [isEdit, setIsEdit] = useState(false);
    const [isXem, setIsXem]=useState(false);

    const [selectedOption, setSelectedOption] = useState();
    const [listDanhMucCay, setDanhMucCay] = useState([]);

    const [selectedFindIdCay, setSelectedFindIdCay] = useState();
    const [findIdCay, setFindIdCay] =  useState();

    let pageIndex = 1;
    let pageSize = 20;

    const [paginationConfig, setPaging] = useState({
        totalPages: 1,
        currentPage: 1,
        showMax: 5,
        size: "sm",
        threeDots: true,
        prevNext: true,
        onClick: function (page) {
            pageIndex = page;
            loadGiaiDoan();
        }
    });

    useEffect(() => {
        loadGiaiDoan();
        loadDanhMucCay();
        setRoleAdd(Authen.checkQuyen('GIAIDOANPHATTRIEN_CREATE'));
        setRoleEdit(Authen.checkQuyen('GIAIDOANPHATTRIEN_UPDATE'));
        setRoleDel(Authen.checkQuyen('GIAIDOANPHATTRIEN_DELETE'));
    }, []);

    const loadGiaiDoan = async () => {
        try {
            const params = {
                filter: filter,
                pageIndex: pageIndex,
                pageSize: pageSize,
                idCayTrong: findIdCay
            }
            const response = await GiaiDoanPhatTrienApi.getPaging(params);
            response.Items.forEach(item => {
                item.Checked = false;
            });
             paginationConfig.totalPages = 1;
             if (response.Items.length > 0) {
                paginationConfig.totalPages = Math.ceil(response.TotalRows / pageSize);
             }
             paginationConfig.currentPage = pageIndex;
             setPaging(paginationConfig);
             setListGiaiDoans(response.Items);
             setIsEdit(false);
        } catch (err) {
        }
    }

    function loadDanhMucCay() {
        CayTrongApi.getAll().then((response) => {
            let list = [];
            list.push({ value: 0, label: 'Chọn danh mục...' });
            response.forEach(item => {
                list.push({ value: item.Id, label: item.TenCayTrong });
            })
            setDanhMucCay(list);
        })
    }

    const timKiem = () => {
        loadGiaiDoan();
    }

    function onChangeFilter(e) {
        setFilter(e.target.value)
    }

    const clickRow = (id) => {
        listGiaiDoans.forEach(element =>{
            if (element.Id===id){
                element.Checked= !element.Checked;
            }
            
        })
        let index=listGiaiDoans.findIndex(x=>x.Checked);
        if (index >= 0)
        {
            setIsEdit(true);
        }
        else
        {
            setIsEdit(false);
        }
        setListGiaiDoans([...listGiaiDoans]);
    }

    const handleShowAdd = () => {
        reset({
            Id: 0,
            TenGiaiDoan:"",
            Status: true
        }, [reset]);
        setGiaiDoanModal({
            Id: 0,
            TenGiaiDoan:"",
            Status: true
        });
        setShow(true);
        setIsXem(false);
        setSelectedOption(listDanhMucCay[0]);
    };

    const handleShowEdit = () => 
    {
        let index = listGiaiDoans.findIndex(x => x.Checked);
        if (index !== -1) {
            GiaiDoanPhatTrienApi.getDetail(listGiaiDoans[index].Id).then((response) => {
                reset({
                    Id: response.ID,
                    TenGiaiDoan: response.TenGiaiDoan,
                    MoTa: response.MoTa,
                    IdCayTrong: response.IdCayTrong,
                    GhiChu: response.GhiChu,
                    Status: response.Status,
                    Delete: response.Delete,
                    CreatedBy: response.CreatedBy,
                    CreatedDate: response.CreatedDate,
                    UpdatedBy: response.UpdatedBy,            
                    UpdatedDate: response.UpdatedDate,
                    SoNgay: response.SoNgay
                }, [reset]);
                var index = listDanhMucCay.findIndex(x => x.value == response.IdCayTrong);
                setSelectedOption(listDanhMucCay[index]);
                setGiaiDoanModal(response);
                setShow(true);
                setIsXem(false);
                setIsEdit(true);
            });
        }
    }

    const handleShowDel = () => 
    {
        ThongBao.printConfirmationDialog("Bạn có đồng ý xóa dữ liệu đã chọn hay không?", () => {
            let lstChecked = listGiaiDoans.filter(x => x.Checked);
            if (lstChecked.length > 0) {
                let lstId = [];
                lstChecked.forEach(element => {
                    lstId.push(element.Id);
                });
                GiaiDoanPhatTrienApi.delete(lstId).then(() => {
                    loadGiaiDoan();
                })
            }
        });
    }
    const handleShowData = () => 
    {
        let index = listGiaiDoans.findIndex(x => x.Checked);
        if (index !== - 1)
        {
            GiaiDoanPhatTrienApi.getDetail(listGiaiDoans[index].Id).then((response)=> 
            {
                reset({
                    Id:response.ID,
                    TenGiaiDoan:response.TenGiaiDoan,
                    MoTa:response.MoTa,
                    IdCayTrong:response.IdCayTrong,
                    GhiChu:response.GhiChu,
                    Status:response.Status,
                    Delete:response.Delete,
                    CreatedBy:response.CreatedBy,
                    CreatedDate:response.CreatedDate,
                    UpdatedBy:response.UpdatedBy,            
                    UpdatedDate:response.UpdatedDate,
                    SoNgay: response.SoNgay   
                }, [reset]);
                var index = listDanhMucCay.findIndex(x => x.value == response.IdCayTrong);
                setSelectedOption(listDanhMucCay[index]);
                setGiaiDoanModal(response);
                setShow(true);
                setIsXem(true);
                setIsEdit(true);
            })
        }
    }

    const saveData = (data) => 
    {
        try
        {  
            var userLogin= Authen.getUser().UserName;
            giaiDoanModal.IdCayTrong=0;
            if (selectedOption) {
                if (selectedOption.value === 0) {
                    giaiDoanModal.IdCayTrong = null;
                }
                else {
                    giaiDoanModal.IdCayTrong = selectedOption.value;
                }
            }
            else 
            {
                giaiDoanModal.IdCayTrong = null;
            }
            if (giaiDoanModal.IdCayTrong==null)
            {
                ThongBao.prinWarningMessage("Hãy lựa chọn danh mục cây trồng");
            }
            else
            {
                if (giaiDoanModal.Id === 0 || giaiDoanModal.Id === null || giaiDoanModal.Id === undefined) 
                {
                    giaiDoanModal.Id = 0;
                    giaiDoanModal.CreatedDate= new Date();
                    giaiDoanModal.CreatedBy=userLogin;
                    GiaiDoanPhatTrienApi.post(giaiDoanModal).then(() => {
                        setShow(false);
                        loadGiaiDoan();
                    })
                }
                else {
                    giaiDoanModal.UpdatedDate=new Date();
                    giaiDoanModal.UpdatedBy=userLogin;
                    GiaiDoanPhatTrienApi.put(giaiDoanModal).then(() => {
                        setShow(false);
                        loadGiaiDoan();
                    })
                }
                setIsEdit(false);
            }
        }
        catch(ex)
        {
        }        
    }

    const checkBoHandler = () => {

    }

    const onChangeDanhMucCay = (e) => {
        setSelectedOption(e);
    }

    const onChangeFindIdCay = (e) => {
        setSelectedFindIdCay(e);
        setFindIdCay(e.value);
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            loadGiaiDoan();
        }
    }

    const closeModalImage = () => {
        setViewModal(false);
    }

    const onChangeName = (e) => {
        giaiDoanModal.TenGiaiDoan = e.target.value;
    }

    const onChangeSoNgay = (e) => {
        if (isNaN(e.target.value))
        {
            giaiDoanModal.SoNgay="";
        }
        else
        {
            giaiDoanModal.SoNgay=e.target.value;
        }
      }

    const onFileSelected = (e) => {

    }

    return (
        <div className="card shadow mb-1">
            <div className="card-header py-2 row">
                <div className="col-md-3">
                    <h6 className="m-0 font-weight-bold text-primary">Giai Đoạn Phát Triển</h6>
                </div>
                <div className="input-group col-md-5">
                    <input type="text" className="form-control" placeholder="Tìm kiếm" value={filter} onChange={onChangeFilter} onKeyPress={handleKeyPress} />
                    <div className="col-sm-5">
                                        <Select
                                            className="select"
                                            name="IdCayTrong"
                                            placeholder="Cây trồng"
                                            value={selectedFindIdCay}
                                            onChange={onChangeFindIdCay}
                                            options={listDanhMucCay}
                                        />
                                    </div>
                    <div className="input-group-append">
                        <button className="btn btn-info" type="button" onClick={timKiem}><i className='fas fa-search'></i> Tìm kiếm</button>
                    </div>
                </div>
                <div className="col-md-4 bd-button text-right">
                    {roleAdd == true ? <Button variant="success" size="sm" onClick={handleShowAdd}>
                        <i className='fas fa-plus'></i> Thêm</Button> : ''}
                    {roleEdit == true ? <Button variant="primary" size="sm" onClick={handleShowEdit} disabled={!isEdit}>
                        <i className='far fa-edit'></i> Sửa</Button> : ''}
                    {roleDel == true ? <Button variant="danger" size="sm" onClick={handleShowDel} disabled={!isEdit}>
                        <i className='fas fa-trash-alt' ></i> Xóa</Button> : ''}
                    <button type="button" className="btn btn-primary btn-sm ml-1 mr-2" disabled={!isEdit} onClick={handleShowData}>
                        <i className='far fa-eye'></i> Xem
                    </button>
                </div>
            </div>
            <div className="card-body">
                <div className="">
                    <table className="table table-bordered" width="100%">
                        <thead>
                            <tr>
                                <th className="col-checkbox"></th>
                                <th>Tên giai đoạn</th>
                                <th>Mô tả</th>
                                <th>Ghi chú</th>
                                <th>Cây trồng</th>
                                <th className="col-trangthai">Trạng thái</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                listGiaiDoans.map((item, key) => {
                                    return (
                                        <tr onClick={() => clickRow(item.Id)} key={key}>
                                            <td className="col-checkbox">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" checked={item.Checked} 
                                                    onChange={checkBoHandler} />
                                                </div>
                                            </td>
                                            <td>{item.TenGiaiDoan}</td>
                                            <td>{item.MoTa}</td>
                                            <td>{item.GhiChu}</td>
                                            <td>{item.TenCayTrong}</td>
                                            <td className="text-center">
                                                {item.Status == true &&
                                                    <span className="label label-success cls-status-label">Kích hoạt</span>
                                                }
                                                {item.Status == false &&
                                                    <span className="label label-danger cls-status-label">Khóa</span>
                                                }
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <div className="col-md-12 text-right">
                    <Pagination {...paginationConfig} />
                </div>
            </div>

            <Modal show={show} onHide={handleClose} animation={false} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Chi tiết giai đoạn</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="user" onSubmit={handleSubmit(saveData)}>
                                <input name="Id" hidden={true} ref={register} onChange={e => giaiDoanModal.Id = e.target.value} ></input>
                                <div className="form-group row">
                                    <label className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">
                                        Tên giai đoạn<span className="text-danger"> *</span>
                                    </label>
                                    <div className="col-sm-10">
                                        <input name="TenGiaiDoan" type="text" className="form-control" placeholder="Tên Giai đoạn phát triển" 
                                        ref={register({ required: true })} onChange={onChangeName} />
                                        {errors.TenGiaiDoan && <span className="text-danger">Hãy nhập tên giai đoạn</span>}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">Cây trồng<span className="text-danger"> *</span></label>
                                    <div className="col-sm-5">
                                        <Select
                                            className="select"
                                            name="IdCayTrong"
                                            placeholder="Danh mục"
                                            value={selectedOption}
                                            onChange={onChangeDanhMucCay}
                                            options={listDanhMucCay}
                                            ref={register({required: true})}
                                        />
                                        {errors.IdCayTrong && <span  className="text-danger">Chọn cây trồng</span>}
                                    </div>
                                    <label className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">Số ngày</label>
                                    <div className="col-sm-3">
                                        <input name="SoNgay" type="text" className="form-control" placeholder="Số ngày" 
                                        ref={register({ 
                                            required:false
                                        })
                                        } onChange={onChangeSoNgay} />
                                        {errors.SoNgay && <span className="text-danger">Hãy nhập số ngày</span>}
                                    </div>
                                </div>
                                <div className="form-group row">
                                   
                                </div>
                                <div className="form-group row">
                                    <label className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">Mô tả</label>
                                    <div className="col-sm-10">
                                        <textarea name="MoTa" className="form-control textrea-size" rows="2" placeholder="Nhập mô tả" ref={register} 
                                        onChange={e => giaiDoanModal.MoTa = e.target.value} ></textarea>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">Ghi chú</label>
                                    <div className="col-sm-10">
                                        <textarea name="GhiChu" className="form-control textrea-size" rows="2" placeholder="Nhập ghi chú" 
                                        ref={register} onChange={e => giaiDoanModal.GhiChu = e.target.value} ></textarea>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH"></label>
                                    <div className="col-sm-10">
                                        <label className="custom-checkbox checkbox-style" >
                                            <input className="form-check-input" name="Status" type="checkbox" ref={register} 
                                            onChange={e => giaiDoanModal.Status = e.target.checked} />
                                            <span className="helping-el"></span> Kích hoạt
                                    </label>
                                    </div>
                                </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" size="sm" onClick={handleSubmit(saveData)} hidden={isXem}>
                        Lưu</Button>
                    <Button variant="danger" size="sm" onClick={handleClose}>
                        Hủy</Button>
                </Modal.Footer>
            </Modal>
            <QuanLyTapTin viewModal={viewModal} closeModal={closeModalImage} onFileSelected={(e) => onFileSelected(e)}></QuanLyTapTin>
        </div>
    )

}
