import React, { useEffect, useState } from 'react'
export const ChuGiaiNen = (props) => {
    const [listChuGiais, setListChuGiai] = useState([]);
    useEffect(() => {
        setListChuGiai(props.listChuGiais);
    }, [props.listChuGiais]);
    const clickRow = (item) => {
        // item.Checked = !item.Checked;
        // setListChuGiai([...listChuGiais]);
        // props.clickChuGiai(listChuGiais.filter(x => x.Checked).map(x => x.maDeXuat).join(','));
    }
    return (
        <table className="table table-bordered tb-chu-giai" width="100%">
            <thead>
                <tr>
                    <th className="mau-ky-hieu">Màu sắc Ký hiệu</th>
                    <th>Tên lớp</th>
                </tr>
            </thead>
            <tbody>
                {
                    listChuGiais?.map((item, key) => {
                        return (
                            <tr onClick={() => clickRow(item)} key={key}>
                                <td className="mau-ky-hieu">
                                    <div style={{ backgroundImage: `url("${item.image}")` }}>
                                        <span>{item.id}</span>
                                    </div>
                                </td>
                                <td>{item.ten}</td>
                            </tr>
                        )
                    })
                }
            </tbody>
        </table>
    )
}
