import React, { useState, useEffect } from 'react'
import { Modal, Tabs, Tab } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import SauBenhApi from '../../../api/SauBenhApi';
import Pagination from 'react-bootstrap-4-pagination';
import Authen from '../../../core/Authen';
import { Loading } from '../../../shared/loading/Loading';
import ThongBao from '../../../core/ThongBao';
import { QuanLyTapTin } from '../../../shared/quanlytaptin/QuanLyTapTin';
import CayTrongApi from '../../../api/CayTrongApi';
import GiongCayTrongApi from '../../../api/GiongCayTrongApi';
import GiaiDoanPhatTrienApi from '../../../api/GiaiDoanPhatTrienApi';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ImportExcel } from '../../tienich/importexcel/ImportExcel';

export const SauBenh = (props) => {
    const { handleSubmit, reset, register, errors } = useForm();
    var data = require('../../../shared/Thang.json');
    const optionThoiGian = [...data];
    var apiUrl = process.env.REACT_APP_API_URL.replace('/api', '');
    var noImage = "/uploadfiles/website/no_image-100x100.jpg";
    var newItem_GiaiDoanPhatTrien = { Ten: '', DonVi: '', TieuChuan: '' };
    const [listSauBenh, setListSauBenh] = useState([]);
    const [cayTrong, setCayTrong] = useState({});
    const [giongCayTrong, setGiongCayTrong] = useState({});
    const [cha, setCha] = useState({});
    const [giaiDoan, setGiaiDoan] = useState({});
    const [loaiSaubenh, setLoaiSauBenh] = useState({});
    const [thoiGianSelect, setThoiGianSelect] = useState([]);
    const [show, setShow] = useState(false);
    const [showImport, setShowImport] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isXem, setIsXem] = useState(false);
    const [search, setSearch] = useState('');
    const [roleAdd, setRoleAdd] = useState(false);
    const [roleEdit, setRoleEdit] = useState(false);
    const [roleDel, setRoleDel] = useState(false);
    const [loading, setLoading] = useState(false);
    let pageIndex = 1, pageSize = 20;
    const [disButtonEdit, setDisButtonEdit] = useState(true);
    const [viewModal, setViewModal] = useState(false);
    const [srcImage, setSrcImage] = useState(noImage);
    const [sauBenh, setSauBenh] = useState({});

    const [moTa, setMoTa] = useState('');
    const [canhTac, setCanhTac] = useState('');
    const [phongTru, setPhongTru] = useState('');
    const [title, setTitle] = useState('');

    const [optionCayTrongs, setOptionCayTrong] = useState([{ value: 0, label: 'Chọn cây trồng' }]);
    const [optionGiongCayTrongs, setOptionGiongCayTrong] = useState([{ value: 0, label: 'Chọn giống cây trồng' }]);
    const [optionChas, setOptionCha] = useState([{ value: 0, label: 'Chọn sâu bệnh cha' }]);
    const [optionChaEdits, setOptionChaEdit] = useState([]);
    const [optionGiaiDoans, setOptionGiaiDoan] = useState([{ value: 0, label: 'Chọn giai đoạn' }]);
    const [loaiSauView, setLoaiSauView] = useState({ value: 0, label: 'Chọn loại sâu bệnh' });
    const [selectLoaiCay, setSelectLoaiCay] = useState({ value: 0, label: 'Chọn cây trồng' });
    const [listGiaiDoanSinhTruong, setListGiaiDoanSinhTruong] = useState([]);

    const optionLoaiSauBenhs = [
        { value: true, label: 'Sâu hại cây trồng' },
        { value: false, label: 'Bệnh hại cây trồng' },
    ];


    const [paginationConfig, setPaginationConfig] = useState({
        totalPages: 1,
        currentPage: 1,
        showMax: 5,
        size: 'sm',
        threeDots: true,
        prevNext: true,
        onClick: function (page) {
            pageIndex = page;
            loadSauBenh();
        }
    });

    const closeModalImage = () => {
        setViewModal(false);
    }

    const openModalImage = () => {
        setViewModal(true);
    }

    const onFileSelected = (event) => {
        setSrcImage(event);
    }

    const onChangeSelect = (optionSelected) => {
        setCayTrong(optionSelected);
    }

    const onChangeSelectGiong = (optionSelected) => {
        setGiongCayTrong(optionSelected);
    }

    const onChangeSelectLoaiSauBenh = (optionSelected) => {
        setLoaiSauBenh(optionSelected)
    }

    const onChangeSelectCha = (optionSelected) => {
        setCha(optionSelected);
    }

    const onChangeSelectGiaiDoan = (optionSelected) => {
        setGiaiDoan(optionSelected);
    }

    const onChangeSelectThoiGian = (optionSelected) => {
        setThoiGianSelect(optionSelected);
    }


    const loadSauBenh = async () => {
        try {
            var idCayTrong = 0;
            if (selectLoaiCay != null) {
                idCayTrong = selectLoaiCay.value;
            }
            const params = {
                filter: search,
                pageIndex: pageIndex,
                pageSize: pageSize,
                loaiSauBenh: props.LoaiSauBenh,
                idCayTrong: idCayTrong
            }

            const response = await SauBenhApi.getPaging(params);
            response.Items.forEach((x) => {
                x.Checked = false
            });

            setListSauBenh(response.Items);
            paginationConfig.totalPages = Math.ceil(response.TotalRow === 0 ? 1 : response.TotalRow / pageSize);
            paginationConfig.currentPage = pageIndex;
            setPaginationConfig(paginationConfig);
        }
        catch (err) { }
    }

    const rowClick = (id) => {
        setIsEdit(false);
        listSauBenh.forEach(element => {
            if (element.Id === id) {
                if (element.Checked === true) {
                    element.Checked = false;
                }
                else {
                    element.Checked = true;
                    setIsEdit(true);
                    setDisButtonEdit(false);
                }
            }
            else {
                element.Checked = false;
            }
        });

        if (listSauBenh.findIndex(element => element.Checked === true) === -1) {
            setIsEdit(false);
            setDisButtonEdit(true);
        };
        setListSauBenh([...listSauBenh]);
    }

    const handleClose = () => {
        setShow(false);
    }

    const handleShowAdd = () => {
        reset({
            Id: 0,
            Status: true
        }, [reset]);
        setSauBenh({ Id: 0, Status: true })
        setListGiaiDoanSinhTruong([newItem_GiaiDoanPhatTrien]);
        setSrcImage(noImage);
        setCayTrong({ value: 0, label: 'Chọn cây trồng' });
        setOptionChaEdit(optionChas);
        setCha({ value: 0, label: 'Chọn sâu bệnh cha' });
        setGiongCayTrong({ value: 0, label: 'Chọn giống cây trồng' });
        setGiaiDoan({ value: 0, label: 'Chọn giai đoạn' });
        setLoaiSauBenh({ value: true, label: 'Sâu hại cây trồng' });
        setThoiGianSelect([]);
        setMoTa('');
        setCanhTac('');
        setPhongTru('');
        setIsEdit(false);
        setIsXem(false);
        setShow(true);
    }

    const loadThang = (tg) => {
        if (tg == null) {
            return;
        }
        let lstThang = Array.from(tg.split(','), Number);
        let lstTG = [];
        lstThang.forEach(x => {
            let index = optionThoiGian.findIndex(p => p.value == Number(x));
            if (index !== -1) {
                lstTG.push(optionThoiGian[index]);
            }
        });
        setThoiGianSelect(lstTG);
    }

    const loadOptionSauBenhById = (Id) => {
        let lstCha = [];
        optionChas.forEach(x => {
            if (x.value !== Id) {
                lstCha.push({ value: x.value, label: x.label });
            }
        });
        setOptionChaEdit(lstCha);
    }

    const handleShowEdit = () => {
        setIsEdit(true);
        setIsXem(false);
        let index = listSauBenh.findIndex(x => x.Checked);
        if (index !== -1) {
            SauBenhApi.getDetail(listSauBenh[index].Id).then((response) => {
                reset({
                    Id: response.Id,
                    TenSauBenh: response.TenSauBenh,
                    TenKhoaHoc: response.TenKhoaHoc,
                    MoTa: response.MoTa,
                    GhiChu: response.GhiChu,
                    Status: response.Status,
                    NhietDoMax: response.NhietDoMax,
                    NhietDoMin: response.NhietDoMin,
                }, [reset]);
                setSauBenh(response);
                var lst = response.GiaiDoanSinhTruongPhatTrien;
                if (lst == null) {
                    lst = [];
                }
                lst.push(newItem_GiaiDoanPhatTrien);
                setListGiaiDoanSinhTruong(lst);
                setSrcImage(response.HinhAnh);
                setCayTrong(optionCayTrongs.filter(x => x.value == response.IdCayTrong).length == 0 ? { value: 0, label: 'Chọn cây trồng' } : optionCayTrongs.filter(x => x.value == response.IdCayTrong)[0]);
                loadOptionSauBenhById(response.Id);
                loadThang(response.ThoiGian);
                setCha(optionChas.filter(x => x.value == response.IdCha).length == 0 ? { value: 0, label: 'Chọn cha' } : optionChas.filter(x => x.value == response.IdCha)[0]);
                setGiongCayTrong(optionGiongCayTrongs.filter(x => x.value == response.IdGiongCay).length == 0 ? { value: 0, label: 'Chọn giống cây trồng' } : optionGiongCayTrongs.filter(x => x.value == response.IdGiongCay)[0]);
                setGiaiDoan(optionGiaiDoans.filter(x => x.value == response.IdGiaiDoan).length == 0 ? { value: 0, label: 'Chọn giai đoạn' } : optionGiaiDoans.filter(x => x.value == response.IdGiaiDoan)[0]);
                setLoaiSauBenh(response.LoaiSauBenh == undefined ? { value: true, label: 'Sâu bệnh gây hại' } : optionLoaiSauBenhs.filter(x => x.value == response.LoaiSauBenh)[0]);
                setMoTa(response.MoTa);
                setCanhTac(response.BienPhapCanhTac);
                setPhongTru(response.BienPhapPhongTru);
                setShow(true);
            });
        }
    }

    const handleShowData = () => {
        setIsXem(true);
        let index = listSauBenh.findIndex(x => x.Checked);
        if (index !== -1) {
            SauBenhApi.getDetail(listSauBenh[index].Id).then((response) => {
                reset({
                    Id: response.Id,
                    TenSauBenh: response.TenSauBenh,
                    TenKhoaHoc: response.TenKhoaHoc,
                    MoTa: response.MoTa,
                    GhiChu: response.GhiChu,
                    Status: response.Status,
                    NhietDoMax: response.NhietDoMax,
                    NhietDoMin: response.NhietDoMin,
                }, [reset]);
                setSauBenh(response);
                setSrcImage(response.HinhAnh);
                setCayTrong(optionCayTrongs.filter(x => x.value == response.IdCayTrong).length == 0 ? { value: 0, label: 'Chọn cây trồng' } : optionCayTrongs.filter(x => x.value == response.IdCayTrong)[0]);
                loadOptionSauBenhById(response.Id);
                loadThang(response.ThoiGian);
                setCha(optionChas.filter(x => x.value == response.IdCha).length == 0 ? { value: 0, label: 'Chọn cha' } : optionChas.filter(x => x.value == response.IdCha)[0]);
                setGiongCayTrong(optionGiongCayTrongs.filter(x => x.value == response.IdGiongCay).length == 0 ? { value: 0, label: 'Chọn giống cây trồng' } : optionGiongCayTrongs.filter(x => x.value == response.IdGiongCay)[0]);
                setGiaiDoan(optionGiaiDoans.filter(x => x.value == response.IdGiaiDoan).length == 0 ? { value: 0, label: 'Chọn giai đoạn' } : optionGiaiDoans.filter(x => x.value == response.IdGiaiDoan)[0]);
                setLoaiSauBenh(response.LoaiSauBenh == undefined ? { value: true, label: 'Sâu bệnh gây hại' } : optionLoaiSauBenhs.filter(x => x.value == response.LoaiSauBenh)[0]);
                setMoTa(response.MoTa);
                setCanhTac(response.BienPhapCanhTac);
                setPhongTru(response.BienPhapPhongTru);
                setShow(true);
            });
        }
    }

    const onSubmit = async (data) => {
        try {
            sauBenh.HinhAnh = srcImage == undefined ? noImage : srcImage;
            sauBenh.IdCayTrong = cayTrong.value;
            sauBenh.IdGiongCay = giongCayTrong.value;
            sauBenh.IdCha = cha.value;
            sauBenh.IdGiaiDoan = giaiDoan.value;
            sauBenh.MoTa = moTa;
            sauBenh.BienPhapCanhTac = canhTac;
            sauBenh.BienPhapPhongTru = phongTru;
            sauBenh.GiaiDoanSinhTruongPhatTrien = listGiaiDoanSinhTruong.filter(x => x.Ten.trim() != '');
            // sauBenh.LoaiSauBenh = loaiSaubenh.value;
            sauBenh.LoaiSauBenh = props.LoaiSauBenh;
            if (!thoiGianSelect.length === 0) {
                let tg = '';
                thoiGianSelect.forEach(x => {
                    if (tg === '') {
                        tg += x.value;
                    }
                    else {
                        tg += ',' + x.value;
                    }
                });
                sauBenh.ThoiGian = tg;
            }

            if (!isEdit) {
                await SauBenhApi.post(sauBenh);
            }
            else {
                await SauBenhApi.put(sauBenh);
            }
            setShow(false);
            loadSauBenh();
        }
        catch (ex) {
            console.log(ex.message);
        }
    }

    const loadCayTrong = async () => {
        const rePonse = await CayTrongApi.getAll();
        let list = [];
        list.push({ value: 0, label: 'Chọn cây trồng' });
        rePonse.map(x => {
            list.push({ 'value': x.Id, 'label': x.TenCayTrong });
        });
        setOptionCayTrong(list);
    }

    const loadGiongCayTrong = async () => {
        const rePonse = await GiongCayTrongApi.getAll();
        let list = [];
        rePonse.map(x => {
            list.push({ 'value': x.Id, 'label': x.TenGiong });
        });
        setOptionGiongCayTrong(list);
    }

    const loadGiaiDoan = async () => {
        const rePonse = await GiaiDoanPhatTrienApi.getAll();
        rePonse.map(x => {
            optionGiaiDoans.push({ 'value': x.Id, 'label': x.TenGiaiDoan });
        })
    }

    const loadSauBenhCha = async () => {
        const rePonse = await SauBenhApi.getAll();
        rePonse.map(x => {
            optionChas.push({ 'value': x.Id, 'label': x.TenSauBenh });
        })
    }

    useEffect(() => {
        loadSauBenh();
        loadCayTrong();
        loadGiongCayTrong();
        loadGiaiDoan();
        loadSauBenhCha();
        listGiaiDoanSinhTruong.push(newItem_GiaiDoanPhatTrien);
        if (props.LoaiSauBenh == true) {
            setTitle("Sinh vật gây hại");
            setRoleAdd(Authen.checkQuyen('SINHVATGAYHAI_CREATE'));
            setRoleEdit(Authen.checkQuyen('SINHVATGAYHAI_UPDATE'));
            setRoleDel(Authen.checkQuyen('SINHVATGAYHAI_DELETE'));
        }
        else {
            setTitle("Bệnh hại cây trồng");
            setRoleAdd(Authen.checkQuyen('BENHHAICAYTRONG_CREATE'));
            setRoleEdit(Authen.checkQuyen('BENHHAICAYTRONG_UPDATE'));
            setRoleDel(Authen.checkQuyen('BENHHAICAYTRONG_DELETE'));
        }
    }, []);

    const setFilter = (e) => {
        setSearch(e.target.value);
    }


    const alertDell = async () => {
        let lstChecked = listSauBenh.filter(x => x.Checked);
        if (lstChecked.length > 0) {
            ThongBao.printConfirmationDialog("Bạn có đồng ý xóa dữ liệu đã chọn hay không?", () => {
                SauBenhApi.delete(lstChecked[0].Id).then(() => {
                    loadSauBenh();
                })
            });
        }
    }

    const Timkiem = () => {
        loadSauBenh();
    }

    const onChangeMoTa = (event, editor) => {
        const data = editor.getData();
        setMoTa(data);
    }

    const onChangeCanhTac = (event, editor) => {
        const data = editor.getData();
        setCanhTac(data);
    }

    const onChangePhongTru = (event, editor) => {
        const data = editor.getData();
        setPhongTru(data);
    }

    const onChangeSelectView = (optionSelected) => {
        setLoaiSauView(optionSelected);
        loadSauBenh();
    }

    const onChangedSelectLoaiCay = (event) => {
        setSelectLoaiCay(event);
        loadSauBenh();
    }

    const handleShowImport = () => {
        setShowImport(true);
    }

    const handleCloseImport = () => {
        setShowImport(false);
    }

    const onChangeTen = (event, item, index) => {
        item.Ten = event.target.value;
        if (index + 1 == listGiaiDoanSinhTruong.length) {
            listGiaiDoanSinhTruong.push(newItem_GiaiDoanPhatTrien);
        }
        setListGiaiDoanSinhTruong([...listGiaiDoanSinhTruong]);
    }

    const onChangeDonVi = (event, item) => {
        item.DonVi = event.target.value;
        setListGiaiDoanSinhTruong([...listGiaiDoanSinhTruong]);
    }

    const onChangeTieuChuan = (event, item) => {
        item.TieuChuan = event.target.value;
        setListGiaiDoanSinhTruong([...listGiaiDoanSinhTruong]);
    }

    const removeItem_Style = {
        color: 'red',
        cursor: 'pointer'
    }

    const onClickRemoveItem = (index) => {
        if (index < listGiaiDoanSinhTruong.length - 1) {
            listGiaiDoanSinhTruong.splice(index, 1);
        }
        setListGiaiDoanSinhTruong([...listGiaiDoanSinhTruong]);
    }

    return (
        <div className="card shadow mb-1">
            <div className="card-header py-2">
                <div className="row ml-1">
                    <div className="col-md-2">
                        <h6 className="m-0 font-weight-bold text-primary">{title}</h6>
                    </div>
                    <div className="col-md-2">
                        <Select options={optionCayTrongs} onChange={onChangedSelectLoaiCay} value={selectLoaiCay}
                            theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                                height: 30,
                                spacing: {
                                    controlHeight: 30,
                                    menuGutter: 1,
                                    baseUnit: 2,
                                }
                            })} />
                    </div>
                    {/* <div className="col-md-2">
                        <Select options={optionViews} onChange={onChangeSelectView} value={loaiSauView}
                            theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                                height: 30,
                                spacing: {
                                    controlHeight: 30,
                                    menuGutter: 1,
                                    baseUnit: 2,
                                }
                            })} />
                    </div> */}
                    <div className="col-md-4">
                        <div className="input-group">
                            <input type="text" className="form-control" placeholder="Tìm kiếm" value={search} onChange={setFilter} />
                            <div className="input-group-append">
                                <button className="btn btn-info btn-sm" type="button" onClick={Timkiem} ><i className='fas fa-search'></i></button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 text-right">
                        {roleAdd === true ? <button type="button" className="btn btn-success btn-sm ml-1" onClick={handleShowImport}><i className='fas fa-file-excel'></i> Import</button> : ''}
                        {roleAdd === true ? <button type="button" className="btn btn-success btn-sm ml-1" onClick={handleShowAdd}><i className='fas fa-plus'></i> </button> : ''}
                        {roleEdit === true ? <button type="button" className="btn btn-info btn-sm ml-1" disabled={disButtonEdit} onClick={handleShowEdit}><i className='far fa-edit'></i> </button> : ''}
                        {roleDel === true ? <button type="button" className="btn btn-danger btn-sm ml-1" disabled={disButtonEdit} onClick={alertDell} ><i className='fas fa-trash-alt'></i> </button> : ''}
                        <button type="button" className="btn btn-primary btn-sm ml-1 mr-2" disabled={disButtonEdit} onClick={handleShowData}><i className='far fa-eye'></i> </button>
                    </div>
                </div>
            </div>

            <div className="card-body">
                <div className="import-body table-import import-scroll">
                    <table className="table table-bordered" width="100%">
                        <thead>
                            <tr>
                                <th><input name="Active" type="Checkbox"></input></th>
                                <th>Hình ảnh</th>
                                <th>Tên sâu bệnh</th>
                                <th>Tên khoa học</th>
                                <th>Mô tả</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                listSauBenh.map((item, key) => {
                                    return (
                                        <tr className="SelectRow" key={key} onClick={() => rowClick(item.Id)}>
                                            <td className="text-center">
                                                <input type="Checkbox" checked={item.Checked} defaultChecked={false} readOnly></input>
                                            </td>
                                            <td className="col-img">
                                                <img className="img-profile rounded-circle" src={apiUrl + item.HinhAnh} />
                                            </td>
                                            <td>{item.TenSauBenh}</td>
                                            <td>{item.TenKhoaHoc}</td>
                                            <td>{item.MoTa}</td>
                                            <td className="text-center">{item.Status ? <span className="label label-success cls-status-label">Kích hoạt</span> : <span className="label label-danger cls-status-label">Khóa</span>}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <div className="col-md-12 text-right">
                    <Pagination {...paginationConfig} />
                </div>
            </div>

            <Modal dialogclassName="modalSB" show={show} onHide={handleClose} backdrop="static" keyboard={false} animation={false} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container-fluid">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Tabs defaultActiveKey="thongtin">
                                <Tab eventKey="thongtin" title="Thông tin">
                                    <div className="form-group row" hidden={true}>
                                        <div className="col-md-2 col-sm-6 col-xl-2 col-form-label  nhanLH">
                                            <label className="nhanTK">ID <code>*</code></label>
                                        </div>
                                        <div className="col-md-10 col-sm-6 col-xl-10">
                                            <input className="form-control" name="Id" type="Text" ref={register()} onChange={e => sauBenh.Id = e.target.value}></input>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <div className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">
                                            Tên sâu bệnh <code>*</code>
                                        </div>
                                        <div className="col-md-4 col-sm-6 col-xl-4">
                                            <input className="form-control" name="TenSauBenh" type="Text" ref={register({ required: "Tên sâu bệnh không được trống" })}
                                                onChange={e => sauBenh.TenSauBenh = e.target.value}   ></input>
                                            <span className="eRor">{errors.TenSauBenh && errors.TenSauBenh.message}</span>
                                        </div>
                                        <div className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">
                                            Tên khoa học
                                        </div>
                                        <div className="col-md-4 col-sm-6 col-xl-4">
                                            <input className="form-control" name="TenKhoaHoc" type="Text" ref={register()} onChange={e => sauBenh.TenKhoaHoc = e.target.value}></input>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <div className="col-md-2 col-sm-3 col-xl-2 col-form-label nhanLH">
                                            Cây trồng
                                        </div>
                                        <div className="col-md-4 col-sm-3 col-xl-4">
                                            <Select options={optionCayTrongs} onChange={onChangeSelect} value={cayTrong}
                                                theme={theme => ({
                                                    ...theme,
                                                    borderRadius: 0,
                                                    height: 30,
                                                    spacing: {
                                                        controlHeight: 30,
                                                        menuGutter: 1,
                                                        baseUnit: 2,
                                                    }
                                                })} />
                                        </div>
                                        <div className="col-md-2 col-sm-3 col-xl-2 col-form-label nhanLH">
                                            Sâu bệnh cha
                                        </div>
                                        <div className="col-md-4 col-sm-3 col-xl-4">
                                            <Select options={optionChaEdits} onChange={onChangeSelectCha} value={cha}
                                                theme={theme => ({
                                                    ...theme,
                                                    borderRadius: 0,
                                                    height: 30,
                                                    spacing: {
                                                        controlHeight: 30,
                                                        menuGutter: 1,
                                                        baseUnit: 2,
                                                    }
                                                })} />
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <div className="col-md-2 col-sm-2 col-xl-2 col-form-label nhanLH">
                                            Thời gian
                                        </div>
                                        <div className="col-md-10 col-sm-10 col-xl-10">
                                            <Select options={optionThoiGian} placeholder='Chọn tháng' onChange={onChangeSelectThoiGian} value={thoiGianSelect} isMulti closeMenuOnSelect={false} hideSelectedOptions={false} theme={theme => ({
                                                ...theme,
                                                borderRadius: 0,
                                                height: 30,
                                                spacing: {
                                                    controlHeight: 30,
                                                    menuGutter: 1,
                                                    baseUnit: 2,
                                                }
                                            })} />
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <div className="col-md-2 col-sm-2 col-xl-2 col-form-label nhanLH">
                                            Nhiệt độ max
                                        </div>
                                        <div className="col-md-4 col-sm-4 col-xl-4">
                                            <input className="form-control" name="NhietDoMax" type="number" ref={register()} onChange={e => sauBenh.NhietDoMax = e.target.value}></input>
                                        </div>
                                        <div className="col-md-2 col-sm-2 col-xl-2 col-form-label nhanLH">
                                            Nhiệt độ min
                                        </div>
                                        <div className="col-md-4 col-sm-4 col-xl-4">
                                            <input className="form-control" name="NhietDoMin" type="number" ref={register()} onChange={e => sauBenh.NhietDoMin = e.target.value}></input>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <div className="col-md-2 col-sm-3 col-xl-2 col-form-label nhanLH">
                                            Giống cây trồng
                                    </div>
                                        <div className="col-md-4 col-sm-3 col-xl-4">
                                            <Select options={optionGiongCayTrongs} onChange={onChangeSelectGiong} value={giongCayTrong} theme={theme => ({
                                                ...theme,
                                                borderRadius: 0,
                                                height: 30,
                                                spacing: {
                                                    controlHeight: 30,
                                                    menuGutter: 1,
                                                    baseUnit: 2,
                                                }
                                            })} />
                                        </div>
                                        <div className="col-md-2 col-sm-3 col-xl-2 col-form-label nhanLH">
                                            Giai Đoạn
                                    </div>
                                        <div className="col-md-4 col-sm-3 col-xl-4">
                                            <Select options={optionGiaiDoans} onChange={onChangeSelectGiaiDoan} value={giaiDoan} theme={theme => ({
                                                ...theme,
                                                borderRadius: 0,
                                                height: 30,
                                                spacing: {
                                                    controlHeight: 30,
                                                    menuGutter: 1,
                                                    baseUnit: 2,
                                                }
                                            })} />
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <div className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">
                                            Ghi chú
                                        </div>
                                        <div className="col-md-10 col-sm-6 col-xl-10">
                                            <textarea className="form-control textrea-size" rows="2" placeholder="Nhập ghi chú" name="GhiChu" type="Text" ref={register()} onChange={e => sauBenh.GhiChu = e.target.value}></textarea>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <div className="col-md-2 col-sm-3 col-xl-2 col-form-label nhanLH">
                                            Trạng thái
                                        </div>
                                        <div className="col-md-4 col-sm-3 col-xl-4 text-left mt-2">
                                            <input name="Status" type="checkbox" ref={register()} onChange={e => sauBenh.Status = e.target.value}></input>
                                        </div>
                                        {/* <div className="col-md-2 col-sm-3 col-xl-2 col-form-label nhanLH">
                                            Loại sâu bệnh
                                        </div>
                                        <div className="col-md-4 col-sm-3 col-xl-4 text-left mt-2">
                                            <Select options={optionLoaiSauBenhs} onChange={onChangeSelectLoaiSauBenh} value={loaiSaubenh}
                                                theme={theme => ({
                                                    ...theme,
                                                    borderRadius: 0,
                                                    height: 30,
                                                    spacing: {
                                                        controlHeight: 30,
                                                        menuGutter: 1,
                                                        baseUnit: 2,
                                                    }
                                                })} />
                                        </div> */}
                                    </div>
                                    <div className="form-group row mb-1">
                                        <div className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">
                                            Hình ảnh
                                        </div>
                                        <div className="col-md-10 col-sm-6 col-xl-10 thumbnail">
                                            <img name="HinhAnh" ref={register} src={apiUrl + srcImage} onClick={openModalImage} />
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="mota" title="Mô tả">
                                    <div className="form-group row">
                                        <div className="col-sm-12">
                                            <CKEditor editor={ClassicEditor} data={moTa} onChange={(event, editor) => onChangeMoTa(event, editor)} />
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="canhtac" title="Canh tác">
                                    <div className="form-group row">
                                        <div className="col-sm-12">
                                            <CKEditor editor={ClassicEditor} data={canhTac} onChange={(event, editor) => onChangeCanhTac(event, editor)} />
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="phongtru" title="Phòng trừ">
                                    <div className="form-group row">
                                        <div className="col-sm-12">
                                            <CKEditor editor={ClassicEditor} data={phongTru} onChange={(event, editor) => onChangePhongTru(event, editor)} />
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="giaidoan" title="Giai đoạn">
                                    <table className="table table-bordered" width="100%">
                                        <thead>
                                            <tr>
                                                <th>Tên</th>
                                                <th>Tiêu chuẩn</th>
                                                <th>Đơn vị</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {listGiaiDoanSinhTruong.map((item, key) => {
                                                return (
                                                    <tr>
                                                        <td> <input className="form-control" name={'ten' + key} type="text" ref={register()} placeholder="Nhập tên" onChange={(event) => onChangeTen(event, item, key)} value={item.Ten}></input></td>
                                                        <td><input className="form-control" name={'tieuchuan' + key} type="number" ref={register()} placeholder="Nhập tiêu chuẩn" onChange={(event) => onChangeTieuChuan(event, item)} value={item.TieuChuan}></input></td>
                                                        <td><input className="form-control" name={'donvi' + key} type="text" ref={register()} placeholder="Nhập đơn vị" onChange={(event) => onChangeDonVi(event, item)} value={item.DonVi}></input></td>
                                                        <td><i class="fa fa-trash" style={removeItem_Style} aria-hidden="true" onClick={() => onClickRemoveItem(key)}></i></td>
                                                    </tr>
                                                )
                                            }
                                            )}
                                        </tbody>
                                    </table>
                                    {/* <div className="form-group row">
                                        <div className="col-sm-12">
                                            <CKEditor editor={ClassicEditor} data={phongTru} onChange={(event, editor) => onChangePhongTru(event, editor)} />
                                        </div>
                                    </div> */}
                                </Tab>
                            </Tabs>
                        </form>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <a href="#" type="button" className="btn btn-info btn-sm ml-1" onClick={handleSubmit(onSubmit)} hidden={isXem}><i className='fas fa-save'></i> Lưu</a>
                    <a href="#" type="button" className="btn btn-danger btn-sm ml-1" onClick={handleClose} disabled><i className="fas fa-sign-out-alt"></i> Hủy</a>
                </Modal.Footer>
            </Modal>
            {
                viewModal ? <QuanLyTapTin viewModal={viewModal} closeModal={closeModalImage} onFileSelected={(e) => onFileSelected(e)}></QuanLyTapTin> : ''
            }
            {
                showImport ? <ImportExcel show={showImport} handleClose={handleCloseImport}></ImportExcel> : ''
            }
            <Loading loading={loading}></Loading>
        </div>
    )
}

