import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import LienHeApi from '../../../api/LienHeApi';
import '../../../css/reacttab.css';
import Pagination from 'react-bootstrap-4-pagination';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import '../../../css/apppublic.css';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Authen from '../../../core/Authen';
import { QuanLyTapTin } from '../../../shared/quanlytaptin/QuanLyTapTin';

export const LienHe = () => {
    const [listLienHes, setListLienHe] = useState([]);
    const [filter, setFilter] = useState('');
    const [show, setShow] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isXem, setIsXem] = useState(false);
    const [lienHe, setLienHe] = useState({});
    const [ckdData, setckdData] = useState('');
    const [roleEdit, setRoleEdit] = useState(false);

    const { handleSubmit, register, reset, errors } = useForm();

    var apiUrl = process.env.REACT_APP_URL;
    var noImage = "/uploadfiles/website/no_image-100x100.jpg";
    const [srcImage, setSrcImage] = useState(noImage);
    const [viewModal, setViewModal] = useState(false);


    const [paginationConfig, setPaginationConfig] = useState({
        totalPages: 1,
        currentPage: 1,
        showMax: 5,
        size: 'sm',
        threeDots: true,
        prevNext: true,
        onClick: function (page) {
            if (page != null) {
                pageIndex = page;
                loadLienHe();
            }
        }
    });

    var pageIndex = 1, pageSize = 20;

    const loadLienHe = async () => {
        try {
            const params = {
                keyword: filter,
                pageIndex: pageIndex,
                pageSize: pageSize
            }
            const res = await LienHeApi.getLienHe(params);
            res.Items.map((x) => {
                x.Checked = false
            });
            setListLienHe(res.Items);
            paginationConfig.totalPages = Math.ceil(res.TotalRow == 0 ? 1 : res.TotalRow / pageSize);
            paginationConfig.currentPage = pageIndex;
            setPaginationConfig(paginationConfig);
            setIsEdit(false);
            setLienHe({});
        }
        catch {
        }

    }

    useEffect(() => {
        loadLienHe();
        setRoleEdit(Authen.checkQuyen('LIENHE_UPDATE'));
    }, [])

    const setFilt = (e) => {
        setFilter(e.target.value);
    }

    const Timkiem = () => {
        loadLienHe();
    }

    const handleClose = () => {
        setShow(false);
        setIsXem(false);
        setckdData(lienHe.other);
    }
    const handleShow = () => {
        let index = listLienHes.findIndex(x => x.Checked);
        if (index !== -1) {
            LienHeApi.getDetail(listLienHes[index].Id).then((x) => {
                reset({
                    id: x.id,
                    name: x.name,
                    phone: x.phone,
                    email: x.email,
                    website: x.website,
                    address: x.address,
                    other: x.other,
                    lat: x.lat,
                    lng: x.lng,
                    status: x.status,
                    logo: x.logo,
                    facebook: x.facebook,
                    youtube: x.youtube,
                    youtubedetail: x.youtubedetail,
                    google: x.google,
                    hotline: x.hotline,
                    description: x.description,
                    zalo: x.zalo,
                    instagram: x.instagram,
                    fax: x.fax,
                    sologan1: x.sologan1,
                    sologan2: x.sologan2
                }, [reset])
                setIsEdit(true);
                setLienHe(x);
                setSrcImage(x.logo);
            });
        }


        setIsXem(false);
        setShow(true);
    }

    const rowClick = (id) => {
        listLienHes.map((x) => {
            if (x.id === id) {
                x.Checked = !x.Checked;
            }
        });
        setListLienHe([...listLienHes]);
        let index = listLienHes.findIndex(x => x.Checked);
        if (index !== -1) {
            setIsEdit(true);
        }

    }

    const handleShowData = () => {
        let index = listLienHes.findIndex(x => x.Checked);
        if (index !== -1) {
            LienHeApi.getDetail(listLienHes[index].id).then((x) => {
                reset({
                    id: x.id,
                    name: x.name,
                    phone: x.phone,
                    email: x.email,
                    website: x.website,
                    address: x.address,
                    other: x.other,
                    lat: x.lat,
                    lng: x.lng,
                    status: x.status,
                    logo: x.logo,
                    facebook: x.facebook,
                    youtube: x.youtube,
                    youtubedetail: x.youtubedetail,
                    google: x.google,
                    hotline: x.hotline,
                    description: x.description,
                    zalo: x.zalo,
                    instagram: x.instagram,
                    fax: x.fax,
                    sologan1: x.sologan1,
                    sologan2: x.sologan2
                }, [reset])
                setIsEdit(true);
                setLienHe(x);
                setSrcImage(x.logo);
            });
        }
        setIsXem(true);
        setShow(true);
    }

    const changeCK = (event, editor) => {

        setckdData(editor.getData());

    }

    const changeTab = () => {
        reset({
            id: lienHe.id,
            name: lienHe.name,
            phone: lienHe.phone,
            email: lienHe.email,
            website: lienHe.website,
            address: lienHe.address,
            other: lienHe.other,
            lat: lienHe.lat,
            lng: lienHe.lng,
            status: lienHe.status,
            logo: lienHe.logo,
            facebook: lienHe.facebook,
            youtube: lienHe.youtube,
            youtubedetail: lienHe.youtubedetail,
            google: lienHe.google,
            hotline: lienHe.hotline,
            description: lienHe.description,
            zalo: lienHe.zalo,
            instagram: lienHe.instagram,
            fax: lienHe.fax,
            sologan1: lienHe.sologan1,
            sologan2: lienHe.sologan2
        }, [reset])
        setckdData(lienHe.other);
    }

    const onSubmit = async (data) => {
        data = lienHe;
        if (ckdData !== '') {
            data.other = ckdData;
        }
        data.logo = srcImage;
        LienHeApi.updateLienHe(data).then(() => {
            setShow(false);
            loadLienHe();
        });
    };


    const openModalImage = () => {
        setViewModal(true);
    }

    const closeModalImage = () => {
        setViewModal(false);
    }
    const onFileSelected = (event) => {
        setSrcImage(event);
    }


    return (
        <div className="card shadow mb-1">
            <div className="card-header py-2">
                <div className="row ml-2">
                    <div className="col-md-4">
                        <h6 className="m-0 font-weight-bold text-primary">Liên hệ</h6>
                    </div>
                    <div className="col-md-4">
                        <div className="input-group">
                            <input type="text" className="form-control" placeholder="Tìm kiếm" value={filter} onChange={setFilt} />
                            <div className="input-group-append">
                                <button className="btn btn-info btn-sm" type="button" onClick={Timkiem} ><i className='fas fa-search'></i> Tìm kiếm</button>
                            </div>
                        </div>
                    </div>
                    <div className="fluid-container col-md-4 text-right">
                        {roleEdit === true ? <button type="button" className="btn btn-info btn-sm ml-1" disabled={!isEdit} onClick={handleShow}><i className='far fa-edit'></i> Sửa</button> : ''}
                        <button type="button" className="btn btn-primary btn-sm ml-1 mr-2" disabled={!isEdit} onClick={handleShowData}><i className='far fa-eye'></i> Xem</button>
                    </div>
                </div>
            </div>

            <div className="card-body">
                <div className="table-responsive">
                    <table className="table table-bordered" width="100%">
                        <thead>
                            <tr>
                                <th><input name="Active" type="Checkbox"></input></th>
                                <th>Tên công ty</th>
                                <th>Điện thoại</th>
                                <th>Đia chỉ</th>
                                <th>Trạng thái</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                listLienHes.map((item, key) => {
                                    return (
                                        <tr className="SelectRow" key={key} onClick={() => rowClick(item.id)}>
                                            <td className="text-center">
                                                <input type="Checkbox" checked={item.Checked} onChange={e => item.Checked = e.target.value}></input>
                                            </td>
                                            <td>{item.Name}</td>
                                            <td>{item.Phone}</td>
                                            <td>{item.Address}</td>
                                            <td className="text-center">{item.Status ? <span className="label label-success cls-status-label">Kích hoạt</span> : <span className="label label-danger cls-status-label">Khóa</span>}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <div className="col-md-12 text-right">
                    <Pagination {...paginationConfig} />
                </div>
            </div>
            <Modal dialogClassName="modalLH" show={show} onHide={handleClose} backdrop="static" keyboard={false} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Liên hệ</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tabs >
                        <TabList>
                            <Tab onClick={changeTab}>Thông tin cơ bản</Tab>
                            <Tab onClick={changeTab}>Thông tin thêm</Tab>
                        </TabList>
                        <TabPanel>
                            <div className="container-fluid">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-group row" hidden={true}>
                                        <div className="col-md-3 col-sm-6 col-xl-3 col-form-label">
                                            <label className="nhanTK">ID <code>*</code></label>
                                        </div>
                                        <div className="col-md-9 col-sm-6 col-xl-9">
                                            <input className="form-control" name="id" type="Text" ref={register()} onChange={e => lienHe.id = e.target.value}></input>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">
                                            Tên công ty <code>*</code>
                                        </div>
                                        <div className="col-md-10 col-sm-6 col-xl-10">
                                            <input className="form-control" name="name" type="Text" ref={register({ required: "Tên công ty không được trống" })} onChange={e => lienHe.name = e.target.value}></input>
                                            <span className="eRor">{errors.name && errors.name.message}</span>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-2 col-sm-6 col-xl-2 col-form-label  nhanLH">
                                            Số điện thoại
                                </div>
                                        <div className="col-md-4 col-sm-6 col-xl-4">
                                            <input className="form-control" name="phone" type="Text" ref={register()} onChange={e => lienHe.phone = e.target.value}></input>
                                        </div>
                                        <div className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">
                                            Email <code>*</code>
                                        </div>
                                        <div className="col-md-4 col-sm-6 col-xl-4">
                                            <input className="form-control" name="email" type="Text" ref={register(
                                                // {
                                                //     required: "Email không được trống",
                                                //     pattern: {
                                                //         value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                //         message: "Sai định dạng email"
                                                //     }
                                                // }
                                            )} onChange={e => lienHe.email = e.target.value}></input>
                                            <span className="eRor">{errors.email && errors.email.message}</span>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">
                                            Hotline
                                </div>
                                        <div className="col-md-4 col-sm-6 col-xl-4">
                                            <input className="form-control" name="hotline" type="Text" ref={register()} onChange={e => lienHe.hotline = e.target.value}></input>
                                        </div>
                                        <div class="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">
                                            Website
                                </div>
                                        <div className="col-md-4 col-sm-6 col-xl-4">
                                            <input className="form-control" name="website" type="Text" ref={register()} onChange={e => lienHe.website = e.target.value}></input>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">
                                            Fax
                                </div>
                                        <div className="col-md-4 col-sm-6 col-xl-4">
                                            <input className="form-control" name="fax" type="Text" ref={register()} onChange={e => lienHe.fax = e.target.value}></input>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">
                                            Địa chỉ
                                </div>
                                        <div className="col-md-10 col-sm-6 col-xl-10">
                                            <input className="form-control" name="address" type="Text" ref={register()} onChange={e => lienHe.address = e.target.value}></input>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">
                                            Facebook
                                </div>
                                        <div className="col-md-4 col-sm-6 col-xl-4">
                                            <input className="form-control" name="facebook" type="Text" ref={register()} onChange={e => lienHe.facebook = e.target.value}></input>
                                        </div>
                                        <div className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">
                                            Youtube
                                </div>
                                        <div className="col-md-4 col-sm-6 col-xl-4">
                                            <input className="form-control" name="youtube" type="Text" ref={register()} onChange={e => lienHe.youtube = e.target.value}></input>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">
                                            Zalo
                                </div>
                                        <div className="col-md-4 col-sm-6 col-xl-4">
                                            <input className="form-control" name="zalo" type="Text" ref={register()} onChange={e => lienHe.zalo = e.target.value}></input>
                                        </div>
                                        <div className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">
                                            App IOS
                                </div>
                                        <div className="col-md-4 col-sm-6 col-xl-4">
                                            <input className="form-control" name="instagram" type="Text" ref={register()} onChange={e => lienHe.instagram = e.target.value}></input>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">
                                            Sologan
                                </div>
                                        <div className="col-md-4 col-sm-6 col-xl-4">
                                            <input className="form-control" name="sologan1" type="Text" ref={register()} onChange={e => lienHe.sologan1 = e.target.value}></input>
                                        </div>
                                        <div className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">
                                            App Androi
                                </div>
                                        <div className="col-md-4 col-sm-6 col-xl-4">
                                            <input className="form-control" name="sologan2" type="Text" ref={register()} onChange={e => lienHe.sologan2 = e.target.value}></input>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">
                                            Kinh độ
                                </div>
                                        <div className="col-md-4 col-sm-6 col-xl-4">
                                            <input className="form-control" name="lng" type="Text" ref={register()} onChange={e => lienHe.lng = e.target.value}></input>
                                        </div>
                                        <div className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">
                                            Vĩ độ
                                </div>
                                        <div className="col-md-4 col-sm-6 col-xl-4">
                                            <input className="form-control" name="lat" type="Text" ref={register()} onChange={e => lienHe.lat = e.target.value}></input>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">
                                            Mô tả
                                </div>
                                        <div className="col-md-10 col-sm-6 col-xl-10">
                                            <textarea className="form-control" name="description" type="Text" ref={register()} onChange={e => lienHe.description = e.target.value}></textarea>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">
                                            Logo
                                </div>
                                        <div className="col-md-3 col-sm-6 col-xl-3">
                                            <div class="thumbnail" >
                                                <img name="Image" ref={register} src={apiUrl + srcImage} onClick={openModalImage} />
                                            </div>

                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">
                                            Trạng thái
                                </div>
                                        <div className="col-md-3 col-sm-6 col-xl-3 text-left mt-2">
                                            <input type="Checkbox" name="status" ref={register()} onChange={e => lienHe.status = e.target.checked}></input>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className="form-group row">
                                <div className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">
                                    Hiển thị liên hệ
                                </div>
                                <div className="col-md-10 col-sm-6 col-xl-10 ">
                                    <CKEditor className="ck-editor" editor={ClassicEditor} name="other" data={ckdData} onChange={(event, editor) => setckdData(editor.getData())} />
                                </div>
                            </div>
                        </TabPanel>
                    </Tabs>
                </Modal.Body>
                <Modal.Footer>
                    <a href="#" type="button" className="btn btn-info btn-sm ml-1" onClick={handleSubmit(onSubmit)} hidden={isXem}><i className='fas fa-save'></i> Lưu</a>
                    <a href="#" type="button" className="btn btn-danger btn-sm ml-1" onClick={handleClose} disabled><i className="fas fa-sign-out-alt"></i> Hủy</a>
                </Modal.Footer>
            </Modal>
            <QuanLyTapTin viewModal={viewModal} closeModal={closeModalImage} onFileSelected={(e) => onFileSelected(e)}></QuanLyTapTin>
        </div>
    );
};
