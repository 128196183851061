const { default: axiosClient } = require("./axiosClient");

const SauBenhApi = {

    getAll: (params) => {       
        const url = '/saubenh/getall';
        return axiosClient.get(url, {params});
    },

    getPaging: (params) => {       
        const url = '/saubenh/paging';
        return axiosClient.get(url, {params});
    },

    getDetail: (id) =>{
        const url = '/saubenh/getbyid/' + id;
        return axiosClient.get(url);
    },

    post: (data) =>{
        const url = '/saubenh/add';
        return axiosClient.post(url, data);
    },

    put: (data) => {
        const url = '/saubenh/edit';
        return axiosClient.put(url, data);
    },

    delete: (id) =>{
        const url = '/saubenh/delete/' + id;
        return axiosClient.delete(url);
    },

    getXemNhieuNhat: () => {
        return axiosClient.get('/saubenh/getxemnhieu');
    },

    getMoiNhat: () => {
        return axiosClient.get('/saubenh/getmoinhat');
    },

    timKiemSauBenh: (params) =>{
        return axiosClient.get('/saubenh/timkiemsaubenh',{params});
    },

    timKiemSauBenhChiTiet: (id) =>{
        const url = '/saubenh/timkiemsaubenhgetbyid/' + id;
        return axiosClient.get(url);
    },

    timKiemSauBenh_AnhHuong: (params) =>{
        return axiosClient.get('/saubenh/timkiemsaubenhanhhuong',{params});
    },

    getByTrieuChung: (params) =>{
        return axiosClient.get('/saubenh/getbytrieuchung',{params});
    },

    getAllGiaiDoanSinhTruong: (params) =>{
        return axiosClient.get('/saubenh/getallgiaidoansinhtruong',{params});
    },

    getGiaiDoanSinhTruongBySauBenh: (idSauBenh) =>{
        return axiosClient.get('/saubenh/getgiaidoansinhtruongbysaubenh/' + idSauBenh);
    }
}

export default SauBenhApi;