import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap';
import Select from 'react-select';
import ThongBao from '../../../core/ThongBao';
import CayTrongApi from '../../../api/CayTrongApi';
import { ExcelRenderer } from "react-excel-renderer";
import { Loading } from '../../../shared/loading/Loading';
import ImportExcelSauBenhHaiApi from '../../../api/ImportExcelSauBenhHaiApi';

export const ImportExcel = (props) => {
    const [listDS, setListDS] = useState([]);
    const [cayTrong, setCayTrong] = useState({ value: 0, label: 'Chọn cây trồng' });
    const [optionCayTrong, setOptionCayTrong] = useState([]);
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [optionLoaiSauBenh, setOptionLoaiSauBenh] = useState([{ value: 1, label: 'Sâu hại' }, { value: 0, label: 'Bệnh hại' }]);
    const [loaiSauBenh, setLoaiSauBenh] = useState({});
    const handleClose = () => {
        setShow(false);
        props.handleClose();
    }

    const loadCayTrong = async () => {
        const rePonse = await CayTrongApi.getAll();
        let lstCayTrong = [{ value: 0, label: 'Chọn cây trồng' }];
        if (!rePonse.length == false) {
            rePonse.forEach(x => {
                lstCayTrong.push({ value: x.Id, label: x.TenCayTrong });
            });
        }
        setOptionCayTrong(lstCayTrong);
    }

    useEffect(() => {
        if (props.show) {
            setShow(true);
        }
    }, [props.show])

    useEffect(() => {
        loadCayTrong();
        setLoaiSauBenh({ value: 1, label: 'Sâu hại' });
    }, [])

    const fileExcel = (e) => {
        if (e.target.value == null) {
            return;
        }
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onerror = function (error) {
            console.log('Error: ', error);
        }
        ExcelRenderer(file, (err, resp) => {
            if (err) {
                console.log(err);
                e.target.value = null;
                return;
            }
            if (resp.rows.length > 5) {
                debugger;
                var aa = resp.rows.length;
                let lst = [];
                let currentItem = {};
                let Item = {};
                resp.rows.map(async (x, key) => {
                    if (key > 5) {
                        Item = currentItem;
                        let x5 = x[6] == undefined ? Item.ThoiKy : x[6];

                        if (x5 == undefined || x5 == '') {
                            return;
                        }

                        let nhietdo = x[8] == undefined ? Item.NhietDo + '-' : x[8] + '-';
                        let lstNhietDo = [];
                        if (nhietdo != undefined) {
                            lstNhietDo = nhietdo.split('-');
                        }

                        currentItem = {
                            CayTrong: x[1] == undefined ? Item.CayTrong : x[1],
                            TenKhoaHoc: x[2] == undefined ? Item.TenKhoaHoc : x[2],
                            TenThuongGoi: x[3] == undefined ? Item.TenThuongGoi : x[3],
                            DacDiemSinhHoc: x[4] == undefined ? Item.DacDiemSinhHoc : x[4],
                            ThoiKy: x[5] == undefined ? Item.ThoiKy : x[5],
                            NoiDung: x[6] == undefined ? Item.NoiDung : x[6],
                            Anh: x[7],
                            AnhSau: x[7] != undefined ? x[7].split(',')[0] : '',
                            NhietDo: x[8] == undefined ? Item.NhietDo : x[8],
                            NhietDoMin: lstNhietDo.length > 0 ? lstNhietDo[0] : 0,
                            NhietDoMax: lstNhietDo.length > 1 ? lstNhietDo[1] : (lstNhietDo.length > 0 ? lstNhietDo[0] : 0),
                            ThoiGian: x[9] == undefined ? Item.ThoiGian : x[9],
                            NoiDungGayHai: x[10] == undefined ? Item.NoiDungGayHai : x[10],
                            MucDoThietHai: x[11] == undefined ? Item.MucDoThietHai : x[11],
                            BienPhapQuanLy: x[12] == undefined ? Item.BienPhapQuanLy : x[12],
                            ThuocBVTV: x[13] == undefined ? Item.ThuocBVTV : x[13],
                            LoaiSauBenh: x[4] == undefined || x[4] == '' ? 0 : 1,
                            IdCayTrong: null,
                            IsValid: true,
                            ThongBao: ""
                        }
                        lst.push(currentItem);
                    }
                });
                lst.forEach(element => {
                    debugger;
                    var index = -1;
                    index = optionCayTrong.findIndex(x => x.label.toLowerCase().trim().includes(element.CayTrong.toLowerCase().trim()))
                    if (index >= 0) {
                        element.IdCayTrong = optionCayTrong[index].value;
                    }
                    else {
                        element.IsValid = false;
                        element.ThongBao += "sai tên cây trồng, ";
                    }
                });
                setListDS(lst);
            }
        });
    }

    const saveData = async () => {
        var lst = listDS.filter(x => x.IsValid == false);
        if (lst.length > 0) {
            ThongBao.prinWarningMessage("Dữ liệu import không hợp lệ. Hãy kiểm tra lại các dòng dữ liệu bị sai (dòng có màu đỏ)")
        }
        else {
            let params = listDS
            console.log(listDS);
            const resuilt = await ImportExcelSauBenhHaiApi.saveFile(params)
            if (resuilt == 'OK') {
                ThongBao.printSuccessMessage('Import file thành công !!!');
                props.handleClose();
            }
        }
    }

    const onChangeSelectCayTrong = (optionSelected) => {
        setCayTrong(optionSelected);
    }

    const onChangeSelectLoaiSauBenh = (optionSelected) => {
        setLoaiSauBenh(optionSelected);
    }


    return (
        <Modal dialog className="modalSB" show={show} onHide={handleClose} backdrop="static" keyboard={false} animation={false} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Sâu bệnh</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="card-header row">
                    {/* <div className="col-md-3">
                        <Select options={optionCayTrong} onChange={onChangeSelectCayTrong} value={cayTrong} theme={theme => ({
                            ...theme,
                            borderRadius: 0,
                            height: 30,
                            spacing: {
                                controlHeight: 30,
                                menuGutter: 1,
                                baseUnit: 2,
                            }
                        })} />
                    </div>
                    <div className="col-md-3">
                        <Select options={optionLoaiSauBenh} onChange={onChangeSelectLoaiSauBenh} value={loaiSauBenh} theme={theme => ({
                            ...theme,
                            borderRadius: 0,
                            height: 30,
                            spacing: {
                                controlHeight: 30,
                                menuGutter: 1,
                                baseUnit: 2,
                            }
                        })} />
                    </div> */}
                    <div className="col-md-8">
                        <input type="file" onChange={fileExcel} />
                    </div>
                    <div className="col-md-4 text-right">
                        <a href="#">Tệp tin mẫu</a>
                    </div>
                </div>
                <div className="card-body">
                    <div className="table-import import-scroll" style={{ maxHeight: "calc(100vh - 240px)", minHeight: "300px" }}>
                        <table className="table table-bordered" width="100%">
                            <thead>
                                <tr>
                                    <th>Cây trồng</th>
                                    <th>Tên thường gọi</th>
                                    <th>thời kỳ</th>
                                    <th>Nhiệt độ min</th>
                                    <th>Nhiệt độ max</th>
                                    <th>Thời gian</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    listDS.map((item, key) => {
                                        return (
                                            <tr key={key} className={item.IsValid == true ? '' : 'text-danger'} data-toggle="tooltip" title={item.ThongBao}>
                                                <td>{item.CayTrong}</td>
                                                <td>{item.TenThuongGoi}</td>
                                                <td>{item.ThoiKy}</td>
                                                <td>{item.NhietDoMin}</td>
                                                <td>{item.NhietDoMax}</td>
                                                <td>{item.ThoiGian}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <a href="#" type="button" className="btn btn-info btn-sm ml-1" onClick={saveData}><i className='fas fa-save'></i> Lưu</a>
                <a href="#" type="button" className="btn btn-danger btn-sm ml-1" onClick={handleClose} disabled><i className="fas fa-sign-out-alt"></i> Hủy</a>
            </Modal.Footer>
            <Loading loading={loading}></Loading>
        </Modal>
    )
}