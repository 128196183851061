import axiosClient from './axiosClient';
const PhanQuyenApi = {
    getAll: () => {
        const url = '/permission/function-actions';
        return axiosClient.get(url);
    },   

    getbyRole: (params) => {
        const url = '/permission/role-permissions/'+ params.roleId;
        return axiosClient.get(url, {params});
    }, 

    post: (data) =>{
        const url = '/permission/save-permissions/' + data.roleId  ;
        return axiosClient.post(url, data.permissions);
    }    
}
export default PhanQuyenApi;