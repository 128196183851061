import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap';
import $ from 'jquery';
import Draggable from 'react-draggable';
import HoiDapApi from '../../api/HoiDapApi'
import CayTrongApi from '../../api/CayTrongApi'
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import moment from 'moment'
import Pagination from 'react-bootstrap-4-pagination';
import './hoidap.css'
import Authen from '../../core/Authen';

export const ThemCauHoi = (props) => {
    var apiUrl = process.env.REACT_APP_URL;
    var noImage = "/uploadfiles/website/no_image-100x100.jpg";
    const [selectedOption, setSelectedOption] = useState();
    const [listCayTrong, setListCayTrong] = useState([{ value: 0, label: 'Chọn cây trồng...' }]);
    const { register, handleSubmit, reset, errors } = useForm();
    const [show, setShow] = useState(false);
    const [currentItem, setCurrentItem] = useState({});
    const [nhanVien, setNhanVien] = useState({});

    useEffect(() => {
        if (props.show) {
            loadListCayTrong();
            setSelectedOption(listCayTrong[0]);
            reset({
                Id: "0",
                ChuDe: '',
                NoiDung: '',
                HoTen: '',
                Email: '',
                Tel: ''
            }, [reset]);
            setNhanVien(Authen.getUser());
            $('#modalgopy').modal('show');
            setShow(true);
        }
    }, [props.show]);

    const handleClose = () => {
        setShow(false);
        $('#modalgopy').modal('hide');
        $('#modalgopy').modal('dispose');
        props.closeModal();
    }

    //#region câu hỏi
    const saveDataCauHoi = (data) => {
        if (selectedOption) {
            if (selectedOption.value == 0) {
                data.IdCayTrong = null;
            }
            else {
                data.IdCayTrong = selectedOption.value;
            }
        }
        else {
            data.IdCayTrong = null;
        }
        data.Status = false;
        if (data.Id === "0" || data.Id == null) {
            data.Id = 0;
            HoiDapApi.postCauHoiPublic(data).then(() => {
                // var item = {
                //     Id: "0",
                //     ChuDe: '',
                //     NoiDung: '',
                //     HoTen: '',
                //     Email: '',
                //     Tel: ''
                // };
                // reset(item, [reset]);
                handleClose();
            })
        }
        else {
            HoiDapApi.put(data).then(() => {

            })
        }
    }

    const onChangeCayTrong = (e) => {
        setSelectedOption(e);
    }
    //#endregion

    function loadListCayTrong() {
        // CayTrongApi.getAll().then((response) => {
        //     let list = [];
        //     list.push({ value: 0, label: 'Chọn cây trồng...' });
        //     response.forEach(item => {
        //         list.push({ value: item.Id, label: item.TenCayTrong });
        //     })
        //     setListCayTrong(list);
        // })
        CayTrongApi.getAll().then((response) => {
            // let list = [];
            // list.push({ value: 0, label: 'Chọn cây trồng...' });
            // response.forEach(item => {
            //     list.push({ value: item.Id, label: item.TenCayTrong });
            // })
            response.forEach(element => {
                element.ClassCss = "btn-secondary";
            });
            if (response.length > 0) {
                response[0].ClassCss = "btn-success";
                setCurrentItem(response[0]);
            }
            setListCayTrong(response);
        })
    }

    const changeCayTrong = (item) => {
        listCayTrong.forEach(element => {
            element.ClassCss = "btn-secondary";
        });
        item.ClassCss = "btn-success";
        setListCayTrong([...listCayTrong]);
        setCurrentItem(item);
    }
    return (
        <Draggable handle=".modal-header">
            <div className="modal modal-custom" id="modalgopy" tabIndex={-1} data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Thêm câu hỏi</h5>
                            <button type="button" className="close" onClick={handleClose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form className="usesdsfdr" onSubmit={handleSubmit(saveDataCauHoi)}>
                                <div className="form-group row">
                                    <label className="col-sm-3">Chọn cây trồng
                                        <span className="text-danger"> *</span>
                                    </label>
                                    {
                                        listCayTrong.map((item, key) => {
                                            return (
                                                <div className="col-sm-2" key={key}>
                                                    <div className="caytrong-container">
                                                        <img src={apiUrl + (item.HinhAnh == null ? noImage : item.HinhAnh)} alt={item.TenCayTrong} onClick={() => changeCayTrong(item)} />
                                                        <button type="button" className={"btn btn-sm " + item.ClassCss} onClick={() => changeCayTrong(item)}> {item.TenCayTrong}</button>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>



                                {/* 
                                <input name="Id" hidden={true} ref={register} ></input>
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label">Cây trồng
                                <span className="text-danger"> *</span>
                                    </label>
                                    <div className="col-sm-9">
                                        <Select className="select" name="IdCayTrong" placeholder="Danh mục cây trồng" value={selectedOption} onChange={onChangeCayTrong} options={listCayTrong} />
                                    </div>
                                </div> */}
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label">Chủ đề
                                <span className="text-danger"> *</span>
                                    </label>
                                    <div className="col-sm-9">
                                        <input name="ChuDe" type="text" className="form-control" placeholder="Chủ đề" ref={register({ required: true })} />
                                        {errors.Name && <span className="text-danger">Hãy nhập dữ liệu</span>}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label">Nội dung
                                <span className="text-danger">*</span>
                                    </label>
                                    <div className="col-sm-9">
                                        <textarea name="NoiDung" className="form-control textrea-size" rows="5" placeholder="Nhập nội dung" ref={register({ required: true })}  ></textarea>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label">Họ tên
                                <span className="text-danger"> *</span>
                                    </label>
                                    <div className="col-sm-9">
                                        <input name="HoTen" type="text" className="form-control" placeholder="Họ tên" ref={register({ required: true })} />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label">Email
                                <span className="text-danger"> *</span>
                                    </label>
                                    <div className="col-sm-9">
                                        <input name="Email" type="text" className="form-control" placeholder="Email" ref={register({ required: true })} />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label">Điện thoại </label>
                                    <div className="col-sm-9">
                                        <input name="Tel" type="text" className="form-control" placeholder="Điện thoại" ref={register} />
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" onClick={handleSubmit(saveDataCauHoi)}>
                                <i className="fas fa-sign-out-alt"></i>Đồng ý</button>
                            <button type="button" className="btn btn-danger btn-sm" onClick={handleClose}>
                                <i className="fas fa-sign-out-alt"></i> Đóng</button>
                        </div>
                    </div>
                </div>
            </div>
        </Draggable>
    )
}
