import axiosClient from './axiosClient';

const GiongCayTrongApi=
{
    getAll: (params) =>{
        const url = '/giongcaytrong/getall';
        return axiosClient.get(url, {params});
    },

    getPaging: (params)=>
    {
        const url= '/giongcaytrong/paging';
        return axiosClient.get(url,{params});
    },
    getDetail: (id) =>
    {
        const url= '/giongcaytrong/getbyid/' + id;
        return axiosClient.get(url);
    },
    getById: (id) =>
    {
        const url= '/giongcaytrong/getbyid/' + id;
        return axiosClient.get(url);
    },
    post: (data) =>{
        const url = '/giongcaytrong/add';
        return axiosClient.post(url, data);
    },

    put: (data) => {
        const url = '/giongcaytrong/edit';
        return axiosClient.put(url, data);
    },

    delete: (id) =>{
        const url = '/giongcaytrong/delete/' + id;
        return axiosClient.delete(url);
    }
}

export default GiongCayTrongApi;