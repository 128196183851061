import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap';
import HuongDanApi from '../../../api/HuongDanApi';
import Pagination from 'react-bootstrap-4-pagination';
import ThongBao from '../../../core/ThongBao';
import Authen from '../../../core/Authen';
import { useHistory } from 'react-router-dom';



export const HuongDan = () => {
    const [listHuongDan, setHuongDan] = useState([]);
    const [filter, setFilter] = useState('');
    const [roleAdd, setRoleAdd] = useState(false);
    const [roleEdit, setRoleEdit] = useState(false);
    const [roleDel, setRoleDel] = useState(false);
    let history = useHistory();

    let pageIndex = 1;
    let pageSize = 20;
    const [paginationConfig, setPaging] = useState({
        totalPages: 1,
        currentPage: 1,
        showMax: 5,
        size: "sm",
        threeDots: true,
        prevNext: true,
        onClick: function (page) {
            pageIndex = page;
            loadHuongDan();
        }
    });

    const loadHuongDan = async () => {
        try {
            const params = {
                pageIndex: pageIndex,
                pageSize: pageSize,
                filter: filter
            }
            const response = await HuongDanApi.getPaging(params);
            response.Items.forEach(item => {
                item.Checked = false;
            });
            if (response.Items.length > 0) {
                paginationConfig.totalPages = Math.ceil(response.TotalRow / pageSize);
            }
            else {
                paginationConfig.totalPages = 1;
            }
            paginationConfig.currentPage = pageIndex;
            setPaging(paginationConfig);
            setHuongDan(response.Items);
        } catch (err) {
        }
    }

    useEffect(() => {
        loadHuongDan();
        setRoleAdd(Authen.checkQuyen('HUONGDANSUDUNG_CREATE'));
        setRoleEdit(Authen.checkQuyen('HUONGDANSUDUNG_UPDATE'));
        setRoleDel(Authen.checkQuyen('HUONGDANSUDUNG_DELETE'));
    }, []);

    const timKiem = () => {
        loadHuongDan();
    }

    function onChangeFilter(e) {
        setFilter(e.target.value)
    }

    const clickRow = (id) => {
        listHuongDan.forEach(item => {
            if (item.IdHuongDan === id) {
                item.Checked = !item.Checked;
            }
        });
        setHuongDan([...listHuongDan]);
    }

    const handleShowAdd = () => {
        history.push("/main/tienich/huongdanaddedit/0");
    };

    const handleShowEdit = () => {
        let index = listHuongDan.findIndex(x => x.Checked);
        if (index !== -1) {
            history.push("/main/tienich/huongdanaddedit/" + listHuongDan[index].IdHuongDan);
        }
    }

    const handleShowDel = () => {
        ThongBao.printConfirmationDialog("Bạn có đồng ý xóa dữ liệu đã chọn hay không?", () => {
            let lstChecked = listHuongDan.filter(x => x.Checked);
            if (lstChecked.length > 0) {
                let lstId = [];
                lstChecked.forEach(element => {
                    lstId.push(element.IdHuongDan);
                });
                HuongDanApi.delete(lstId).then(() => {
                    loadHuongDan();
                })
            }
        });
    }

    const checkBoHandler = () => {

    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            loadHuongDan();
        }
    }


    return (
        <div className="card shadow mb-1">
            <div className="card-header py-2 row">
                <div className="col-md-4">
                    <h6 className="m-0 font-weight-bold text-primary">Hướng dẫn</h6>
                </div>
                <div className="input-group col-md-4">
                    <input type="text" className="form-control" placeholder="Tìm kiếm" value={filter} onChange={onChangeFilter} onKeyPress={handleKeyPress} />
                    <div className="input-group-append">
                        <button className="btn btn-info" type="button" onClick={timKiem}>Tìm kiếm</button>
                    </div>
                </div>
                <div className="col-md-4 bd-button text-right">
                    {roleAdd == true ? <Button variant="success" size="sm" onClick={handleShowAdd}>Thêm</Button> : ''}
                    {roleEdit == true ? <Button variant="primary" size="sm" onClick={handleShowEdit}>Sửa</Button> : ''}
                    {roleDel == true ? <Button variant="danger" size="sm" onClick={handleShowDel}>Xóa</Button> : ''}
                </div>
            </div>
            <div className="card-body table-solieu">
                <div className="table-responsive">
                    <table className="table table-bordered" width="100%">
                        <thead>
                            <tr>
                                <th className="col-checkbox"></th>
                                <th>Thứ tự</th>
                                <th>Tiêu đề</th>
                                <th>Mô tả</th>
                                <th className='col-trangthai'>Hiển thị công khai</th>
                                <th>Trạng thái</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                listHuongDan.map((item, key) => {
                                    return (
                                        <tr onClick={() => clickRow(item.IdHuongDan)} key={key}>
                                            <td className="col-checkbox">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" checked={item.Checked} onChange={checkBoHandler} />
                                                </div>
                                            </td>
                                            <td>{item.ThuTu}</td>
                                            <td>{item.TieuDe}</td>
                                            <td>{item.MoTa}</td>
                                            <td className="text-center col-trangthai">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" checked={item.HienThiCongKhai} />
                                                </div>
                                            </td>
                                            <td className="text-center">
                                                {item.Status == true &&
                                                    <span className="label label-success cls-status-label">Kích hoạt</span>
                                                }
                                                {item.Status == false &&
                                                    <span className="label label-danger cls-status-label">Khóa</span>
                                                }
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <div className="col-md-12 text-right">
                    <Pagination {...paginationConfig} />
                </div>
            </div>
        </div>

    )
}
