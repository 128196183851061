import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap';
import $ from 'jquery';
import HoiDapApi from '../../api/HoiDapApi'
import CayTrongApi from '../../api/CayTrongApi'
import { useForm } from 'react-hook-form';
import moment from 'moment'
import Pagination from 'react-bootstrap-4-pagination';
import './hoidap.css'
import { ThemCauHoi } from './themcauhoi'
import { NavLink, useParams } from 'react-router-dom';
import { DangNhap } from '../../public/dangnhap/dangnhap'
import Authen from '../../core/Authen';
import TaiKhoanApi from '../../api/TaiKhoanApi';
import ThongBao from '../../core/ThongBao';

export const TraLoi = (props) => {
    var apiUrl = process.env.REACT_APP_URL;
    var noImage = "/uploadfiles/website/no_image-100x100.jpg";
    const [filter, setFilter] = useState('');
    const [selectedOption, setSelectedOption] = useState();
    const [currentItem, setCurrentItem] = useState({});
    const [listTraLoi, setListTraLoi] = useState([]);
    const { register, handleSubmit, reset, errors } = useForm();
    const [showAnh, setShowAnh] = useState(false);
    const [urlShowAnh, setUrlShowAnh] = useState();
    const [showDangNhap, setShowDangNhap] = useState(false);
    const [nhanVien, setNhanVien] = useState({});

    const [listHoiDaps, setListHoiDaps] = useState([]);

    useEffect(() => {
        AddViewed();
        loadTraLois();
        setNhanVien(Authen.getUser());
    }, []);

    const { IdCauHoi } = useParams();

    const AddViewed = () => {
        HoiDapApi.addView({ Id: IdCauHoi }).then(() => {

        })
    }

    const saveDataTraLoi = (data) => {
        data.IdCayTrong = currentItem.IdCayTrong;
        data.IdCha = currentItem.Id;
        data.Status = true;
        if (data.Id === "0" || data.Id == null) {
            data.Id = 0;
            HoiDapApi.postPublic(data).then(() => {
                loadTraLois();
                var item = {
                    Id: 0,
                    NoiDung: '',
                    HoTen: '',
                    Email: '',
                    DienThoai: '',
                };
                reset(item, [reset]);
            })
        }
        else {
            HoiDapApi.put(data).then(() => {
                loadTraLois();
            })
        }
    }

    const addLike = async (cauHoi) => {
        if (cauHoi.IsLiked != true) {
            var str = localStorage.getItem(process.env.REACT_LIKE_HOIDAP);
            var listLike = [];
            if (str != null) {
                listLike = JSON.parse(str);
            }
            listLike.push({ Id: cauHoi.Id });
            localStorage.setItem(process.env.REACT_LIKE_HOIDAP, JSON.stringify(listLike));
            HoiDapApi.addLike({ Id: cauHoi.Id }).then(() => {
                var lst = JSON.parse(JSON.stringify(listHoiDaps));
                lst.forEach(element => {
                    if (element.Id == cauHoi.Id) {
                        element.IsLiked = true;
                        element.Liked = +element.Liked + 1
                    }
                });
                setListHoiDaps(lst);
            });
        }
    }
    const handleCloseAnh = () => {
        setShowAnh(false);
    }
    const handleShowAnh = (urlAnh) => {
        setUrlShowAnh(urlAnh);
        setShowAnh(true);
    }

    const loadTraLois = async () => {
        debugger;
        var idCauHoi = Number.parseInt(IdCauHoi || 0);
        if (idCauHoi > 0) {
            try {
                const response = await HoiDapApi.getByCha(idCauHoi);
                var str = localStorage.getItem(process.env.REACT_LIKE_HOIDAP);
                var listLike = [];
                if (str != null) {
                    listLike = JSON.parse(str);
                }
                if (listLike.filter(x => x.Id == response.Id).length > 0) {
                    response.IsLiked = true;
                }
                setCurrentItem(response);
                setListTraLoi(response.TraLois);
            } catch (err) {
            }

        }
    }

    const closeModalDangNhap = () => {
        setShowDangNhap(false);
    }
    const showModalDangNhap = () => {
        setShowDangNhap(true);
    }
    return (
        <>
            <div class="fl-builder-content">
                <div className="container">
                    <div className="fl-row-content-wrap">
                        <div className="col-md-12">
                            <div className="breadcrumb-container">
                                <a href="/" className="breadcrumb-dis">Trang chủ / </a>
                                <a href="/public/hoi-dap" className="breadcrumb-dis">Hỏi đáp / </a>
                                <a href="#" className="breadcrumb-active"> Chi tiết</a>
                                <div className="breadcrumb-line">
                                </div>
                            </div>
                        </div>

                        <div className="form-group traloi">
                            <div className="text-right traloi-close">
                                <NavLink to={'/public/hoi-dap'}>
                                    <button type="button" className="btn btn-danger btn-sm">
                                        <i className="fas fa-sign-out-alt"></i> Quay lại</button>
                                </NavLink>
                            </div>
                            <div className="col-md-12">
                                <img name="HinhAnhs" src={apiUrl + (currentItem.Avatar == null ? noImage : currentItem.Avatar)} className="cauhoi-avatar" onClick={() => handleShowAnh(currentItem.HinhAnhs)} />
                                <div>
                                    <span className="hoten">
                                        <i class="fa fa-user" aria-hidden="true">&nbsp;</i>
                                        {currentItem.FullName != null ? currentItem.FullName : currentItem.HoTen}
                                    </span>
                                </div>
                                <div>
                                    <span className="created-date">
                                        <i class="fa fa-check" aria-hidden="true">&nbsp;</i>
                                        {currentItem.TenCayTrong}
                                    </span>
                                    <span className="created-date">
                                        <i class="fa fa-history" aria-hidden="true">&nbsp;</i>
                                        {currentItem.CreatedDate == '' ? '' : moment(currentItem.CreatedDate, "DD/MM/YYYY HH:mm:ss").format("DD/MM/YYYY HH:mm")}
                                    </span>
                                </div>
                                <div className="clearfix"></div>
                            </div>
                            <div className="col-md-12">
                                <div className="font-weight-bold" >{currentItem.ChuDe}</div>
                            </div>
                            <div className="col-md-12">
                                <div>{currentItem.NoiDung}</div>
                            </div>
                            {currentItem.HinhAnhs != null && currentItem.HinhAnhs != "" &&
                                <div className="col-sm-12">
                                    <img name="HinhAnhs" src={apiUrl + currentItem.HinhAnhs} className="cauhoi-hinhanh" onClick={() => handleShowAnh(currentItem.HinhAnhs)} />
                                </div>
                            }
                            <div className="col-md-12">
                                <div className="traloi-link">
                                    <i class="fa fa-comments" aria-hidden="true"  > {listTraLoi.length} Trả lời</i>
                                    <i class="fa fa-thumbs-up" aria-hidden="true" onClick={() => addLike(currentItem)} className={currentItem.IsLiked == true ? 'like-style' : ''}> {currentItem.Liked} Like</i>
                                </div>
                            </div>
                        </div>
                        <div className='traloi-container'>
                            {listTraLoi.length == 0 &&
                                <div className="form-group ">
                                    Chưa có câu trả lời nào.
                                                </div>
                            }
                            {listTraLoi.length > 0 &&
                                listTraLoi.map((item, key) => {
                                    return (
                                        <>
                                            <div className="form-group row">
                                                <div className="col-sm-12">
                                                    <img name="HinhAnhs" className="thumb-user" src={apiUrl + (item.Avatar == null ? noImage : item.Avatar)} />
                                                    <div className="test">
                                                        <div className="font-weight-bold">{item.FullName == null ? item.HoTen : item.FullName}</div>
                                                        <div>
                                                            <span className="created-date">
                                                                <i class="fa fa-history" aria-hidden="true">&nbsp;</i>
                                                                {item.CreatedDate == '' ? '' : moment(item.CreatedDate, "DD/MM/YYYY HH:mm:ss").format("DD/MM/YYYY HH:mm")}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div >
                                                    <span className="col-sm-3 col-form-label">{item.NoiDung} </span>
                                                </div>
                                            </div>
                                            <hr></hr>
                                        </>
                                    )
                                })
                            }
                        </div>
                        <hr></hr>
                        {nhanVien.UserName != null &&
                            <form className="user" onSubmit={handleSubmit(saveDataTraLoi)}>
                                <input name="Id" hidden={true} ref={register} ></input>
                                <div className="form-group">
                                    <label className="col-sm-3 col-form-label">Trả lời.
                                                <span className="text-danger">*</span>
                                    </label>
                                    <div className="col-sm-12">
                                        <textarea name="NoiDung" className="form-control textrea-size" rows="2" placeholder="Nhập nội dung" ref={register({ required: true })}  ></textarea>
                                    </div>
                                </div>
                            </form>
                        }
                        {nhanVien.UserName == null &&
                            <div className="form-group">
                                <label className="col-sm-12 col-form-label font-weight-bold">Hãy <span className="dang-nhap" onClick={showModalDangNhap}>đăng nhập</span> để trả lời </label>
                            </div>
                        }
                        <div className="text-right traloi-button">
                            {nhanVien.UserName != null &&
                                <button type="button" className="btn btn-warning btn-sm" onClick={handleSubmit(saveDataTraLoi)}>
                                    <i className="fas fa-sign-out-alt"></i>Trả lời</button>
                            }
                            <NavLink to={'/public/hoi-dap'}>
                                <button type="button" className="btn btn-danger btn-sm">
                                    <i className="fas fa-sign-out-alt"></i>
                                Quay lại
                                </button>
                            </NavLink>
                        </div>

                        <Modal show={showAnh} onHide={handleCloseAnh} animation={false} size='lg'>
                            <Modal.Header closeButton>
                                <Modal.Title>Hình ảnh</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <img name="hs" src={apiUrl + urlShowAnh} className="show-hinhanh" />
                            </Modal.Body>
                        </Modal>
                    </div>
                </div>
            </div>
            { showDangNhap && <DangNhap show={showDangNhap} closeModal={closeModalDangNhap}></DangNhap>}
        </>
    )
}
