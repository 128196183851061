import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { BanDo } from './bando/BanDo';
import { SelectHuyenMulti } from '../shared/selecthuyenmulti/SelectHuyenMulti';
import Autocomplete from 'react-autocomplete';
import TimKiemApi from '../api/TimKiemApi';
import { Header } from '../header';
import { HuongDan } from './huongdan/HuongDan';

export const Public = () => {
    const [urlFunction, setUrlFunction] = useState({});
    const [printMap, setPrintMap] = useState(0);
    const [listFilters, setListFilter] = useState([]);
    const [valueFilter, setValueFilter] = useState('');
    var requestTimer = null;
    const [maXa, setMaXa] = useState('');
    const [listHuyens, setListHuyen] = useState([]);
    const [countTraCuu, setCountTraCuu] = useState(0);
    const [mobile, setMobile] = useState(false);
    const [countDuDoan, setCountDuDoan] = useState(0);

    const onPrint = () => {
        setPrintMap(printMap + 1);
    }

    const showTraCuu = () => {
        setCountTraCuu(countTraCuu + 1);
    }

    const showDuDoan = () => {
        setCountDuDoan(countDuDoan + 1);
    }

    const getIdHuyen = (listHuyens) => {
        setListHuyen(listHuyens)
    }

    const localtion = useLocation();
    useEffect(() => {
        setPrintMap(0);
        if(localtion.pathname === '/public/ban-do-chuyen-de-mobile'){
            setMobile(true);
        }
    }, [localtion.pathname]);

    return (
        <div id="wrapper" className={(mobile?'mobile ': '') + 'public'}>
            {
                mobile === false?<Header></Header>:''
            }            
            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                    <nav className="navbar navbar-expand navbar-light bg-white topbar static-top shadow">
                        <div className="col-md-3">
                            <SelectHuyenMulti onChangeHuyenProps={(e) => getIdHuyen(e)}></SelectHuyenMulti>
                        </div>
                        <div className="input-group col-md-3">
                            <Autocomplete
                                inputProps={{ id: 'states-autocomplete', className: 'form-control', placeholder: "Tên xã" }}
                                wrapperStyle={{ position: 'relative', display: 'inline-block', zIndex: 999, width: "100%" }}
                                value={valueFilter}
                                items={listFilters}
                                getItemValue={(item) => item.tenXa}
                                onSelect={(value, item) => {
                                    setMaXa(item.maXa);
                                    setValueFilter(value);
                                }}
                                onChange={(event, value) => {
                                    setValueFilter(value);
                                    clearTimeout(requestTimer)

                                    requestTimer = TimKiemApi.getFilter({ listHuyens: listHuyens.map(x => "'" + x.Ma + "'").join(','), filter: value }).then((response) => {
                                        setListFilter(response);
                                    });
                                }}
                                renderItem={(item, isHighlighted) => (
                                    <div
                                        className={`item ${isHighlighted ? 'item-highlighted' : ''}`}
                                    >{item.tenXa}</div>
                                )}
                            />
                            <div className="input-group-append">
                                <i class="fas fa-search tk-ban-do"></i>
                            </div>
                        </div>
                        <ul className="navbar-nav ml-auto">
                        <div className="topbar-divider d-none d-sm-block" />
                            <li>
                                <button type="button" className="btn btn-info btn-sm" onClick={showDuDoan}>
                                <i class="fas fa-chart-line"></i> Chẩn đoán
                                </button>
                            </li>
                            <div className="topbar-divider d-none d-sm-block" />
                            <li>
                                <button type="button" className="btn btn-info btn-sm" onClick={showTraCuu}>
                                    <i class="fas fa-search"></i> Tra cứu
                                </button>
                            </li>
                            <div className="topbar-divider d-none d-sm-block" />
                            <li>
                                <button type="button" className="btn btn-info btn-sm" onClick={onPrint}>
                                    <i class="fas fa-print"></i>
                                </button>
                            </li>
                            <div className="topbar-divider d-none d-sm-block" />
                            <li>
                                <a href="/login" class="btn btn-info btn-sm btn-dang-nhap">
                                <i class="fas fa-sign-in-alt"></i> Đăng nhập
                                </a>
                            </li>
                        </ul>
                    </nav>
                    <div className="container-fluid">
                        <BanDo urlFunction={urlFunction} listHuyens={listHuyens} maXa={maXa} printMap={printMap} showTraCuu={countTraCuu} showDuDoan={countDuDoan} mobile={mobile}></BanDo>
                    </div>
                </div>
            </div>
        </div>
    )
}
