import React, { useState, useEffect } from 'react'
import { Fade, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import TaiKhoanApi from '../../../api/TaiKhoanApi';
import NhomQuyenApi from '../../../api/NhomQuyenApi';
import '../../../css/apppublic.css';
import { confirmAlert } from 'react-confirm-alert';
import '../../../css/reactconfirm.css';
import '../../../css/reactselect.css';
import Pagination from 'react-bootstrap-4-pagination';
import Authen from '../../../core/Authen';
import ExportExcelApi from '../../../api/ExportExcelApi';
import { Loading } from '../../../shared/loading/Loading';
import ThongBao from '../../../core/ThongBao';
import QuanHuyenApi from '../../../api/QuanHuyenApi';

export const TaiKhoan = () => {
    var apiUrl = process.env.REACT_APP_API_URL.replace('api', '');
    const [listNhanViens, setListNhanVien] = useState([]);
    const [showDoiMatKhau, setShowDoiMatKhau] = useState(false);
    const [listQuyen, setListQuyen] = useState([]);
    const [roles, setRoles] = useState('');
    const [roleMutil, setRoleMutil] = useState([]);
    const [show, setShow] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isXem, setIsXem] = useState(false);
    const [avatarFile, setAvatarFile] = useState(null);
    const [search, setSearch] = useState('');
    const [roleAdd, setRoleAdd] = useState(false);
    const [roleEdit, setRoleEdit] = useState(false);
    const [roleDel, setRoleDel] = useState(false);
    const { handleSubmit, reset, register, errors } = useForm();
    const [loading, setLoading] = useState(false);
    let pageIndex = 1, pageSize = 20;
    const [quanHuyenMutil, setQuanHuyenMutil] = useState();
    const [listQuanHuyen, setListQuanHuyen] = useState([]);
    const optionsQuanHuyen = listQuanHuyen.Items;
    const [disQuanHuyen, setDisQuanHuyen] = useState(false);
    const [disButtonEdit, setDisButtonEdit] = useState(true);

    const [paginationConfig, setPaginationConfig] = useState({
        totalPages: 1,
        currentPage: 1,
        showMax: 5,
        size: 'sm',
        threeDots: true,
        prevNext: true,
        onClick: function (page) {
            if (page != null) {
                pageIndex = page;
                loadTaiKhoan();
            }
        }
    });

    const loadUserRoles = async (id) => {
        const res = await TaiKhoanApi.getUserRoles(id);
        if (res.length >= 0) {
            let opt = {
                options: res.map((x) => ({
                    value: x.RoleId,
                    label: x.Description
                }))
            };
            setRoleMutil(opt);
        }
        else {
            setRoleMutil([]);
        }
    }

    const loadQuyen = async () => {
        try {
            const res = await NhomQuyenApi.getAll();
            setListQuyen({
                Items: res.map((x) => ({
                    value: x.Id,
                    label: x.Description
                }))
            });
        }
        catch (err) {
        }
    };

    const loadQuanHuyen = async () => {
        try {
            const res = await QuanHuyenApi.getAll();
            setListQuanHuyen({
                Items: res.map((x) => ({
                    value: x.Ma,
                    label: x.Ten,
                    id: x.Id
                }))
            });
        }
        catch (err) {
        }
    };

    const options = listQuyen.Items;

    const onChangeSelect = (optionSelected) => {
        /* setRoles({values: optionSelected.values,label: optionSelected.label}); */
        setRoleMutil(optionSelected);
    }

    const onChangeSelectQuanHuyen = (optionSelected) => {
        setQuanHuyenMutil(optionSelected);
    }

    const loadTaiKhoan = async () => {
        try {
            const params = {
                filter: search,
                pageIndex: pageIndex,
                pageSize: pageSize
            }
            const response = await TaiKhoanApi.getPaging(params);
            response.Items.forEach((x) => {
                x.Checked = false
            });
            setDisButtonEdit(true);
            setListNhanVien(response.Items);
            paginationConfig.totalPages = Math.ceil(response.TotalRow == 0 ? 1 : response.TotalRow / pageSize);
            paginationConfig.currentPage = pageIndex;
            setPaginationConfig(paginationConfig);
        }
        catch (err) { }
    }

    const rowClick = (id) => {
        listNhanViens.forEach(element => {
            if (element.Id === id) {
                element.Checked = true;
            }
            else {
                element.Checked = false;
            }
        });
        setDisButtonEdit(false);
        setIsEdit(true);
        setListNhanVien([...listNhanViens]);
    }

    const handleClose = () => {
        setShow(false);
    }

    const handleShowAdd = () => {
        reset({
            Id: 0,
            Status: true
        }, [reset]);
        setIsEdit(false);
        setAvatarFile(null);
        setRoleMutil([]);
        setQuanHuyenMutil(null);
        setIsXem(false);
        setDisQuanHuyen(false);
        setShow(true);
    }

    const handleShowEdit = () => {
        setIsEdit(true);
        setIsXem(false);
        setAvatarFile(null);
        let index = listNhanViens.findIndex(x => x.Checked);
        if (index !== -1) {
            TaiKhoanApi.getDetail(listNhanViens[index].Id).then((response) => {
                reset(response, [reset]);
                loadUserRoles(response.Id);
                setIsXem(false);
                setQuanHuyenMutil(null);
                var index = listQuanHuyen.Items.findIndex(x => x.value == response?.MaQuanHuyen);
                if (index >= 0) {
                    setQuanHuyenMutil(listQuanHuyen.Items[index]);
                }
                setDisQuanHuyen(response?.TatCaQuanHuyen == true);
                setShow(true);
            });
        }
    }

    const handleShowData = () => {
        setIsXem(true);
        let index = listNhanViens.findIndex(x => x.Checked);
        if (index !== -1) {
            TaiKhoanApi.getDetail(listNhanViens[index].Id).then((response) => {
                reset(response, [reset]);
                loadUserRoles(response.Id);
                setQuanHuyenMutil(null);
                var index = listQuanHuyen.Items.findIndex(x => x.value == response?.MaQuanHuyen);
                if (index >= 0) {
                    setQuanHuyenMutil(listQuanHuyen.Items[index]);
                }
                setDisQuanHuyen(response?.TatCaQuanHuyen == true);
                setShow(true);
            });
        }
    }

    const GetArrQuyen = () => {
        try {
            var roleArr = '';
            if (roleMutil.options == null) {
                roleMutil.map((x) => {
                    if (roleArr == '') {
                        roleArr = x.value;
                    }
                    else {
                        roleArr += ',' + x.value;
                    }
                })
            }
            else {
                roleMutil.options.map((x) => {
                    if (roleArr == '') {
                        roleArr = x.value;
                    }
                    else {
                        roleArr += ',' + x.value;
                    }
                })
            }
            setRoles(roleArr);
        }
        catch {
        }
    }

    useEffect(() => {
        GetArrQuyen();
    }, [roleMutil])

    const onSubmit = async (data) => {
        try {
            var fielPath = '';
            if (avatarFile != null) {
                let dataUpload = new FormData();
                dataUpload.append('file', avatarFile);
                fielPath = await TaiKhoanApi.UploadFile(dataUpload);
                data.Avatar = fielPath;
            }
            if (quanHuyenMutil?.value != null) {
                data.MaQuanHuyen = quanHuyenMutil.value;
                data.IdQuanHuyen = quanHuyenMutil.id;
            }
            data.UserRoles = roles;
            if (!isEdit) {
                const res = await TaiKhoanApi.createUser(data);
            }
            else {
                const res = await TaiKhoanApi.updateUser(data);
            }
            setShow(false);
            loadTaiKhoan();
        }
        catch (ex) {
            console.log(ex.message);
        }
    }

    useEffect(() => {
        loadQuyen();
        loadQuanHuyen();
        loadTaiKhoan();
        setRoleAdd(Authen.checkQuyen('TAIKHOAN_CREATE'));
        setRoleEdit(Authen.checkQuyen('TAIKHOAN_UPDATE'));
        setRoleDel(Authen.checkQuyen('TAIKHOAN_DELETE'));
    }, []);

    const setFilter = (e) => {
        setSearch(e.target.value);
    }


    const alertDell = async () => {
        let lstChecked = listNhanViens.filter(x => x.Checked);
        if (lstChecked.length > 0) {
            ThongBao.printConfirmationDialog("Bạn có đồng ý xóa dữ liệu đã chọn hay không?", () => {
                TaiKhoanApi.DeleteUser(lstChecked[0].Id).then((res) => {
                    if (res.Message == "") {
                        loadTaiKhoan();
                    }
                    else {
                        ThongBao.prinWarningMessage(res.Message);
                    }
                })
            });
        }
    }

    const Timkiem = () => {
        loadTaiKhoan();
    }

    const exportExcel = () => {
        let params = {

        }
        setLoading(true);
        ExportExcelApi.getTaiKhoan(params).then((res) => {
            const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            setLoading(false);
            a.download = 'download.xlsx';
            a.click();
        }, () => {
            setLoading(false);
        });
    }

    const saveDatLaiMatKhau = (data) => {
        if (data.UserName === "Admin") {
            ThongBao.prinWarningMessage("Bạn không đặt lại được mật khẩu tài khoản Admin.");
            return;
        }
        else {
            if (data.Password != data.ConfirmPassword) {
                ThongBao.prinWarningMessage("Xác nhận mật khẩu không khớp.");
            }
            else {

                const params = {
                    Id: data.Id,
                    Password: data.Password
                };
                setLoading(true);
                TaiKhoanApi.setPass(params).then(() => {
                    setLoading(false);
                    setShowDoiMatKhau(false);
                    loadTaiKhoan();
                }).catch(() => setLoading(false))
            }
        }
    }

    const handleDoiMatKhauClose = () => {
        setShowDoiMatKhau(false);
    }

    const showDatLaiMatKhau = () => {
        var lst = listNhanViens.filter(x => x.Checked == true);
        if (lst.length > 0) {
            reset({
                Id: lst[0].Id,
                UserName: lst[0].UserName
            }, [reset]);
        }
        setShowDoiMatKhau(true);
    }

    const onChangeTatCaQuanHuyen = (e) => {
        setDisQuanHuyen(e.target.checked);
        if (e.target.checked) {
            setQuanHuyenMutil(null);
        }
    }

    return (
        <div className="card shadow mb-1">
            <div className="card-header py-2">
                <div className="row ml-2">
                    <div className="col-md-2">
                        <h6 className="m-0 font-weight-bold text-primary">Tài khoản</h6>
                    </div>
                    <div className="col-md-4">
                        <div className="input-group">
                            <input type="text" className="form-control" placeholder="Tìm kiếm" value={search} onChange={setFilter} />
                            <div className="input-group-append">
                                <button className="btn btn-info btn-sm" type="button" onClick={Timkiem} ><i className='fas fa-search'></i> Tìm kiếm</button>
                            </div>
                        </div>
                    </div>
                    <div className="fluid-container col-md-6 text-right">
                        {roleAdd == true ? <button className="btn btn-warning btn-sm ml-1" onClick={exportExcel}><i className="fas fa-file-excel"></i> Excel</button> : ''}
                        {roleAdd == true ? <button type="button" className="btn btn-success btn-sm ml-1" onClick={handleShowAdd}><i className='fas fa-plus'></i> Thêm</button> : ''}
                        {roleEdit == true ? <button type="button" className="btn btn-info btn-sm ml-1" disabled={disButtonEdit} onClick={handleShowEdit}><i className='far fa-edit'></i> Sửa</button> : ''}
                        {roleEdit == true ? <button type="button" className="btn btn-info btn-sm ml-1" disabled={disButtonEdit} onClick={showDatLaiMatKhau}><i className='far fa-edit'></i> Đổi MK</button> : ''}
                        {roleDel == true ? <button type="button" className="btn btn-danger btn-sm ml-1" disabled={disButtonEdit} onClick={alertDell} ><i className='fas fa-trash-alt'></i> Xóa</button> : ''}
                        <button type="button" className="btn btn-primary btn-sm ml-1 mr-2" disabled={disButtonEdit} onClick={handleShowData}><i className='far fa-eye'></i> Xem</button>
                    </div>
                </div>
            </div>

            <div className="card-body">
                <div className="table-responsive">
                    <table className="table table-bordered" width="100%">
                        <thead>
                            <tr>
                                <th><input name="Active" type="Checkbox"></input></th>
                                <th>Avatar</th>
                                <th>Tài khoản</th>
                                <th>Họ tên</th>
                                <th>Email</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                listNhanViens.map((item, key) => {
                                    return (
                                        <tr className="SelectRow" key={key} onClick={() => rowClick(item.Id)}>
                                            <td className="text-center">
                                                <input type="Checkbox" checked={item.Checked} onChange={e => item.checked = e.target.value}></input>
                                            </td>
                                            <td className="col-img">
                                                <img className="img-profile rounded-circle" src={apiUrl + item.Avatar} />
                                            </td>
                                            <td>{item.UserName}</td>
                                            <td>{item.FullName}</td>
                                            <td>{item.Email}</td>
                                            <td className="text-center">{item.Status ? <span className="label label-success cls-status-label">Kích hoạt</span> : <span className="label label-danger cls-status-label">Khóa</span>}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <div className="col-md-12 text-right">
                    <Pagination {...paginationConfig} />
                </div>
            </div>

            <Modal dialogclassName="modalTK" show={show} onHide={handleClose} backdrop="static" keyboard={false} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Tài khoản</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container-fluid">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-group row" hidden={true}>
                                <div className="col-md-3 col-sm-6 col-xl-3 col-form-label  nhanLH">
                                    <label className="nhanTK">ID <code>*</code></label>
                                </div>
                                <div className="col-md-9 col-sm-6 col-xl-9">
                                    <input className="form-control" name="Id" type="Text" ref={register()}></input>
                                </div>
                            </div>
                            {isEdit == false &&
                                <>
                                    <div className="form-group row">
                                        <div className="col-md-3 col-sm-6 col-xl-3 col-form-label nhanLH ">
                                            Tên tài khoản <code>*</code>
                                        </div>
                                        <div className="col-md-9 col-sm-6 col-xl-9">
                                            <input className="form-control" name="UserName" type="Text" ref={register({
                                                required: "Tài khoản không được trống",
                                                pattern: {
                                                    value: /^[a-z\d]+$/i,
                                                    message: "Sai định dạng tài khoản"
                                                }, minLength: 3
                                            })}></input>
                                            <span className="eRor">{errors.UserName && errors.UserName.message}</span>
                                        </div>
                                    </div>
                                    <div className="form-group row" hidden={isEdit}>
                                        <div className="col-md-3 col-sm-6 col-xl-3 col-form-label nhanLH">
                                            Mật khẩu <code>*</code>
                                        </div>
                                        <div className="col-md-9 col-sm-6 col-xl-9">
                                            <input className="form-control" name="Password" type="PassWord" ref={register(
                                                { required: !isEdit ? "Mật khẩu không được trống" : "", minLength: 3 }
                                            )}></input>
                                            <span className="eRor">{errors.Password && errors.Password.message}</span>
                                        </div>
                                    </div>
                                </>
                            }
                            <div className="form-group row mb-1">
                                <div className="col-md-3 col-sm-6 col-xl-3 col-form-label nhanLH">
                                    Họ và tên <code>*</code>
                                </div>
                                <div className="col-md-3 col-sm-6 col-xl-3">
                                    <input className="form-control" name="FullName" type="Text" ref={register({ required: "Họ tên không được trống" })}
                                    ></input>
                                    <span className="eRor">{errors.FullName && errors.FullName.message}</span>
                                </div>
                                <div className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">
                                    Email <code>*</code>
                                </div>
                                <div className="col-md-4 col-sm-6 col-xl-4">
                                    <input className="form-control" name="Email" type="Text" ref={register(
                                        {
                                            required: "Email không được trống",
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                message: "Sai định dạng email"
                                            }
                                        }
                                    )}></input>
                                    <span className="eRor">{errors.Email && errors.Email.message}</span>
                                </div>
                            </div>
                            <div className="form-group row mb-1">
                                <div className="col-md-3 col-sm-6 col-xl-3 col-form-label nhanLH">
                                    Số điện thoại <code>*</code>
                                </div>
                                <div className="col-md-3 col-sm-6 col-xl-3">
                                    <input className="form-control" name="PhoneNumber" type="Text" ref={register(
                                        { required: "SĐT không được trống" }
                                    )}></input>
                                    <span className="eRor">{errors.PhoneNumber && errors.PhoneNumber.message}</span>
                                </div>
                            </div>
                            <div className="form-group row mb-1">
                                <div className="col-md-3 col-sm-6 col-xl-3 col-form-label nhanLH">
                                    Địa chỉ<code>*</code>
                                </div>
                                <div className="col-md-9 col-sm-6 col-xl-9">
                                    <input className="form-control" name="Address" type="Text" ref={register()}></input>
                                </div>
                            </div>
                            <div className="form-group row mb-1">
                                <div className="col-md-3 col-sm-6 col-xl-3 col-form-label nhanLH">
                                    Avartar
                                </div>
                                <div className="col-md-9 col-sm-6 col-xl-9 ">
                                    <input className="rowTK" name="Avatar" type="text" ref={register} hidden={true} ></input>
                                    <input className="rowTK" name="Avatar_File" type="file" ref={register} onChange={e => setAvatarFile(e.target.files[0])}></input>
                                </div>
                            </div>
                            <div className="form-group row mb-1">
                                <div className="col-md-3 col-sm-6 col-xl-3 col-form-label nhanLH">
                                    Thuộc nhóm
                                </div>
                                <div className="col-md-9 col-sm-6 col-xl-9">
                                    <Select options={options} name="UserRoles" onChange={onChangeSelect} value={roleMutil.options} isMulti components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }} />
                                    {/* <input className="rowTK" name="RoleId" type="Text" ref={register} ></input> */}
                                    <span className="eRor" >{errors.RoleId && errors.RoleId.message}</span>
                                </div>
                            </div>
                            <div className="form-group row mb-1">
                                <div className="col-md-3 col-sm-6 col-xl-3 col-form-label nhanLH">
                                    Quận huyện
                                </div>
                                <div className="col-md-3 col-sm-6 col-xl-3 text-left mt-2">
                                    <label>
                                        <input name="TatCaQuanHuyen" type="checkbox" ref={register} onChange={onChangeTatCaQuanHuyen}></input>
                                        &nbsp;Tất cả
                                    </label>
                                </div>
                                <div className="col-md-6 col-sm-6 col-xl-6">
                                    <Select options={optionsQuanHuyen} name="MaQuanHuyen" onChange={onChangeSelectQuanHuyen} value={quanHuyenMutil} components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }} isDisabled={disQuanHuyen} placeholder="Chọn quận huyện..." />
                                </div>
                            </div>
                            <div className="form-group row mb-1">
                                <div className="col-md-3 col-sm-6 col-xl-3 col-form-label nhanLH">
                                    Kích hoạt
                                </div>
                                <div className="col-md-9 col-sm-6 col-xl-9 text-left mt-2">
                                    <input name="Status" type="checkbox" ref={register}></input>
                                </div>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <a href="#" type="button" className="btn btn-info btn-sm ml-1" onClick={handleSubmit(onSubmit)} hidden={isXem}><i className='fas fa-save'></i> Lưu</a>
                    <a href="#" type="button" className="btn btn-danger btn-sm ml-1" onClick={handleClose} disabled><i className="fas fa-sign-out-alt"></i> Hủy</a>
                </Modal.Footer>
            </Modal>

            <Modal show={showDoiMatKhau} onHide={handleDoiMatKhauClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Đặt lại mật khẩu.</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="user" onSubmit={handleSubmit(saveDatLaiMatKhau)}>
                        <input name="Id" hidden={true} ref={register}></input>
                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label">Tài khoản</label>
                            <div className="col-sm-8">
                                <input name="UserName" type="text" className="form-control" placeholder="Tên tài khoản" ref={register} readOnly />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label">Mật khẩu mới <span className="text-danger"> *</span></label>
                            <div className="col-sm-8">
                                <input name="Password" type="password" className="form-control" placeholder="Mật khẩu mới" ref={register({ required: "Hãy nhập dữ liệu", minLength: 3 })} autoFocus />
                                <span className="eRor">{errors.Password && errors.Password.message}</span>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label">Xác nhận mật khẩu <span className="text-danger"> *</span></label>
                            <div className="col-sm-8">
                                <input name="ConfirmPassword" type="password" className="form-control" placeholder="Xác nhận lại mật khẩu" ref={register({ required: "Hãy nhập dữ liệu", minLength: 3 })} />
                                <span className="eRor">{errors.ConfirmPassword && errors.ConfirmPassword.message}</span>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <a href="#" type="button" className="btn btn-info btn-sm ml-1" onClick={handleSubmit(saveDatLaiMatKhau)} hidden={isXem}><i className='fas fa-save'></i> Lưu</a>
                    <a href="#" type="button" className="btn btn-danger btn-sm ml-1" onClick={handleDoiMatKhauClose} disabled><i className="fas fa-sign-out-alt"></i> Hủy</a>
                </Modal.Footer>
            </Modal>
            <Loading loading={loading}></Loading>
        </div>

    )
}

