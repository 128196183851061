import React, { useState, useEffect } from 'react';
import PhanQuyenApi from '../../../api/PhanQuyenApi';
import NhomQuyenApi from '../../../api/NhomQuyenApi';
import Select from 'react-select';
import './PhanQuyen.css';
import ThongBao from '../../../core/ThongBao';
import Authen from '../../../core/Authen';

export const PhanQuyen = () => {
    const [listRoles, setNhomQuyen] = useState([]);
    // const [selectedOption, setSelectedOption] = useState('');
    const [listQuyens, setQuyen] = useState([]);
    const [listFunctions, setFunction] = useState([]);
    const [roleId, setroleId] = useState('');
    const [roleEdit, setRoleEdit] = useState(false);

    const loadChucNang = async () => {
        try {
            const response = await PhanQuyenApi.getAll();
            setQuyen(response);
            console.log(response);
            setFunction(BuildTree(response));
        } catch (err) {
        }
    }

    useEffect(() => {
        loadChucNang();
        loadRoles();
        /* setRoleEdit(true); */
        setRoleEdit(Authen.checkQuyen('PHANQUYEN_UPDATE'));
        
    }, []);

    function loadRoles() {
        NhomQuyenApi.getAll().then((response) => {
            let list = [];
            list.push({ value: '', label: 'Chọn nhóm quyền' });
            response.forEach(item => {
                list.push({ value: item.Id, label: item.Description });
            })
            setNhomQuyen(list);
        })
    }


    function onChangeRole(e) {
        //console.log(e);
        // console.log(listQuyens);
        // console.log(listFunctions);        
        // setSelectedOption(e.value)        
        setroleId(e.value);
        loadPermissionByRole(e.value);
    }

    function loadPermissionByRole(role) {
        //console.log(roleId);
        const params = {
            roleId: role
        }
        if (role !== '') {
            PhanQuyenApi.getbyRole(params).then((response) => {
                listQuyens.forEach(item => {
                    let listActions = response.filter(x => x.FunctionId === item.Id);
                    for (let action of item.Actions) {
                        action.Checked = listActions.findIndex(x => x.ActionId === action.Id) !== -1;
                    }
                });
                setFunction(BuildTree(listQuyens));

                
            });
        }
        else {
            PhanQuyenApi.getAll().then((response) => {
                setQuyen(response);
                setFunction(BuildTree(response));
            }
            );
        }

    }

    function BuildTree(arr = []) {
        let roots = [];
        roots = arr.filter(x => x.ParentId === null);
        for (var i = 0; i < roots.length; i++) {
            let lstTemp = arr.filter(x => x.ParentId == roots[i].Id);
            if (lstTemp.length == 0) {
                let objTemp = JSON.parse(JSON.stringify(roots[i]));
                objTemp.ParentId = roots[i].Id + "_OK";
                roots[i].children = [objTemp];
                roots[i].Id = roots[i].Id + "_OK";
            }
            else {
                Tree(arr, roots[i]);
            }
        }
        return roots;
    }

    function Tree(arr = [], list) {
        var kq = false;
        let childs = arr.filter(x => x.ParentId === list.Id);
        //list.data.expanded = true;
        list.children = childs;
        for (var i = 0; i < childs.length; i++) {
            Tree(arr, childs[i]);
        }
    }

    const clickFunction = (funtion) => {
        funtion.Checked = !funtion.Checked;
        for (let action of funtion.Actions) {
            action.Checked = funtion.Checked;
        }
        let i = listQuyens.findIndex(x => x.Id === funtion.Id);
        if (i !== -1)
            listQuyens[i] = funtion;

        setFunction(BuildTree(listQuyens));
    }

    const clickAction = (func, action) => {
        // console.log(action);
        // console.log(fuc);

        action.Checked = !action.Checked;

        let j = func.Actions.findIndex(x => x.Id === action.Id);
        if (j !== -1)
            func.Actions[j] = action;

        let i = listQuyens.findIndex(x => x.Id === func.Id);
        if (i !== -1)
            listQuyens[i] = func;

        setFunction(BuildTree(listQuyens));
    }

    const saveData = () => {
        let data = {};
        data.roleId = roleId;

        let list = [];
        listQuyens.forEach(item => {
            for (let action of item.Actions) {
                if (action.Checked) {
                    list.push({
                        FunctionId: item.Id,
                        ActionId: action.Id
                    });
                }
            }
        });
        data.permissions = list;
        if (roleId === '')
        {
            ThongBao.prinWarningMessage("Chọn nhóm cần phân quyền!");
            return;
        }
        PhanQuyenApi.post(data).then(() => {
            ThongBao.printSuccessMessage("Cập nhật thành công!");
        })
    }

    return (
        <div className="card shadow mb-1">
            <div className="card-header py-2 row">
                <div className="col-md-4">
                    <h6 className="m-0 font-weight-bold text-primary">Phân quyền</h6>
                </div>
                <div className="col-md-4">
                    <div>
                        <Select
                            className="select"
                            name="RoleId"
                            placeholder="Nhóm quyền"
                            defaultValue={roleId}
                            onChange={onChangeRole}
                            options={listRoles}
                            theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                                height: 30,
                                spacing: {
                                    controlHeight: 30,
                                    menuGutter: 1,
                                    baseUnit: 2,
                                }
                            })}
                        />
                    </div>
                </div>
                <div className="col-md-4 bd-button text-right">
                    {roleEdit == true ? <button className="btn btn-success btn-sm" type="button" onClick={saveData}><i className='far fa-edit'></i>&nbsp;Cập nhật</button> : ''}
                </div>
            </div>
            <div className="card-body">
                <ul>
                    {
                        listFunctions.map((item, key) => {
                            return (
                                <li className="cha">
                                    <span className="menu-Cha" key={key}>
                                        {item.Name}
                                    </span>
                                    <ul>
                                        {
                                            item.children.map((child, key1) => {
                                                return (
                                                    <li>
                                                        <label className="custom-checkbox" key={key1} >
                                                            <input className="form-check-input" type="checkbox" checked={child.Checked} onChange={() => clickFunction(child)} />
                                                            <span className="helping-el"></span> {child.Name}
                                                        </label>
                                                        <ul>
                                                            <li>
                                                                {
                                                                    child.Actions.map((action, key2) => {
                                                                        return (
                                                                            <div className="custom-checkbox" key={key2} >
                                                                                <input className="form-check-input" type="checkbox" checked={action.Checked} onChange={() => clickAction(child, action)} />
                                                                                <span className="helping-el"></span> {action.Name}
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </li>

                                                        </ul>
                                                    </li>

                                                )
                                            })
                                        }

                                    </ul>
                                </li>
                            )
                        })
                    }
                </ul>


            </div>

        </div>

    )
}
