import React, { useEffect, useState } from 'react';
import PostApi from '../../api/PostApi'
import PostCategoryApi from '../../api/PostCategoryApi'
import Pagination from 'react-bootstrap-4-pagination';
import { Link, NavLink, Route, useParams, withRouter } from 'react-router-dom';
/* import { defaultProps } from 'react-select/src/Select'; */

export const BaiViet = (props) => {

    var apiUrl = process.env.REACT_APP_API_URL.replace('/api', '');
    const [listBaiViets, setListBaiViet] = useState([]);
    const [postCategory, setPostCategory] = useState({});
    const [filter, setFilter] = useState('');
    const [listNoiBats, setListNoiBat] = useState([]);
    const [listNews, setListNew] = useState([]);

    const { IdDanhMuc } = useParams();
    useEffect(() => {
        loadBaiViet();
        loadCategory();
        loadNoiBat();
        loadTinMoi();
    }, []);

    let pageIndex = 1;
    let pageSize = 20;
    const [paginationConfig, setPaging] = useState({
        totalPages: 1,
        currentPage: 1,
        showMax: 5,
        size: "sm",
        threeDots: true,
        prevNext: true,
        onClick: function (page) {
            pageIndex = page;
            loadBaiViet();
        }
    });

    const loadCategory = async () => {
        const category = await PostCategoryApi.getDetail(IdDanhMuc);
        setPostCategory(category);
    }

    const loadBaiViet = async () => {
        const params = {
            pageIndex: pageIndex,
            pageSize: pageSize,
            categoryId: IdDanhMuc,
            filter: filter
        }
        var res = await PostApi.getPagingByCategory(params);
        setListBaiViet(res.Items);
        paginationConfig.totalPages = 1;
        if (res.Items.length > 0) {
            paginationConfig.totalPages = Math.ceil(res.TotalRow / pageSize);
        }
        paginationConfig.currentPage = pageIndex;
        setPaging(paginationConfig);
    }

    const loadNoiBat = async () => {
        var res = await PostApi.getTinNoiBat();
        setListNoiBat(res);
    }

    const loadTinMoi = async () => {
        var res = await PostApi.getTinMoi();
        setListNew(res);
    }


    return (
        <>
            <div className="container fl-builder-content trang-container">
                <div className="fl-row-content-wrap">
                    <div className="row">
                        <div className="breadcrumb-container">
                            <a href="/" className="breadcrumb-dis">Trang chủ / </a>
                            <a href={'/public'+postCategory.Url} className="breadcrumb-active"> {postCategory.Name}</a>
                            <div className="breadcrumb-line">
                            </div>
                        </div>
                    </div>
                    <br></br>
                    <div className="row">
                        <div className="col-md-9">
                            <div className="row">
                                {
                                    listBaiViets.map((item, key) => {
                                        return (
                                            <div className="tt-item" key={key}>
                                                <div className="tt-img">
                                                    <NavLink to={'/public/bai-viet/' + item.alias + '/' + item.id} className="tt-ten">
                                                        <img src={apiUrl + item.image} alt={item.name} />
                                                    </NavLink>
                                                </div>
                                                <NavLink to={'/public/bai-viet/' + item.alias + '/' + item.id} className="tt-ten">
                                                    <p>{item.name}</p>
                                                </NavLink>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className="col-md-12 text-right">
                                <Pagination {...paginationConfig} />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="row">
                                <h4 className="text-center">Tin nổi bật</h4>
                                {
                                    listNoiBats.map((item, key) => {
                                        return (
                                            <div className="media" key={key}>
                                                <NavLink to={'/public/bai-viet/' + item.alias + '/' + item.id}>
                                                    <img className="tt-noi-bat align-self-start mr-3" src={apiUrl + item.image} alt={item.name} />
                                                </NavLink>
                                                <div className="media-body">
                                                    <NavLink to={'/public/bai-viet/' + item.alias + '/' + item.id}>
                                                        <p>{item.name}</p>
                                                    </NavLink>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className="row">
                                <h4 className="text-center">Tin mới nhất</h4>
                                {
                                    listNews.map((item, key) => {
                                        return (
                                            <div className="media" key={key}>
                                                <NavLink to={'/public/bai-viet/' + item.alias + '/' + item.id}>
                                                    <img className="tt-noi-bat align-self-start mr-3" src={apiUrl + item.image} alt={item.name} />
                                                </NavLink>
                                                <div className="media-body">
                                                    <NavLink to={'/public/bai-viet/' + item.alias + '/' + item.id}>
                                                        <p>{item.name}</p>
                                                    </NavLink>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}