const { default: axiosClient } = require("./axiosClient");

const HuongDanApi = {
    getPaging: (params) => {
        const url = '/huongdan/paging';
        return axiosClient.get(url, {params});
    },

    getDetail: (id) =>{
        const url = '/huongdan/getbyid/' + id;
        return axiosClient.get(url);
    },

    post: (data) =>{
        const url = '/huongdan/add';
        return axiosClient.post(url, data);
    },

    put: (data) => {
        const url = '/huongdan/edit';
        return axiosClient.put(url, data);
    },

    delete: (id) =>{
        const url = '/huongdan/delete/' + id;
        return axiosClient.delete(url);
    },
    getAll: (params) =>{
        const url = 'huongdan/getall';
        return axiosClient.get(url, {params});
    }
}

export default HuongDanApi;