import React, { useState, useEffect } from 'react'

export const Footer = (props) => {
    var apiUrl = process.env.REACT_APP_URL;
    const [lienHe, setLienHe] = useState({});

    useEffect(() => {
        debugger;
        if(props.lienHe != null){
            setLienHe(props.lienHe);
        }
    }, [props.lienHe]);

    return (
        <>
            <footer className="footer">
                <div className="container">
                    <div className="f-logo">
                        <img src={apiUrl + lienHe.Logo} />
                    </div>
                    <div className="f-thong-tin">
                        <h5>{lienHe.Name}</h5>
                        <p>Địa chỉ: {lienHe.Address}</p>
                        <p>Điện thoại: {lienHe.Phone}</p>
                        <p>Email: <a href={"mailto:" + lienHe.Email}>{lienHe.Email}</a></p>
                    </div>
                    <div className="f-mxh text-right">
                        <a href={lienHe.Facebook} ><i className="fab fa-facebook-square"></i></a>
                        <a href={lienHe.Youtube} ><i className="fab fa-youtube"></i></a>
                        <a href={lienHe.Zalo} ><i className="fab fa-twitter-square"></i></a>
                    </div>
                </div>
            </footer>
        </>
    )
}
