import React, { useState, useEffect } from 'react'
import PostApi from '../src/api/PostApi'
import TrangApi from '../src/api/TrangApi'
import SauBenhApi from '../src/api/SauBenhApi'
import { Slide } from '../src/public/website/slide'
import Slider from "react-slick";
import { NavLink } from 'react-router-dom';
import { Home } from './main/home/Home'
import LuotTruyCapApi from './api/LuotTruyCapApi'
import SlideApi from './api/SlideApi'
import LienHeApi from './api/LienHeApi'


export const TrangChu = (props) => {
    var apiUrl = process.env.REACT_APP_URL;
    var noImage = "/uploadfiles/website/no_image-100x100.jpg";
    const [listTopPage, setListTopPage] = useState([]);
    const [listButomPage, setListButomPage] = useState([]);
    const [listPostHotFlag, setListPostHotFlag] = useState([]);
    const [listSauBenh, setListSauBenh] = useState([]);
    const [listLuotTruyCap, setLuotTruyCap] = useState([]);
    const [listSlide, setListSlide] = useState([]);
    const [lienHe, setLienHe] = useState({});

    const loadTrangs = async () => {
        const res = await TrangApi.getAll();
        var lstTop = [];
        var lstButom = [];
        var lstTopAlias = [] = ['gioi-thieu-tinh-nang', 'gioi-thieu-website', 'tinh-nang-noi-bat'];
        var lstButomAlias = [] = ['gioi-thieu-ban-do', 'gioi-thieu-phan-bon'];
        lstTopAlias.forEach(element => {
            var temp = res.filter(x => x.Alias == element);
            if (temp.length > 0) {
                lstTop.push(...temp);
            }
        });
        lstButomAlias.forEach(element => {
            var temp = res.filter(x => x.Alias == element);
            if (temp.length > 0) {
                lstButom.push(...temp);
            }
        });
        setListTopPage(lstTop);
        setListButomPage(lstButom);
    }

    const loadBaiVietTrangChu = async () => {
        const res = await PostApi.getHotFlag();
        setListPostHotFlag(res);
    }

    const loadLuotTruyCap = async () => {
        const res = await LuotTruyCapApi.getAll();
        setLuotTruyCap(res);
    }

    const loadSauBenh = async () => {
        const res = await SauBenhApi.getAll();
        setListSauBenh(res);
    }

    const loadSlide = async () => {
        const res = await SlideApi.getAll({ type: 3 });
        setListSlide(res);
    }

    useEffect(() => {
        loadLienHe();
        loadTrangs();
        loadSlide();
        loadBaiVietTrangChu();
        loadSauBenh();
        loadLuotTruyCap();
    }, []);

    const loadLienHe = async () => {
        const params = {
            keyword: '',
            pageIndex: 1,
            pageSize: 20
        }
        const res = await LienHeApi.getLienHe(params);
        if (res.Items.length > 0) {
            setLienHe(res.Items[0]);
        }
    }

    var settings = {
        dots: false,
        infinite: true,
        slidesToShow: window.innerWidth < 600 ? 1 : 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: true,
        arrows: false
    };
    var slide_Style = {
        height: '200px'
    }

    var slideItem_Style = {
        marginLeft: "auto",
        marginRight: "auto",
        padding: "0px 5px",
        height: "160px",
        maxWidth: "100%"
    }
    var textItem_Style = {
        textAlign: "center"
    }




    var settingsApp = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: true,
        arrows: false
    };

    var slide_StyleApp = {
        height: '600px'
    }

    var slideItem_StyleApp = {
        width: '100%',
        height: "600px"
    }
    return (
        <>
            <Slide></Slide>
            <Home></Home>
            {listButomPage.map((item, key) => {
                return (
                    <div key={key} dangerouslySetInnerHTML={{ __html: item.Content }}></div>
                )
            })}
            <div className="fl-builder-content">
                <div className="fl-group">
                    <div className="col-md-12 text-center">
                        <h4>DANH MỤC SÂU BỆNH</h4>
                    </div>
                </div>
                <div >
                    <Slider {...settings} style={slide_Style}>
                        {listSauBenh.map((item, key) => {
                            return (
                                <div key={key}>
                                    <NavLink to={'/public/sau-benh/chi-tiet/' + item.Id}>
                                        <img src={apiUrl + (item.HinhAnh == null ? noImage : item.HinhAnh)} style={slideItem_Style} />
                                        <p style={textItem_Style}>{item.TenSauBenh}</p>
                                    </NavLink>
                                </div>
                            )
                        })}
                    </Slider>
                </div>
            </div>
            <div className="fl-builder-content bg-bak">
                <div className="container">
                    <div className="fl-group row">
                        <div className="col-md-12 text-center">
                            <h3>Thông kê truy cập</h3>
                        </div>
                    </div>
                    <div className="fl-group row text-center">
                        {
                            listLuotTruyCap.map((item, key) => {
                                return (
                                    <div className="center-text col2" key = {key}>
                                        <span className="page-nav-text" style={{fontSize: "30px"}}>{item.SoLuong} </span>
                                        <span className="page-nav-text">{item.Name}</span>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            <div className="fl-builder-content">
                <div className="fl-group">
                    <div className="col-md-12 text-center">
                        <h4>TIN TỨC</h4>
                    </div>
                </div>
                <div >
                    <Slider {...settings} style={slide_Style}>
                        {listPostHotFlag.map((item, key) => {
                            return (
                                <div key={key}>
                                    <NavLink to={'/public/bai-viet/' + item.Alias + "/" + item.Id}>
                                        <img src={apiUrl + item.Image} style={slideItem_Style} />
                                        <p style={textItem_Style}>{item.Name}</p>
                                    </NavLink>
                                </div>
                            )
                        })}
                    </Slider>
                </div>
            </div>


            <div className="fl-builder-content">
                <div className="fl-group">
                    <div className="col-md-12 text-center">
                        <h4>ỨNG DỤNG QUẢN LÝ SÂU BỆNH</h4>
                    </div>
                </div>
                <div>
                    <Slider {...settingsApp} style={slide_StyleApp}>
                        {listSlide.map((item, key) => {
                            return (
                                <div key={key}>
                                    <a href="#">
                                        <img src={apiUrl + item.image} style={slideItem_StyleApp} />
                                    </a>
                                </div>
                            )
                        })}
                    </Slider>
                </div>
                <div className="fl-group row">
                    <div className="col-md-3"></div>
                    <div className="col-md-3 text-center">
                        <a href={lienHe.Instagram == null ? '' : lienHe.Instagram} target='_blank'>
                            <img src='/assets/img/ios.png' />
                        </a>
                    </div>
                    <div className="col-md-3 text-center">
                        <a href={lienHe.Sologan2 == null ? '' : lienHe.Sologan2} target='_blank'>
                            <img src='/assets/img/androi.png' />
                        </a>
                    </div>
                </div>
            </div>

        </>

    )
}
