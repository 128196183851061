import axiosClient from './axiosClient';

//const { param } = require("jquery");
//const {default: axiosClient} = require("./axiosClient");

const CayTrongApi={
    getAll: (params) =>{
        const url = '/caytrong/getall';
        return axiosClient.get(url, {params});
    },

    getPaging: (params) => {
        const url='/caytrong/paging';
        return axiosClient.get(url,{params});
    },

    getPagingPublic: (params) => {
        const url='/caytrong/pagingpublic';
        return axiosClient.get(url,{params});
    },

    getDetail: (id) =>{
        const url = '/caytrong/getbyid/' + id;
        return axiosClient.get(url);
    },
    getById: (id) =>{
        const url = '/caytrong/getbyid/' + id;
        return axiosClient.get(url);
    },

    post: (data) =>{
        const url = '/caytrong/add';
        return axiosClient.post(url, data);
    },

    put: (data) => {
        const url = '/caytrong/edit';
        return axiosClient.put(url, data);
    },

    delete: (id) =>{
        const url = '/caytrong/delete/' + id;
        return axiosClient.delete(url);
    }
    
}

export default CayTrongApi;