import axiosClient from './axiosClient';
import axiosClientFile from './axiosClientFile';
const TaiKhoanApi = {
    getPaging: (params) => {
        const url = '/User/paging';
        return axiosClient.get(url, {params})
    },
    
    getDetail: (id) =>{
        const url = '/User/getbyid/' + id;
        return axiosClient.get(url);
    },

    createUser:(params) => {
        const url = '/User/add';
        return axiosClient.post(url, params);
        
    },

    updateUser:(params) => {
        const url = '/User/edit';
        return axiosClient.put(url, params);
    },

    DeleteUser:(Id) => {
        const url = '/User/delete/'+ Id;
        return axiosClient.delete(url);
    },

    UploadFile:(params) => {
        const url = '/Upload/addAvatar';
        return axiosClientFile.post(url,params);
    },

    getUserRoles: (Id) => {
        const url = '/User/getuserroles/' +Id;
        return axiosClient.get(url)
    },
    changePass: (data)=>{
        const url = '/changedpass';
        return axiosClient.post(url, data);
    },
    setPass: (data)=>{
        const url = '/user/setpass';
        return axiosClient.post(url, data);
    }
}
export default TaiKhoanApi;