import React, { useEffect, useState } from 'react'
import { components } from 'react-select';
import TimKiemApi from '../../api/TimKiemApi';
import { SelectMulti } from './SelectMulti'

export const SelectXaMulti = (props) => {
    const [listQuanHuyens, setQuanHuyen] = useState([]);
    const [selectedOption, setSelectedOption] = useState();

    useEffect(() => {
        const loadQuanHuyen = () => {
            let params = {
                listHuyens: props.filterXa
            }
            if ( props.listHuyens !== null) {
                TimKiemApi.getFilterByDuAn(params).then((response) => {
                    let list = [];
                    response.forEach(item => {
                        list.push({ value: item.maXa, label: item.tenXa });
                    });
                    setQuanHuyen(list);
                    // list.unshift(allOption);
                    // setTimeout(() => {
                    //     props.onChangeXaProps(list);
                    // }, 100);
                })
            }
        }
        loadQuanHuyen();
    }, [props.filterXa]);

    const allOption = {
        label: "Chọn xã",
        value: 0,
        Ma: 0
    };

    const Option = props => {
        return (
            <div>
                <components.Option {...props}>
                    <input
                        type="checkbox"
                        checked={props.isSelected}
                        onChange={() => null}
                    />{" "}
                    <label>{props.label}</label>
                </components.Option>
            </div>
        );
    };

    const ValueContainer = ({ children, ...props }) => {
        const currentValues = props.getValue();
        let toBeRendered = children;
        if (currentValues.some(val => val.value === allOption.value)) {
            toBeRendered = [[children[0][0]], children[1]];
        }
        return (
            <components.ValueContainer {...props}>
                {toBeRendered}
            </components.ValueContainer>
        );
    };
    const MultiValue = props => {
        let labelToBeDisplayed = `${props.data.label}, `;

        const currentValues = props.getValue();
        if (currentValues.length > 2) {
            if (currentValues[currentValues.length - 1].value === props.data.value) {
                labelToBeDisplayed = `Bạn đã chọn (${currentValues.length})`
            }
            else {
                if (props.data.value === allOption.value) {
                    labelToBeDisplayed = allOption.label;
                }
                else {
                    return '';
                }
            }
        }
        return (
            <components.MultiValue {...props}>
                <span>{labelToBeDisplayed}</span>
            </components.MultiValue>
        );
    };
    const handleChange = (e) => {
        setSelectedOption(e);
        props.onChangeXaProps(e);

    }
    return (
        <span
            // class="d-inline-block"
            data-toggle="popover"
            data-trigger="focus"
            data-content="Please selecet account(s)"
            className="select"
        >
            <SelectMulti
                options={listQuanHuyens}
                isMulti
                placeholder="Chọn xã"
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{
                    Option,
                    MultiValue,
                    ValueContainer
                }}
                theme={theme => ({
                    ...theme,
                    borderRadius: 0,
                    height: 30,
                    spacing: {
                        controlHeight: 30,
                        menuGutter: 1,
                        baseUnit: 2,
                    }
                })}
                onChange={handleChange}
                allowSelectAll={true}
                value={selectedOption}
                styles={{ container: (base) => ({ ...base, zIndex: 1100 }) }}
            />
        </span>
    )
}
