import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { ModalDienTichSanXuat } from '../modaldientichsanxuat/modaldientichsanxuat';

export const BaoVeThucVat = (props) => {
    const [edit, setEdit] = useState(false);
    const showInfo = () => {
        setEdit(true);
    }
    const closeModalDienTich = () => {
        setEdit(false);
    }
    return (
        <div className="thong-tin">
            {
                props.thongTin.ten_huyen ? <table className="table table-bordered" width="100%">
                    <thead>
                        <tr>
                            <th colSpan="2">Phân bố cây trồng
                            <button type="button" className="btn btn-info btn-sm suathongtin" onClick={showInfo}><i className='fas fa-info'></i></button>
                            {/* {
                                    props.admin ? <button type="button" className="btn btn-primary btn-sm suathongtin" onClick={ShowEdit}><i className='fas fa-pen'></i></button> : ''
                                } */}
                            </th>
                        </tr>
                        <tr>
                            <th className="col-name">Tên</th>
                            <th>Thông tin</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="col-name">Huyện</td>
                            <td>{props.thongTin?.ten_huyen}</td>
                        </tr>
                        <tr>
                            <td className="col-name">Xã</td>
                            <td>{props.thongTin?.texxa_vn}</td>
                        </tr>
                        <tr>
                            <td className="col-name">Diện tích(ha)</td>
                            <td>
                                <NumberFormat value={props.thongTin?.dientich} displayType={'text'} thousandSeparator={true} decimalScale={2}></NumberFormat>
                            </td>
                        </tr>
                        <tr>
                            <td className="col-name">Hiện trạng sử dụng</td>
                            <td>{props.thongTin?.caytrong}</td>
                        </tr>
                        {
                            props.thongTin.anhhuongsaubenh?.length > 0?
                            <tr>
                                <td className="col-name">Sâu bệnh</td>
                                <td>{props.thongTin.anhhuongsaubenh[0].TenSauBenh}</td>
                            </tr>:''
                        }
                        {
                            props.thongTin.anhhuongsaubenh?.length > 0?
                            <tr>
                                <td className="col-name">Mật độ</td>
                                <td>{props.thongTin.anhhuongsaubenh[0].MatDo}</td>
                            </tr>:''
                        }
                        {
                            props.thongTin.anhhuongsaubenh?.length > 0?
                            <tr>
                                <td className="col-name">Diện tích nhẹ(toàn xã)</td>
                                <td>{props.thongTin.anhhuongsaubenh[0].DienTichNang}</td>
                            </tr>:''
                        }
                        {
                            props.thongTin.anhhuongsaubenh?.length > 0?
                            <tr>
                                <td className="col-name">Diện tích trung bình(toàn xã)</td>
                                <td>{props.thongTin.anhhuongsaubenh[0].DienTichTrungBinh}</td>
                            </tr>:''
                        }
                        {
                            props.thongTin.anhhuongsaubenh?.length > 0?
                            <tr>
                                <td className="col-name">Diện tích nặng(toàn xã)</td>
                                <td>{props.thongTin.anhhuongsaubenh[0].DienTichNang}</td>
                            </tr>:''
                        }
                        {
                            props.thongTin.anhhuongsaubenh?.length > 0?
                            <tr>
                                <td className="col-name">Diện tích mất trắng(toàn xã)</td>
                                <td>{props.thongTin.anhhuongsaubenh[0].DienTichMatTrang}</td>
                            </tr>:''
                        }
                        {
                            props.thongTin.anhhuongsaubenh?.length > 0?
                            <tr>
                                <td className="col-name">Diện tích phòng trừ(toàn xã)</td>
                                <td>{props.thongTin.anhhuongsaubenh[0].DienTichPhongTru}</td>
                            </tr>:''
                        }
                        {
                            props.thongTin.anhhuongsaubenh?.length === 0?
                            <tr>
                                <td className="col-name">Sâu bệnh</td>
                                <td>Không nhiễm bệnh</td>
                            </tr>:''
                        }
                    </tbody>
                </table> : <h5 className="text-center">Không có thông tin</h5>
            }
            <ModalDienTichSanXuat show={edit} closeModal={closeModalDienTich} ThongTin={props.thongTin}></ModalDienTichSanXuat>
        </div>
    )
};