import React, { useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import PostApi from '../../../api/PostApi';
import Select from 'react-select';
import Pagination from 'react-bootstrap-4-pagination';
import ThongBao from '../../../core/ThongBao';
import { QuanLyTapTin } from '../../../shared/quanlytaptin/QuanLyTapTin';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import PostCategoryApi from '../../../api/PostCategoryApi';
import CayTrongApi from '../../../api/CayTrongApi';
import SauBenhApi from '../../../api/SauBenhApi';

import Authen from '../../../core/Authen';
import Ultility from '../../../core/Ultility';
import { post } from 'jquery';

export const Post = () => {
    var apiUrl = process.env.REACT_APP_URL;
    var noImage = "/uploadfiles/website/no_image-100x100.jpg";
    const [listPosts, setListPosts] = useState([]);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const { register, handleSubmit, reset, errors } = useForm();
    const [filter, setFilter] = useState('');
    const [listDanhMucCha, setDanhMucCha] = useState([]);
    const [selectedOption, setSelectedOption] = useState();
    const [srcImage, setSrcImage] = useState(noImage);
    const [alias, setAlias] = useState("");

    const [viewModal, setViewModal] = useState(false);
    const [postModal, setPostModal] = useState({});
    const [content, setContent] = useState('');

    const [roleAdd, setRoleAdd] = useState(false);
    const [roleEdit, setRoleEdit] = useState(false);
    const [roleDel, setRoleDel] = useState(false);

    const [cayTrong, setCayTrong] = useState({});
    const [sauBenh, setSauBenh] = useState({});

    const [optionCayTrongs, setOptionCayTrong] = useState([]);
    const [optionSauBenhs, setOptionSauBenh] = useState([]);
    const [isEdit, setIsEdit] = useState(false);

    let pageIndex = 1;
    let pageSize = 20;
    const [paginationConfig, setPaging] = useState({
        totalPages: 1,
        currentPage: 1,
        showMax: 5,
        size: "sm",
        threeDots: true,
        prevNext: true,
        onClick: function (page) {
            pageIndex = page;
            loadPosts();
        }
    });

    const loadPosts = async () => {
        try {
            const params = {
                pageIndex: pageIndex,
                pageSize: pageSize,
                filter: filter
            }
            const response = await PostApi.getPaging(params);
            response.Items.forEach(item => {
                item.Checked = false;
            });
            paginationConfig.totalPages = 1;
            if (response.Items.length > 0) {
                paginationConfig.totalPages = Math.ceil(response.TotalRow / pageSize);
            }
            paginationConfig.currentPage = pageIndex;
            setPaging(paginationConfig);
            setListPosts(response.Items);
            setIsEdit(false);
        } catch (err) {
        }
    }

    const loadSauBenh = async () => {
        const resPonse = await SauBenhApi.getAll();
        let lst = [];
        lst.push({ value: 0, label: 'Chọn sâu bệnh ...' });
        if (!resPonse.length == false) {
            resPonse.map(x => {
                lst.push({ 'value': x.Id, 'label': x.TenSauBenh });
            })
        }
        setOptionSauBenh(lst);
    }

    const loadCayTrong = async () => {
        const rePonse = await CayTrongApi.getAll();
        let lst = [];
        lst.push({ value: 0, label: 'Chọn cây trồng ...' });
        if (!rePonse.length == false) {
            rePonse.map(x => {
                lst.push({ 'value': x.Id, 'label': x.TenCayTrong });
            })
        }
        setOptionCayTrong(lst);
    }

    useEffect(() => {
        loadPosts();
        loadDanhMucCha();
        loadSauBenh();
        loadCayTrong();
        setRoleAdd(Authen.checkQuyen('BAIVIET_CREATE'));
        setRoleEdit(Authen.checkQuyen('BAIVIET_UPDATE'));
        setRoleDel(Authen.checkQuyen('BAIVIET_DELETE'));
    }, []);

    const timKiem = () => {
        loadPosts();
    }

    function onChangeFilter(e) {
        setFilter(e.target.value)
    }

    const saveData = (data) => {
        postModal.Alias = alias;
        postModal.Image = srcImage;
        postModal.Content = content;
        postModal.IdSauBenh = sauBenh.value;
        postModal.IdCayTrong = cayTrong.value;
        if (selectedOption) {
            if (selectedOption.value == 0) {
                postModal.CategoryId = null;
            }
            else {
                postModal.CategoryId = selectedOption.value;
            }
        }
        else {
            postModal.CategoryId = null;
        }
        if (postModal.CategoryId == null) {
            ThongBao.prinWarningMessage("Bạn hãy nhập danh mục");
        }
        else {
            if (postModal.Id === 0 || postModal.Id == null) {
                postModal.Id = 0;
                PostApi.post(postModal).then(() => {
                    setShow(false);
                    loadPosts();
                })
            }
            else {
                PostApi.put(postModal).then(() => {
                    setShow(false);
                    loadPosts();
                })
            }
        }
    }

    const clickRow = (id) => {
        listPosts.forEach(item => {
            if (item.Id === id) {
                item.Checked = !item.Checked;
            }
        });
        if (listPosts.filter(x => x.Checked == true).length > 0) {
            setIsEdit(true);
        }
        else {
            setIsEdit(false);
        }
        setListPosts([...listPosts]);
    }

    const handleShowAdd = () => {
        var login = Authen.getUser();
        reset({
            Id: 0,
            Name: "",
            Alias: "",
            Status: true
        }, [reset]);
        setPostModal({
            Id: 0,
            Name: "",
            Alias: "",
            Status: true
        });
        setShow(true)
        setAlias("");
        setContent("");
        setCayTrong({ value: 0, label: 'Chọn cây trồng ...' });
        setSauBenh({ value: 0, label: 'Chọn sâu bệnh ...' });
        setSelectedOption(listDanhMucCha[0]);
        setSrcImage(noImage);
    };

    const handleShowEdit = () => {
        let index = listPosts.findIndex(x => x.Checked);
        if (index !== -1) {
            PostApi.getDetail(listPosts[index].Id).then((response) => {
                reset({
                    Id: response.Id,
                    Name: response.Name,
                    Alias: response.Alias,
                    Description: response.Description,
                    ParentId: response.ParentId,
                    DisplayOrder: response.DisplayOrder,
                    Image: response.Image,
                    HomeFlag: response.HomeFlag,
                    HotFlag: response.HotFlag,
                    Status: response.Status,
                    MetaKeyword: response.MetaKeyword,
                    MetaDescription: response.MetaDescription,
                    IconCss: response.IconCss,
                    Content: response.Content
                }, [reset]);
                var index = listDanhMucCha.findIndex(x => x.value == response.CategoryId);
                setSelectedOption(listDanhMucCha[index]);
                setAlias(response.Alias);
                setSrcImage(response.Image);
                setContent(response.Content);
                setPostModal(response);

                setCayTrong(response.IdCayTrong == null ? { value: 0, label: 'Chọn cây trồng ...' } : optionCayTrongs.filter(x => x.value == response.IdCayTrong)[0]);
                setSauBenh(response.IdSauBenh == null ? { value: 0, label: 'Chọn sâu bệnh ...' } : optionSauBenhs.filter(x => x.value == response.IdSauBenh)[0]);

                setShow(true);
            });
        }
    }

    const handleShowDel = () => {
        ThongBao.printConfirmationDialog("Bạn có đồng ý xóa dữ liệu đã chọn hay không?", () => {
            let lstChecked = listPosts.filter(x => x.Checked);
            if (lstChecked.length > 0) {
                let lstId = [];
                lstChecked.forEach(element => {
                    lstId.push(element.Id);
                });
                PostApi.delete(lstId).then(() => {
                    loadPosts();
                })
            }
        });
    }

    const checkBoHandler = () => {

    }

    function loadDanhMucCha() {
        PostCategoryApi.getAll().then((response) => {
            let list = [];
            list.push({ value: 0, label: 'Chọn danh mục...' });
            response.forEach(item => {
                if (item.ParentId == null) {
                    list.push({ value: item.Id, label: item.Name });
                }
            })
            setDanhMucCha(list);
        })
    }

    const onChangeDanhMucCha = (e) => {
        setSelectedOption(e);
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            loadPosts();
        }
    }

    const openModalImage = () => {
        setViewModal(true);
    }


    const closeModalImage = () => {
        setViewModal(false);
    }

    const onFileSelected = (event) => {
        setSrcImage(event);
    }

    const onChangeContent = (event, editor) => {
        const data = editor.getData();
        setContent(data);
    }

    const changeTab = () => {
        reset({
            Id: postModal.Id,
            Name: postModal.Name,
            Alias: postModal.Alias,
            CategoryId: postModal.CategoryId,
            Image: postModal.Image,
            Description: postModal.Description,
            Content: postModal.Content,
            HomeFlag: postModal.HomeFlag,
            HotFlag: postModal.HotFlag,
            ViewCount: postModal.ViewCount,
            Status: postModal.Status,
            Delete: postModal.Delete,
            MetaKeyword: postModal.MetaKeyword,
            MetaDescription: postModal.MetaDescription,
            Tags: postModal.Tags
        }, [reset])
    }

    const onChangeName = (e) => {
        let str = "";
        postModal.Name = e.target.value
        if (e.target.value != null) {
            str = Ultility.MakeSeoTitle(e.target.value);
        }
        postModal.Alias = str;
        setAlias(str);
    }

    const onchangeAlias = (e) => {
        let str = "";
        if (e.target.value != null) {
            str = Ultility.MakeSeoTitle(e.target.value);
        }
        postModal.Alias = str;
        setAlias(str)
    }

    const onChangeSelectCayTrong = (optionSelect) => {
        setCayTrong(optionSelect);
    }

    const onChangeSelectSauBenh = (optionSelect) => {
        setSauBenh(optionSelect);
    }

    return (
        <div className="card shadow mb-1">
            <div className="card-header py-2 row">
                <div className="col-md-4">
                    <h6 className="m-0 font-weight-bold text-primary">Bài viết</h6>
                </div>
                <div className="input-group col-md-4">
                    <input type="text" className="form-control" placeholder="Tìm kiếm" value={filter} onChange={onChangeFilter} onKeyPress={handleKeyPress} />
                    <div className="input-group-append">
                        <button className="btn btn-info" type="button" onClick={timKiem}><i className='fas fa-search'></i> Tìm kiếm</button>
                    </div>
                </div>
                <div className="col-md-4 bd-button text-right">
                    {roleAdd == true ? <Button variant="success" size="sm" onClick={handleShowAdd}><i className='fas fa-plus'></i> Thêm</Button> : ''}
                    {roleEdit == true ? <Button variant="primary" size="sm" onClick={handleShowEdit} disabled={!isEdit}><i className='far fa-edit'></i> Sửa</Button> : ''}
                    {roleDel == true ? <Button variant="danger" size="sm" onClick={handleShowDel} disabled={!isEdit}><i className='fas fa-trash-alt'></i> Xóa</Button> : ''}
                </div>
            </div>
            <div className="card-body">
                <div className="">
                    <table className="table table-bordered" width="100%">
                        <thead>
                            <tr>
                                <th className="col-checkbox"></th>
                                <th>Hình ảnh</th>
                                <th>Tên bài viết</th>
                                <th>Mô tả</th>
                                <th className="col-trangthai">Trạng thái</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                listPosts.map((item, key) => {
                                    return (
                                        <tr onClick={() => clickRow(item.Id)} key={key}>
                                            <td className="col-checkbox">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" checked={item.Checked} onChange={checkBoHandler} />
                                                </div>
                                            </td>
                                            <td className="col-img"><img src={apiUrl + item.Image}></img></td>
                                            <td>{item.Name}</td>
                                            <td>{item.Description}</td>
                                            <td className="text-center">
                                                {item.Status == true &&
                                                    <span className="label label-success cls-status-label">Kích hoạt</span>
                                                }
                                                {item.Status == false &&
                                                    <span className="label label-danger cls-status-label">Khóa</span>
                                                }

                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <div className="col-md-12 text-right">
                    <Pagination {...paginationConfig} />
                </div>
            </div>

            <Modal show={show} onHide={handleClose} animation={false} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Danh mục bài viết</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="user" onSubmit={handleSubmit(saveData)}>
                        <Tabs>
                            <TabList>
                                <Tab onClick={changeTab}>Thông tin cơ bản</Tab>
                                <Tab onClick={changeTab}> Nội dung.</Tab>
                            </TabList>

                            {/* tab 1 */}
                            <TabPanel>
                                <input name="Id" hidden={true} ref={register} onChange={e => postModal.Id = e.target.value} ></input>
                                <div className="form-group row">
                                    <label className="col-sm-2 col-form-label">Tên bài viết
                                        <span className="text-danger"> *</span>
                                    </label>
                                    <div className="col-sm-10">
                                        <input name="Name" type="text" className="form-control" placeholder="Tên bài viết" ref={register({ required: true })} onChange={onChangeName} />
                                        {errors.Name && <span className="text-danger">Hãy nhập dữ liệu</span>}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-2 col-form-label">Alias
                                        <span className="text-danger"> *</span>
                                    </label>
                                    <div className="col-sm-10">
                                        <input name="Alias" type="text" className="form-control" placeholder="Alias" ref={register({ required: true })} value={alias} onChange={onchangeAlias} />
                                        {errors.Alias && <span className="text-danger">Hãy nhập dữ liệu</span>}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-2 col-form-label">Danh mục
                                        <span className="text-danger"> *</span></label>
                                    <div className="col-sm-10">
                                        <Select
                                            className="select"
                                            name="CategoryId"
                                            placeholder="Danh mục"
                                            // defaultValue={selectedOption}
                                            value={selectedOption}
                                            onChange={onChangeDanhMucCha}
                                            options={listDanhMucCha}
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-2 col-form-label">Mô tả</label>
                                    <div className="col-sm-10">
                                        <textarea name="Description" className="form-control textrea-size" rows="2" placeholder="Nhập mô tả" ref={register} onChange={e => postModal.Description = e.target.value}  ></textarea>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-2 col-form-label">Image</label>
                                    <div className="col-sm-10">
                                        <div className="thumbnail" >
                                            <img name="Image" ref={register} src={apiUrl + srcImage} onClick={openModalImage} />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-2 col-sm-3 col-xl-2 col-form-label">
                                        Cây trồng
                                    </div>
                                    <div className="col-md-4 col-sm-3 col-xl-4">
                                        <Select options={optionCayTrongs} placeholder="Chọn cây trồng" onChange={onChangeSelectCayTrong} value={cayTrong} components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }} />
                                    </div>
                                    <div className="col-md-2 col-sm-3 col-xl-2 col-form-label">
                                        Sâu bệnh
                                    </div>
                                    <div className="col-md-4 col-sm-3 col-xl-4">
                                        <Select options={optionSauBenhs} placeholder="Chọn sâu bệnh" onChange={onChangeSelectSauBenh} value={sauBenh} components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }} />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-2 col-form-label"></label>
                                    <div className="col-sm-10">
                                        <label className="custom-checkbox checkbox-style" >
                                            <input className="form-check-input" name="Status" type="checkbox" ref={register} onChange={e => postModal.Status = e.target.checked} />
                                            <span className="helping-el"></span> Kích hoạt
                                    </label>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-2 col-form-label"></label>
                                    <div className="col-sm-10">
                                        <label className="custom-checkbox checkbox-style" >
                                            <input className="form-check-input" name="HomeFlag" type="checkbox" ref={register} onChange={e => postModal.HomeFlag = e.target.checked} />
                                            <span className="helping-el"></span> Hiển thị trang chủ
                                    </label>
                                    </div>
                                </div>
                                {/* <div className="form-group row">
                                    <label className="col-sm-2 col-form-label"></label>
                                    <div className="col-sm-10">
                                        <label className="custom-checkbox checkbox-style" >
                                            <input className="form-check-input" name="HotFlag" type="checkbox" ref={register} onChange={e => postModal.HotFlag = e.target.checked} />
                                            <span className="helping-el"></span> Sản phẩm nổi bật
                                    </label>
                                    </div>
                                </div> */}
                            </TabPanel>

                            {/* tab 2 */}
                            <TabPanel>
                                <div className="form-group row">
                                    <label className="col-sm-2 col-form-label">Nội dung</label>
                                    <div className="col-sm-10">
                                        <CKEditor editor={ClassicEditor} data={content} onChange={(event, editor) => onChangeContent(event, editor)}
                                            onInit={editor => {
                                                // You can store the "editor" and use when it is needed.
                                                // console.log("Editor is ready to use!", editor);
                                                editor.editing.view.change(writer => {
                                                    writer.setStyle(
                                                        "height",
                                                        "100px",
                                                        editor.editing.view.document.getRoot()
                                                    );
                                                });
                                            }}

                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-2 col-form-label">Từ khóa
                                    </label>
                                    <div className="col-sm-10">
                                        <input name="MetaKeyword" type="text" className="form-control" placeholder="Nhập từ khóa" ref={register} onChange={e => postModal.MetaKeyword = e.target.value} />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-2 col-form-label">Mô tả SEO</label>
                                    <div className="col-sm-10">
                                        <textarea name="MetaDescription" className="form-control textrea-size" rows="2" placeholder="Nhập mô tả seo" ref={register} onChange={e => postModal.MetaDescription = e.target.value}  ></textarea>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-2 col-form-label">Tag
                                    </label>
                                    <div className="col-sm-10">
                                        <input name="Tags" type="text" className="form-control" placeholder="Nhập tag" ref={register} onChange={e => postModal.Tags = e.target.value} />
                                    </div>
                                </div>
                            </TabPanel>
                        </Tabs>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" size="sm" onClick={handleSubmit(saveData)}>
                        Lưu</Button>
                    <Button variant="danger" size="sm" onClick={handleClose}>
                        Hủy</Button>
                </Modal.Footer>
            </Modal>
            <QuanLyTapTin viewModal={viewModal} closeModal={closeModalImage} onFileSelected={(e) => onFileSelected(e)}></QuanLyTapTin>
        </div>
    )
}
