const { default: axiosClient } = require("./axiosClient");

const TrieuChungApi = {
    getTraCuu: (params) => {       
        const url = '/trieuchung/gettracuu';
        return axiosClient.get(url, {params});
    },
    getAll: (params) => {       
        const url = '/trieuchung/getall';
        return axiosClient.get(url, {params});
    },

    getPaging: (params) => {       
        const url = '/trieuchung/paging';
        return axiosClient.get(url, {params});
    },

    getDetail: (id) =>{
        const url = '/trieuchung/getbyid/' + id;
        return axiosClient.get(url);
    },

    post: (data) =>{
        const url = '/trieuchung/add';
        return axiosClient.post(url, data);
    },

    put: (data) => {
        const url = '/trieuchung/edit';
        return axiosClient.put(url, data);
    },

    delete: (id) =>{
        const url = '/trieuchung/delete/' + id;
        return axiosClient.delete(url);
    }   
}

export default TrieuChungApi;