import React, { useState, useEffect } from 'react'
import TrangApi from '../../api/TrangApi';
import { useParams } from 'react-router';
import { Slide } from '../../public/website/slide'

export const Trang = () => {
    const [trangModel, setTrangModel] = useState({});
    let { alias } = useParams();
    const loadByAlias = async () => {
        try {
            alias = alias.replace(".html", "");
            const params = {
                alias: alias
            }
            const response = await TrangApi.getByAlias(params);
            if (response.Items.length > 0) {
                setTrangModel(response.Items[0]);
            }
        } catch (err) {
        }
    }

    useEffect(() => {
        loadByAlias();
    }, []);

    return (
        <div className="fl-builder-content trang-container">
                <div className="fl-row-content-wrap">
                    <div className="col-md-12">
                        <div className="breadcrumb-container">
                            <a href="/" className="breadcrumb-dis">Trang chủ / </a>
                            <a href={trangModel.Url} className="breadcrumb-active"> {trangModel.Name}</a>
                            <div className="breadcrumb-line">
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 trang-content">
                        <div dangerouslySetInnerHTML={{ __html: trangModel.Content }} className="text-justify"></div>
                    </div>
                </div>
            </div>
    )
}
