const { default: axiosClient } = require("./axiosClient");

const PostApi = {
    getPaging: (params) => {
        const url = '/post/paging';
        return axiosClient.get(url, {params});
    },

    getPagingByCategory: (params) => {
        const url = '/post/pagingByCategory';
        return axiosClient.get(url, {params});
    },

    getTinNoiBat: () =>{
        return axiosClient.get('post/getnoibat');
    },
    getTinMoi: () =>{
        return axiosClient.get('post/gettinmoi');
    },
    getHotFlag: (params) => {
        const url = '/post/gethotflag';
        return axiosClient.get(url, {params});
    },

    getPagingByCategory: (params) => {
        const url = '/post/pagingbycategory';
        return axiosClient.get(url, {params});
    },

    getDetail: (id) =>{
        const url = '/post/getbyid/' + id;
        return axiosClient.get(url);
    },

    post: (data) =>{
        const url = '/post/add';
        return axiosClient.post(url, data);
    },

    put: (data) => {
        const url = '/post/edit';
        return axiosClient.put(url, data);
    },

    delete: (id) =>{
        const url = '/post/delete/' + id;
        return axiosClient.delete(url);
    },
    getAll: (params) =>{
        const url = 'post/getall';
        return axiosClient.get(url, {params});
    }
}

export default PostApi;