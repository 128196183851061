import axiosClient from './axiosClient';
const ChuGiaiApi = {
    getNongHoa: () => {
        const url = '/chugiai/getnonghoa';
        return axiosClient.get(url)
    },
    getThoNhuong: () => {
        const url = 'chugiai/getthonhuong';
        return axiosClient.get(url)
    },
    getDeXuat: () => {
        const url = 'chugiai/getdexuat';
        return axiosClient.get(url)
    },
    getThichHop: () => {
        const url = 'chugiai/getthichhop';
        return axiosClient.get(url)
    },
    getChatLuongDat: () => {
        const url = 'chugiai/getchatluongdat';
        return axiosClient.get(url)
    },
    getHienTrang: () => {
        const url = 'chugiai/gethientrang';
        return axiosClient.get(url)
    },
    getThichHopPhanCap: () => {
        const url = 'chugiai/getthichhopphancap';
        return axiosClient.get(url)
    },
    getHanhChinhHuyen: () => {
        const url = 'chugiai/gethanhchinhhuyen';
        return axiosClient.get(url)
    },
    getVungDuAn: () => {
        const url = 'chugiai/getvungduan';
        return axiosClient.get(url)
    },
    getBanDoNen: () => {
        const url = 'chugiai/getbandonen';
        return axiosClient.get(url)
    }
}
export default ChuGiaiApi;