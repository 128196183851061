import React, { useState, useEffect } from 'react'
import { Fade, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import SauBenhApi from '../../../api/SauBenhApi';
import '../../../css/apppublic.css';
import { confirmAlert } from 'react-confirm-alert';
import '../../../css/reactconfirm.css';
import '../../../css/reactselect.css';
import Pagination from 'react-bootstrap-4-pagination';
import Authen from '../../../core/Authen';
import { Loading } from '../../../shared/loading/Loading';
import ThongBao from '../../../core/ThongBao';
import QuanHuyenApi from '../../../api/QuanHuyenApi';
import CayTrongApi from '../../../api/CayTrongApi';
import GiongCayTrongApi from '../../../api/GiongCayTrongApi';
import 'react-tabs/style/react-tabs.css';
import AnhHuongSauBenhApi from '../../../api/AnhHuongSauBenhApi';
import MuaVuApi from '../../../api/MuaVuApi';
import PhuongXaApi from '../../../api/PhuongXaApi';


export const AnhHuongSauBenh = () => {

    const { handleSubmit, reset, register, errors } = useForm();

    var data = require('../../../shared/Thang.json');
    const optionThoiGian = [...data];

    const [listAnhHuongSauBenh, setListAnhHuongSauBenh] = useState([]);
    const [roles, setRoles] = useState('');

    const [listSauBenh, setListSauBenh] = useState([]);

    const [show, setShow] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isXem, setIsXem] = useState(false);
    const [search, setSearch] = useState('');
    const [roleAdd, setRoleAdd] = useState(false);
    const [roleEdit, setRoleEdit] = useState(false);
    const [roleDel, setRoleDel] = useState(false);
    const [loading, setLoading] = useState(false);
    let pageIndex = 1, pageSize = 20;

    const [listQuanHuyen, setListQuanHuyen] = useState([]);
    const [listXaPhuong, setListXaPhuong] = useState([]);


    const [disButtonEdit, setDisButtonEdit] = useState(true);

    const [optionQuanHuyens, setOptionQuanHuyen] = useState([]);
    const [optionXaPhuongs, setOptionXaPhuong] = useState([]);
    const [optionCayTrongs, setOptionCayTrong] = useState([]);
    const [optionGiongCayTrongs, setOptionGiongCayTrong] = useState([]);
    const [optionSauBenhs, setOptionSauBenh] = useState([]);
    const [optionMuaVus, setOptionMuaVu] = useState([]);

    const [quanHuyen, setQuanHuyen] = useState({ value: 0, label: 'Chọn quận huyện' });
    const [xaPhuong, setXaPhuong] = useState({ value: 0, label: 'Chọn xã phường' });
    const [cayTrong, setCayTrong] = useState({ value: 0, label: 'Chọn cây trồng' });
    const [giongCayTrong, setGiongCayTrong] = useState({ value: 0, label: 'Chọn giống cây trồng' });
    const [sauBenh, setSauBenh] = useState({ value: 0, label: 'Chọn sâu bệnh' });
    const [muaVu, setMuaVu] = useState({ value: 0, label: 'Chọn mùa vụ' });

    const [optionNgay, setOptionNgay] = useState([]);
    const [optionThang, setOptionThang] = useState([]);
    const [optionNam, setOptionNam] = useState([]);

    const [ngay, setNgay] = useState({ value: 0, label: '...' });
    const [thang, setThang] = useState({ value: 0, label: '...' });
    const [nam, setNam] = useState({ value: 0, label: '...' });

    const LoadNgay = (year, month, ngay) => {
        let songay = new Date(year, month, 0).getDate();
        let lstNgay = [{ value: 0, label: '...' }];
        for (let index = 0; index < songay; index++) {
            lstNgay.push({ value: index + 1, label: index + 1 });
        }
        setOptionNgay(lstNgay);
        let d = new Date().getDate();
        if (ngay > songay) {
            setNgay(lstNgay.filter(x => x.value == d));
        }
        else {
            if (ngay == undefined || ngay.value == 0) {
                setNgay({ value: 0, label: '...' });
            }
            else {
                let lstNgayNew = lstNgay.filter(x => x.value == ngay.value);
                if (lstNgayNew.length > 0) {
                    setNgay(ngay);
                }
                else {
                    setNgay({ value: 0, label: '...' });
                }
            }

        }
    }

    const LoadThang = () => {
        let lstThang = [{ value: 0, label: '...' }];
        data.forEach(x => {
            lstThang.push({ value: x.value, label: x.value });
        });
        setOptionThang(lstThang);
        let d = new Date();
    }

    const LoadNam = (sonam) => {
        let lstNam = [{ value: 0, label: '...' }];
        let date = new Date();
        let year = date.getFullYear();
        for (let index = 0; index < sonam; index++) {
            lstNam.push({ value: year - index, label: year - index });
        }
        setOptionNam(lstNam);
    }



    const [paginationConfig, setPaginationConfig] = useState({
        totalPages: 1,
        currentPage: 1,
        showMax: 5,
        size: 'sm',
        threeDots: true,
        prevNext: true,
        onClick: function (page) {
            pageIndex = page;
            loadAnhHuongSauBenh();
        }
    });

    const onChangeSelectCayTrong = (optionSelected) => {
        setCayTrong(optionSelected);
    }

    const onChangeSelectGiongCayTrong = (optionSelected) => {
        setGiongCayTrong(optionSelected);
    }

    const onChangeSelectSauBenh = (optionSelected) => {
        setSauBenh(optionSelected)
    }

    const onChangeSelectMuaVu = (optionSelected) => {
        setMuaVu(optionSelected);
    }

    const onChangeSelectQuanHuyen = (optionSelected) => {
        setQuanHuyen(optionSelected);
        // let lstXa = [{ value: 0, label: 'Chọn xã phường' }]
        debugger;
        var maHuyen = '0';
        if (optionSelected.value != null) {
            maHuyen = optionSelected.value;
        }
        loadOptionXaPhuong(maHuyen, null);
        // let lst = listXaPhuong.filter(x => x.maHuyen == maHuyen || x.value == "0");
        // setOptionXaPhuong(lst);
        // if (lst.length > 0) {
        //     setXaPhuong(lst[0]);
        // }
    }

    const loadOptionXaPhuong = (maHuyen, maXa) => {
        let lst = listXaPhuong.filter(x => x.maHuyen == maHuyen || x.value == "0");
        setOptionXaPhuong(lst);
        if (lst.length > 0) {
            setXaPhuong(lst[0]);
        }
        if (maXa != null && maXa != "0") {
            var index = lst.findIndex(x => x.value == maXa);
            if (index > 0) {
                setXaPhuong(lst[index]);
            }
        }
    }

    const onChangeSelectXaPhuong = (optionSelected) => {
        setXaPhuong(optionSelected);
    }

    const onChangeSelectNgay = (optionSelected) => {
        setNgay(optionSelected);
    }

    const onChangeSelectThang = (optionSelected) => {
        setThang(optionSelected);
        if (nam.value != 0 && thang.value != 0) {
            LoadNgay(nam.value, optionSelected.value, ngay);
        }

    }

    const onChangeSelectNam = (optionSelected) => {
        setNam(optionSelected);
        if (optionSelected.value != 0 && thang.value != 0) {
            LoadNgay(nam.value, thang.value, ngay);
        }
    }


    const loadAnhHuongSauBenh = async () => {
        try {
            const params = {
                filter: search,
                pageIndex: pageIndex,
                pageSize: pageSize
            }
            const response = await AnhHuongSauBenhApi.getPaging(params);

            response.Items.forEach((x) => {
                x.Checked = false
            });
            setListAnhHuongSauBenh(response.Items);
            paginationConfig.totalPages = Math.ceil(response.TotalRows == 0 ? 1 : response.TotalRows / pageSize);
            paginationConfig.currentPage = pageIndex;
            setPaginationConfig(paginationConfig);
        }
        catch (err) { }
    }

    const rowClick = (id) => {
        setIsEdit(false);
        listAnhHuongSauBenh.forEach(element => {
            if (element.Id === id) {
                if (element.Checked === true) {
                    element.Checked = false;
                }
                else {
                    element.Checked = true;
                    setIsEdit(true);
                    setDisButtonEdit(false);
                }
            }
            else {
                element.Checked = false;
            }
        });

        if (listAnhHuongSauBenh.filter(element => element.Checked === true).length == 0) {
            setIsEdit(false);
            setDisButtonEdit(true);
        };
        setListAnhHuongSauBenh([...listAnhHuongSauBenh]);
    }

    const handleClose = () => {
        setShow(false);
    }

    const handleShowAdd = () => {
        reset({
            Id: 0,
            Status: true
        }, [reset]);
        setQuanHuyen({ value: 0, label: 'Chọn quận huyện' });
        setXaPhuong({ value: 0, label: 'Chọn xã phường' });
        setCayTrong({ value: 0, label: 'Chọn cây trồng' });
        setGiongCayTrong({ value: 0, label: 'Chọn giống cây trồng' });
        setSauBenh({ value: 0, label: 'Chọn sâu bệnh' });
        setMuaVu({ value: 0, label: 'Chọn mùa vụ' });
        setNgay({ value: 0, label: '...' });
        setThang({ value: 0, label: '...' });
        setNam({ value: 0, label: '...' });

        setIsEdit(false);
        setIsXem(false);
        setShow(true);
    }

    const handleShowEdit = (isXem) => {
        setIsEdit(true);
        setIsXem(isXem);
        let index = listAnhHuongSauBenh.findIndex(x => x.Checked);
        if (index !== -1) {
            AnhHuongSauBenhApi.getDetail(listAnhHuongSauBenh[index].Id).then((response) => {
                reset({
                    Id: response.Id,
                    VungNhiemBenh: response.VungNhiemBenh,
                    MatDo: response.MatDo,
                    DienTichNhe: response.DienTichNhe,
                    DienTichTrungBinh: response.DienTichTrungBinh,
                    DienTichNang: response.DienTichNang,
                    DienTichMatTrang: response.DienTichMatTrang,
                    DienTichPhongTru: response.DienTichPhongTru,
                    GhiChu: response.GhiChu,
                    Status: response.Status,
                }, [reset]);

                setQuanHuyen(optionQuanHuyens.filter(x => x.value == response.MaHuyen).length == 0 ? { value: 0, label: 'Chọn quận huyện' } : optionQuanHuyens.filter(x => x.value == response.MaHuyen)[0]);
                loadOptionXaPhuong(response.MaHuyen,response.MaXa);
                // setXaPhuong(optionXaPhuongs.filter(x => x.value == response.IdXaPhuong).length == 0 ? { value: 0, label: 'Chọn xã phường' } : optionXaPhuongs.filter(x => x.value == response.IdXaPhuong)[0]);
                setCayTrong(optionCayTrongs.filter(x => x.value == response.IdCayTrong).length == 0 ? { value: 0, label: 'Chọn cây trồng' } : optionCayTrongs.filter(x => x.value == response.IdCayTrong)[0]);
                setGiongCayTrong(optionGiongCayTrongs.filter(x => x.value == response.IdGiongCayTrong).length == 0 ? { value: 0, label: 'Chọn giống cây trồng' } : optionGiongCayTrongs.filter(x => x.value == response.IdGiongCayTrong)[0]);
                setSauBenh(optionSauBenhs.filter(x => x.value == response.IdSauBenh).length == 0 ? { value: 0, label: 'Chọn Sâu bệnh' } : optionSauBenhs.filter(x => x.value == response.IdSauBenh)[0]);
                setMuaVu(optionMuaVus.filter(x => x.value == response.IdMuaVu).length == 0 ? { value: 0, label: 'Chọn mùa vụ' } : optionMuaVus.filter(x => x.value == response.IdMuaVu)[0]);
                setNgay(optionNgay.filter(x => x.value == response.Ngay).length == 0 ? { value: 0, label: '...' } : optionNgay.filter(x => x.value == response.Ngay)[0]);
                setThang(optionThang.filter(x => x.value == response.Thang).length == 0 ? { value: 0, label: '...' } : optionThang.filter(x => x.value == response.Thang)[0]);
                setNam(optionNam.filter(x => x.value == response.Nam).length == 0 ? { value: 0, label: '...' } : optionNam.filter(x => x.value == response.Nam)[0]);

                setShow(true);
            });
        }
    }

    // const handleShowData = () => {
    //     setIsXem(true);
    //     let index = listAnhHuongSauBenh.findIndex(x => x.Checked);
    //     if (index !== -1) {
    //         AnhHuongSauBenhApi.getDetail(listAnhHuongSauBenh[index].Id).then((response) => {
    //             reset({
    //                 response
    //             }, [reset]);
    //             onChangeSelectQuanHuyen({ 'value': response.MaHuyen });
    //             setQuanHuyen(optionQuanHuyens.filter(x => x.value == response.IdQuanHuyen).length == 0 ? { value: 0, label: 'Chọn quận huyện' } : optionQuanHuyens.filter(x => x.value == response.IdQuanHuyen)[0]);
    //             setXaPhuong(optionXaPhuongs.filter(x => x.value == response.IdXaPhuong).length == 0 ? { value: 0, label: 'Chọn xã phường' } : optionXaPhuongs.filter(x => x.value == response.IdXaPhuong)[0]);
    //             setCayTrong(optionCayTrongs.filter(x => x.value == response.IdCayTrong).length == 0 ? { value: 0, label: 'Chọn cây trồng' } : optionCayTrongs.filter(x => x.value == response.IdCayTrong)[0]);
    //             setGiongCayTrong(optionGiongCayTrongs.filter(x => x.value == response.IdGiongCay).length == 0 ? { value: 0, label: 'Chọn giống cây trồng' } : optionGiongCayTrongs.filter(x => x.value == response.IdGiongCay)[0]);
    //             setSauBenh(optionSauBenhs.filter(x => x.value == response.IdSauBenh).length == 0 ? { value: 0, label: 'Chọn Sâu bệnh' } : optionSauBenhs.filter(x => x.value == response.IdSauBenh)[0]);
    //             setMuaVu(optionMuaVus.filter(x => x.value == response.IdGiaiDoan).length == 0 ? { value: 0, label: 'Chọn mùa vụ' } : optionMuaVus.filter(x => x.value == response.IdGiaiDoan)[0]);
    //             setNgay(optionNgay.filter(x => x.value == response.Ngay).length == 0 ? { value: 0, label: '...' } : optionNgay.filter(x => x.value == response.Ngay)[0]);
    //             setThang(optionThang.filter(x => x.value == response.Thang).length == 0 ? { value: 0, label: '...' } : optionThang.filter(x => x.value == response.Thang)[0]);
    //             setNgay(optionNam.filter(x => x.value == response.Nam).length == 0 ? { value: 0, label: '...' } : optionNam.filter(x => x.value == response.Nam)[0]);

    //             setShow(true);
    //         });
    //     }
    // }

    const onSubmit = async (data) => {
        try {

            data.MaHuyen = quanHuyen.value;
            data.MaXa = xaPhuong.value;
            data.IdCayTrong = cayTrong.value;
            data.IdGiongCayTrong = giongCayTrong.value;
            data.IdSauBenh = sauBenh.value;
            data.IdMuaVu = muaVu.value;
            data.Ngay = ngay.value;
            data.Thang = thang.value;
            data.Nam = nam.value;
            if (data.DienTichNhe == '') data.DienTichNhe = null;
            if (data.DienTichTrungBinh == '') data.DienTichTrungBinh = null;
            if (data.DienTichNang == '') data.DienTichNang = null;
            if (data.DienTichMatTrang == '') data.DienTichMatTrang = null;
            if (data.DienTichPhongTru == '') data.DienTichPhongTru = null;

            if (!isEdit) {
                const res = await AnhHuongSauBenhApi.post(data);
            }
            else {
                const res = await AnhHuongSauBenhApi.put(data);
            }
            setShow(false);
            loadAnhHuongSauBenh();
        }
        catch (ex) {
        }
    }

    const loadCayTrong = async () => {
        const rePonse = await CayTrongApi.getAll();
        if (!rePonse.length == false) {
            rePonse.map(x => {
                optionCayTrongs.push({ 'value': x.Id, 'label': x.TenCayTrong });
            })
        }
    }

    const loadGiongCayTrong = async () => {
        const rePonse = await GiongCayTrongApi.getAll();
        if (!rePonse.length == false) {
            rePonse.map(x => {
                optionGiongCayTrongs.push({ 'value': x.Id, 'label': x.TenGiong });
            })
        }
    }

    const loadMuaVu = async () => {
        const rePonse = await MuaVuApi.getAll();
        if (!rePonse.length == false) {
            rePonse.map(x => {
                optionMuaVus.push({ 'value': x.Id, 'label': x.Ten });
            })
        }
    }

    const loadSauBenh = async () => {
        const rePonse = await SauBenhApi.getAll();
        if (!rePonse.length == false) {
            rePonse.map(x => {
                optionSauBenhs.push({ 'value': x.Id, 'label': x.TenSauBenh });
            })
        }
    }

    const loadQuanHuyen = async () => {
        const rePonse = await QuanHuyenApi.getAll();
        if (!rePonse.length == false) {
            rePonse.map(x => {
                optionQuanHuyens.push({ 'value': x.Ma, 'label': x.Ten });
            })
        }
    }

    const loadXaPhuong = async () => {
        // let params = {
        //     maHuyen: quanHuyen.value
        // }
        const rePonse = await QuanHuyenApi.getXaByHuyen();
        var lst = [];
        lst.push({ 'value': "0", 'label': "Chọn xã phường", 'maHuyen': '0' });
        rePonse.map(x => {
            lst.push({ 'value': x.maXa, 'label': x.tenXa, 'maHuyen': x.maHuyen });
        })
        debugger;
        setListXaPhuong(lst);
    }


    const loadNgayThang = () => {
        LoadNam(10);
        LoadThang();
        let d = new Date();
        LoadNgay(d.getFullYear(), d.getMonth() + 1, ngay);
    }

    useEffect(() => {
        loadAnhHuongSauBenh();
        loadQuanHuyen();
        loadXaPhuong();
        loadCayTrong();
        loadGiongCayTrong();
        loadMuaVu();
        loadSauBenh();
        loadNgayThang();
        setRoleAdd(Authen.checkQuyen('ANHHUONGSAUBENH_CREATE'));
        setRoleEdit(Authen.checkQuyen('ANHHUONGSAUBENH_UPDATE'));
        setRoleDel(Authen.checkQuyen('ANHHUONGSAUBENH_DELETE'));
    }, []);

    const setFilter = (e) => {
        setSearch(e.target.value);
    }


    const alertDell = async () => {
        let lstChecked = listAnhHuongSauBenh.filter(x => x.Checked);
        if (lstChecked.length > 0) {
            ThongBao.printConfirmationDialog("Bạn có đồng ý xóa dữ liệu đã chọn hay không?", () => {
                AnhHuongSauBenhApi.delete(lstChecked[0].Id).then(() => {
                    loadAnhHuongSauBenh();
                })
            });
        }
    }

    const Timkiem = () => {
        loadAnhHuongSauBenh();
    }

    return (
        <div className="card shadow mb-1">
            <div className="card-header py-2">
                <div className="row ml-2">
                    <div className="col-md-2">
                        <h6 className="m-0 font-weight-bold text-primary">Ảnh hưởng sâu Bệnh</h6>
                    </div>
                    <div className="col-md-6">
                        <div className="input-group">
                            <input type="text" className="form-control" placeholder="Tìm kiếm" value={search} onChange={setFilter} />
                            <div className="input-group-append">
                                <button className="btn btn-info btn-sm" type="button" onClick={Timkiem} ><i className='fas fa-search'></i> Tìm kiếm</button>
                            </div>
                        </div>
                    </div>
                    <div className="fluid-container col-md-4 text-right">
                        {roleAdd == true ? <button type="button" className="btn btn-success btn-sm ml-1" onClick={handleShowAdd}><i className='fas fa-plus'></i> Thêm</button> : ''}
                        {roleEdit == true ? <button type="button" className="btn btn-info btn-sm ml-1" disabled={disButtonEdit} onClick={()=>handleShowEdit(false)}><i className='far fa-edit'></i> Sửa</button> : ''}
                        {roleDel == true ? <button type="button" className="btn btn-danger btn-sm ml-1" disabled={disButtonEdit} onClick={alertDell} ><i className='fas fa-trash-alt'></i> Xóa</button> : ''}
                        <button type="button" className="btn btn-primary btn-sm ml-1 mr-2" disabled={disButtonEdit} onClick={()=>handleShowEdit(true)}><i className='far fa-eye'></i> Xem</button>
                    </div>
                </div>
            </div>

            <div className="card-body">
                <div className="table-import import-scroll">
                    <table className="table table-bordered" width="100%">
                        <thead>
                            <tr>
                                <th><input name="Active" type="Checkbox"></input></th>
                                <th>Vùng nhiễm bệnh</th>
                                <th>Mật độ</th>
                                <th>Ghi chú</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                listAnhHuongSauBenh.map((item, key) => {
                                    return (
                                        <tr className="SelectRow" key={key} onClick={() => rowClick(item.Id)}>
                                            <td className="text-center">
                                                <input type="Checkbox" checked={item.Checked}></input>
                                            </td>
                                            <td>{item.VungNhiemBenh}</td>
                                            <td>{item.MatDo}</td>
                                            <td>{item.GhiChu}</td>
                                            <td className="text-center">{item.Status ? <span className="label label-success cls-status-label">Kích hoạt</span> : <span className="label label-danger cls-status-label">Khóa</span>}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <div className="col-md-12 text-right">
                    <Pagination {...paginationConfig} />
                </div>
            </div>

            <Modal dialogclassName="modalSB" show={show} onHide={handleClose} backdrop="static" keyboard={false} animation={false} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Ảnh hưởng sâu bệnh</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container-fluid">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-group row" hidden={true}>
                                <div className="col-md-2 col-sm-6 col-xl-2 col-form-label  nhanLH">
                                    <label className="nhanTK">ID <code>*</code></label>
                                </div>
                                <div className="col-md-10 col-sm-6 col-xl-10">
                                    <input className="form-control" name="Id" type="Text" ref={register()} ></input>
                                </div>
                            </div>
                            <div className="form-group row mb-1">
                                <div className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">
                                    Quận huyện <code>*</code>
                                </div>
                                <div className="col-md-4 col-sm-6 col-xl-4">
                                    <Select options={optionQuanHuyens} onChange={onChangeSelectQuanHuyen} value={quanHuyen} components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }} />
                                </div>
                                <div className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">
                                    Xã phường <code>*</code>
                                </div>
                                <div className="col-md-4 col-sm-6 col-xl-4">
                                    <Select options={optionXaPhuongs} onChange={onChangeSelectXaPhuong} value={xaPhuong} components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }} />
                                </div>
                            </div>
                            <div className="form-group row mb-1">
                                <div className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">Vùng nhiễm bệnh <code>*</code></div>
                                <div className="col-md-10 col-sm-6 col-xl-10">
                                    <input className="form-control" name="VungNhiemBenh" type="Text" ref={register({ required: "Vùng nhiễm bệnh không được trống" })}></input>
                                    <span className="eRor">{errors.VungNhiemBenh && errors.VungNhiemBenh.message}</span>
                                </div>
                            </div>
                            <div className="form-group row mb-1">
                                <div className="col-md-2 col-sm-2 col-xl-2 col-form-label nhanLH">Ngày</div>
                                <div className="col-md-1 col-sm-2 col-xl-1">
                                    <Select options={optionNgay} onChange={onChangeSelectNgay} value={ngay} components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }} />
                                </div>
                                <div className="col-md-1 col-sm-2 col-xl-1"></div>
                                <div className="col-md-1 col-sm-2 col-xl-1 col-form-label nhanLH">Tháng</div>
                                <div className="col-md-1 col-sm-2 col-xl-1">
                                    <Select options={optionThang} onChange={onChangeSelectThang} value={thang} components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }} />
                                </div>
                                <div className="col-md-2 col-sm-1 col-xl-2 col-form-label nhanLH">Năm</div>
                                <div className="col-md-2 col-sm-2 col-xl-2">
                                    <Select options={optionNam} onChange={onChangeSelectNam} value={nam} components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }} />
                                </div>
                            </div>
                            <div className="form-group row mb-1">
                                <div className="col-md-2 col-sm-3 col-xl-2 col-form-label nhanLH">Cây trồng</div>
                                <div className="col-md-4 col-sm-3 col-xl-4">
                                    <Select options={optionCayTrongs} onChange={onChangeSelectCayTrong} value={cayTrong} components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }} />
                                </div>
                                <div className="col-md-2 col-sm-3 col-xl-2 col-form-label nhanLH">Giống cây trồng</div>
                                <div className="col-md-4 col-sm-3 col-xl-4">
                                    <Select options={optionGiongCayTrongs} onChange={onChangeSelectGiongCayTrong} value={giongCayTrong} components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }} />
                                </div>
                            </div>
                            <div className="form-group row mb-1">
                                <div className="col-md-2 col-sm-3 col-xl-2 col-form-label nhanLH">Sâu bệnh</div>
                                <div className="col-md-4 col-sm-3 col-xl-4">
                                    <Select options={optionSauBenhs} onChange={onChangeSelectSauBenh} value={sauBenh} components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }} />
                                </div>
                                <div className="col-md-2 col-sm-3 col-xl-2 col-form-label nhanLH">Mùa vụ</div>
                                <div className="col-md-4 col-sm-3 col-xl-4">
                                    <Select options={optionMuaVus} onChange={onChangeSelectMuaVu} value={muaVu} components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }} />
                                </div>
                            </div>
                            <div className="form-group row mb-1">
                                <div className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">Mật độ <code>*</code></div>
                                <div className="col-md-10 col-sm-6 col-xl-10">
                                    <input className="form-control" name="MatDo" type="Text" ref={register()}></input>
                                </div>
                            </div>
                            <div className="form-group row mb-1">
                                <div className="col-md-2 col-sm-3 col-xl-2 col-form-label nhanLH">Diện tích nhẹ</div>
                                <div className="col-md-4 col-sm-3 col-xl-4">
                                    <input className="form-control" name="DienTichNhe" type="number" ref={register()}></input>
                                </div>
                                <div className="col-md-2 col-sm-3 col-xl-2 col-form-label nhanLH">Diện tích trung bình</div>
                                <div className="col-md-4 col-sm-3 col-xl-4">
                                    <input className="form-control" name="DienTichTrungBinh" type="number" ref={register()}></input>
                                </div>
                            </div>
                            <div className="form-group row mb-1">
                                <div className="col-md-2 col-sm-3 col-xl-2 col-form-label nhanLH">Diện tích nặng</div>
                                <div className="col-md-4 col-sm-3 col-xl-4">
                                    <input className="form-control" name="DienTichNang" type="number" ref={register()}></input>
                                </div>
                                <div className="col-md-2 col-sm-3 col-xl-2 col-form-label nhanLH">Diện tích mặt tráng</div>
                                <div className="col-md-4 col-sm-3 col-xl-4">
                                    <input className="form-control" name="DienTichMatTrang" type="number" ref={register()}></input>
                                </div>
                            </div>
                            <div className="form-group row mb-1">
                                <div className="col-md-2 col-sm-3 col-xl-2 col-form-label nhanLH">Diện tích phòng trừ</div>
                                <div className="col-md-4 col-sm-3 col-xl-4">
                                    <input className="form-control" name="DienTichPhongTru" type="number" ref={register()}></input>
                                </div>
                            </div>
                            <div className="form-group row mb-1">
                                <div className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">Ghi chú</div>
                                <div className="col-md-10 col-sm-6 col-xl-10">
                                    <textarea className="form-control textrea-size" rows="2" placeholder="Nhập nội dung" name="GhiChu" type="Text" ref={register()}></textarea>
                                </div>
                            </div>
                            <div className="form-group row mb-1">
                                <div className="col-md-2 col-sm-3 col-xl-2 col-form-label nhanLH">
                                    Trạng thái
                                    </div>
                                <div className="col-md-4 col-sm-3 col-xl-4 text-left mt-2">
                                    <input name="Status" type="checkbox" ref={register()} ></input>
                                </div>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <a href="#" type="button" className="btn btn-info btn-sm ml-1" onClick={handleSubmit(onSubmit)} hidden={isXem}><i className='fas fa-save'></i> Lưu</a>
                    <a href="#" type="button" className="btn btn-danger btn-sm ml-1" onClick={handleClose} disabled><i className="fas fa-sign-out-alt"></i> Hủy</a>
                </Modal.Footer>
            </Modal>
            <Loading loading={loading}></Loading>
        </div>
    )
}

