import React, { useEffect, useState } from 'react'

export const ChuGiaiVungDuAn = (props) => {
    const [listChuGiais, setListChuGiai] = useState([]);
    useEffect(() => {
        setListChuGiai(props.listChuGiais.filter(x=>x.ma !== 'SAU'));
    }, [props.listChuGiais]);
    const clickRow = (item) => {
        item.Checked = !item.Checked;
        setListChuGiai([...listChuGiais]);
        props.clickChuGiai(listChuGiais.filter(x => x.Checked).length == 0? "''":listChuGiais.filter(x => x.Checked).map(x => "'" + x.ma + "'").join(','));
    }
    return (
        <table className="table table-bordered tb-chu-giai" width="100%">
            <thead>
                <tr>
                    <th className="col-checkbox"></th>
                    <th className="mau-ky-hieu">Màu sắc Ký hiệu</th>
                    <th>Tên lớp</th>
                </tr>
            </thead>
            <tbody>
                {
                    listChuGiais?.map((item, key) => {
                        return (
                            <tr onClick={() => clickRow(item)} key={key}>
                                <td className="col-checkbox">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" checked={item.Checked} readOnly />
                                    </div>
                                </td>
                                <td className="mau-ky-hieu">
                                    <div style={{ backgroundImage: `url("${item.image}")` }}>
                                        <span>{item.ma}</span>
                                    </div>
                                </td>
                                <td>{item.ten}</td>
                            </tr>
                        )
                    })
                }
            </tbody>
        </table>
    )
}
