import React from 'react'
import ScaleLoader from "react-spinners/ScaleLoader";
import { css } from "@emotion/core";

export const Loading = (props) => {
    const override = css`
    position: fixed;
    top: 50%;
    left: calc(50% - 50px)
        `;
    return (
        props.loading ?
        <div className="sweet-loading">
            <ScaleLoader
                css={override}
                size={100}
                color={"#ffffff"}
                loading={props.loading}
            />
        </div>
        : ''
    )
}
