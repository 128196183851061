import React, { useEffect, useState } from 'react'
import { NavLink, Switch, Route } from 'react-router-dom';
import SauBenhApi from '../../api/SauBenhApi';
import SauBenh from './sau-benh';
import SauBenhChiTiet from './sau-benh-chi-tiet';

export default function SauBenhHome() {
    var apiUrl = process.env.REACT_APP_API_URL.replace('/api', '');
    var noImage = "/uploadfiles/website/no_image-100x100.jpg";
    const [listXemNhieus, setListXemNhieu] = useState([]);
    const [listMoiNhats, setMoiNhat] = useState([]);

    useEffect(() => {
        loadMoiNhat();
        loadXemNhieu();
    }, [])

    const loadXemNhieu = async () => {
        var res = await SauBenhApi.getXemNhieuNhat();
        setListXemNhieu(res);
    }

    const loadMoiNhat = async () => {
        var res = await SauBenhApi.getMoiNhat();
        setMoiNhat(res);
    }


    return (
        <>
            <div className="container fl-builder-content trang-container">
                <div className="fl-row-content-wrap">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="breadcrumb-container">
                                <a href="/" className="breadcrumb-dis">Trang chủ / </a>
                                <a href="/" className="breadcrumb-dis">Sâu bệnh </a>
                                {/* <a href={postCategory.Url} className="breadcrumb-active"> {postCategory.Name}</a> */}
                            </div>
                        </div>
                    </div>
                    <br></br>
                    <div className="row">
                        <div className="col-md-9 sau-b-cont">
                            <Switch>
                                <Route path="/public/sau-benh/chi-tiet/:IdSauBenh" component={SauBenhChiTiet}></Route>
                                <Route path="/">
                                    <SauBenh></SauBenh>
                                </Route>
                                <Route path="/public/sau-benh/sau-benh">
                                    <SauBenh></SauBenh>
                                </Route>
                            </Switch>
                        </div>
                        <div className="col-md-3">
                            <div className="col-md-12">
                                <h4 className="text-center">Xem nhiều nhất</h4>
                            </div>
                            <div className="row">
                                {
                                    listXemNhieus.map((item, key) => {
                                        return (
                                            <div className="media" key={key}>
                                                <NavLink to={'/public/sau-benh/chi-tiet/' + item.Id}>
                                                    <img className="tt-noi-bat align-self-start mr-3" src={apiUrl + (item.HinhAnh != null || item.HinhAnh == '' ? item.HinhAnh : noImage)} alt={item.TenSauBenh} />
                                                </NavLink>
                                                <div className="media-body">
                                                    <NavLink to={'/public/sau-benh/chi-tiet/' + item.Id}>
                                                        <p>{item.TenSauBenh}</p>
                                                    </NavLink>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className="col-md-12">
                                <h4 className="text-center">Mới nhất</h4>
                            </div>
                            <div className="row">
                                {
                                    listMoiNhats.map((item, key) => {
                                        return (
                                            <div className="media" key={key}>
                                                <NavLink to={'/public/sau-benh/chi-tiet/' + item.Id}>
                                                    <img className="tt-noi-bat align-self-start mr-3" src={apiUrl + (item.HinhAnh != null || item.HinhAnh == '' ? item.HinhAnh : noImage)} alt={item.TenSauBenh} />
                                                </NavLink>
                                                <div className="media-body">
                                                    <NavLink to={'/public/sau-benh/chi-tiet/' + item.Id}>
                                                        <p>{item.TenSauBenh}</p>
                                                    </NavLink>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}
