import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap';
import $ from 'jquery';
import HoiDapApi from '../../api/HoiDapApi'
import CayTrongApi from '../../api/CayTrongApi'
import { useForm } from 'react-hook-form';
import moment from 'moment'
import Pagination from 'react-bootstrap-4-pagination';
import './hoidap.css'
import { ThemCauHoi } from './themcauhoi'
import { NavLink, useHistory } from 'react-router-dom';
import { DangNhap } from '../../public/dangnhap/dangnhap'
import Authen from '../../core/Authen';
import TaiKhoanApi from '../../api/TaiKhoanApi';
import ThongBao from '../../core/ThongBao';

export const HoiDap = (props) => {
    var apiUrl = process.env.REACT_APP_URL;
    var noImage = "/uploadfiles/website/no_image-100x100.jpg";
    let history = useHistory();
    const [filter, setFilter] = useState('');
    const [selectedOption, setSelectedOption] = useState();
    const [listCayTrong, setListCayTrong] = useState([{ value: 0, label: 'Chọn cây trồng...' }]);
    const [currentItem, setCurrentItem] = useState({});
    const { register, handleSubmit, reset, errors } = useForm();
    const [showAnh, setShowAnh] = useState(false);
    const [urlShowAnh, setUrlShowAnh] = useState();
    const [showThemCauHoi, setShowThemCauHoi] = useState(false);
    const [showDangNhap, setShowDangNhap] = useState(false);
    const [nhanVien, setNhanVien] = useState({});
    const [listFileUpload, setListFileUpload] = useState(null);
    let pageIndex = 1;
    let pageSize = 20;
    const [listHoiDaps, setListHoiDaps] = useState([]);
    const [listHoiDaps_XemNhieuNhat, setListHoiDaps_XemNhieuNhat] = useState([]);
    const [paginationConfig, setPaging] = useState({
        totalPages: 1,
        currentPage: 1,
        showMax: 5,
        size: "sm",
        threeDots: true,
        prevNext: true,
        onClick: function (page) {
            pageIndex = page;
            loadHoiDaps();
        }
    });

    useEffect(() => {
        if (props.show) {
            loadListCayTrong();
            setSelectedOption(listCayTrong[0]);
            loadHoiDaps();
            loadXemNhieuNhat();
            setNhanVien(Authen.getUser());
            $('#modalhoidap').modal('show');
        }
    }, [props.show]);

    //#region câu hỏi
    const loadXemNhieuNhat= async ()=>{
        try {
            const response = await HoiDapApi.getXemNhieuNhat();
            setListHoiDaps_XemNhieuNhat(response.Items);            
        } catch (error) {
            
        }
    }

    const loadHoiDaps = async () => {
        try {
            const params = {
                pageIndex: pageIndex,
                pageSize: pageSize,
                filter: filter,
                idCayTrong: selectedOption == null ? '' : selectedOption.value,
                status: true
            }
            const response = await HoiDapApi.getPagingPublic(params);
            var str = localStorage.getItem(process.env.REACT_LIKE_HOIDAP);
            var listLike = [];
            if (str != null) {
                listLike = JSON.parse(str);
            }
            response.Items.forEach(item => {
                item.Checked = false;
                if (listLike.filter(x => x.Id == item.Id).length > 0) {
                    item.IsLiked = true;
                }
            });
            paginationConfig.totalPages = 1;
            if (response.Items.length > 0) {
                paginationConfig.totalPages = Math.ceil(response.TotalRow / pageSize);
            }
            paginationConfig.currentPage = pageIndex;
            paginationConfig.onClick = function (page) {
                pageIndex = page;
                loadHoiDaps();
            }
            setPaging(paginationConfig);
            setListHoiDaps(response.Items);
        } catch (err) {
        }
    }
 
    const handleCloseCauHoi = () => {
        setShowThemCauHoi(false);
    }

    const addLike = async (cauHoi) => {
        if (cauHoi.IsLiked != true) {
            var str = localStorage.getItem(process.env.REACT_LIKE_HOIDAP);
            var listLike = [];
            if (str != null) {
                listLike = JSON.parse(str);
            }
            listLike.push({ Id: cauHoi.Id });
            localStorage.setItem(process.env.REACT_LIKE_HOIDAP, JSON.stringify(listLike));
            HoiDapApi.addLike({ Id: cauHoi.Id }).then(() => {
                var lst = JSON.parse(JSON.stringify(listHoiDaps));
                lst.forEach(element => {
                    if (element.Id == cauHoi.Id) {
                        element.IsLiked = true;
                        element.Liked = +element.Liked + 1
                    }
                });
                setListHoiDaps(lst);
            });
        }
    }

    //#endregion

    //#region  hình ảnh
    const handleCloseAnh = () => {
        setShowAnh(false);
    }
    const handleShowAnh = (urlAnh) => {
        setUrlShowAnh(urlAnh);
        setShowAnh(true);
    }
    //#endregion

    function loadListCayTrong() {
        CayTrongApi.getAll().then((response) => {
             response.forEach(element => {
                element.ClassCss = "btn-light";
            });
            if (response.length > 0) {
                response[0].ClassCss = "btn-success";
                setCurrentItem(response[0]);
            }
            setListCayTrong(response);
        })
    }

    const closeModalDangNhap = () => {
        setShowDangNhap(false);
    }

    const saveDataCauHoi = async (data) => {
        debugger;
        if (listFileUpload != null) {
            var fielPath = '';
            let dataUpload = new FormData();
            dataUpload.append('file', listFileUpload);
            fielPath = await TaiKhoanApi.UploadFile(dataUpload);
            data.HinhAnhs = fielPath;
        }
        if (currentItem) {
            data.IdCayTrong = currentItem.Id;
        }
        else {
            data.IdCayTrong = null;
        }
        data.Status = false;
        if (data.Id === "0" || data.Id == null) {
            data.Id = 0;
            HoiDapApi.post(data).then(() => {
                reset({ Id: 0, ChuDe: '', NoiDung: '' }[reset]);
                setListFileUpload(null);
                ThongBao.printSuccessMessage("Gửi câu hỏi thành công.");
            })
        }
        else {
            HoiDapApi.put(data).then(() => {

            })
        }
    }
    const changeCayTrong = (item) => {
        listCayTrong.forEach(element => {
            element.ClassCss = "btn-light";
        });
        item.ClassCss = "btn-success";
        setListCayTrong([...listCayTrong]);
        setCurrentItem(item);
    }
    const showModalDangNhap = () => {
        setShowDangNhap(true);
    }
    const showModalDangKy = () => {
        history.push("/dangky");
    }
    const onChangeHandlerUpload = event => {
        setListFileUpload(event.target.files[0]);
    }

    return (
        <>
            <div class="fl-builder-content">
                <div className="container">
                    <div className="fl-row-content-wrap">
                        <div className="col-md-12">
                            <div className="breadcrumb-container">
                                <a href="/" className="breadcrumb-dis">Trang chủ / </a>
                                <a href="#" className="breadcrumb-active"> Hỏi đáp</a>
                                <div className="breadcrumb-line">
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-9">
                                <div className="cauhoi-container">
                                    {nhanVien.UserName == null &&
                                        <>
                                            <label className="col-sm-12 col-form-label font-weight-bold">Chào bạn, hãy <span className="dang-nhap" onClick={showModalDangNhap}>đăng nhập</span> hoặc <span className="dang-nhap" onClick={showModalDangKy}>đăng ký</span> để trao đổi với các chuyên gia và mọi người về chủ đề cây trồng, mùa vụ, sâu bệnh..... </label>
                                            <div className="col-sm-12">
                                                <div className="cauhoi-themmoi">Câu hỏi của bạn?</div>
                                            </div>
                                        </>
                                    }
                                    {nhanVien.UserName != null &&
                                        <>
                                            <form className="form-cauhoi" onSubmit={handleSubmit(saveDataCauHoi)}>
                                                <div className="row form-group">
                                                    <label className="col-sm-12 font-weight-bold">Chào bạn, hãy chọn cây trồng bạn muốn đặt câu hỏi
                                                            </label></div>
                                                <div className="row form-group">
                                                    {
                                                        listCayTrong.map((item, key) => {
                                                            return (
                                                                <div className="col-sm-3" key={key}>
                                                                    <div className="caytrong-container">
                                                                        <img src={apiUrl + (item.HinhAnh == null ? noImage : item.HinhAnh)} alt={item.TenCayTrong} onClick={() => changeCayTrong(item)} />
                                                                        <button type="button" className={"btn btn-sm " + item.ClassCss} onClick={() => changeCayTrong(item)}> {item.TenCayTrong}</button>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                <div className="form-group row">
                                                    <div className="col-sm-12">
                                                        <input name="ChuDe" type="text" className="form-control" placeholder="Tiêu đề câu hỏi..." ref={register({ required: true })} />
                                                        {errors.Name && <span className="text-danger">Hãy nhập dữ liệu</span>}
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <div className="col-sm-12">
                                                        <textarea name="NoiDung" className="form-control textrea-size" rows="1" placeholder="Nội dung câu hỏi..." ref={register({ required: true })}  ></textarea>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <div className="col-sm-6">
                                                        <input name="ChuDe" type="file" className="" placeholder="Tập tin" onChange={onChangeHandlerUpload} accept="image/x-png,image/gif,image/jpeg" />
                                                    </div>
                                                    <div className="col-sm-6 text-right">
                                                        <button type="button" className="btn btn-success btn-sm" onClick={handleSubmit(saveDataCauHoi)}>
                                                            <i className="fas fa-sign-out-alt"></i>Đồng ý</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </>
                                    }
                                </div>
                                <div className="form-group">
                                    {
                                        listHoiDaps.map((item, key) => {
                                            return (
                                                <div className="cauhoi-container">
                                                    <div className="col-md-12">
                                                        <img name="HinhAnhs" src={apiUrl + (item.Avatar == null ? noImage : item.Avatar)} className="cauhoi-avatar" onClick={() => handleShowAnh(item.HinhAnhs)} />
                                                        <div>
                                                            <span className="hoten">
                                                                <i class="fa fa-user" aria-hidden="true">&nbsp;</i>
                                                                {item.FullName != null ? item.FullName : item.HoTen}
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <span className="created-date">
                                                                <i class="fa fa-check" aria-hidden="true">&nbsp;</i>
                                                                {item.TenCayTrong}
                                                            </span>
                                                            <span className="created-date">
                                                                <i class="fa fa-history" aria-hidden="true">&nbsp;</i>
                                                                {item.CreatedDate == '' ? '' : moment(item.CreatedDate, "DD/MM/YYYY HH:mm:ss").format("DD/MM/YYYY HH:mm")}
                                                            </span>
                                                        </div>
                                                        <div className="clearfix"></div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="font-weight-bold" >
                                                            <NavLink to={'/public/hoi-dap/' + item.Id}>{item.ChuDe} </NavLink>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div>{item.NoiDung}</div>
                                                    </div>
                                                    {item.HinhAnhs != null && item.HinhAnhs != "" &&
                                                        <div className="col-sm-12">
                                                            <img name="HinhAnhs" src={apiUrl + item.HinhAnhs} className="cauhoi-hinhanh" onClick={() => handleShowAnh(item.HinhAnhs)} />
                                                        </div>
                                                    }
                                                    <div className="col-md-12">
                                                        <div className="traloi-link">
                                                            {/* <i class="fa fa-comments" aria-hidden="true" onClick={() => showTraLoi(item)}> {item.CountTraLoi} Trả lời</i> */}
                                                            <i class="fa fa-comments" aria-hidden="true">
                                                                <NavLink to={'/public/hoi-dap/' + item.Id}> {item.CountTraLoi} Trả lời</NavLink>
                                                            </i>
                                                            <i class="fa fa-thumbs-up" aria-hidden="true" onClick={() => addLike(item)} className={item.IsLiked == true ? 'like-style' : ''}> {item.Liked} Like</i>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <div className="col-md-12 text-right">
                                    <Pagination {...paginationConfig} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="row">
                                    <h4 className="text-center">Xem nhiều nhất</h4>
                                </div>
                                {
                                    listHoiDaps_XemNhieuNhat.map((item, key) => {
                                        return (
                                            <div className="media" key={key}>
                                                <NavLink to={'/public/hoi-dap/' + item.Id}>
                                                    <img className="tt-noi-bat align-self-start mr-3" src={apiUrl + (item.HinhAnhs != null || item.HinhAnhs == '' ? item.HinhAnhs : noImage)} alt={item.ChuDe} />
                                                </NavLink>
                                                <div className="media-body">
                                                    <NavLink to={'/public/hoi-dap/' + item.Id}>
                                                        <p>{item.ChuDe}</p>
                                                    </NavLink>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <ThemCauHoi show={showThemCauHoi} closeModal={handleCloseCauHoi}></ThemCauHoi>
                        <Modal show={showAnh} onHide={handleCloseAnh} animation={false} size='lg'>
                            <Modal.Header closeButton>
                                <Modal.Title>Hình ảnh</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <img name="hs" src={apiUrl + urlShowAnh} className="show-hinhanh" />
                            </Modal.Body>
                        </Modal>
                    </div>
                </div>
            </div>
            { showDangNhap && <DangNhap show={showDangNhap} closeModal={closeModalDangNhap}></DangNhap>}
        </>
    )
}
