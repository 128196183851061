import React, { useEffect, useState } from 'react'
import ol from "openlayers";
import "ol/ol.css";
import { jsPDF } from "jspdf";
import * as htmlToImage from 'html-to-image';
import { SidebarLayer } from '../../shared/sidebar-layer/SidebarLayer';
import { FooterNote } from '../../shared/footer-note/FooterNote';
import { SidebarLeft } from '../../shared/sidebar-left/SidebarLeft';
import BanDoPublicApi from '../../api/BanDoPublicApi';
import { TimKiemNangCao } from '../../shared/timkiemnangcao/TimKiemNangCao';
import { Loading } from '../../shared/loading/Loading';
import TimKiemApi from '../../api/TimKiemApi';
import { ModalTraCuu } from '../modaltracuu/ModalTraCuu';
import { DuDoanDichHai } from '../../shared/dudoandichhai/DuDoanDichHai';

export const BanDo = (props) => {
    const view = new ol.View({
        center: [11728080.0278, 2357260.9077],
        zoom: 10.2,
        minZoom: 9,
        maxZoom: 17,
        projection: new ol.proj.get('EPSG:3857'),
    });
    const [loading, setLoading] = useState(false);
    var [isThongTin, setIsThongTin] = useState(true);
    var [showLeft, setShowLeft] = useState(0);
    const [showFooter, setShowFooter] = useState(-1);
    var [showTimKiemNangCao, setShowTimKiemNangCao] = useState(0);
    var [showRight, setShowRight] = useState(0);
    const [selectGhiChu, setSelectGhiChu] = useState({});
    var [map, setMap] = useState(new ol.Map());
    var [thongTin, setThongTin] = useState({});
    var [listLopBanDos, setLopBanDo] = useState([]);
    var [listMenu, setListMenu] = useState([]);
    var [tabSelect, setTabSelect] = useState('');
    var [x, setX] = useState(0);
    var [y, setY] = useState(0);
    var [id, setId] = useState(null);
    const [showTraCuu, setShowTraCuu] = useState(false);
    const [showDuDoan, setShowDuDoan] = useState(false);

    var [mousePositionControl, setMousePositionControl] = useState()
    var [drawDo, setDrawDo] = useState(null);
    var [soureDo, setSoureDo] = useState(new ol.source.Vector());
    var [vectorDo, setVectorDo] = useState(new ol.layer.Vector());
    var [vectorSelect, setVectorSelect] = useState(new ol.layer.Vector());
    var [positionFeature, setPositionFeature] = useState(new ol.Feature());
    var [accuracyFeature, setAccuracyFeature] = useState(new ol.Feature());
    var [sourceBase, setSourceBase] = useState(new ol.source.OSM({
        attributions: ['Phần mềm tra cứu sâu bệnh Hòa Bình'],
        url: 'https://mt0.google.com/vt/lyrs=s&hl=vn&x={x}&y={y}&z={z}',
    }));
    var [mapBase, setMapBase] = useState(new ol.layer.Tile({
        source: sourceBase
    }));
    var [bingMap, setBingMap] = useState(new ol.layer.Tile({
        preload: Infinity,
        zIndex: 1,
        source: new ol.source.BingMaps({
            key: 'Avt2mvsr-HdT809JeTWasadqQrzmbWjSKja8sX14syV5YXV6xywEfc_Ub6cvJLN0',
            imagerySet: 'Aerial'
        })
    }));
    var [geolocaltion, setGeoLocaltion] = useState(new ol.Geolocation({
        trackingOptions: {
            enableHighAccuracy: true,
        },
        projection: view.getProjection()
    }));

    useEffect(() => {
        if(props.showTraCuu > 0){
            setShowTraCuu(true);
        }
    }, [props.showTraCuu]);

    useEffect(() => {
        if(props.showDuDoan > 0){
            setShowDuDoan(true);
        }
    }, [props.showDuDoan]);

    const loadThongTin = async (x, y) => {
        try {
            showRight++;
            setShowRight(showRight);
            let tableName = listMenu.filter(x => x.Checked && x.ParentId !== 'BANDONEN' && x.ParentId !== 'BANDOVETINH' && x.ParentId !== 'BDHANHCHINH' && x.LayerName != null);
            if (tableName.length === 0) {
                tableName = listMenu.filter(x => x.Ma === 'BDHANHCHINHHUYEN');
                if (tableName.length === 0) {
                    return;
                }
                tabSelect = tableName[0].Ma;
                setTabSelect(tabSelect);
            }
            else {
                if (tabSelect === '') {
                    tabSelect = tableName[0].Ma;
                    setTabSelect(tabSelect);
                }
                else {
                    setTabSelect(tabSelect);
                }
            }
            const params = {
                x: x,
                y: y,
                tableName: tabSelect
            }
            setLoading(true);
            thongTin = await BanDoPublicApi.getDetail(params);
            setThongTin(thongTin);
            setX(x);
            setY(y);
            setId(null);
            setLoading(false);
            if (thongTin.geomjson != null) {
                map.getLayers().forEach(item => {
                    if (item != null && item.type === 'VECTOR') {
                        map.removeLayer(item);
                    }
                });
                let features = new ol.format.GeoJSON().readFeatures(thongTin.geomjson);
                if (map.getView().getProjection().getCode() === 'EPSG:3857') {
                    features = new ol.format.GeoJSON({ featureProjection: 'EPSG:3857', defaultDataProjection: 'EPSG:4326' }).readFeatures(thongTin.geomjson);
                }
                vectorSelect = new ol.layer.Vector({
                    source: new ol.source.Vector({
                        features: features,
                    }),
                    style: highlightStyle,
                    zIndex: 999
                });
                setVectorSelect(vectorSelect);
                map.addLayer(vectorSelect);
            }
            else {
                map.getLayers().forEach(item => {
                    if (item == vectorSelect) {
                        map.removeLayer(vectorSelect);
                    }
                });
            }
        }
        catch {
            setLoading(false);
        }
    }

    const loadThongTinById = async (e) => {
        try {
            const index = listMenu.findIndex(x => x.TableName === e.tableName);
            if (index !== -1) {
                tabSelect = listMenu[index].Ma;
                setTabSelect(tabSelect);
            }
            setLoading(true);
            showRight++;
            setShowRight(showRight);
            console.log(e);
            thongTin = await BanDoPublicApi.getById(e);
            setThongTin(thongTin);
            console.log(thongTin)
            setId(e.id);
            if (thongTin.geomjson != null) {
                map.getLayers().forEach(item => {
                    if (item != null && item.type === 'VECTOR') {
                        map.removeLayer(item);
                    }
                });
                const features = new ol.format.GeoJSON().readFeatures(thongTin.geomjson);
                vectorSelect = new ol.layer.Vector({
                    source: new ol.source.Vector({
                        features: features,
                    }),
                    style: highlightStyle,
                    zIndex: 999
                });
                setVectorSelect(vectorSelect);
                map.addLayer(vectorSelect);
                const toaDo = new ol.extent.getCenter(features[0].getGeometry().getExtent());
                flyTo(toaDo, 14);
            }
            else {
                map.getLayers().forEach(item => {
                    if (item == vectorSelect) {
                        map.removeLayer(vectorSelect);
                    }
                });
            }
            setLoading(false);
        }
        catch {
            setLoading(false);
        }

    }

    useEffect(() => {
        vectorDo = new ol.layer.Vector({
            source: soureDo,
            style: new ol.style.Style({
                fill: ol.style.Fill({
                    color: 'rgba(255, 255, 255, 0.2)',
                }),
                stroke: new ol.style.Stroke({
                    color: '#ffcc33',
                    width: 2,
                }),
                image: new ol.style.Circle({
                    radius: 7,
                    fill: new ol.style.Fill({
                        color: '#ffcc33',
                    }),
                })
            })
        });
        positionFeature.setStyle(
            new ol.style.Style({
                image: new ol.style.Circle({
                    radius: 6,
                    fill: new ol.style.Fill({
                        color: '#3399CC',
                    }),
                    stroke: new ol.style.Stroke({
                        color: '#fff',
                        width: 2,
                    }),
                }),
            })
        );
        geolocaltion.on('change:accuracyGeometry', function () {
            accuracyFeature.setGeometry(geolocaltion.getAccuracyGeometry());
        });
        geolocaltion.on('change:position', function () {
            let coordinates = geolocaltion.getPosition();
            positionFeature.setGeometry(coordinates ? new ol.geom.Point(coordinates) : null);
            if (coordinates) {
                map.setView(new ol.View({
                    center: coordinates,
                    zoom: 14
                }))
            }
        });
        mousePositionControl = new ol.control.MousePosition({
            coordinateFormat: ol.coordinate.createStringXY(4),
            projection: 'EPSG:3857',
            className: 'custom-mouse-position',
            target: document.getElementById('mouse-position'),
            undefinedHTML: ''
        });
        setMousePositionControl(mousePositionControl);
        var projectionSelect = document.getElementById('projection');
        projectionSelect.addEventListener('change', function (event) {
            mousePositionControl.setProjection(event.target.value);
        });
        map = new ol.Map({
            target: "map",
            controls: new ol.control.defaults().extend([
                new ol.control.ScaleLine({
                    units: 'metric'
                }),
                new ol.control.FullScreen(),
                new ol.control.ZoomSlider(),
                mousePositionControl
            ]),
            layers: [
                mapBase
                // bingMap
            ],
            view: view
        });
        let vectorViTri = new ol.layer.Vector({
            map: map,
            source: new ol.source.Vector({
                features: [accuracyFeature, positionFeature]
            })
        });
        map.addLayer(vectorViTri);
        map.addControl(customControl('id-do-khoang-cach', 'do-khoang-cach', '<i class="fas fa-ruler-horizontal"></i>', clickDoKhoangCach));
        map.addControl(customControl('id-do-dien-tich', 'do-dien-tich', '<i class="fas fa-draw-polygon"></i>', clickDoDienTich));
        map.addControl(customControl('id-do-vi-tri', 'do-vi-tri', '<i class="fas fa-map-marker-alt"></i>', clickViTri));
        map.addControl(customControl('id-tk-nang-cao', 'tk-nang-cao', '<i class="fas fa-search"></i>', timKiemNangCao));
        map.addControl(customControl('id-lop-ban-do', 'left-lop-ban-do', '<i class="fas fa-layer-group"></i>', showLopBanDo));
        setVectorDo(vectorDo);
        setPositionFeature(positionFeature);
        map.on('click', function (evt) {
            if (isThongTin === false) {
                return;
            }
            let coordinate = evt.coordinate;
            if(map.getView().getProjection().getCode() === 'EPSG:3857'){
                coordinate = new ol.proj.transform(evt.coordinate, 'EPSG:3857', 'EPSG:4326');
            }
            loadThongTin(coordinate[0], coordinate[1]);
        });
        setMap(map);
    }, []);

    const showLopBanDo = () => {
        showLeft++;
        setShowLeft(showLeft);
    }

    const updateTableSelect = (e) => {
        tabSelect = e;
        setTabSelect(tabSelect);
        if (id !== null) {
            if (vectorSelect.getSource() != null && vectorSelect.getSource().getFeatures().length > 0) {
                const center = vectorSelect.getSource().getFeatures()[0].getGeometry().getExtent();
                const toaDo = new ol.extent.getCenter(center);
                // console.log(toaDo);
                x = toaDo[0];
                y = toaDo[1];
                setX(x);
                setY(y);
                loadThongTin(x, y);
            }
        }
        else {
            loadThongTin(x, y);
        }
    }

    const highlightStyle = new ol.style.Style({
        stroke: new ol.style.Stroke({
            color: 'blue',
            width: 2,
        }),
    });

    const removeAttribute = (id) => {
        let controll = document.getElementById(id);
        if (controll.hasAttribute('active')) {
            controll.removeAttribute('active');
            controll.removeAttribute('class');
            map.removeInteraction(drawDo);
            vectorDo.getSource().clear();
            map.removeLayer(vectorDo);
            map.getOverlays().clear();
        }
    }

    const clickDoKhoangCach = () => {
        let controllButton = document.getElementById('id-do-khoang-cach');
        removeAttribute('id-do-dien-tich');
        if (controllButton.hasAttribute('active')) {
            removeAttribute('id-do-khoang-cach');
            isThongTin = true;
        }
        else {
            isThongTin = false;
            controllButton.setAttribute('active', 1);
            controllButton.setAttribute('class', 'ctrl-active');
            map.addLayer(vectorDo);
            addInteractionDo('LineString');
        }
        setIsThongTin(isThongTin);
    }

    const clickDoDienTich = () => {
        let controllButton = document.getElementById('id-do-dien-tich');
        removeAttribute('id-do-khoang-cach');
        if (controllButton.hasAttribute('active')) {
            removeAttribute('id-do-dien-tich');
            isThongTin = true;
        }
        else {
            isThongTin = false;
            controllButton.setAttribute('active', 1);
            controllButton.setAttribute('class', 'ctrl-active');
            map.addLayer(vectorDo);
            addInteractionDo('Polygon');
        }
        setIsThongTin(isThongTin);
    }

    const clickViTri = () => {
        geolocaltion.setTracking(false);
        geolocaltion.setTracking(true);
    }

    function customControl(id, classCss, icon, func) {
        let button = document.createElement('button');
        button.setAttribute('id', id);
        button.innerHTML = icon;
        let element = document.createElement('div');
        element.className = classCss + ' ol-unselectable ol-control';
        element.appendChild(button);
        button.addEventListener('click', function () {
            func();
        }, false);
        return new ol.control.Control({
            element: element
        });

    }

    //Cập nhật thay đổi chọn huyện
    useEffect(() => {
        if (listLopBanDos.length > 0) {
            let params = {
                CQL_FILTER: props.listHuyens.length > 0 ? `mahuyen IN (${props.listHuyens.map(x => x.Ma).join(',')})` : 'mahuyen=100'
            }
            listLopBanDos.forEach(item => {
                if (item.province.getSource()) {
                    item.province.getSource().updateParams(params);
                }
            })
            setMap(map);
        }

    }, [props.listHuyens]);

    useEffect(() => {
        if (props.maXa !== '') {
            setLoading(true);
            TimKiemApi.getXaDetail({ maXa: props.maXa }).then((res) => {
                if (res.geomjson != null) {
                    map.getLayers().forEach(item => {
                        if (item != null && item.type === 'VECTOR') {
                            map.removeLayer(item);
                        }
                    });
                    let features = new ol.format.GeoJSON().readFeatures(res.geomjson);
                    if (map.getView().getProjection().getCode() === 'EPSG:3857') {
                        features = new ol.format.GeoJSON({ featureProjection: 'EPSG:3857', defaultDataProjection: 'EPSG:4326' }).readFeatures(res.geomjson);
                    }
                    vectorSelect = new ol.layer.Vector({
                        source: new ol.source.Vector({
                            features: features,
                        }),
                        style: highlightStyle,
                        zIndex: 999
                    });
                    map.addLayer(vectorSelect);
                }
                const center = vectorSelect.getSource().getFeatures()[0].getGeometry().getExtent();
                const toaDo = new ol.extent.getCenter(center);
                flyTo(toaDo, 13);
                setLoading(false);
            }, err => {
                setLoading(false);
            })
        }
    }, [props.maXa]);

    useEffect(() => {
        if (props.printMap > 0) {
            let view = map.getViewport();
            htmlToImage.toPng(view).then(function (dataUrl) {
                var pdf = new jsPDF('landscape');
                // // var pdf = new jsPDF();
                pdf.addImage(dataUrl, 'PNG', 0, 0, view.clientWidth / 3.8, view.clientHeight / 3.8);
                // pdf.save("bando.pdf");
                // pdf.autoPrint();
                window.open(pdf.output('bloburl'), '_blank')
            });

        }
    }, [props.printMap]);

    function flyTo(localtion, zoomNew) {
        let duration = 2000;
        let parts = 2;
        let called = false;
        function callback(complete) {
            --parts;
            if (called) {
                return;
            }
            if (parts === 0 || !complete) {
                called = true;
            }
        }
        map.getView().animate(
            {
                center: localtion,
                duration: duration
            },
            callback
        );
        map.getView().animate(
            {
                zoom: zoomNew - 1,
                duration: duration / 2
            },
            {
                zoom: zoomNew,
                duration: duration / 2
            },
            callback
        )
    }
    var helpTooltipElement;
    var helpTooltip;

    const createHelpTooltip = () => {
        if (helpTooltipElement) {
            helpTooltipElement.parentNode.removeChild(helpTooltipElement);
        }
        helpTooltipElement = document.createElement('div');
        helpTooltipElement.className = 'ol-tooltip hidden';
        helpTooltip = new ol.Overlay({
            element: helpTooltipElement,
            offset: [15, 0],
            positioning: 'center-left',
        });
        map.addOverlay(helpTooltip);
        setMap(map);
    }
    var sketch;
    var measureTooltipElement;
    var measureTooltip;
    const addInteractionDo = (type) => {
        drawDo = new ol.interaction.Draw({
            source: soureDo,
            type: type,
            style: new ol.style.Style({
                fill: new ol.style.Fill({
                    color: 'rgba(255, 255, 255, 0.2)',
                }),
                stroke: new ol.style.Stroke({
                    color: 'rgba(0, 0, 0, 0.5)',
                    lineDash: [10, 10],
                    width: 2,
                }),
                image: new ol.style.Circle({
                    radius: 5,
                    stroke: new ol.style.Stroke({
                        color: 'rgba(0, 0, 0, 0.7)',
                    }),
                    fill: new ol.style.Fill({
                        color: 'rgba(255, 255, 255, 0.2)'
                    })
                })
            })
        });
        map.addInteraction(drawDo);
        setDrawDo(drawDo);
        createMeasureTooltip();
        createHelpTooltip();
        var listener;
        drawDo.on('drawstart', function (evt) {
            sketch = evt.feature;
            var tooltipCoord = evt.coordinate;
            listener = sketch.getGeometry().on('change', function (evt) {
                var geom = evt.target;
                var output;
                if (geom instanceof ol.geom.Polygon) {
                    output = formatArea(geom);
                    tooltipCoord = geom.getInteriorPoint().getCoordinates();
                } else if (geom instanceof ol.geom.LineString) {
                    output = formatLength(geom);
                    tooltipCoord = geom.getLastCoordinate();
                }
                measureTooltipElement.innerHTML = output;
                measureTooltip.setPosition(tooltipCoord);
            })
        });
        drawDo.on('drawend', function () {
            measureTooltipElement.className = 'ol-tooltip ol-tooltip-static';
            measureTooltip.setOffset([0, -7]);
            // unset sketch
            sketch = null;
            // unset tooltip so that a new one can be created
            measureTooltipElement = null;
            createMeasureTooltip();
            ol.Observable.unByKey(listener);
        });
        setDrawDo(drawDo);
        setMap(map);
    }

    const formatArea = (polygon) => {
        var area = ol.Sphere.getArea(polygon);
        var output;
        if (area > 1000000) {
            output = Math.round((area / 1000000) * 100) / 100 + ' km<sup>2</sup>';
        } else {
            output = Math.round(area * 100) / 100 + ' m<sup>2</sup>';
        }
        return output;
    };

    const formatLength = (line) => {
        var length = ol.Sphere.getLength(line);
        var output;
        if (length > 1000) {
            output = Math.round((length / 1000) * 100) / 100 + ' ' + 'km';
        } else {
            output = Math.round(length * 100) / 100 + ' ' + 'm';
        }
        return output;
    };

    function createMeasureTooltip() {
        if (measureTooltipElement) {
            measureTooltipElement.parentNode.removeChild(measureTooltipElement);
        }
        measureTooltipElement = document.createElement('div');
        measureTooltipElement.className = 'ol-tooltip ol-tooltip-measure';
        measureTooltip = new ol.Overlay({
            element: measureTooltipElement,
            offset: [0, -15],
            positioning: 'bottom-center',
        });
        map.addOverlay(measureTooltip);
    }

    //thay đổi lựa chọn bản đồ từ menu
    const selectBanDo = async (listMenus) => {
        listMenus.forEach(item => {
            const index = listMenu.findIndex(x => x.Ma === item.Ma);
            if (index === -1) {
                listMenu.push(item);
            }
            else {
                listMenu[index].Checked = item.Checked;
            }
        });
        listMenus.forEach(item => {
            if (item.ParentId === 'BANDOVETINH') {
                if (item.Ma === 'BING') {
                    let isCheck = true;
                    map.getLayers().forEach(l => {
                        if (l === bingMap) {
                            isCheck = false;
                        }
                    });
                    if (isCheck) {
                        bingMap.setVisible(true);
                        map.addLayer(bingMap);
                    }
                    else {
                        bingMap.setVisible(item.Checked);
                    }
                    mapBase.setVisible(false);
                }
                else {
                    if (sourceBase.getUrls().findIndex(x => x === item.Url) !== -1) {
                        mapBase.setVisible(item.Checked);
                    }
                    else if (item.Checked) {
                        sourceBase.setUrl(item.Url);
                        mapBase.setVisible(item.Checked);
                    }
                    bingMap.setVisible(false);
                    setSourceBase(sourceBase);
                    setMapBase(mapBase);
                }
            }
            if (item.ParentId !== 'BANDOVETINH' && item.Url != null) {
                const index = listLopBanDos.findIndex(x => x.Ma === item.Ma);
                if (index === -1) {
                    let province = new ol.layer.Image({
                        source: new ol.source.ImageWMS({
                            url: item.Url,
                            params: { layers: item.LayerName, version: item.Version, CQL_FILTER: 'mahuyen IN (' + props.listHuyens.map(x => x.Ma).join(',') + ')' },
                            serverType: "geoserver",
                            ratio: item.Ratio,
                            crossOrigin: ''
                        }),
                        zIndex: item.SortOrder
                    });
                    province.setVisible(item.Checked);
                    province.setZIndex(item.SortOrder);
                    map.addLayer(province);
                    item.province = province;
                    listLopBanDos.push(item);
                }
                else {
                    listLopBanDos[index].province.setVisible(item.Checked);
                }
            }
        });
        setLopBanDo([...listLopBanDos]);
        setMap(map);
    }

    const getSelectGhiChu = (e) => {
        setSelectGhiChu(e);
        setShowFooter(showFooter + 1);
    }

    const timKiemNangCao = () => {
        showTimKiemNangCao++;
        setShowTimKiemNangCao(showTimKiemNangCao);
    }

    const onTimKiemNangCao = (e) => {
        listLopBanDos.forEach(item => {
            if (item.Ma == e.Ma && item.province.getSource()) {
                let params = {
                    // CQL_FILTER: props.listHuyens.length > 0 ? `mahuyen IN (${props.listHuyens.map(x => x.Ma).join(',')}) AND ${e.Sql}` : 'mahuyen=100'
                    CQL_FILTER: e.Sql
                }
                item.province.getSource().updateParams(params);
            }
        })
        setMap(map);
    }

    const timkiemGhiChu = (e) => {
        listLopBanDos.forEach(item => {
            if (item.Ma == selectGhiChu.Ma && item.province.getSource()) {
                let params = {
                    CQL_FILTER: props.listHuyens.length > 0 ? `mahuyen IN (${props.listHuyens.map(x => x.Ma).join(',')}) AND ${selectGhiChu.TimKiemGhiChu} IN (${e})` : 'mahuyen=100'
                }
                item.province.getSource().updateParams(params);
            }
        });
        setMap(map);
    }

    const changeProjection = (event) => {
        // console.log(event.target.value);
        const newProj = new ol.proj.get(event.target.value);
        const center = event.target.value == 'EPSG:3857' ? [11728080.0278, 2357260.9077] : new ol.proj.transform([11728080.0278, 2357260.9077], 'EPSG:3857', 'EPSG:4326');
        // const newProjExtent = event.target.value == 'EPSG:3857' ? [11701396.5663, 2339161.4027, 11822104.2950, 2444697.4873] : new ol.proj.transform([11701396.5663, 2339161.4027, 11822104.2950, 2444697.4873], 'EPSG:3857', 'EPSG:4326');
        const view = new ol.View({
            projection: newProj,
            center: center,
            zoom: 10.2,
            minZoom: 9,
            maxZoom: 17,
            // extent: newProjExtent
        });
        // map.getView().getCenter()
        map.setView(view);
        // setMap(map);
    }

    const onSapXep = (list) => {
        console.log(list);
        if (list.length > 0) {
            list.forEach(item => {
                const index = listLopBanDos.findIndex(x => x.Ma === item.Ma);
                if (index !== -1) {
                    listLopBanDos[index].province.setZIndex(item.SortOrder);
                }
            })
        }
    }

    return (
        <div className="card shadow mb-1">
            <div className="card-body">
                <div className="col-md-12">

                    <div className="row">
                        <SidebarLayer show={showRight} thongTin={thongTin} listLopBanDos={listLopBanDos} tabSelect={tabSelect} x={x} y={y} width="12" updateTableSelect={(e) => updateTableSelect(e)}>
                        </SidebarLayer>
                        <div className="col-md-12 ban-do" id="map">
                        </div>
                        <TimKiemNangCao show={showTimKiemNangCao} listLopBanDos={listLopBanDos} onTimKiemNangCao={(e) => onTimKiemNangCao(e)} listHuyens={props.listHuyens}
                            loadThongTinById={(e) => loadThongTinById(e)}></TimKiemNangCao>
                        <SidebarLeft selectBanDo={selectBanDo} show={showLeft} getGhiChu={(e) => getSelectGhiChu(e)} onSapXep={(e) => onSapXep(e)}>
                        </SidebarLeft>
                        <FooterNote show={showFooter} maBanDo={selectGhiChu.Ma} timKiemGhiChu={(e) => timkiemGhiChu(e)} height={props.mobile?80:126}>
                        </FooterNote>
                    </div>
                    <div className="row footer-map">
                        <div className="col-md-2 col-4">
                            <select className="form-control" id="projection" onChange={changeProjection}>
                                <option value="EPSG:3857">VN-2000 / UTM zone 48N</option>
                                <option value="EPSG:4326">WGS 84 (EPSG:4326)</option>
                            </select>
                        </div>
                        <div className="col-md-1 col-3 text-right">
                            <i class="fas fa-mouse-pointer"></i> Tọa độ:
                        </div>
                        <div className="col-md-4 col-5">
                            <div id="mouse-position"></div>
                        </div>
                    </div>
                </div>
            </div>
            {
                showTraCuu?<ModalTraCuu show={showTraCuu} handleClose={() => setShowTraCuu(false)} x={x} y={y}></ModalTraCuu>:''
            }
            {
                showDuDoan? <DuDoanDichHai show = {showDuDoan} handleClose={() => setShowDuDoan(false)} x={x} y={y}></DuDoanDichHai>:''
            }
            <Loading loading={loading}></Loading>
        </div>
    )
}