import React, { useState, useEffect } from 'react'
import Select from 'react-select';
import Pagination from 'react-bootstrap-4-pagination';
import DienTichSanXuatApi from '../../../api/DienTichSanXuatApi';
import CayTrongApi from '../../../api/CayTrongApi';
import MuaVuApi from '../../../api/MuaVuApi';
import PhuongXaApi from '../../../api/PhuongXaApi';
import QuanHuyenApi from '../../../api/QuanHuyenApi';
import ExportExcelApi from '../../../api/ExportExcelApi';
import { Bar } from "react-chartjs-2";
import Chart from 'chart.js';
import NumberFormat from 'react-number-format';

export const DienTichSanXuatPublic = (props) => {

    const [search, setSearch] = useState('');
    const [listDienTichSanXuats, setListDienTichSanXuats] = useState([]);

    const [listPhuongXas, setListPhuongXa] = useState([]);
    const [timListQuanHuyen, setTimListQuanHuyen] = useState([]);
    const [timListPhuongXa, setTimListPhuongXa] = useState([]);
    const [timListCayTrong, setTimListCayTrong] = useState([]);
    const [timListMuaVu, setTimListMuaVu] = useState([]);
    const [timQuanHuyen, setTimQuanHuyen] = useState();
    const [timXaPhuong, setTimXaPhuong] = useState();
    const [timCayTrong, setTimCayTrong] = useState();
    const [timMuaVu, setTimMuaVu] = useState();
    const [timListNam, setTimListNam] = useState([]);
    const [timNam, setTimNam] = useState();
    const [bieudo, setBieuDo] = useState({});
    const [listLoaiBieuDo, setListLoaiBieuDo] = useState([
        { value: 1, label: 'Diện tích' },
        { value: 2, label: 'Năng suất' },
        { value: 3, label: 'Sản lượng' }
    ]);
    const [loaiBieuDo, setLoaiBieuDo] = useState({ value: 1, label: 'Diện tích' });
    var [dataReponse, setDataReponse] = useState({});

    let pageIndex = 1;
    let pageSize = 20;

    const [paginationConfig, setPaging] = useState({
        totalPages: 1,
        currentPage: 1,
        showMax: 5,
        size: "sm",
        threeDots: true,
        prevNext: true,
        onClick: function (page) {
            pageIndex = page;
            loadDienTichSanXuat();
        }
    });

    useEffect(() => {
        loadDienTichSanXuat(props.mahuyen, props.maxa);
        loadDanhQuanHuyen();
        loadDanhPhuongXa();
        loadDanhMucMuaVu();
        loadDanhMucNam();
    }, []);

    const loadDienTichSanXuat = async (mahuyen = '', maxa = '') => {
        try {
            const idQuanHuyen = mahuyen != '' ? mahuyen : (timQuanHuyen === undefined ? '' : timQuanHuyen.value);
            const idXaPhuong = maxa != '' ? maxa : (timXaPhuong === undefined ? '' : timXaPhuong.value);
            const idCayTrong = timCayTrong === undefined ? 0 : timCayTrong.value;
            const idMuaVu = timMuaVu == undefined ? 0 : timMuaVu.value;
            const nam = timNam == undefined ? 0 : timNam.value;
            const params = {
                filter: search,
                pageIndex: pageIndex,
                pageSize: pageSize,
                idQuanHuyen: idQuanHuyen,
                idXaPhuong: idXaPhuong,
                idCayTrong: idCayTrong,
                idMuaVu: idMuaVu,
                nam: nam,
            }

            const response = await DienTichSanXuatApi.getThongKe(params);
            paginationConfig.totalPages = 1;
            if (response.timkiem.TotalRows > 0) {
                paginationConfig.totalPages = Math.ceil(response.timkiem.TotalRows / pageSize);
            }
            paginationConfig.currentPage = pageIndex;
            setPaging(paginationConfig);
            setListDienTichSanXuats(response.timkiem.Items);
            let lstCayTrong = [];
            if (timListCayTrong.length == 0) {
                await CayTrongApi.getAll().then((cay) => {
                    let list = [];
                    list.push({ value: 0, label: 'Chọn cây trồng...' });
                    cay.forEach(item => {
                        list.push({ value: item.Id, label: item.TenCayTrong, ma: item.MaCayTrong });
                    })
                    lstCayTrong = list.map(x => x);
                    setTimListCayTrong(list);
                })
            }
            else {
                lstCayTrong = timListCayTrong;
            }
            dataReponse = response;
            setDataReponse(dataReponse);
            buildBieuDo(lstCayTrong, 1);
        } catch (err) {
        }
    }

    const ExportExcel = async (mahuyen = '', maxa = '') => {
        try {
            const idQuanHuyen = mahuyen != '' ? mahuyen : (timQuanHuyen === undefined ? '' : timQuanHuyen.value);
            const idXaPhuong = maxa != '' ? maxa : (timXaPhuong === undefined ? '' : timXaPhuong.value);
            const idCayTrong = timCayTrong === undefined ? 0 : timCayTrong.value;
            const idMuaVu = timMuaVu == undefined ? 0 : timMuaVu.value;
            const nam = timNam == undefined ? 0 : timNam.value;
            const params = {
                filter: search,
                pageIndex: pageIndex,
                pageSize: pageSize,
                idQuanHuyen: idQuanHuyen,
                idXaPhuong: idXaPhuong,
                idCayTrong: idCayTrong,
                idMuaVu: idMuaVu,
                nam: nam,
            }

            ExportExcelApi.getDienTichSx(params).then((res) => {
                const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;

                a.download = 'download.xlsx';
                a.click();
            }, () => {

            });
        }
        catch (err) {

        }
    }

    const buildBieuDo = (lstCayTrong, type = 1) => {
        let datasets = [];
        let endYear = new Date().getFullYear();
        if (timNam != undefined && timNam.value != 0) {
            endYear = timNam.value;
        }
        let labels = [];
        for (let i = 0; i < 5; i++) {
            labels.push(endYear - 4 + i);
        }
        lstCayTrong.filter(x => x.value != 0).forEach(item => {
            let data = [];
            labels.forEach(nam => {
                const index = dataReponse.bieudo.findIndex(x => x.MaCayTrong == item.ma && x.Nam == nam);
                let vl = 0;
                switch (type) {
                    case 1:
                        vl = (index == -1 ? 0 : dataReponse.bieudo[index].DienTich);
                        break;
                    case 2:
                        vl = (index == -1 ? 0 : dataReponse.bieudo[index].NangSuat);
                        break;
                    case 3:
                        vl = (index == -1 ? 0 : dataReponse.bieudo[index].SanLuong);
                        break;
                }
                data.push(vl);
            });
            let color = '#CF5C00';
            switch (item.ma) {
                case 'LUA':
                    color = '#FFDB15';
                    break;
                case 'RAU':
                    color = '#04D010';
                    break;
            }
            datasets.push({
                label: item.label,
                data: data,
                backgroundColor: color
            })
        });
        const bd = {
            labels: labels,
            datasets: datasets
        }
        setBieuDo(bd);
    }

    function loadDanhQuanHuyen() {
        QuanHuyenApi.getAll().then((response) => {
            let list = [];
            list.push({ value: '', label: 'Chọn quận huyện...' });
            response.forEach(item => {
                list.push({ value: item.Ma, label: item.Ten });
            });
            setTimListQuanHuyen(list);
            if (props.mahuyen != null) {
                const index = list.findIndex(x => x.value == props.mahuyen);
                if (index != -1) {
                    setTimQuanHuyen(list[index]);
                }
            }
        });
    }

    function loadDanhPhuongXa() {
        PhuongXaApi.getAll().then((response) => {
            let list = [];
            list.push({ value: '', label: 'Chọn phường xã...' });
            response.forEach(item => {
                list.push({ value: item.Ma, label: item.Ten, mahuyen: item.MaHuyen });
            });
            setListPhuongXa(list);
            if (props.mahuyen != null) {
                let listXas = list.filter(x => x.mahuyen == props.mahuyen);
                listXas.unshift({ value: '', label: 'Tất cả xã', mahuyen: '' });
                setTimListPhuongXa(listXas);
                const index = list.findIndex(x => x.value == props.maxa);
                if (index != -1) {
                    setTimXaPhuong(list[index]);
                }
            }
            else {
                setTimListPhuongXa(list);
            }
        })
    }

    function loadDanhMucMuaVu() {
        MuaVuApi.getAll().then((response) => {
            let list = [];
            list.push({ value: 0, label: 'Chọn mùa vụ...' });
            response.forEach(item => {
                list.push({ value: item.Id, label: item.Ten });
            });
            setTimListMuaVu(list);
        })
    }

    const loadDanhMucNam = () => {
        const endYear = new Date().getFullYear();
        let list = [];
        list.push({ value: 0, label: 'Chọn năm...' });
        for (let i = 0; i < 10; i++) {
            list.push({ value: endYear - i, label: endYear - i });
        }
        setTimListNam(list);
    }

    const timKiem = () => {
        loadDienTichSanXuat();
    }

    const exportExcel = () => {
        ExportExcel();
    }

    const setFilter = (e) => {
        setSearch(e.target.value)
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            loadDienTichSanXuat();
        }
    }

    // TÌM KIẾM
    const onChangeTimQuanHuyen = (e) => {
        setTimQuanHuyen(e);
        let listXas = listPhuongXas.filter(x => x.mahuyen == e.value);
        listXas.unshift({ value: '', label: 'Tất cả xã', mahuyen: '' });
        setTimXaPhuong({ value: '', label: 'Tất cả xã', mahuyen: '' });
        setTimListPhuongXa(listXas);
    }

    const onChangeTimXaPhuong = (e) => {
        setTimXaPhuong(e);
    }

    const onChangeTimCayTrong = (e) => {
        setTimCayTrong(e);
    }

    const onChangeTimMuaVu = (e) => {
        setTimMuaVu(e);
    }

    const onChangeTimNam = (e) => {
        setTimNam(e);
    }

    const onChaneLoaiBieuDo = (e) => {
        setLoaiBieuDo(e);
        buildBieuDo(timListCayTrong, e.value);
    }

    return (

        <div className="card shadow mb-1">
            {
                props.showName == true ? <div className="row">
                    <div className="col-md-12">
                        <div className="breadcrumb-container">
                            <a href="/" className="breadcrumb-dis">Trang chủ / </a>
                            <a href="/public/dien-tich-san-xuat" className="breadcrumb-active"> Diện tích sản xuất</a>
                            <div className="breadcrumb-line">
                            </div>
                        </div>
                    </div>
                </div> : ''
            }
            <div className="card-header row h-solieu">
                <div className="col-md-2">
                    <Select placeholder="Quận huyện"
                        onChange={onChangeTimQuanHuyen} options={timListQuanHuyen} value={timQuanHuyen} theme={theme => ({
                            ...theme,
                            borderRadius: 0,
                            height: 30,
                            spacing: {
                                controlHeight: 30,
                                menuGutter: 1,
                                baseUnit: 2,
                            }
                        })} />
                </div>
                <div className="col-md-2">
                    <Select placeholder="Phường xã"
                        onChange={onChangeTimXaPhuong} options={timListPhuongXa} value={timXaPhuong} theme={theme => ({
                            ...theme,
                            borderRadius: 0,
                            height: 30,
                            spacing: {
                                controlHeight: 30,
                                menuGutter: 1,
                                baseUnit: 2,
                            }
                        })} />
                </div>
                <div className="col-md-2">
                    <Select placeholder="Cây trồng"
                        onChange={onChangeTimCayTrong} options={timListCayTrong} value={timCayTrong} theme={theme => ({
                            ...theme,
                            borderRadius: 0,
                            height: 30,
                            spacing: {
                                controlHeight: 30,
                                menuGutter: 1,
                                baseUnit: 2,
                            }
                        })} />
                </div>
                <div className="col-md-1">
                    <Select placeholder="Mùa vụ"
                        onChange={onChangeTimMuaVu} options={timListMuaVu} value={timMuaVu} theme={theme => ({
                            ...theme,
                            borderRadius: 0,
                            height: 30,
                            spacing: {
                                controlHeight: 30,
                                menuGutter: 1,
                                baseUnit: 2,
                            }
                        })} />
                </div>
                <div className="col-md-1">
                    <Select placeholder="Năm"
                        onChange={onChangeTimNam} options={timListNam} value={timNam} theme={theme => ({
                            ...theme,
                            borderRadius: 0,
                            height: 30,
                            spacing: {
                                controlHeight: 30,
                                menuGutter: 1,
                                baseUnit: 2,
                            }
                        })} />
                </div>
                <div className="col-md-2">
                    <Select placeholder="Biểu đồ"
                        onChange={onChaneLoaiBieuDo} options={listLoaiBieuDo} value={loaiBieuDo} theme={theme => ({
                            ...theme,
                            borderRadius: 0,
                            height: 30,
                            spacing: {
                                controlHeight: 30,
                                menuGutter: 1,
                                baseUnit: 2,
                            }
                        })} />
                </div>
                <div className="col-md-2">
                    <div className="input-group">
                        <input type="text" className="form-control" placeholder="Tìm kiếm" value={search} onChange={setFilter} onKeyPress={handleKeyPress} />
                        <div className="input-group-append">
                            <button className="btn btn-info btn-sm" type="button" onClick={timKiem} ><i className='fas fa-search'></i></button>
                            <button className="btn btn-primary btn-sm" type="button" onClick={exportExcel} ><i className='fas fa-file-excel'></i> Excel</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-body">
                <div className="row bieu-do">
                    <Bar
                        data={bieudo}
                        options={{
                            legend: { display: true },
                            title: {
                                display: true,
                                text: "Biểu đồ diện tích sản xuất"
                            },
                            hover: {
                                animationDuration: 0
                            },
                            animation: {
                                duration: 1,
                                onComplete: function () {
                                    var chartInstance = this.chart,
                                        ctx = chartInstance.ctx;
                                    ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
                                    ctx.textAlign = 'center';
                                    ctx.textBaseline = 'bottom';
                                    this.data.datasets.forEach(function (dataset, i) {
                                        var meta = chartInstance.controller.getDatasetMeta(i);
                                        meta.data.forEach(function (bar, index) {
                                            if (dataset.data[index] > 0) {
                                                var data = dataset.data[index];
                                                
                                                ctx.fillText(
                                                    parseInt((data*100))/100, 
                                                    bar._model.x, bar._model.y - 5);
                                            }
                                        });
                                    });
                                }
                            }
                        }}
                    />
                </div>
                <div id="div-table">
                    <table className="table table-bordered" width="100%">
                        <thead>
                            <tr>
                                <th>Quận, huyện</th>
                                <th>Phường xã</th>
                                <th>Cây trồng</th>
                                <th>Mùa vụ</th>
                                <th>Giống cây</th>
                                {/* <th>Loại đất</th> */}
                                <th>Diện tích(ha)</th>
                                <th>Năng suất</th>
                                <th>Sản lượng(tấn)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                listDienTichSanXuats.map((item, key) => {
                                    return (
                                        <tr key={key}>
                                            <td>{item.TenQuanHuyen}</td>
                                            <td>{item.TenXaPhuong}</td>
                                            <td>{item.TenCayTrong}</td>
                                            <td>{item.TenMuaVu}</td>
                                            <td>{item.GiongCays}</td>
                                            {/* <td>{item.LoaiDat}</td> */}
                                            <td className="td-number-style">
                                                <NumberFormat value={item.DienTich} displayType={'text'} thousandSeparator={true} decimalScale={2}></NumberFormat>
                                            </td>
                                            <td className="td-number-style">
                                                <NumberFormat value={item.NangSuat} displayType={'text'} thousandSeparator={true} decimalScale={2}></NumberFormat>
                                            </td>
                                            <td className="td-number-style">
                                                <NumberFormat value={item.SanLuong} displayType={'text'} thousandSeparator={true} decimalScale={2}></NumberFormat>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <div className="col-md-12 text-right">
                    <Pagination {...paginationConfig} />
                </div>
            </div>
        </div>
    )


}