import React, { useEffect, useState } from 'react'
import { Modal, Tabs, Tab } from 'react-bootstrap';
import { DienTichSanXuatPublic } from '../../public/solieu/dientichsanxuat/dientichsanxuat';
import 'react-tabs/style/react-tabs.css';
import { ThongKeAnhHuongSauBenh } from '../../public/solieu/anhhuongsaubenh/thongkeanhhuongsaubenh';
import { ThongKeDonViCungCap } from '../../public/solieu/donvicungcap/DonViCungCap';

export const ModalDienTichSanXuat = (props) => {
	const [thongTin, setThongTin] = useState([]);

	const [show, setShow] = useState(false);
	const [keyTab, setKeyTab] = useState('');

	useEffect(() => {
		if (props.show) {
			setThongTin(props.ThongTin);
			setShow(props.show);
			setKeyTab('dientich');
		}
	}, [props.show]);

	const handleClose = () => {
		props.closeModal();
		setShow(false);
	}

	return (
		<Modal dialog className="modalSB" show={show} onHide={handleClose} backdrop="static" keyboard={false} animation={false} size="xl">
			<Modal.Header closeButton>
				<Modal.Title>Số liệu</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Tabs activeKey={keyTab} onSelect={(k) => setKeyTab(k)}>
					<Tab eventKey="dientich" title="Diện tích sản xuất">
						{
							keyTab === "dientich"?<DienTichSanXuatPublic mahuyen={thongTin.mahuyen} maxa={thongTin.ma_px}></DienTichSanXuatPublic>:''
						}
					</Tab>
					<Tab eventKey="anhhuong" title="Ảnh hưởng sâu bệnh">
						{
							keyTab === 'anhhuong'?<ThongKeAnhHuongSauBenh mahuyen={thongTin.mahuyen} maxa={thongTin.ma_px}></ThongKeAnhHuongSauBenh>:''
						}
					</Tab>
					<Tab eventKey="donvi" title="Đơn vị cung cấp">
						{
							keyTab === 'donvi'?<ThongKeDonViCungCap mahuyen={thongTin.mahuyen} maxa={thongTin.ma_px}></ThongKeDonViCungCap>:''
						}
					</Tab>
				</Tabs>
			</Modal.Body>
			<Modal.Footer>
				<a href="#" type="button" className="btn btn-danger btn-sm ml-1" onClick={handleClose} disabled><i className="fas fa-sign-out-alt"></i> Hủy</a>
			</Modal.Footer>
		</Modal>
	)
}
