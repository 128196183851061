import React from 'react';
import { Sidebar } from '../shared/sidebar/Sidebar';
import { Header } from '../shared/header/Header';
import Switch from 'react-bootstrap/esm/Switch';
import { Home } from './home/Home';
import { Route } from 'react-router-dom';

import { LienHe } from './tienich/lienhe/LienHe';
import { Trang } from './tienich/trang/Trang';
import { BanDo } from './bando/BanDo';
import { GopY } from './tienich/gopy/GopY';
import { HuongDan } from './tienich/huongdan/huongdan';
import { NhomQuyen } from './hethong/nhomquyen/NhomQuyen';
import { TaiKhoan } from './hethong/taikhoan/TaiKhoan';
import { PhanQuyen } from './hethong/phanquyen/PhanQuyen';
import { BaoCao } from './baocao/BaoCao';
import { NhatKyDangNhap } from './hethong/nhatkydangnhap/nhatkydangnhap';
import { NhatKySuDung } from './hethong/nhatkysudung/nhatkysudung';
import { Database } from './hethong/database/database';
import { HuongDanAddEdit } from './tienich/huongdan/huongdanaddedit';
import { Post} from './tienich/post/post';
import { PostCategory} from './tienich/post/postcategory';
import { SauBenh} from './saubenh/sauvabenh/sauvabenh';
import { CayTrong } from './tienich/caytrong/CayTrong';
import { GiongCayTrong } from './tienich/giongcaytrong/GiongCayTrong'
import { DacDiemTrieuChung } from './saubenh/dacdiemtrieuchung/dacdiemtrieuchung';
import { GiaiDoanPhatTrien } from './tienich/giaidoanphattrien/GiaiDoanPhatTrien';
import { DonViCungCap } from './tienich/donvicungcap/DonViCungCap';
import { HoiDap } from './tienich/hoidap/hoidap'
import { MuaVu } from './tienich/muavu/muavu'
import { ThoiTiet } from './tienich/thoitiet/thoitiet'
import { ThuocBaoVeThucVat } from './tienich/thuocbaovethucvat/thuocbaovethucvat'
import { AnhHuongSauBenh } from './saubenh/anhhuongsaubenh/anhhuongsaubenh';
import { ImportExcel } from './tienich/importexcel/ImportExcel';
import { DienTichSanXuat } from './tienich/dientichsanxuat/DienTichSanXuat';
import { BenhHaiCayTrong } from './saubenh/benhhaicaytrong/benhhaicaytrong';
import { SinhVatGayHai } from './saubenh/sinhvatgayhai/sinhvatgayhai';
import { LichSuChanDoan } from './hethong/lichsuchandoan/lichsuchandoan';
import { Slide} from './tienich/slide/Slide';

export const Main = () => {
    return (
        <div id="wrapper">
            <Sidebar></Sidebar>
            <div id="content-wrapper">
                <div id="content">
                    <Header></Header>
                    <div className="container-fluid">
                        <Switch>
                            <Route path='/' component={Home} exact />
                            <Route path='/main/home' component={Home} />
                            
                            <Route path='/main/tienich/lienhe' component={LienHe} />
                            <Route path='/main/tienich/trang' component={Trang} />
                            <Route path='/main/tienich/baiviet' component={Post} />
                            <Route path='/main/tienich/danhmucbaiviet' component={PostCategory} />
                            <Route path='/main/tienich/gopy' component={GopY} />
                            <Route path='/main/tienich/huongdan' component={HuongDan} />
                            <Route path='/main/tienich/huongdanaddedit/:id' component={HuongDanAddEdit} />
                            <Route path='/main/tienich/hoidap' component={HoiDap} />
                            <Route path='/main/tienich/muavu' component={MuaVu} />
                            <Route path='/main/tienich/thoitiet' component={ThoiTiet} />
                            <Route path='/main/tienich/thuocbaovethucvat' component={ThuocBaoVeThucVat} />

                            <Route path='/main/hethong/nhomquyen' component={NhomQuyen} />
                            <Route path='/main/hethong/taikhoan' component={TaiKhoan} />
                            <Route path='/main/hethong/phanquyen' component={PhanQuyen} />
                            <Route path='/main/hethong/nhatkydangnhap' component={NhatKyDangNhap} />
                            <Route path='/main/hethong/lichsuchandoan' component={LichSuChanDoan} />
                            <Route path='/main/hethong/nhatkysudung' component={NhatKySuDung} />
                            <Route path='/main/bando' component={BanDo}></Route>
                            <Route path='/main/baocao' component={BaoCao} />
                            <Route path='/main/hethong/database' component={Database} />
                            <Route path='/main/tienich/caytrong' component={CayTrong}/>
                            <Route path='/main/tienich/giongcaytrong' component={GiongCayTrong}/>
                            <Route path='/main/saubenh/sauvabenh' component={SauBenh} />
                            <Route path='/main/tienich/giaidoanphattrien' component={GiaiDoanPhatTrien} />
                            
                            <Route path='/main/saubenh/dacdiemtrieuchung' component={DacDiemTrieuChung} />
                            <Route path='/main/tienich/donvicungcap' component={DonViCungCap} />
                            <Route path='/main/tienich/importexcel' component={ImportExcel} />
                            <Route path='/main/saubenh/anhhuongsaubenh' component={AnhHuongSauBenh} />
                            <Route path='/main/tienich/dientichsanxuat' component={DienTichSanXuat}/>
                            <Route path='/main/saubenh/benhhaicaytrong' component={BenhHaiCayTrong} />
                            <Route path='/main/saubenh/sinhvatgayhai' component={SinhVatGayHai} />
                        </Switch>
                    </div>
                </div>
            </div>
        </div>
    )
}
