const { default: axiosClient } = require("./axiosClient");

const BaoCaoApi = {
    getPaging: (params) => {
        const url = '/baocao/paging';
        return axiosClient.get(url, {params});
    },

    getDetail: (id) =>{
        const url = '/baocao/getbyid/' + id;
        return axiosClient.get(url);
    },

    post: (data) =>{
        const url = '/baocao/add';
        return axiosClient.post(url, data);
    },

    put: (data) => {
        const url = '/baocao/edit';
        return axiosClient.put(url, data);
    },

    delete: (id) =>{
        const url = '/baocao/delete/' + id;
        return axiosClient.delete(url);
    },

    getLoaiBaoCao:()=>{
        const url = '/baocao/getall';
        return axiosClient.get(url);
    }

}

export default BaoCaoApi;