import React, { useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Pagination from 'react-bootstrap-4-pagination';
import ThongBao from '../../../core/ThongBao';
import 'react-tabs/style/react-tabs.css';
import Authen from '../../../core/Authen';
import moment from 'moment';
import DonViCungCapApi from '../../../api/DonViCungCapApi';
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import { ExcelRenderer } from "react-excel-renderer";
import Select from 'react-select';
import * as XLSX from "xlsx";
import QuanHuyenApi from '../../../api/QuanHuyenApi';
import { set } from 'ol/transform';
import Ultility, { stringIsNumber, stringToNumber } from '../../../core/Ultility';

export const DonViCungCap = () => {
    var apiUrl = process.env.REACT_APP_URL;
    var noImage = "/uploadfiles/website/no_image-100x100.jpg";
    var theme = {
        borderRadius: 0,
        height: 30,
        spacing: {
            controlHeight: 30,
            menuGutter: 1,
            baseUnit: 2,
        }
    }
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [showXlsForm, setShowXlsForm] = useState(false);
    const handleCloseXlsForm = () => setShowXlsForm(false);
    const { register, handleSubmit, reset, errors } = useForm();
    const [filter, setFilter] = useState('');

    const [listDonViCungCaps, setListDonViCungCaps] = useState([]);

    const [roleAdd, setRoleAdd] = useState(false);
    const [roleEdit, setRoleEdit] = useState(false);
    const [roleDel, setRoleDel] = useState(false);

    const [isEdit, setIsEdit] = useState(false);

    const [fileNameXls, setFileNameXls] = useState({});
    const [listXls, setListXls] = useState([]);
    // const [listDonViCungCapXlsRoot, setListDonViCungCapXlsRoot] = useState([]);
    const [listDonViCungCapXls, setListDonViCungCapXls] = useState([]);

    const [startDate, setStartDate] = useState(new Date());
    const [startDate2, setStartDate2] = useState(new Date());
    const [startDate3, setStartDate3] = useState(new Date());
    const [startDate4, setStartDate4] = useState(new Date());

    let pageIndex = 1;
    let pageSize = 20;
    const [listQuanHuyen, setListQuanHuyen] = useState([]);
    const [listPhuongXa_All, setListPhuongXa_All] = useState([]);
    const [listPhuongXa, setListPhuongXa] = useState([]);
    const [listPhuongXa_TimKiem, setListPhuongXa_TimKiem] = useState([]);
    const [selectedQuanHuyen, setSelectedQuanHuyen] = useState();
    const [selectedQuanHuyen_TimKiem, setSelectedQuanHuyen_TimKiem] = useState();
    const [selectedPhuongXa, setSelectedPhuongXa] = useState();
    const [selectedPhuongXa_TimKiem, setSelectedPhuongXa_TimKiem] = useState();

    const [paginationConfig, setPaging] = useState({
        totalPages: 1,
        currentPage: 1,
        showMax: 5,
        size: "sm",
        threeDots: true,
        prevNext: true,
        onClick: function (page) {
            pageIndex = page;
            loadDonViCungCap();
        }
    });

    useEffect(() => {
        loadDonViCungCap();
        loadQuanHuyen();
        loadPhuongXa_All();
        setRoleAdd(Authen.checkQuyen('DONVICUNGCAP_CREATE'));
        setRoleEdit(Authen.checkQuyen('DONVICUNGCAP_UPDATE'));
        setRoleDel(Authen.checkQuyen('DONVICUNGCAP_DELETE'));
    }, []);

    const loadDonViCungCap = async () => {
        var maHuyen = '';
        var maXa = '';
        if (selectedQuanHuyen_TimKiem != null && selectedQuanHuyen_TimKiem.value != null) {
            maHuyen = selectedQuanHuyen_TimKiem.value;
        }
        if (selectedPhuongXa_TimKiem != null && selectedPhuongXa_TimKiem.value != null) {
            maXa = selectedPhuongXa_TimKiem.value;
        }
        try {
            const params = {
                filter: filter,
                pageIndex: pageIndex,
                pageSize: pageSize,
                maHuyen: maHuyen,
                maXa: maXa
            }
            const response = await DonViCungCapApi.getPaging(params);
            response.Items.forEach(item => {
                item.Checked = false;
            });
            paginationConfig.totalPages = 1;
            if (response.Items.length > 0) {
                paginationConfig.totalPages = Math.ceil(response.TotalRows / pageSize);
            }
            paginationConfig.currentPage = pageIndex;
            setPaging(paginationConfig);
            setListDonViCungCaps(response.Items);
            setIsEdit(false);
        } catch (err) {
        }
    }

    const timKiem = () => {
        loadDonViCungCap();
    }

    function onChangeFilter(e) {
        setFilter(e.target.value)
    }

    const clickRow = (id) => {
        listDonViCungCaps.forEach(element => {
            if (element.Id === id) {
                element.Checked = !element.Checked;
            }
        })
        let index = listDonViCungCaps.findIndex(x => x.Checked);
        if (index >= 0) {
            setIsEdit(true);
        }
        else {
            setIsEdit(false);
        }
        setListDonViCungCaps([...listDonViCungCaps]);
    }

    const handleShowAdd = () => {
        try {
            reset({
                Id: 0,
                Ma: "",
                Ten: "",
                ToaDoX: 0,
                ToaDoY: 0,
                DiaChi: "",
                DienThoai: "",
                Email: "",
                WebSite: "",
                GhiChu: "",
                Status: true,
                Delete: false,
                CreatedBy: Authen.getUser().UserName,
                CreatedDate: new Date(),
                UpdatedBy: "",
                UpdatedDate: null,
                SoDangKyKinhDoanh: "",
                DonViCapDangKyKinhDoanh: "",
                NgayCapDangKyKinhDoanh: null,
                SoChungChiChuyenMon: "",
                NgayCapChungChiChuyenMon: null,
                SoChungNhanDieuKien: "",
                NgayCapChungNhanDieuKien: null,
                NgayHetHanChungNhanDieuKien: null
            }, [reset]);
            setShow(true)
            setStartDate(moment(new Date()));
            setStartDate2(moment(new Date()));
            setStartDate3(moment(new Date()));
            setStartDate4(moment(new Date()));
            if (listQuanHuyen.length >= 0) {
                setSelectedQuanHuyen(listQuanHuyen[0]);
            }
            if (listPhuongXa.length >= 0) {
                setSelectedPhuongXa(listPhuongXa[0]);
            }
        }
        catch (ex) {
            console.log(ex);
        }
    };

    const handleShowEdit = () => {
        try {
            let index = listDonViCungCaps.findIndex(x => x.Checked);
            if (index !== -1) {
                DonViCungCapApi.getDetail(listDonViCungCaps[index].Id).then((response) => {
                    reset({
                        Id: response.Id,
                        Ma: response.Ma,
                        Ten: response.Ten,
                        ToaDoX: response.ToaDoX,
                        ToaDoY: response.ToaDoY,
                        DiaChi: response.DiaChi,
                        DienThoai: response.DienThoai,
                        Email: response.Email,
                        WebSite: response.WebSite,
                        GhiChu: response.GhiChu,
                        Status: response.Status,
                        Delete: response.Delete,
                        CreatedBy: response.CreatedBy,
                        CreatedDate: response.CreatedDate,
                        UpdatedBy: response.UpdatedBy,
                        UpdatedDate: response.UpdatedDate,
                        SoDangKyKinhDoanh: response.SoDangKyKinhDoanh,
                        DonViCapDangKyKinhDoanh: response.DonViCapDangKyKinhDoanh,
                        NgayCapDangKyKinhDoanh: response.NgayCapDangKyKinhDoanh,
                        SoChungChiChuyenMon: response.SoChungChiChuyenMon,
                        NgayCapChungChiChuyenMon: response.NgayCapChungChiChuyenMon,
                        SoChungNhanDieuKien: response.SoChungNhanDieuKien,
                        NgayCapChungNhanDieuKien: response.NgayCapChungNhanDieuKien,
                        NgayHetHanChungNhanDieuKien: response.NgayHetHanChungNhanDieuKien
                    }, [reset]);
                    setStartDate(moment(response.NgayCapDangKyKinhDoanh));
                    setStartDate2(moment(response.NgayCapChungChiChuyenMon));
                    setStartDate3(moment(response.NgayCapChungNhanDieuKien));
                    setStartDate4(moment(response.NgayHetHanChungNhanDieuKien));
                    setShow(true);
                    setIsEdit(true);
                    setSelectedQuanHuyen(listQuanHuyen[0]);
                    var index = listQuanHuyen.findIndex(x => x.value == response.MaHuyen);
                    if (index >= 0) {
                        setSelectedQuanHuyen(listQuanHuyen[index]);
                    }
                    loadPhuongXa({ value: response.MaHuyen }, false, response.MaXa);
                });
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    const handleShowDel = () => {
        ThongBao.printConfirmationDialog("Bạn có đồng ý xóa dữ liệu đã chọn hay không?", () => {
            let lstChecked = listDonViCungCaps.filter(x => x.Checked);
            if (lstChecked.length > 0) {
                let lstId = [];
                lstChecked.forEach(element => {
                    lstId.push(element.Id);
                });
                DonViCungCapApi.delete(lstId).then(() => {
                    loadDonViCungCap();
                })
            }
        });
    }
    const handleShowData = () => {
        let index = listDonViCungCaps.findIndex(x => x.Checked);
        if (index !== - 1) {
            DonViCungCapApi.getDetail(listDonViCungCaps[index].Id).then((response) => {
                reset({
                    Id: response.Id,
                    Ma: response.Ma,
                    Ten: response.Ten,
                    ToaDoX: response.ToaDoX,
                    ToaDoY: response.ToaDoY,
                    DiaChi: response.DiaChi,
                    DienThoai: response.DienThoai,
                    Email: response.Email,
                    WebSite: response.WebSite,
                    GhiChu: response.GhiChu,
                    Status: response.Status,
                    Delete: response.Delete,
                    CreatedBy: response.CreatedBy,
                    CreatedDate: response.CreatedDate,
                    UpdatedBy: response.UpdatedBy,
                    UpdatedDate: response.UpdatedDate,
                    SoDangKyKinhDoanh: response.SoDangKyKinhDoanh,
                    DonViCapDangKyKinhDoanh: response.DonViCapDangKyKinhDoanh,
                    NgayCapDangKyKinhDoanh: response.NgayCapDangKyKinhDoanh,
                    SoChungChiChuyenMon: response.SoChungChiChuyenMon,
                    NgayCapChungChiChuyenMon: response.NgayCapChungChiChuyenMon,
                    SoChungNhanDieuKien: response.SoChungNhanDieuKien,
                    NgayCapChungNhanDieuKien: response.NgayCapChungNhanDieuKien,
                    NgayHetHanChungNhanDieuKien: response.NgayHetHanChungNhanDieuKien
                }, [reset]);
                setStartDate(moment(response.NgayCapDangKyKinhDoanh));
                setStartDate2(moment(response.NgayCapChungChiChuyenMon));
                setStartDate3(moment(response.NgayCapChungNhanDieuKien));
                setStartDate4(moment(response.NgayHetHanChungNhanDieuKien));
                setShow(true);
                setIsEdit(true);
            })
        }
    }

    const saveData = (data) => {
        try {
            if (selectedQuanHuyen == null || selectedQuanHuyen.value == '') {
                ThongBao.prinWarningMessage("Chưa chọn quận huyện");
            }
            else {
                data.NgayCapDangKyKinhDoanh = moment(startDate).format("DD/MM/YYYY");
                data.NgayCapChungChiChuyenMon = moment(startDate2).format("DD/MM/YYYY");
                data.NgayCapChungNhanDieuKien = moment(startDate3).format("DD/MM/YYYY");
                data.NgayHetHanChungNhanDieuKien = moment(startDate4).format("DD/MM/YYYY");
                if (selectedQuanHuyen != null && selectedQuanHuyen.value != '') {
                    data.MaHuyen = selectedQuanHuyen.value;
                }
                if (selectedPhuongXa != null && selectedPhuongXa.value != '') {
                    data.MaXa = selectedPhuongXa.value;
                }
                if (data.Id == 0 || data.Id == null || data.Id == undefined) {
                    data.Id = 0;
                    data.CreatedDate = new Date();
                    DonViCungCapApi.post(data).then(() => {
                        setShow(false);
                        loadDonViCungCap();
                    })
                }
                else {
                    data.UpdatedDate = new Date();
                    DonViCungCapApi.put(data).then(() => {
                        setShow(false);
                        loadDonViCungCap();
                    })
                }
            }
        }
        catch (ex) {
            console.log(ex);
        }
    }

    const checkBoHandler = () => {

    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            loadDonViCungCap();
        }
    }

    const handleImportXlsx = () => {
        setListDonViCungCapXls([]);
        setShowXlsForm(true);
    }

    const Ngay_ValueChange = (event) => {
        setStartDate(event._d);
    }

    const Ngay_ValueChange2 = (event) => {
        setStartDate2(event._d);
    }

    const Ngay_ValueChange3 = (event) => {
        setStartDate3(event._d);
    }

    const Ngay_ValueChange4 = (event) => {
        setStartDate4(event._d);
    }

    const colTen_Style = {
        minWidth: '100px'
    }

    const colDiaChi_Style = {
        minWidth: '120px'
    }

    // Import Xls
    function toDate(obj) {
        if (obj == null) {
            return null;
        }
        else {
            var str = obj.toString().trim();
            if (moment(str, 'D/M/YYYY', true).isValid() == true) {
                return str;
            }
            else {
                if (stringIsNumber(obj)) {
                    var num = stringToNumber(obj);
                    if (num > 25569) {
                        return moment(new Date(Math.round((num - 25569) * 86400 * 1000))).format("DD/MM/YYYY");
                    }
                }
                return "Invalid date";
            }
        }
    }

    const selectXls = (e) => {
        var file = e.target.files[0];
        setFileNameXls(file);
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onerror = function (error) {
            console.log('Error: ', error);
        }
        ExcelRenderer(file, (err, resp) => {
            if (err) {
                console.log(err);
            }
            else {
                setListXls({
                    cols: resp.cols,
                    rows: resp.rows
                });

                // let listRoot = [];
                let list = [];
                let count = 0;
                resp.rows.forEach((item, key) => {
                    try {
                        if (key != 0 && item[3] != undefined && item[3] != "" && item[0] != undefined && item[0] != "TT" && item[0] != "STT") {
                            list.push({
                                // Stt: item[0],
                                Ma: "",
                                Ten: item[3],
                                ToaDoX: 0,
                                ToaDoY: 0,
                                DiaChi: (item[5] == undefined) ? '' : item[5],
                                DienThoai: (item[4] == undefined) ? '' : item[4],
                                Email: "",
                                WebSite: "",
                                GhiChu: "",
                                Status: true,
                                Delete: false,
                                CreatedBy: "Admin",
                                CreatedDate: new Date(),
                                SoDangKyKinhDoanh: (item[6] == undefined) ? '' : item[6].trim().toUpperCase(),
                                DonViCapDangKyKinhDoanh: (item[7] == undefined) ? '' : item[7],
                                NgayCapDangKyKinhDoanh: toDate(item[8]),
                                SoChungChiChuyenMon: (item[9] == undefined) ? '' : item[9],
                                NgayCapChungChiChuyenMon: toDate(item[10]),
                                SoChungNhanDieuKien: (item[11] == undefined) ? '' : item[11],
                                NgayCapChungNhanDieuKien: toDate(item[12]),
                                NgayHetHanChungNhanDieuKien: toDate(item[13]),
                                TenHuyen: item[1],
                                TenXa: item[2],
                                MaHuyen: null,
                                MaXa: null,
                                IsValid: true,
                                ThongBao: ""
                            });
                        }
                    }
                    catch (ex) {
                        console.log(ex);
                    }
                });
                list.forEach((element, i) => {
                    var index = -1;
                    if (element.TenHuyen != null && element.TenHuyen.length > 0) {
                        index = listQuanHuyen.findIndex(x => x.label.toLowerCase().trim().includes(element.TenHuyen.toLowerCase().trim()));
                        if (index >= 0) {
                            element.MaHuyen = listQuanHuyen[index].value;
                        }
                        else {
                            element.ThongBao += "sai tên huyện, ";
                        }
                        if (element.MaHuyen != null && element.TenXa != null && element.TenXa.length > 0) {
                            index = listPhuongXa_All.findIndex(x => element.MaHuyen == x.maHuyen && x.label.toLowerCase().trim().includes(element.TenXa.toLowerCase().trim()))
                            if (index >= 0) {
                                element.MaXa = listPhuongXa_All[index].value;
                            }
                            else {
                                element.ThongBao += "sai tên xã, ";
                                element.IsValid = false;
                            }
                        }
                    }
                    else {
                        element.ThongBao += " chưa nhập tên huyện, ";
                        element.IsValid = false;
                    }
                    if (element.NgayCapDangKyKinhDoanh == "Invalid date" || element.NgayCapChungChiChuyenMon == "Invalid date" || element.NgayCapChungNhanDieuKien == "Invalid date" || element.NgayHetHanChungNhanDieuKien == "Invalid date") {
                        element.ThongBao += " sai định dạng ngày tháng, ";
                        element.IsValid = false;
                    }
                    if (element.IsValid == false) {
                        // element.ThongBao = "Dòng " + i.toString() + element.ThongBao;
                    }
                });
                setListDonViCungCapXls(list);
            }
        });
    }

    const isDate = (input, i, lineXls) => {
        var isKq = Boolean = true;
        if (input != null && input != undefined && input != "") {
            var utc = moment(input, 'dd/mm/yyyy');
            var isUtc = utc.isValid();
            var txt = "";
            txt = input;
            var sltrim = txt.trimLeft().trimRight().split(' ').length;
            if (isUtc == false || sltrim > 1) {
                ThongBao.printErrorMessage('Dòng ' + i + ':\n Column ' + lineXls + i + ' Sai định dạng ngày hoặc giữa ngày tháng năm có khoảng trắng');
                isKq = false;
            }
        }
        return isKq;
    }

    const importData = async () => {
        try {
            if (listDonViCungCapXls.filter(x => x.IsValid == false).length == 0) {
                const response = await DonViCungCapApi.importXls(listDonViCungCapXls);
                if (response.Status == true) {
                    ThongBao.printSuccessMessage("Đã import thành công");
                    setShowXlsForm(false);
                    loadDonViCungCap();
                }
            }
            else {
                ThongBao.prinWarningMessage("Dữ liệu import không hợp lệ. Hãy kiểm tra lại các dòng dữ liệu bị sai (dòng có màu đỏ)")
            }
        } catch (err) {
        }
    }

    const onChangeQuanHuyen = (e) => {
        setSelectedQuanHuyen(e);
        loadPhuongXa(e, false, null);
    }

    const onChangePhuongXa = (e) => {
        setSelectedPhuongXa(e);
    }

    const onChangeQuanHuyen_TimKiem = (e) => {
        setSelectedQuanHuyen_TimKiem(e);
        loadPhuongXa(e, true, null);
    }

    const onChangePhuongXa_TimKiem = (e) => {
        setSelectedPhuongXa_TimKiem(e);
    }

    function loadQuanHuyen() {
        QuanHuyenApi.getAll().then((response) => {
            let list = [];
            list.push({ value: '', label: 'Chọn quận huyện...' });
            response.forEach(item => {
                list.push({ value: item.Ma, label: item.Ten });
            })
            setListQuanHuyen(list);
        })
    }

    // function loadPhuongXa(idQuanHuyen, isTimKiem, maXa) {
    //     const params = {
    //         maHuyen: Number(idQuanHuyen.value == undefined ? '' : idQuanHuyen.value)
    //     }
    //     QuanHuyenApi.getXaByHuyen(params).then((response) => {
    //         let list = [];
    //         list.push({ value: '', label: 'Chọn phường xã...' });
    //         response.forEach(item => {
    //             list.push({ value: item.maXa, label: item.tenXa });
    //         })
    //         if (isTimKiem == true) {
    //             setListPhuongXa_TimKiem(list);
    //             setSelectedPhuongXa_TimKiem(list[0]);
    //         }
    //         else {
    //             setListPhuongXa(list);
    //             var index = list.findIndex(x => x.value == maXa);
    //             if (index >= 0) {
    //                 setSelectedPhuongXa(list[index]);
    //             }
    //             else {
    //                 setSelectedPhuongXa(list[0]);
    //             }
    //         }
    //     })
    // }

    function loadPhuongXa_All() {
        QuanHuyenApi.getXaByHuyen({ maHuyen: '' }).then((response) => {
            let list = [];
            list.push({ value: '', label: 'Chọn phường xã...', maHuyen: '' });
            response.forEach(item => {
                list.push({ value: item.maXa, label: item.tenXa, maHuyen: item.maHuyen });
            })
            setListPhuongXa_All(list);
        })
    }

    function loadPhuongXa(idQuanHuyen, isTimKiem, maXa) {
        var maHuyen = idQuanHuyen.value == undefined ? '' : idQuanHuyen.value;
        let list = [];
        list = listPhuongXa_All.filter(x => x.maHuyen == maHuyen || x.maHuyen == '')
        if (isTimKiem == true) {
            setListPhuongXa_TimKiem(list);
            setSelectedPhuongXa_TimKiem(list[0]);
        }
        else {
            setListPhuongXa(list);
            var index = list.findIndex(x => x.value == maXa);
            if (index >= 0) {
                setSelectedPhuongXa(list[index]);
            }
            else {
                setSelectedPhuongXa(list[0]);
            }
        }
    }


    return (
        <div className="card shadow mb-1">
            <div className="card-header py-2 row">
                <div className="col-md-2">
                    <h6 className="m-0 font-weight-bold text-primary">Đơn Vị Cung Cấp</h6>
                </div>

                <div className="col-md-2">
                    <Select
                        className="select" name="MaHuyen" placeholder="Chọn quận huyện" value={selectedQuanHuyen_TimKiem} onChange={onChangeQuanHuyen_TimKiem} options={listQuanHuyen} theme={theme}
                    />
                </div>
                <div className="col-md-2">
                    <Select
                        className="select" name="MaXa" placeholder="Chọn xã" value={selectedPhuongXa_TimKiem} onChange={onChangePhuongXa_TimKiem} options={listPhuongXa_TimKiem} theme={theme}
                    />
                </div>


                <div className="input-group col-md-3">
                    <input type="text" className="form-control" placeholder="Tìm kiếm" value={filter} onChange={onChangeFilter} onKeyPress={handleKeyPress} />
                    <div className="input-group-append">
                        <button className="btn btn-info" type="button" onClick={timKiem}><i className='fas fa-search'></i> Tìm kiếm</button>
                    </div>
                </div>
                <div className="col-md-3 bd-button text-right">
                    <button type="file" className="btn btn-success btn-sm ml-1 mr-2" onClick={handleImportXlsx}><i className='fas fa-plus'> Import</i></button>
                    {roleAdd == true ? <Button variant="success" size="sm" onClick={handleShowAdd}>
                        <i className='fas fa-plus'></i> </Button> : ''}
                    {roleEdit == true ? <Button variant="primary" size="sm" onClick={handleShowEdit} disabled={!isEdit}>
                        <i className='far fa-edit'></i> </Button> : ''}
                    {roleDel == true ? <Button variant="danger" size="sm" onClick={handleShowDel} disabled={!isEdit}>
                        <i className='fas fa-trash-alt' ></i> </Button> : ''}
                    <button type="button" className="btn btn-primary btn-sm ml-1 mr-2" disabled={!isEdit} onClick={handleShowData}>
                        <i className='far fa-eye'></i>
                    </button>
                </div>
            </div>
            <div className="card-body">
                <div className="">
                    <table className="table table-bordered" width="100%">
                        <thead>
                            <tr>
                                <th className="col-checkbox"></th>
                                <th>Huyện</th>
                                <th>Mã đơn vị</th>
                                <th>Tên đơn vị</th>
                                <th>Địa chỉ</th>
                                <th>Điện thoại</th>
                                <th>Email</th>
                                <th>WebSite</th>
                                <th>Ghi chú</th>
                                <th>Trạng thái</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                listDonViCungCaps.map((item, key) => {
                                    return (
                                        <tr onClick={() => clickRow(item.Id)} key={key}>
                                            <td className="col-checkbox">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" checked={item.Checked}
                                                        onChange={checkBoHandler} />
                                                </div>
                                            </td>
                                            <td>{item.TenHuyen}</td>
                                            <td>{item.Ma}</td>
                                            <td>{item.Ten}</td>
                                            <td>{item.DiaChi}</td>
                                            <td>{item.DienThoai}</td>
                                            <td>{item.Email}</td>
                                            <td>{item.WebSite}</td>
                                            <td>{item.GhiChu}</td>
                                            <td className="text-center">
                                                {item.Status == true &&
                                                    <span className="label label-success cls-status-label">Kích hoạt</span>
                                                }
                                                {item.Status == false &&
                                                    <span className="label label-danger cls-status-label">Khóa</span>
                                                }
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <div className="col-md-12 text-right">
                    <Pagination {...paginationConfig} />
                </div>
            </div>


            <Modal name='mdl1' show={showXlsForm} onHide={handleCloseXlsForm} size='lg'>
                <Modal.Header closeButton>
                    <input type="file" className="form-control" onChange={selectXls} onKeyPress={handleKeyPress} />

                </Modal.Header>
                <div name='div1' style={{ height: 600, overflowY: 'scroll' }} >
                    <table className="table table-bordered" >
                        <thead>
                            <tr>
                                <td>STT</td>
                                <td style={colTen_Style}>Huyện</td>
                                <td>Xã</td>
                                <td style={colTen_Style}>Tên</td>
                                <td>Điện thoại</td>
                                <td style={colDiaChi_Style}>Địa chỉ</td>
                                <td>Số đkkd</td>
                                <td style={colTen_Style}>Đơn vị cấp đkkd</td>
                                <td>Ngày cấp đkkd</td>
                                <td>Số CCCM</td>
                                <td>Ngày cấp CCCM</td>
                                <td>Số CNDDK</td>
                                <td>Ngày cấp CNDDK</td>
                                <td>Ngày hết hạn CNDDK</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                listDonViCungCapXls.map((item, key) => {
                                    return (
                                        <tr className={item.IsValid == true ? '' : 'text-danger'} data-toggle="tooltip" title={item.ThongBao}>
                                            <td>{key + 1}</td>
                                            {/* <td>{item.Stt}-</td> */}
                                            <td>{item.TenHuyen}</td>
                                            <td>{item.TenXa}</td>
                                            <td>{item.Ten}</td>
                                            <td>{item.DienThoai}</td>
                                            <td>{item.DiaChi}</td>
                                            <td>{item.SoDangKyKinhDoanh}</td>
                                            <td>{item.DonViCapDangKyKinhDoanh}</td>
                                            <td>{item.NgayCapDangKyKinhDoanh}</td>
                                            <td>{item.SoChungChiChuyenMon}</td>
                                            <td>{item.NgayCapChungChiChuyenMon}</td>
                                            <td>{item.SoChungNhanDieuKien}</td>
                                            <td>{item.NgayCapChungNhanDieuKien}</td>
                                            <td>{item.NgayHetHanChungNhanDieuKien}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <Modal.Footer>
                    <Button variant="success" size="sm" onClick={handleSubmit(importData)}>
                        Import data</Button>
                    <Button variant="success" size="sm" >
                        Làm mới</Button>
                    <Button variant="danger" size="sm" onClick={handleCloseXlsForm}>
                        Thoát</Button>
                </Modal.Footer>
            </Modal>


            <Modal show={show} onHide={handleClose} animation={false} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Chi tiết</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="user" onSubmit={handleSubmit(saveData)}>
                        <input name="Id" hidden={true} ref={register}  ></input>
                        <div className="form-group row">
                            <label className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">
                                Mã đơn vị<span className="text-danger"> *</span>
                            </label>
                            <div className="col-md-4 col-sm-6 col-xl-4">
                                <input name="Ma" type="text" className="form-control" placeholder="Mã đơn vị cung cấp"
                                    ref={register({ required: true })} />
                                {errors.Ma && <span className="text-danger">Hãy nhập mã đơn vị..</span>}
                            </div>
                        </div>

                        <div className="form-group row">
                            <label className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">
                                Tên đơn vị<span className="text-danger"> *</span>
                            </label>
                            <div className="col-sm-10">
                                <input name="Ten" type="text" className="form-control" placeholder="Tên đơn vị cung cấp"
                                    ref={register({ required: true })} />
                                {errors.Ten && <span className="text-danger">Hãy nhập tên đơn vị..</span>}
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">
                                Quận huyện<span className="text-danger"> *</span>
                            </label>
                            <div className="col-sm-4">
                                <Select
                                    className="select" name="MaHuyen" placeholder="Chọn quận huyện" value={selectedQuanHuyen} onChange={onChangeQuanHuyen} options={listQuanHuyen} theme={theme}
                                />
                            </div>
                            <label className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">
                                Phường xã
                            </label>
                            <div className="col-sm-4">
                                <Select
                                    className="select" name="MaXa" placeholder="Chọn xã" value={selectedPhuongXa} onChange={onChangePhuongXa} options={listPhuongXa} theme={theme}
                                />
                            </div>
                        </div>






                        <div className="form-group row">
                            <label className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">
                                Tọa độ X
                                    </label>
                            <div className="col-md-4 col-sm-6 col-xl-4">
                                <input name="ToaDoX" type="text" className="form-control" placeholder="Nhập tọa độ X"
                                    ref={register} />
                                <span className="eRor">{errors.ToaDoX && errors.ToaDoX.message}</span>
                            </div>
                            <label className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">
                                Tọa độ Y
                                    </label>
                            <div className="col-md-4 col-sm-6 col-xl-4">
                                <input name="ToaDoY" type="text" className="form-control" placeholder="Nhập tọa độ Y"
                                    ref={register} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">Địa chỉ</label>
                            <div className="col-sm-10">
                                <textarea name="DiaChi" type="text" className="form-control" placeholder="Địa chỉ"
                                    ref={register} />
                            </div>
                        </div>
                        <div className="form-group row mb-1">
                            <label className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">Điện thoại</label>
                            <div className="col-sm-4">
                                <input name="DienThoai" className="form-control textrea-size" rows="2" placeholder="Nhập số điện thoại" ref={register}
                                ></input>
                            </div>
                            <label className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">Email</label>
                            <div className="col-sm-4">
                                <input name="Email" className="form-control textrea-size" rows="2" placeholder="Nhập email" ref={register(
                                    {
                                        required: false,
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: "Sai định dạng email (eg: tcsoft@tcsoft.vn )"
                                        }
                                    }
                                )}
                                ></input>
                                <span className="eRor">{errors.Email && errors.Email.message}</span>
                            </div>
                        </div>
                        {/* <div className="form-group row">
                            <label className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">Email</label>
                            <div className="col-sm-10">
                                <input name="Email" className="form-control textrea-size" rows="2" placeholder="Nhập email" ref={register(
                                    {
                                        required: false,
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: "Sai định dạng email (eg: tcsoft@tcsoft.vn )"
                                        }
                                    }
                                )}
                                ></input>
                                <span className="eRor">{errors.Email && errors.Email.message}</span>
                            </div>
                        </div> */}
                        <div className="form-group row">
                            <label className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">Website</label>
                            <div className="col-sm-10">
                                <input name="WebSite" className="form-control textrea-size" rows="2" placeholder="Nhập website" ref={register}
                                ></input>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">Ghi chú</label>
                            <div className="col-sm-10">
                                <textarea name="GhiChu" className="form-control textrea-size" rows="2" placeholder="Nhập ghi chú"
                                    ref={register}  ></textarea>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">
                                Số ĐKKD
                                    </label>
                            <div className="col-md-4 col-sm-6 col-xl-4">
                                <input name="SoDangKyKinhDoanh" type="text" className="form-control" placeholder="Nhập số đăng ký kd"
                                    ref={register} />
                                <span className="eRor">{errors.ToaDoX && errors.ToaDoX.message}</span>
                            </div>
                            <label className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">
                                Đơn vị cấp ĐKKD
                                    </label>
                            <div className="col-md-4 col-sm-6 col-xl-4">
                                <input name="DonViCapDangKyKinhDoanh" type="text" className="form-control" placeholder="Nhập đơn vị cấp đăng ký kd"
                                    ref={register} />
                            </div>
                            <label className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">
                                Ngày cấp ĐKKD
                                    </label>
                            <div className="col-md-4 col-sm-6 col-xl-4">
                                <Datetime name="NgayCapDangKyKinhDoanh" initialValue={startDate} timeFormat={false} dateFormat="DD/MM/YYYY" closeOnSelect={true} onChange={(e) => Ngay_ValueChange(e)} ref={register} value={startDate} />
                            </div>

                        </div>
                        <div className="form-group row">
                            <label className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">
                                Số CCCM
                                    </label>
                            <div className="col-md-4 col-sm-6 col-xl-4">
                                <input name="SoChungChiChuyenMon" type="text" className="form-control" placeholder="Nhập số chứng chỉ chuyên môn"
                                    ref={register} />
                                <span className="eRor">{errors.ToaDoX && errors.ToaDoX.message}</span>
                            </div>
                            <label className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">
                                Ngày cấp CCCM
                                    </label>
                            <div className="col-md-4 col-sm-6 col-xl-4">
                                <Datetime name="NgayCapChungChiChuyenMon" initialValue={startDate2} timeFormat={false} dateFormat="DD/MM/YYYY" closeOnSelect={true} onChange={(e) => Ngay_ValueChange2(e)} ref={register} value={startDate2} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">
                                Số chứng nhận đủ ĐK
                                    </label>
                            <div className="col-md-4 col-sm-6 col-xl-4">
                                <input name="SoChungNhanDieuKien" type="text" className="form-control" placeholder="Nhập số chứng nhận đủ điều kiện"
                                    ref={register} />
                                <span className="eRor">{errors.ToaDoX && errors.ToaDoX.message}</span>
                            </div>
                            <label className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">
                                Ngày cấp CNĐĐK
                                    </label>
                            <div className="col-md-4 col-sm-6 col-xl-4">
                                <Datetime name="NgayCapChungNhanDieuKien" initialValue={startDate3} timeFormat={false} dateFormat="DD/MM/YYYY" closeOnSelect={true} onChange={(e) => Ngay_ValueChange3(e)} ref={register} value={startDate3} />
                            </div>
                            <label className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">
                                Ngày hết hạn CNĐĐK
                                    </label>
                            <div className="col-md-4 col-sm-6 col-xl-4">
                                <Datetime name="NgayHetHanChungNhanDieuKien" initialValue={startDate4} timeFormat={false} dateFormat="DD/MM/YYYY" closeOnSelect={true} onChange={(e) => Ngay_ValueChange4(e)} ref={register} value={startDate4} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH"></label>
                            <div className="col-sm-10">
                                <label className="custom-checkbox checkbox-style" >
                                    <input className="form-check-input" name="Status" type="checkbox" ref={register}
                                    />
                                    <span className="helping-el"></span> Kích hoạt
                                    </label>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" size="sm" onClick={handleSubmit(saveData)}>
                        Lưu</Button>
                    <Button variant="danger" size="sm" onClick={handleClose}>
                        Hủy</Button>

                </Modal.Footer>
            </Modal>
        </div>
    )

}
