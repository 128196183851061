import axiosClient from './axiosClient';

const BanDoPublicApi = {

    getDetail: (params) =>{
        const url = '/mappublic/getinfo';
        return axiosClient.get(url, {params});
    },
    delete: (params) =>{
        const url = 'xoabando/delete';
        return axiosClient.delete(url, {params});
    },
    getById: (params) =>{
        const url = '/mappublic/getbyid';
        return axiosClient.get(url, {params});
    },
    getThongTinTinhPhanBon: (params) =>{
        const url = '/mappublic/getthongtintinhphanbon';
        return axiosClient.get(url, {params});
    },
    getInfoById: (params) =>{
        const url = '/mappublic/getinfobyid';
        return axiosClient.get(url, {params});
    },
    getInfoHome: (params) =>{
        const url = '/mappublic/getinfohome';
        return axiosClient.get(url, {params});
    },
}

export default BanDoPublicApi;