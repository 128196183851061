import React, { useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import DatabaseApi from '../../../api/DatabaseApi';
import Select from 'react-select';
import Pagination from 'react-bootstrap-4-pagination';
import ThongBao from '../../../core/ThongBao';
import Authen from '../../../core/Authen';
import Ultility from '../../../core/Ultility';
import { Loading } from '../../../shared/loading/Loading';
import * as moment from 'moment'

export const Database = () => {
    const [listPostCategory, setPostCategory] = useState([]);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const { register, handleSubmit, reset, errors } = useForm();
    const [filter, setFilter] = useState('');
    const [listDanhMucCha, setDanhMucCha] = useState([]);
    const [roleAdd, setRoleAdd] = useState(false);
    const [roleEdit, setRoleEdit] = useState(false);
    const [roleDel, setRoleDel] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isChecked, setIsChecked] = useState(false);

    let pageIndex = 1;
    let pageSize = 20;
    const [paginationConfig, setPaging] = useState({
        totalPages: 1,
        currentPage: 1,
        showMax: 5,
        size: "sm",
        threeDots: true,
        prevNext: true,
        onClick: function (page) {
            pageIndex = page;
            loadCategory();
        }
    });

    const loadCategory = async () => {
        try {
            const params = {
                pageIndex: pageIndex,
                pageSize: pageSize,
                filter: filter
            }
            const response = await DatabaseApi.getPaging(params);
            response.Items.forEach(item => {
                item.Checked = false;
            });
            paginationConfig.totalPages = Math.ceil(response.TotalRow / pageSize);
            paginationConfig.currentPage = pageIndex;
            setPaging(paginationConfig);
            setPostCategory(response.Items);
        } catch (err) {
        }
    }

    useEffect(() => {
        loadCategory();
        setRoleAdd(true);
        setRoleDel(true);
        setRoleEdit(true);
        setRoleAdd(Authen.checkQuyen('DATABASE_CREATE'));
        setRoleEdit(Authen.checkQuyen('DATABASE_UPDATE'));
        setRoleDel(Authen.checkQuyen('DATABASE_DELETE'));
    }, []);

    const timKiem = () => {
        loadCategory();
    }

    function onChangeFilter(e) {
        setFilter(e.target.value)
    }

    const saveData = (data) => {
        if (data.Id === "0") {
            setLoading(true);
            DatabaseApi.post(data).then(() => {
                setLoading(false);
                setShow(false);
                loadCategory();
            }).catch(() => {
                setLoading(false);
            })
        }
        else {
            setLoading(true);
            DatabaseApi.put(data).then(() => {
                setLoading(false);
                setShow(false);
                loadCategory();
            }).catch(() => {
                setLoading(false);
            })
        }
    }

    const clickRow = (id) => {
        listPostCategory.forEach(item => {
            if (item.Id === id) {
                item.Checked = !item.Checked;
            }
        });
        let lstChecked = listPostCategory.filter(x => x.Checked);
        if(lstChecked.length==1){
            setIsChecked(true);
        }
        else{
            setIsChecked(false);
        }
        setPostCategory([...listPostCategory]);
    }

    const handleShowAdd = () => {
        reset({
            Id: 0,
            Name: "",
            Status: true
        }, [reset]);
        setShow(true)
    };

    const handleShowEdit = () => {
        let index = listPostCategory.findIndex(x => x.Checked);
        if (index !== -1) {
            DatabaseApi.getDetail(listPostCategory[index].Id).then((response) => {
                reset(response, [reset]);
                setShow(true);
            });
        }
    }

    const handleShowDel = () => {
        ThongBao.printConfirmationDialog("Bạn có đồng ý xóa dữ liệu đã chọn hay không?", () => {
            let lstChecked = listPostCategory.filter(x => x.Checked);
            if (lstChecked.length > 0) {
                let lstId = [];
                lstChecked.forEach(element => {
                    lstId.push(element.Id);
                });
                DatabaseApi.delete(lstId).then(() => {
                    loadCategory();
                })
            }
        });
    }

    const handleShowRestore = () => {
        ThongBao.printConfirmationDialog("Bạn có đồng ý khôi phục CSDL tại điểm backup này không?", () => {
            let lstChecked = listPostCategory.filter(x => x.Checked);
            if (lstChecked.length > 0) {
                setLoading(true);
                DatabaseApi.restore({ Id: lstChecked[0].Id }).then(() => {
                    setLoading(false);
                    ThongBao.printSuccessMessage("Khôi phục thành công.");
                }).catch(() => {
                    setLoading(false);
                })
            }
        });
    }

    const checkBoHandler = () => {

    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            loadCategory();
        }
    }

    return (
        <div className="card shadow mb-1">
            <div className="card-header py-2 row">
                <div className="col-md-4">
                    <h6 className="m-0 font-weight-bold text-primary">Cơ sở dữ liệu</h6>
                </div>
                <div className="input-group col-md-4">
                    <input type="text" className="form-control" placeholder="Tìm kiếm" value={filter} onChange={onChangeFilter} onKeyPress={handleKeyPress} />
                    <div className="input-group-append">
                        <button className="btn btn-info" type="button" onClick={timKiem}>Tìm kiếm</button>
                    </div>
                </div>
                <div className="col-md-4 bd-button text-right">
                    {roleAdd == true ? <Button variant="warning" size="sm" onClick={handleShowRestore} disabled={!isChecked}>Restore</Button> : ''}
                    {roleAdd == true ? <Button variant="success" size="sm" onClick={handleShowAdd}>Backup</Button> : ''}
                    {roleEdit == true ? <Button variant="primary" size="sm" onClick={handleShowEdit}>Sửa</Button> : ''}
                    {roleDel == true ? <Button variant="danger" size="sm" onClick={handleShowDel}>Xóa</Button> : ''}
                </div>
            </div>
            <div className="card-body">
                <div className="table-responsive">
                    <table className="table table-bordered" width="100%">
                        <thead>
                            <tr>
                                <th className="col-checkbox"></th>
                                <th>Tiêu đề</th>
                                <th>Ghi chú</th>
                                <th>Đường dẫn</th>
                                <th>Ngày</th>
                                <th>Trạng thái</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                listPostCategory.map((item, key) => {
                                    return (
                                        <tr onClick={() => clickRow(item.Id)} key={key}>
                                            <td className="col-checkbox">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" checked={item.Checked} onChange={checkBoHandler} />
                                                </div>
                                            </td>
                                            <td>{item.Ten}</td>
                                            <td>{item.GhiChu}</td>
                                            <td>{item.DuongDan}</td>
                                            <td>{moment(item.CreatedDate).format('DD/MM/YYYY HH:mm')}</td>
                                            <td className="text-center">
                                                {item.Status == true &&
                                                    <span className="label label-success cls-status-label">Kích hoạt</span>
                                                }
                                                {item.Status == false &&
                                                    <span className="label label-danger cls-status-label">Khóa</span>
                                                }
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <div className="col-md-12 text-right">
                    <Pagination {...paginationConfig} />
                </div>
            </div>

            <Modal show={show} onHide={handleClose} animation={false} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Backup cơ sở dữ liệu</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="user" onSubmit={handleSubmit(saveData)}>
                        <input name="Id" hidden={true} ref={register}></input>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Tiêu đề
                                <span className="text-danger"> *</span>
                            </label>
                            <div className="col-sm-9">
                                <input name="Ten" type="text" className="form-control" placeholder="Tiêu đề" ref={register({ required: true })} />
                                {errors.Ten && <span className="text-danger">Hãy nhập dữ liệu</span>}
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Ghi chú
                            </label>
                            <div className="col-sm-9">
                                <input name="GhiChu" type="text" className="form-control" placeholder="Ghi chú" ref={register} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Đường dẫn
                            </label>
                            <div className="col-sm-9">
                                <input name="DuongDan" type="text" className="form-control" placeholder="" ref={register} readOnly="true" />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label"></label>
                            <div className="col-sm-9">
                                <label className="custom-checkbox checkbox-style" >
                                    <input className="form-check-input" name="Status" type="checkbox" ref={register} />
                                    <span className="helping-el"></span> Kích hoạt
                                </label>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" size="sm" onClick={handleSubmit(saveData)}>
                        Lưu</Button>
                    <Button variant="danger" size="sm" onClick={handleClose}>
                        Hủy</Button>
                </Modal.Footer>
            </Modal>
            <Loading loading={loading}></Loading>
        </div>

    )
}
