const { default: axiosClient } = require("./axiosClient");

const PostCategoryApi = {
    getPaging: (params) => {
        const url = '/postcategory/paging';
        return axiosClient.get(url, {params});
    },

    getDetail: (id) =>{
        const url = '/postcategory/getbyid/' + id;
        return axiosClient.get(url);
    },

    post: (data) =>{
        const url = '/postcategory/add';
        return axiosClient.post(url, data);
    },

    put: (data) => {
        const url = '/postcategory/edit';
        return axiosClient.put(url, data);
    },

    delete: (id) =>{
        const url = '/postcategory/delete/' + id;
        return axiosClient.delete(url);
    },
    getAll: (params) =>{
        const url = 'postcategory/getall';
        return axiosClient.get(url, {params});
    },
    getMenu: (params) =>{
        const url = 'postcategory/getmenu';
        return axiosClient.get(url, {params});
    },
    getBreadcrumb: (params) =>{
        const url = 'postcategory/getbreadcrumb';
        return axiosClient.get(url, {params});
    },
}

export default PostCategoryApi;