const { default: axiosClient } = require("./axiosClient");

const LopBanDoApi = {
    getPaging: (params) => {
        const url = '/lopbando/paging';
        return axiosClient.get(url, {params});
    },

    getDetail: (id) =>{
        const url = '/lopbando/getbyid/' + id;
        return axiosClient.get(url);
    },

    getAll: () =>{
        const url = '/lopbando/getall';
        return axiosClient.get(url);
    },

    post: (data) =>{
        const url = '/lopbando/add';
        return axiosClient.post(url, data);
    },

    put: (data) => {
        const url = '/lopbando/edit';
        return axiosClient.put(url, data);
    },

    delete: (id) =>{
        const url = '/lopbando/delete?listId=' + id;
        return axiosClient.delete(url);
    },
    getLopBanDo: (params) =>{
        const url = 'lopbando/getlopbando';
        return axiosClient.get(url, {params});
    }
}

export default LopBanDoApi;