import React, { useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import Pagination from 'react-bootstrap-4-pagination';
import ThongBao from '../../../core/ThongBao';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Authen from '../../../core/Authen';
import Ultility from '../../../core/Ultility';
import { param, post } from 'jquery';
import { now } from 'moment';
import DienTichSanXuatApi from '../../../api/DienTichSanXuatApi';
import CayTrongApi from '../../../api/CayTrongApi';
import MuaVuApi from '../../../api/MuaVuApi';
import PhuongXaApi from '../../../api/PhuongXaApi';
import QuanHuyenApi from '../../../api/QuanHuyenApi';

export const DienTichSanXuat = () => {
    var apiUrl = process.env.REACT_APP_URL;
    var noImage = "/uploadfiles/website/no_image-100x100.jpg";

    const [roleAdd, setRoleAdd] = useState(false);
    const [roleEdit, setRoleEdit] = useState(false);
    const [roleDel, setRoleDel] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const { register, handleSubmit, reset, errors } = useForm();
    const [filter, setFilter] = useState('');
    const [srcImage, setSrcImage] = useState(noImage);
    const [viewModal, setViewModal] = useState(false);
    const [listDienTichSanXuats, setListDienTichSanXuats] = useState([]);
    const [dienTichSanXuatModal, setDienTichSanXuatModal] = useState({ DienTichSanXuat });

    const [selectedQuanHuyen, setSelectedQuanHuyen] = useState();
    const [danhMucQuanHuyen, setDanhMucQuanHuyen] = useState([]);
    const [selectedPhuongXa, setSelectedPhuongXa] = useState();
    const [danhMucPhuongXa, setDanhMucPhuongXa] = useState([]);
    const [selectedCay, setSelectedCay] = useState();
    const [danhMucCay, setDanhMucCay] = useState([]);
    const [selectedMuaVu, setSelectedMuaVu] = useState();
    const [danhMucMuaVu, setDanhMucMuaVu] = useState([]);

    const [timListQuanHuyen, setTimListQuanHuyen] = useState([]);
    const [timListPhuongXa, setTimListPhuongXa] = useState([]);
    const [timListCayTrong, setTimListCayTrong] = useState([]);
    const [timListMuaVu, setTimListMuaVu] = useState([]);
    const [timQuanHuyen, setTimQuanHuyen] = useState();
    const [timXaPhuong, setTimXaPhuong] = useState();
    const [timCayTrong, setTimCayTrong] = useState();
    const [timMuaVu, setTimMuaVu] = useState();
    const [timListNam, setTimListNam] = useState([]);
    const [timNam, setTimNam] = useState();
    const [timThang, setTimThang] = useState();
    const [timNgay, setTimNgay] = useState();
    const [isXem, setIsXem] = useState(false);
    var theme = {
        borderRadius: 0,
        height: 30,
        spacing: {
            controlHeight: 30,
            menuGutter: 1,
            baseUnit: 2,
        }
    }

    let pageIndex = 1;
    let pageSize = 20;

    const [paginationConfig, setPaging] = useState({
        totalPages: 1,
        currentPage: 1,
        showMax: 5,
        size: "sm",
        threeDots: true,
        prevNext: true,
        onClick: function (page) {
            pageIndex = page;
            loadDienTichSanXuat();
        }
    });

    useEffect(() => {
        loadDienTichSanXuat();
        loadQuanHuyen();
        loadDanhMucCay();
        loadDanhMucMuaVu();
        loadDanhMucNam();
        setRoleAdd(Authen.checkQuyen('DIENTICHSANXUAT_CREATE'));
        setRoleEdit(Authen.checkQuyen('DIENTICHSANXUAT_UPDATE'));
        setRoleDel(Authen.checkQuyen('DIENTICHSANXUAT_DELETE'));
    }, []);

    const loadDienTichSanXuat = async () => {
        try {
            const idQuanHuyen = timQuanHuyen == undefined ? '' : timQuanHuyen.value;
            const idXaPhuong = timXaPhuong == undefined ? '' : timXaPhuong.value;
            const idCayTrong = timCayTrong == undefined ? 0 : timCayTrong.value;
            const idMuaVu = timMuaVu == undefined ? 0 : timMuaVu.value;
            const nam = timNam == undefined ? 0 : timNam.value;
            const thang = timThang == undefined ? 0 : timThang;
            const ngay = timNgay == undefined ? 0 : timNgay;
            const params = {
                filter: filter,
                pageIndex: pageIndex,
                pageSize: pageSize,
                idQuanHuyen: idQuanHuyen,
                idXaPhuong: idXaPhuong,
                idCayTrong: idCayTrong,
                idMuaVu: idMuaVu,
                nam: nam,
                thang: thang,
                ngay: ngay
            }
            const response = await DienTichSanXuatApi.getPaging(params);
            response.Items.forEach(item => {
                item.Checked = false;
            });
            paginationConfig.totalPages = 1;
            if (response.Items.length > 0) {
                paginationConfig.totalPages = Math.ceil(response.TotalRows / pageSize);
            }
            paginationConfig.currentPage = pageIndex;
            setPaging(paginationConfig);
            setListDienTichSanXuats(response.Items);
            setIsEdit(false);
        } catch (err) {
        }
    }

    function loadQuanHuyen() {
        QuanHuyenApi.getAll().then((response) => {
            let list = [];
            list.push({ value: '', label: 'Chọn quận huyện...' });
            response.forEach(item => {
                list.push({ value: item.Ma, label: item.Ten });
            })
            setDanhMucQuanHuyen(list);
            setTimListQuanHuyen(list);
        })
    }

    function loadPhuongXa(idQuanHuyen, isTimKiem, maXa) {
        const params = {
            maHuyen: Number(idQuanHuyen.value == undefined ? '' : idQuanHuyen.value)
        }
        QuanHuyenApi.getXaByHuyen(params).then((response) => {
            let list = [];
            list.push({ value: '', label: 'Chọn phường xã...' });
            response.forEach(item => {
                list.push({ value: item.maXa, label: item.tenXa });
            })
            if (isTimKiem == true) {
                setTimListPhuongXa(list);
                setTimXaPhuong(list[0]);
            }
            else {
                setDanhMucPhuongXa(list);
                var index = list.findIndex(x => x.value == maXa);
                if (index >= 0) {
                    setSelectedPhuongXa(list[index]);
                }
                else {
                    setSelectedPhuongXa(list[0]);
                }
            }
        })
    }

    function loadDanhMucCay() {
        CayTrongApi.getAll().then((response) => {
            let list = [];
            list.push({ value: '', label: 'Chọn cây trồng...' });
            response.forEach(item => {
                list.push({ value: item.Id, label: item.TenCayTrong });
            })
            setDanhMucCay(list);
            setTimListCayTrong(list);
        })
    }

    function loadDanhMucMuaVu() {
        MuaVuApi.getAll().then((response) => {
            let list = [];
            list.push({ value: 0, label: 'Chọn mùa vụ...' });
            response.forEach(item => {
                list.push({ value: item.Id, label: item.Ten });
            })
            setDanhMucMuaVu(list);
            setTimListMuaVu(list);
        })
    }

    const loadDanhMucNam = () => {
        const endYear = new Date().getFullYear();
        let list = [];
        list.push({ value: 0, label: 'Chọn năm...' });
        for (let i = 2000; i <= endYear; i++) {
            list.push({ value: i, label: i });
        }
        setTimListNam(list);
    }

    const timKiem = () => {
        loadDienTichSanXuat();
    }

    function onChangeFilter(e) {
        setFilter(e.target.value)
    }

    const setSelectedToData = () => {
        dienTichSanXuatModal.IdCayTrong = null;
        dienTichSanXuatModal.IdMuaVu = null;
        const idQuanHuyen = selectedQuanHuyen == undefined ? '' : selectedQuanHuyen.value;
        const idXaPhuong = selectedPhuongXa == undefined ? '' : selectedPhuongXa.value;
        dienTichSanXuatModal.MaHuyen = idQuanHuyen;
        dienTichSanXuatModal.MaXa = idXaPhuong;
        if (selectedCay != undefined && selectedCay != null && selectedCay != 0) {
            dienTichSanXuatModal.IdCayTrong = selectedCay.value;
        }
        if (selectedMuaVu != undefined && selectedMuaVu != null && selectedMuaVu != 0) {
            dienTichSanXuatModal.IdMuaVu = selectedMuaVu.value;
        }
    }

    const saveData = (data) => {
        try {
            if (selectedCay == null || selectedCay.value == '') {
                ThongBao.prinWarningMessage("Bạn chưa nhập cây trồng.");
            }
            else {
                setSelectedToData();
                if (dienTichSanXuatModal.Id === 0 || dienTichSanXuatModal.Id == null) {
                    dienTichSanXuatModal.Id = 0;
                    dienTichSanXuatModal.CreatedDate = new Date();
                    DienTichSanXuatApi.post(dienTichSanXuatModal).then(() => {
                        setShow(false);
                        ThongBao.printSuccessMessage("Đã cập nhật thành công");
                        loadDienTichSanXuat();
                    })
                }
                else {
                    dienTichSanXuatModal.UpdatedDate = new Date();
                    DienTichSanXuatApi.put(dienTichSanXuatModal).then(() => {
                        setShow(false);
                        ThongBao.printSuccessMessage("Đã cập nhật thành công");
                        loadDienTichSanXuat();
                    })
                }
            }
        }
        catch (ex) {
            console.log(ex);
        }
    }

    const clickRow = (id) => {
        listDienTichSanXuats.forEach(element => {
            if (element.Id === id) {
                element.Checked = !element.Checked;
            }
        })
        let index = listDienTichSanXuats.findIndex(x => x.Checked);
        if (index >= 0) {
            setIsEdit(true);
        }
        else {
            setIsEdit(false);
        }
        setListDienTichSanXuats([...listDienTichSanXuats]);
    }

    const checkBoHandler = () => {

    }

    const handleShowAdd = () => {
        reset({
            Id: 0,
            TenCayTrong: "",
            Status: true
        }, [reset]);
        setDienTichSanXuatModal({
            Id: 0,
            TenCayTrong: "",
            Status: true
        });
        setIsXem(false);
        setSrcImage(noImage);
        setSelectedQuanHuyen(null);
        setSelectedPhuongXa(null);
        setSelectedCay(null);
        setSelectedMuaVu(null);
        setShow(true);
    };

    const handleShowEdit = (isXem) => {
        let index = listDienTichSanXuats.findIndex(x => x.Checked);
        if (index !== -1) {
            DienTichSanXuatApi.getDetail(listDienTichSanXuats[index].Id).then((response) => {
                reset({
                    Id: response.Id,
                    IdQuanHuyen: response.IdQuanHuyen,
                    IdXaPhuong: response.IdXaPhuong,
                    Ngay: response.Ngay,
                    Thang: response.Thang,
                    Nam: response.Nam,
                    IdCayTrong: response.IdCayTrong,
                    IdMuaVu: response.IdMuaVu,
                    GiongCays: response.GiongCays,
                    LoaiDat: response.LoaiDat,
                    DienTich: response.DienTich,
                    NangSuat: response.NangSuat,
                    SanLuong: response.SanLuong,
                    GhiChu: response.GhiChu,
                    Status: response.Status,
                    Delete: response.Delete,
                    CreatedBy: response.CreatedBy,
                    CreatedDate: response.CreatedDate,
                    UpdatedBy: response.UpdatedBy,
                    UpdatedDate: response.UpdatedDate
                }, [reset]);
                setDienTichSanXuatModal(response);
                setShow(true);
                setIsEdit(true);
                setIsXem(isXem);
                setSelectedQuanHuyen(null);
                setSelectedPhuongXa(null);
                setSelectedCay(null);
                setSelectedMuaVu(null);
                var idQuan = danhMucQuanHuyen.findIndex(x => x.value == response.MaHuyen);
                if (idQuan >= 0) {
                    setSelectedQuanHuyen(danhMucQuanHuyen[idQuan]);
                }
                loadPhuongXa({ value: response.MaHuyen }, false, response.MaXa)
                var idCay = danhMucCay.findIndex(x => x.value == response.IdCayTrong);
                if (idCay >= 0) {
                    setSelectedCay(danhMucCay[idCay]);
                }
                var idMua = danhMucMuaVu.findIndex(x => x.value == response.IdMuaVu);
                if (idMua > 0) {
                    setSelectedMuaVu(danhMucMuaVu[idMua]);
                }
            });
        }
    }

    const handleShowDel = () => {
        ThongBao.printConfirmationDialog("Bạn có đồng ý xóa dữ liệu đã chọn hay không?", () => {
            let lstChecked = listDienTichSanXuats.filter(x => x.Checked);
            if (lstChecked.length > 0) {
                let lstId = [];
                lstChecked.forEach(element => {
                    lstId.push(element.Id);
                });
                DienTichSanXuatApi.delete(lstId).then(() => {
                    loadDienTichSanXuat();
                    setIsEdit(false);
                })
            }
        });
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            loadDienTichSanXuat();
        }
    }

    const openModalImage = () => {
        setViewModal(true);
    }


    const closeModalImage = () => {
        setViewModal(false);
    }

    const onFileSelected = (event) => {
        setSrcImage(event);
    }

    const changeTab = () => {
        reset({
            Id: dienTichSanXuatModal.Id,
            IdQuanHuyen: dienTichSanXuatModal.IdQuanHuyen,
            IdXaPhuong: dienTichSanXuatModal.IdXaPhuong,
            Ngay: dienTichSanXuatModal.Ngay,
            Thang: dienTichSanXuatModal.Thang,
            Nam: dienTichSanXuatModal.Nam,
            IdCayTrong: dienTichSanXuatModal.IdCayTrong,
            IdMuaVu: dienTichSanXuatModal.IdMuaVu,
            GiongCays: dienTichSanXuatModal.GiongCays,
            LoaiDat: dienTichSanXuatModal.LoaiDat,
            DienTich: dienTichSanXuatModal.DienTich,
            NangSuat: dienTichSanXuatModal.NangSuat,
            SanLuong: dienTichSanXuatModal.SanLuong,
            GhiChu: dienTichSanXuatModal.GhiChu,
            Status: dienTichSanXuatModal.Status,
            Delete: dienTichSanXuatModal.Delete,
            CreatedBy: dienTichSanXuatModal.CreatedBy,
            CreatedDate: dienTichSanXuatModal.CreatedDate,
            UpdatedBy: dienTichSanXuatModal.UpdatedBy,
            UpdatedDate: dienTichSanXuatModal.UpdatedDate
        }, [reset])
    }

    const onChangeDanhMucQuanHuyen = (e) => {
        setSelectedQuanHuyen(e);
        loadPhuongXa(e, false, null);
    }

    const onChangeDanhMucPhuongXa = (e) => {
        setSelectedPhuongXa(e);
    }

    const onChangeDanhMucCay = (e) => {
        setSelectedCay(e);
    }

    const onChangeDanhMucMuaVu = (e) => {
        setSelectedMuaVu(e);
    }

    // TÌM KIẾM
    const onChangeTimQuanHuyen = (e) => {
        setTimQuanHuyen(e);
        loadPhuongXa(e, true, null);
    }

    const onChangeTimXaPhuong = (e) => {
        setTimXaPhuong(e);
    }

    const onChangeTimCayTrong = (e) => {
        setTimCayTrong(e);
    }

    const onChangeTimMuaVu = (e) => {
        setTimMuaVu(e);
    }

    const onChangeTimNam = (e) => {
        setTimNam(e);
    }

    return (
        <div className="card shadow mb-1">
            <div className="card-header py-2 ">
                <div className="row">
                    <div className="col-md-2">
                        <h6 className="m-0 font-weight-bold text-primary">Diện tích sản xuất</h6>
                    </div>
                    <div className="col-md-2">
                        <Select placeholder="Cây trồng" theme={theme}
                            onChange={onChangeTimCayTrong} options={timListCayTrong} value={timCayTrong} />
                    </div>
                    <div className="col-md-2">
                        <Select placeholder="Mùa vụ" theme={theme}
                            onChange={onChangeTimMuaVu} options={timListMuaVu} value={timMuaVu} />
                    </div>
                    <div className="col-md-3 input-group ">
                        <input type="text" className="form-control" placeholder="Tìm kiếm" value={filter} onChange={onChangeFilter} onKeyPress={handleKeyPress} />
                        <div className="input-group-append">
                            <button className="btn btn-info" type="button" onClick={timKiem}><i className='fas fa-search'></i> Tìm kiếm</button>
                        </div>
                    </div>
                    <div className="col-md-3 bd-button text-right">
                        {roleAdd == true ? <Button variant="success" size="sm" onClick={handleShowAdd}><i className='fas fa-plus'></i> Thêm</Button> : ''}
                        {roleEdit == true ? <Button variant="primary" size="sm" onClick={() => handleShowEdit(false)} disabled={!isEdit}>
                            <i className='far fa-edit'></i> Sửa</Button> : ''}
                        {roleDel == true ? <Button variant="danger" size="sm" onClick={handleShowDel} disabled={!isEdit}>
                            <i className='fas fa-trash-alt' ></i> Xóa</Button> : ''}
                        <button type="button" className="btn btn-primary btn-sm ml-1 mr-2" disabled={!isEdit} onClick={() => handleShowEdit(true)}>
                            <i className='far fa-eye'></i> Xem</button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-2">
                    </div>
                    <div className="col-md-2">
                        <Select placeholder="Năm" theme={theme}
                            onChange={onChangeTimNam} options={timListNam} value={timNam} />
                    </div>
                    <div className="col-md-2">
                        <Select placeholder="Quận" theme={theme}
                            onChange={onChangeTimQuanHuyen} options={timListQuanHuyen} value={timQuanHuyen} />
                    </div>
                    <div className="col-md-3">
                        <Select placeholder="Phường xã" theme={theme}
                            onChange={onChangeTimXaPhuong} options={timListPhuongXa} value={timXaPhuong} />
                    </div>
                </div>
            </div>
            <div className="card-body">
                <div className="">
                    <table className="table table-bordered" width="100%">
                        <thead>
                            <tr>
                                <th className="col-checkbox"></th>
                                <th>Quận, huyện</th>
                                <th>Xã, Thị trấn</th>
                                <th>Cây trồng</th>
                                <th>Mùa vụ</th>
                                <th>Giống cây</th>
                                <th>Loại đất</th>
                                <th>Diện tích(ha)</th>
                                <th>Tổng diện tích</th>
                                <th>Năng suất</th>
                                <th>Sản lượng(tấn)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                listDienTichSanXuats.map((item, key) => {
                                    return (
                                        <tr onClick={() => clickRow(item.Id)} key={key}>
                                            <td className="col-checkbox">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" checked={item.Checked}
                                                        onChange={checkBoHandler} />
                                                </div>
                                            </td>
                                            <td>{item.TenQuanHuyen}</td>
                                            <td>{item.TenXaPhuong}</td>
                                            <td>{item.TenCayTrong}</td>
                                            <td>{item.TenMuaVu}</td>
                                            <td>{item.GiongCays}</td>
                                            <td>{item.LoaiDat}</td>
                                            <td>{item.DienTich}</td>
                                            <td>{item.TongDienTich}</td>
                                            <td>{item.NangSuat}</td>
                                            <td>{item.SanLuong}</td>
                                            <td className="text-center">
                                                {item.Status == true &&
                                                    <span className="label label-success cls-status-label">Kích hoạt</span>
                                                }
                                                {item.Status == false &&
                                                    <span className="label label-danger cls-status-label">Khóa</span>
                                                }

                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <div className="col-md-12 text-right">
                    <Pagination {...paginationConfig} />
                </div>
            </div>

            <Modal show={show} onHide={handleClose} animation={false} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Chi tiết</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="user" onSubmit={handleSubmit(saveData)}>
                        <input name="Id" hidden={true} ref={register} onChange={e => dienTichSanXuatModal.Id = e.target.value} ></input>
                        <div className="form-group row">
                            <label className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">Quận huyện</label>
                            <div className="col-sm-4">
                                <Select
                                    className="select"
                                    name="IdQuanHuyen"
                                    placeholder="Chọn quận huyện"
                                    value={selectedQuanHuyen}
                                    onChange={onChangeDanhMucQuanHuyen}
                                    options={danhMucQuanHuyen}
                                    ref={register({ required: true })}
                                    theme={theme}
                                />
                            </div>
                            <label className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">Phường xã</label>
                            <div className="col-sm-4">
                                <Select
                                    className="select"
                                    name="IdXaPhuong"
                                    placeholder="Chọn phường xã"
                                    value={selectedPhuongXa}
                                    onChange={onChangeDanhMucPhuongXa}
                                    options={danhMucPhuongXa}
                                    ref={register({ required: true })}
                                    theme={theme}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">Ngày/tháng/năm</label>
                            <div className="col-sm-2">
                                <input name="Ngay" type="text" className="form-control" placeholder="Nhập ngày" ref={register} onChange={e => dienTichSanXuatModal.Ngay = e.target.value} />
                            </div>
                            <div className="col-sm-2">
                                <input name="Thang" type="text" className="form-control" placeholder="Nhập tháng" ref={register} onChange={e => dienTichSanXuatModal.Thang = e.target.value} />
                            </div>
                            <div className="col-sm-2">
                                <input name="Nam" type="text" className="form-control" placeholder="Nhập năm" ref={register} onChange={e => dienTichSanXuatModal.Nam = e.target.value} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">Cây trồng
                                <code> *</code>
                            </label>
                            <div className="col-sm-4">
                                <Select
                                    className="select"
                                    name="IdCayTrong"
                                    placeholder="Chọn cây trồng"
                                    value={selectedCay}
                                    onChange={onChangeDanhMucCay}
                                    options={danhMucCay}
                                    ref={register({ required: true })}
                                    theme={theme}
                                />
                            </div>
                            <label className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">Mùa vụ</label>
                            <div className="col-sm-4">
                                <Select
                                    className="select"
                                    name="IdMuaVu"
                                    placeholder="Chọn mùa vụ"
                                    value={selectedMuaVu}
                                    onChange={onChangeDanhMucMuaVu}
                                    options={danhMucMuaVu}
                                    ref={register({ required: true })}
                                    theme={theme}
                                />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">Giống cây</label>
                            <div className="col-sm-10">
                                <input name="GiongCays" type="text" className="form-control" placeholder="Giống chính" ref={register} onChange={e => dienTichSanXuatModal.GiongCays = e.target.value} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">Loại đất</label>
                            <div className="col-sm-4">
                                <input name="LoaiDat" type="text" className="form-control" placeholder="Nhập loại đất" ref={register} onChange={e => dienTichSanXuatModal.LoaiDat = e.target.value} />
                            </div>
                            <label className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">Diện tích (ha)</label>
                            <div className="col-sm-4">
                                <input name="DienTich" type="text" className="form-control" placeholder="Nhập diện tích (ha)" ref={register} onChange={e => dienTichSanXuatModal.DienTich = e.target.value} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">Năng suất</label>
                            <div className="col-sm-4">
                                <input name="NangSuat" type="text" className="form-control" placeholder="Nhập năng suất" ref={register} onChange={e => dienTichSanXuatModal.NangSuat = e.target.value} />
                            </div>
                            <label className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">Sản lượng (tấn)</label>
                            <div className="col-sm-4">
                                <input name="SanLuong" type="text" className="form-control" placeholder="Nhập sản lượng (tấn)" ref={register} onChange={e => dienTichSanXuatModal.SanLuong = e.target.value} />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label className="col-md-2 col-sm-6 col-form-label nhanLH">Ghi chú</label>
                            <div className="col-sm-10">
                                <textarea name="GhiChu" className="form-control textrea-size" rows="2" placeholder="Nhập ghi chú"
                                    ref={register} onChange={e => dienTichSanXuatModal.GhiChu = e.target.value} ></textarea>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH"></label>
                            <div className="col-sm-10">
                                <label className="custom-checkbox checkbox-style" >
                                    <input className="form-check-input" name="Status" type="checkbox" ref={register}
                                        onChange={e => dienTichSanXuatModal.Status = e.target.checked} />
                                    <span className="helping-el"></span> Kích hoạt
                                    </label>
                            </div>
                        </div>

                    </form>
                </Modal.Body>
                <Modal.Footer>
                    {isXem==false && <Button variant="success" size="sm" onClick={handleSubmit(saveData)}>
                        Lưu</Button>
                    }
                    <Button variant="danger" size="sm" onClick={handleClose}>
                        Hủy</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )

}