import React, { useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import HoiDapApi from '../../../api/HoiDapApi';
import Select from 'react-select';
import Pagination from 'react-bootstrap-4-pagination';
import ThongBao from '../../../core/ThongBao';
import { QuanLyTapTin } from '../../../shared/quanlytaptin/QuanLyTapTin';
import 'react-tabs/style/react-tabs.css';
import CayTrongApi from '../../../api/CayTrongApi';
import Authen from '../../../core/Authen';
import moment from 'moment'
import { TraLoi } from './traloi'

export const HoiDap = () => {
    var apiUrl = process.env.REACT_APP_URL;
    var noImage = "/uploadfiles/website/no_image-100x100.jpg";
    const [listHoiDaps, setListHoiDaps] = useState([]);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const { register, handleSubmit, reset, errors } = useForm();
    const [filter, setFilter] = useState('');
    const [listCayTrong, setListCayTrong] = useState([]);
    const [selectedOption, setSelectedOption] = useState();
    const [srcImage, setSrcImage] = useState(noImage);
    const [viewModal, setViewModal] = useState(false);
    const [roleAdd, setRoleAdd] = useState(false);
    const [roleEdit, setRoleEdit] = useState(false);
    const [roleDel, setRoleDel] = useState(false);
    const [checkedRow, setCheckedRow] = useState(false);
    const [currentItem, setCurrentItem] = useState({});
    const [idHoiDap, setIdHoiDap] = useState();
    const [showTraLoi, setShowTraLoi] = useState(false);
    const [isAdminCreated, setIsAdminCreated] = useState(false);
    let pageIndex = 1;
    let pageSize = 20;
    const [paginationConfig, setPaging] = useState({
        totalPages: 1,
        currentPage: 1,
        showMax: 5,
        size: "sm",
        threeDots: true,
        prevNext: true,
        onClick: function (page) {
            pageIndex = page;
            loadHoiDaps();
        }
    });

    useEffect(() => {
        loadListCayTrong();
        loadHoiDaps();
        setRoleAdd(Authen.checkQuyen('HOIDAP_CREATE'));
        setRoleEdit(Authen.checkQuyen('HOIDAP_UPDATE'));
        setRoleDel(Authen.checkQuyen('HOIDAP_DELETE'));
    }, []);

    const loadHoiDaps = async () => {
        try {
            const params = {
                pageIndex: pageIndex,
                pageSize: pageSize,
                filter: filter,
                idCayTrong: ''
            }
            const response = await HoiDapApi.getPaging(params);
            response.Items.forEach(item => {
                item.Checked = false;
            });
            paginationConfig.totalPages = 1;
            if (response.Items.length > 0) {
                paginationConfig.totalPages = Math.ceil(response.TotalRow / pageSize);
            }
            paginationConfig.currentPage = pageIndex;
            paginationConfig.onClick = function (page) {
                pageIndex = page;
                loadHoiDaps();
            }
            setPaging(paginationConfig);
            setListHoiDaps(response.Items);
            setCheckedRow(false);
        } catch (err) {
        }
    }

    const saveData = (data) => {
        data.HinhAnhs = srcImage;
        if (selectedOption) {
            if (selectedOption.value === 0) {
                data.IdCayTrong = null;
            }
            else {
                data.IdCayTrong = selectedOption.value;
            }
        }
        else {
            data.IdCayTrong = null;
        }
        if (data.Id === "0" || data.Id === null) {
            data.Id = 0;
            HoiDapApi.post(data).then(() => {
                setShow(false);
                loadHoiDaps();
            })
        }
        else {
            HoiDapApi.put(data).then(() => {
                setShow(false);
                loadHoiDaps();
            })
        }
    }

    const handleShowAdd = () => {
        var item = {
            Id: 0,
            ChuDe: '',
            NoiDung: '',
            GhiChu: '',
            HoTen: '',
            Email: '',
            Tel: '',
            Linked: 0,
            Shared: 0,
            Viewed: 0,
            Status: true
        };
        reset(item, [reset]);
        setSelectedOption(listCayTrong[0]);
        setSrcImage(noImage);
        setCurrentItem(item);
        setIsAdminCreated(true);
        setShow(true)
    };

    const handleShowEdit = () => {
        let index = listHoiDaps.findIndex(x => x.Checked);
        if (index !== -1) {
            HoiDapApi.getDetail(listHoiDaps[index].Id).then((response) => {
                reset(response, [reset]);
                setCurrentItem(response);
                var index = listCayTrong.findIndex(x => x.value === response.IdCayTrong);
                setSelectedOption(listCayTrong[index]);
                setSrcImage(response.HinhAnhs);
                setIsAdminCreated(response.CreatedBy !== null);
                setShow(true);
            });
        }
    }

    const handleShowDel = () => {
        ThongBao.printConfirmationDialog("Bạn có đồng ý xóa dữ liệu đã chọn hay không?", () => {
            let lstChecked = listHoiDaps.filter(x => x.Checked);
            if (lstChecked.length > 0) {
                let lstId = [];
                lstChecked.forEach(element => {
                    lstId.push(element.Id);
                });
                HoiDapApi.delete(lstId).then(() => {
                    loadHoiDaps();
                })
            }
        });
    }

    const timKiem = () => {
        loadHoiDaps();
    }

    function onChangeFilter(e) {
        setFilter(e.target.value)
    }

    const clickRow = (id) => {
        listHoiDaps.forEach(item => {
            if (item.Id === id) {
                item.Checked = !item.Checked;
            }
        });
        if (listHoiDaps.findIndex(x => x.Checked === true) >= 0) {
            setCheckedRow(true);
        }
        else {
            setCheckedRow(false);
        }
        setListHoiDaps([...listHoiDaps]);
    }

    const checkBoHandler = () => {

    }

    function loadListCayTrong() {
        CayTrongApi.getAll().then((response) => {
            let list = [];
            list.push({ value: 0, label: 'Chọn cây trồng...' });
            response.forEach(item => {
                list.push({ value: item.Id, label: item.TenCayTrong });
            })
            setListCayTrong(list);
        })
    }

    const onChangeCayTrong = (e) => {
        setSelectedOption(e);
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            loadHoiDaps();
        }
    }

    const openModalImage = () => {
        setViewModal(true);
    }

    const closeModalImage = () => {
        setViewModal(false);
    }

    const onFileSelected = (event) => {
        setSrcImage(event);
    }

    const colNgay_Style = {
        width: '120px',
        maxWidth: '150px'
    }

    const showModalTraLoi = () => {
        var lst = listHoiDaps.filter(x => x.Checked);
        if (lst.length > 0) {
            setShowTraLoi(true);
            setIdHoiDap(lst[0].Id);
        }
    }

    const closeModalTraLoi = () => {
        setShowTraLoi(false);
    }
    return (
        <div className="card shadow mb-1">
            <div className="card-header py-2 row">
                <div className="col-md-4">
                    <h6 className="m-0 font-weight-bold text-primary">Hỏi đáp</h6>
                </div>
                <div className="input-group col-md-4">
                    <input type="text" className="form-control" placeholder="Tìm kiếm" value={filter} onChange={onChangeFilter} onKeyPress={handleKeyPress} />
                    <div className="input-group-append">
                        <button className="btn btn-info" type="button" onClick={timKiem}><i className='fas fa-search'></i> Tìm kiếm</button>
                    </div>
                </div>
                <div className="col-md-4 bd-button text-right">
                    {roleAdd === true ? <Button variant="warning" size="sm" onClick={showModalTraLoi}><i className='fas fa-plus'></i> Trả lời</Button> : ''}
                    {roleAdd === true ? <Button variant="success" size="sm" onClick={handleShowAdd}><i className='fas fa-plus'></i> Thêm</Button> : ''}
                    {roleEdit === true ? <Button variant="primary" size="sm" onClick={handleShowEdit} disabled={!checkedRow}><i className='far fa-edit'></i> Sửa</Button> : ''}
                    {roleDel === true ? <Button variant="danger" size="sm" onClick={handleShowDel} disabled={!checkedRow}><i className='fas fa-trash-alt'></i> Xóa</Button> : ''}
                </div>
            </div>
            <div className="card-body">
                <div className="">
                    <table className="table table-bordered" width="100%">
                        <thead>
                            <tr>
                                <th className="col-checkbox"></th>
                                <th>Hình ảnh</th>
                                <th>Chủ đề</th>
                                <th>Nội dung</th>
                                <th>Họ tên</th>
                                <th>Email</th>
                                <th style={colNgay_Style}>Ngày tạo</th>
                                <th style={colNgay_Style}>Trạng thái</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                listHoiDaps.map((item, key) => {
                                    return (
                                        <tr onClick={() => clickRow(item.Id)} key={key}>
                                            <td className="col-checkbox">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" checked={item.Checked} onChange={checkBoHandler} />
                                                </div>
                                            </td>
                                            <td className="col-img"><img src={apiUrl + item.HinhAnhs}></img></td>
                                            <td>{item.ChuDe}</td>
                                            <td>{item.NoiDung}</td>
                                            <td>{item.HoTen}</td>
                                            <td>{item.Email}</td>
                                            <td>{item.CreatedDate===''?'':moment(item.CreatedDate,"DD/MM/YYYY HH:mm:ss").format("DD/MM/YYYY HH:mm")}</td>
                                            <td className="text-center">
                                                {item.Status === true &&
                                                    <span className="label label-success cls-status-label">Kích hoạt</span>
                                                }
                                                {item.Status === false &&
                                                    <span className="label label-danger cls-status-label">Khóa</span>
                                                }

                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <div className="col-md-12 text-right">
                    <Pagination {...paginationConfig} />
                </div>
            </div>

            <Modal show={show} onHide={handleClose} animation={false} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Hỏi đáp</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="user" onSubmit={handleSubmit(saveData)}>
                        <input name="Id" hidden={true} ref={register} ></input>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Cây trồng
                                <span className="text-danger"> *</span>
                            </label>
                            <div className="col-sm-9">
                                <Select className="select" name="IdCayTrong" placeholder="Danh mục cây trồng" value={selectedOption} onChange={onChangeCayTrong} options={listCayTrong} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Chủ đề
                                <span className="text-danger"> *</span>
                            </label>
                            <div className="col-sm-9">
                                <input name="ChuDe" type="text" className="form-control" placeholder="Chủ đề" ref={register({ required: true })} />
                                {errors.Name && <span className="text-danger">Hãy nhập dữ liệu</span>}
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Nội dung
                                <span className="text-danger">*</span>
                            </label>
                            <div className="col-sm-9">
                                <textarea name="NoiDung" className="form-control textrea-size" rows="8" placeholder="Nhập nội dung" ref={register({ required: true })}  ></textarea>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Hình ảnh</label>
                            <div className="col-sm-9">
                                <div className="thumbnail" >
                                    <img name="HinhAnhs" ref={register} src={apiUrl + srcImage} onClick={openModalImage} />
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Ghi chú </label>
                            <div className="col-sm-9">
                                <input name="GhiChu" type="text" className="form-control" placeholder="Ghi chú" ref={register} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Trạng thái</label>
                            <div className="col-sm-9">
                                <label className="custom-checkbox checkbox-style" >
                                    <input className="form-check-input" name="Status" type="checkbox" ref={register} />
                                    <span className="helping-el"></span> Kích hoạt
                                    </label>
                            </div>
                        </div>
                        {isAdminCreated === false &&
                            <>
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label">Họ tên
                                <span className="text-danger"> *</span>
                                    </label>
                                    <div className="col-sm-9">
                                        <input name="HoTen" type="text" className="form-control" placeholder="Họ tên" ref={register({ required: true })} />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label">Email
                                <span className="text-danger"> *</span>
                                    </label>
                                    <div className="col-sm-9">
                                        <input name="Email" type="text" className="form-control" placeholder="Email" ref={register({ required: true })} />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label">Điện thoại </label>
                                    <div className="col-sm-9">
                                        <input name="Tel" type="text" className="form-control" placeholder="Điện thoại" ref={register} />
                                    </div>
                                </div>
                            </>
                        }
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label"></label>
                            <label className="col-sm-3">
                                <span className="label label-success cls-status-label" >{currentItem.Linked} Thích</span>
                                <span className="label label-primary cls-status-label" >{currentItem.Shared} Chia sẻ</span>
                                <span className="label label-warning cls-status-label" >{currentItem.Linked} Trả lời...</span>
                            </label>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="warning" size="sm" onClick={() => showModalTraLoi()} disabled={currentItem.Id === "0" ? true : false} >
                        Trả lời</Button>
                    <Button variant="success" size="sm" onClick={handleSubmit(saveData)}>
                        Lưu</Button>
                    <Button variant="danger" size="sm" onClick={handleClose}>
                        Hủy</Button>
                </Modal.Footer>
            </Modal>
            <QuanLyTapTin viewModal={viewModal} closeModal={closeModalImage} onFileSelected={(e) => onFileSelected(e)}></QuanLyTapTin>
            <TraLoi show={showTraLoi} idHoiDap={idHoiDap} closeModal={closeModalTraLoi}></TraLoi>
        </div>
    )
}
