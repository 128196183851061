import React, { useState, useEffect } from 'react'
import Select from 'react-select';
import Pagination from 'react-bootstrap-4-pagination';
import CayTrongApi from '../../../api/CayTrongApi';
import MuaVuApi from '../../../api/MuaVuApi';
import PhuongXaApi from '../../../api/PhuongXaApi';
import QuanHuyenApi from '../../../api/QuanHuyenApi';
import AnhHuongSauBenhApi from '../../../api/AnhHuongSauBenhApi';
import { Bar } from "react-chartjs-2";
import Chart from 'chart.js';
import ExportExcelApi from '../../../api/ExportExcelApi';
import NumberFormat from 'react-number-format';



export const ThongKeAnhHuongSauBenh = (props) => {
    const [listPhuongXas, setListPhuongXa] = useState([]);
    const [listAnhHuongSauBenh, setListAnhHuongSauBenh] = useState([]);
    const [timListQuanHuyen, setTimListQuanHuyen] = useState([]);
    const [timListPhuongXa, setTimListPhuongXa] = useState([]);
    const [timListCayTrong, setTimListCayTrong] = useState([]);
    const [timListMuaVu, setTimListMuaVu] = useState([]);
    const [timQuanHuyen, setTimQuanHuyen] = useState();
    const [timXaPhuong, setTimXaPhuong] = useState();
    const [timCayTrong, setTimCayTrong] = useState();
    const [timMuaVu, setTimMuaVu] = useState();
    const [timListNam, setTimListNam] = useState([]);
    const [timNam, setTimNam] = useState();

    const [search, setSearch] = useState('');
    var [dataReponse, setDataReponse] = useState({});
    const [bieudo, setBieuDo] = useState({});
    const [listLoaiBieuDo, setListLoaiBieuDo] = useState([]);
    const [loaiBieuDo, setLoaiBieuDo] = useState();

    let pageIndex = 1;
    let pageSize = 20;

    const [paginationConfig, setPaginationConfig] = useState({
        totalPages: 1,
        currentPage: 1,
        showMax: 5,
        size: "sm",
        threeDots: true,
        prevNext: true,
        onClick: function (page) {
            pageIndex = page;
            loadAnhHuongSauBenh();
        }
    });

    useEffect(() => {
        loadAnhHuongSauBenh(props.mahuyen, props.maxa);
        loadDanhQuanHuyen();
        loadDanhPhuongXa();
        loadDanhMucMuaVu();
        loadDanhMucNam();
    }, []);

    const loadAnhHuongSauBenh = async (mahuyen = '', maxa = '') => {
        try {
            const maquanhuyen = mahuyen != '' ? mahuyen : (timQuanHuyen === undefined ? '' : timQuanHuyen.value);
            const maxaphuong = maxa != '' ? maxa : (timXaPhuong === undefined ? '' : timXaPhuong.value);
            const idCayTrong = timCayTrong == undefined ? 0 : timCayTrong.value;
            const idMuaVu = timMuaVu == undefined ? 0 : timMuaVu.value;
            const nam = timNam == undefined ? 0 : timNam.value;

            const params = {
                filter: search,
                pageIndex: pageIndex,
                pageSize: pageSize,
                maquanhuyen: maquanhuyen,
                maxaphuong: maxaphuong,
                idcaytrong: idCayTrong,
                idmuavu: idMuaVu,
                nam: nam
            }
            const response = await AnhHuongSauBenhApi.getThongKe(params);
            setListAnhHuongSauBenh(response.timkiem.Items);
            paginationConfig.totalPages = Math.ceil(response.timkiem.TotalRows == 0 ? 1 : response.timkiem.TotalRows / pageSize);
            paginationConfig.currentPage = pageIndex;
            setPaginationConfig(paginationConfig);

            let lstCayTrong = [];
            if (timListCayTrong.length == 0) {
                await CayTrongApi.getAll().then((cay) => {
                    let list = [];
                    let listKieuBD = [];
                    list.push({ value: 0, label: 'Chọn cây trồng...' });
                    cay.forEach(item => {
                        list.push({ value: item.Id, label: item.TenCayTrong, ma: item.MaCayTrong });
                        listKieuBD.push({ value: item.MaCayTrong, label: item.TenCayTrong });
                    });
                    lstCayTrong = listKieuBD.map(x => x);
                    setListLoaiBieuDo(listKieuBD);
                    setTimListCayTrong(list);
                    setLoaiBieuDo(listKieuBD[0]);
                });
            }
            else {
                lstCayTrong = timListCayTrong;
            }
            dataReponse = response;
            setDataReponse(dataReponse);
            const maCay = timListCayTrong.length == 0 ? lstCayTrong[0].value : listLoaiBieuDo[0].value;
            buildBieuDo(maCay);
        }
        catch (err) { }
    }

    const ExportExcel = async (mahuyen = '', maxa = '') => {
        try {
            const maquanhuyen = mahuyen != '' ? mahuyen : (timQuanHuyen === undefined ? '' : timQuanHuyen.value);
            const maxaphuong = maxa != '' ? maxa : (timXaPhuong === undefined ? '' : timXaPhuong.value);
            const idCayTrong = timCayTrong == undefined ? 0 : timCayTrong.value;
            const idMuaVu = timMuaVu == undefined ? 0 : timMuaVu.value;
            const nam = timNam == undefined ? 0 : timNam.value;

            const params = {
                filter: search,
                pageIndex: pageIndex,
                pageSize: pageSize,
                maquanhuyen: maquanhuyen,
                maxaphuong: maxaphuong,
                idcaytrong: idCayTrong,
                idmuavu: idMuaVu,
                nam: nam
            }

            ExportExcelApi.getAnhHuongSauBenh(params).then((res) => {
                const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;

                a.download = 'download.xlsx';
                a.click();
            }, () => {

            });
        } catch (error) {

        }
    }

    const buildBieuDo = (maCay) => {
        let datasets = [];
        const endYear = new Date().getFullYear();
        if (timNam != undefined && timNam.value != 0) {
            endYear = timNam.value;
        }
        let labels = [];
        for (let i = 0; i < 5; i++) {
            labels.push(endYear - 4 + i);
        }
        let dataNhe = [];
        let dataTrungBinh = [];
        let dataNang = [];
        let dataMatTrang = [];
        let dataPhongTru = [];
        labels.forEach(nam => {
            const index = dataReponse.bieudo.findIndex(x => x.MaCayTrong == maCay && x.Nam == nam);
            dataNhe.push(index == -1 ? 0 : dataReponse.bieudo[index].DienTichNhe);
            dataTrungBinh.push(index == -1 ? 0 : dataReponse.bieudo[index].DienTichTrungBinh);
            dataNang.push(index == -1 ? 0 : dataReponse.bieudo[index].DienTichNang);
            dataMatTrang.push(index == -1 ? 0 : dataReponse.bieudo[index].DienTichMatTrang);
            dataPhongTru.push(index == -1 ? 0 : dataReponse.bieudo[index].DienTichPhongTru);
        });
        datasets.push({
            label: 'Diện tích nhẹ',
            data: dataNhe,
            backgroundColor: '#d2ef7a'
        });
        datasets.push({
            label: 'Diện tích trung bình',
            data: dataTrungBinh,
            backgroundColor: '#f5ea6e'
        });
        datasets.push({
            label: 'Diện tích nặng',
            data: dataNang,
            backgroundColor: '#f5ad29'
        });
        datasets.push({
            label: 'Diện tích mất trắng',
            data: dataMatTrang,
            backgroundColor: '#e01313'
        });
        datasets.push({
            label: 'Diện tích phòng trừ',
            data: dataPhongTru,
            backgroundColor: '#14e053'
        });
        const bd = {
            labels: labels,
            datasets: datasets
        }
        setBieuDo(bd);
    }

    function loadDanhQuanHuyen() {
        QuanHuyenApi.getAll().then((response) => {
            let list = [];
            list.push({ value: '', label: 'Quận huyện...' });
            response.forEach(item => {
                list.push({ value: item.Ma, label: item.Ten });
            })
            setTimListQuanHuyen(list);
            if (props.mahuyen != null) {
                const index = list.findIndex(x => x.value == props.mahuyen);
                if (index != -1) {
                    setTimQuanHuyen(list[index]);
                }
            }
        })
    }

    function loadDanhPhuongXa() {
        PhuongXaApi.getAll().then((response) => {
            let list = [];
            list.push({ value: '', label: 'Phường xã...' });
            response.forEach(item => {
                list.push({ value: item.Ma, label: item.Ten, mahuyen: item.MaHuyen });
            });
            setListPhuongXa(list);
            if (props.mahuyen != null) {
                let listXas = list.filter(x => x.mahuyen == props.mahuyen);
                listXas.unshift({ value: '', label: 'Tất cả xã', mahuyen: '' });
                setTimListPhuongXa(listXas);
                const index = list.findIndex(x => x.value == props.maxa);
                if (index != -1) {
                    setTimXaPhuong(list[index]);
                }
            }
            else {
                setTimListPhuongXa(list);
            }
        })
    }

    function loadDanhMucMuaVu() {
        MuaVuApi.getAll().then((response) => {
            let list = [];
            list.push({ value: 0, label: 'Mùa vụ...' });
            response.forEach(item => {
                list.push({ value: item.Id, label: item.Ten });
            });
            setTimListMuaVu(list);
        })
    }

    const exportExcel = () => {
        ExportExcel();
    }

    const loadDanhMucNam = () => {
        const endYear = new Date().getFullYear();
        let list = [];
        list.push({ value: 0, label: 'Năm...' });
        for (let i = 2000; i <= endYear; i++) {
            list.push({ value: i, label: i });
        }
        setTimListNam(list);
    }

    const timKiem = () => {
        loadAnhHuongSauBenh();
    }

    const setFilter = (e) => {
        setSearch(e.target.value)
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            loadAnhHuongSauBenh();
        }
    }

    // TÌM KIẾM
    const onChangeTimQuanHuyen = (e) => {
        setTimQuanHuyen(e);
        setTimXaPhuong({ value: '', label: 'Tất cả xã', mahuyen: '' });
        const list = listPhuongXas.filter(x => x.mahuyen === e.value);
        list.unshift({ value: '', label: 'Tất cả xã', mahuyen: '' });
        setTimListPhuongXa(list);
    }

    const onChangeTimXaPhuong = (e) => {
        setTimXaPhuong(e);
    }

    const onChangeTimCayTrong = (e) => {
        setTimCayTrong(e);
    }

    const onChangeTimMuaVu = (e) => {
        setTimMuaVu(e);
    }

    const onChangeTimNam = (e) => {
        setTimNam(e);
    }

    const typeValueChange = (e) => {
        setLoaiBieuDo(e);
        buildBieuDo(e.value);
    }

    return (
        <div className="card shadow mb-1">
            {
                props.showName == true ? <div className="row">
                    <div className="col-md-12">
                        <div className="breadcrumb-container">
                            <a href="/" className="breadcrumb-dis">Trang chủ / </a>
                            <a href="/public/anh-huong-sau-benh" className="breadcrumb-active"> Ảnh hưởng sâu bệnh</a>
                            <div className="breadcrumb-line">
                            </div>
                        </div>
                    </div>
                </div> : ''
            }
            <div className="card-header row h-solieu">
                <div className="col-md-2">
                    <Select placeholder="Quận huyện"
                        onChange={onChangeTimQuanHuyen} options={timListQuanHuyen} value={timQuanHuyen} theme={theme => ({
                            ...theme,
                            borderRadius: 0,
                            height: 30,
                            spacing: {
                                controlHeight: 30,
                                menuGutter: 1,
                                baseUnit: 2,
                            }
                        })} />
                </div>
                <div className="col-md-2">
                    <Select placeholder="Phường xã"
                        onChange={onChangeTimXaPhuong} options={timListPhuongXa} value={timXaPhuong} theme={theme => ({
                            ...theme,
                            borderRadius: 0,
                            height: 30,
                            spacing: {
                                controlHeight: 30,
                                menuGutter: 1,
                                baseUnit: 2,
                            }
                        })} />
                </div>
                <div className="col-md-2">
                    <Select placeholder="Cây trồng"
                        onChange={onChangeTimCayTrong} options={timListCayTrong} value={timCayTrong} theme={theme => ({
                            ...theme,
                            borderRadius: 0,
                            height: 30,
                            spacing: {
                                controlHeight: 30,
                                menuGutter: 1,
                                baseUnit: 2,
                            }
                        })} />
                </div>
                <div className="col-md-1">
                    <Select placeholder="Mùa vụ"
                        onChange={onChangeTimMuaVu} options={timListMuaVu} value={timMuaVu} theme={theme => ({
                            ...theme,
                            borderRadius: 0,
                            height: 30,
                            spacing: {
                                controlHeight: 30,
                                menuGutter: 1,
                                baseUnit: 2,
                            }
                        })} />
                </div>
                <div className="col-md-1">
                    <Select placeholder="Năm"
                        onChange={onChangeTimNam} options={timListNam} value={timNam} theme={theme => ({
                            ...theme,
                            borderRadius: 0,
                            height: 30,
                            spacing: {
                                controlHeight: 30,
                                menuGutter: 1,
                                baseUnit: 2,
                            }
                        })} />
                </div>
                <div className="col-md-2">
                    <Select
                        className="select"
                        name="chart"
                        placeholder="Biểu đồ"
                        value={loaiBieuDo}
                        onChange={typeValueChange}
                        options={listLoaiBieuDo}
                        theme={theme => ({
                            ...theme,
                            borderRadius: 0,
                            height: 30,
                            spacing: {
                                controlHeight: 30,
                                menuGutter: 1,
                                baseUnit: 2,
                            }
                        })}
                    />
                </div>
                <div className="col-md-2">
                    <div className="input-group">
                        <input type="text" className="form-control" placeholder="Tìm kiếm" value={search} onChange={setFilter} onKeyPress={handleKeyPress} />
                        <div className="input-group-append">
                            <button className="btn btn-info btn-sm" type="button" onClick={timKiem} ><i className='fas fa-search'></i></button>
                            <button className="btn btn-primary btn-sm" type="button" onClick={exportExcel} ><i className='fas fa-file-excel'></i> Excel</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-body">
                <div className="row bieu-do">
                    <Bar
                        data={bieudo}
                        options={{
                            legend: { display: true },
                            responsive: true,
                            title: {
                                display: true,
                                text: "Biểu đồ diện tích ảnh hướng sâu bệnh"
                            },
                            hover: {
                                animationDuration: 0
                            },
                            animation: {
                                duration: 1,
                                onComplete: function () {
                                    var chartInstance = this.chart,
                                        ctx = chartInstance.ctx;
                                    ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
                                    ctx.textAlign = 'center';
                                    ctx.textBaseline = 'bottom';
                                    this.data.datasets.forEach(function (dataset, i) {
                                        var meta = chartInstance.controller.getDatasetMeta(i);
                                        meta.data.forEach(function (bar, index) {
                                            if (dataset.data[index] > 0) {
                                                var data = dataset.data[index];
                                                ctx.fillText(parseInt((data*100))/100, bar._model.x, bar._model.y - 5);
                                            }
                                        });
                                    });
                                }
                            }
                        }}
                    />
                </div>
                <div id="div-table">
                    <table className="table table-bordered" width="100%">
                        <thead>
                            <tr>
                                <th>Quận, huyện</th>
                                <th>Xã, Thị trấn</th>
                                <th>Cây trồng</th>
                                <th>Mùa vụ</th>
                                <th>Vùng nhiễm bệnh</th>
                                <th>Giống cây</th>
                                <th>Mật độ</th>
                                <th>Diện tích nhẹ</th>
                                <th>Diện tích trung bình</th>
                                <th>Diện tích nặng</th>
                                <th>Diện tích mất trắng</th>
                                <th>Diện tích phòng trừ</th>
                                <th>Ghi chú</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                listAnhHuongSauBenh.map((item, key) => {
                                    return (
                                        <tr key={key}>
                                            <td>{item.TenQuanHuyen}</td>
                                            <td>{item.TenXaPhuong}</td>
                                            <td>{item.TenCayTrong}</td>
                                            <td>{item.TenMuaVu}</td>
                                            <td>{item.VungNhiemBenh}</td>
                                            <td>{item.GiongCays}</td>
                                            <td>{item.MatDo}</td>
                                            <td className="td-number-style">
                                                <NumberFormat value={item.DienTichNhe} displayType={'text'} thousandSeparator={true} decimalScale={2}></NumberFormat>
                                            </td>
                                            <td className="td-number-style">
                                                <NumberFormat value={item.DienTichTrungBinh} displayType={'text'} thousandSeparator={true} decimalScale={2}></NumberFormat>
                                            </td>
                                            <td className="td-number-style">
                                                <NumberFormat value={item.DienTichNang} displayType={'text'} thousandSeparator={true} decimalScale={2}></NumberFormat>
                                            </td>
                                            <td className="td-number-style">
                                                <NumberFormat value={item.DienTichMatTrang} displayType={'text'} thousandSeparator={true} decimalScale={2}></NumberFormat>
                                            </td>
                                            <td className="td-number-style">
                                                <NumberFormat value={item.DienTichPhongTru} displayType={'text'} thousandSeparator={true} decimalScale={2}></NumberFormat>
                                            </td>
                                            <td>{item.GhiChu}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <div className="col-md-12 text-right">
                    <Pagination {...paginationConfig} />
                </div>
            </div>
        </div>
    )


}