import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { useHistory } from "react-router-dom";
import { style } from 'openlayers';
import { Loading } from '../shared/loading/Loading';
import ThongBao from '../core/ThongBao';


export const Login = () => {
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const { register, handleSubmit, errors } = useForm({
        mode: "onBlur"
    });
    const onSubmit = (data) => {
        setLoading(true);
        axios.post(process.env.REACT_APP_API_URL + '/login', data).then(response => {
            localStorage.setItem(process.env.REACT_APP_TOKEN, response.data.token);
            setLoading(false);
            history.push("/main/home");
        }, (eror) => {
            if(eror.response){
                if(eror.response.data === "Bad Request"){
                    ThongBao.printErrorMessage("Tên đăng nhập hoặc mật khẩu không đúng.");
                }
                else{
                    ThongBao.printErrorMessage(eror.response.data);
                }
            }
            setLoading(false);
        });
    }
    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-xl-5 col-lg-12 col-md-9">
                    <div className="card o-hidden border-0 shadow-lg my-5">
                        <div className="card-body p-0 login">
                            <div className="p-5">
                                <div className="text-center">
                                    <h1 className="h4 text-gray-900 mb-4">ĐĂNG NHẬP HỆ THỐNG</h1>
                                </div>
                                <form className="user" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-group">
                                        <input name="userName" type="text" autoFocus={true} className="form-control form-control-user" placeholder="Tên đăng nhập" style={{ ...style.input, borderColor: errors.userName && "red" }} ref={register({ require: true })}/>
                                        {errors.userName}
                                    </div>
                                    <div className="form-group">
                                        <input name="password" type="password" className="form-control form-control-user" placeholder="Mật khẩu" style={{ ...style.input, borderColor: errors.password && "red" }} ref={register({ require: true })} />
                                        {errors.password}
                                    </div>
                                    <button type="submit" className="btn btn-primary btn-lg btn-block btn-dangnhap" onClick={handleSubmit(onSubmit)}>Đăng nhập</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Loading loading={loading}></Loading>
        </div>
    )

}
