import React, { useEffect, useState } from 'react'
import LopBanDoApi from '../../api/LopBanDoApi';
import stylelocal from "./SidebarLeft.module.css"

export const SidebarLeft = (props) => {
    const width = 350;
    const [xPosition, setX] = React.useState(-width);
    const [listBanDos, setListBanDo] = useState([]);
    const [isSapXep, setSapXep] = useState(false);
    const toggleMenu = () => {
        if (xPosition < -5) {
            setX(-5);
        } else {
            setX(-width);
        }
        // console.log(xPosition)
    };

    useEffect(() => {
        if (props.show > 0) {
            toggleMenu();
        }
    }, [props.show]);

    useEffect(() => {
        setX(-width);
        loadMenu();
    }, []);

    const loadMenu = async () => {
        const list = await LopBanDoApi.getAll();
        const listTrees = BuilTree(list);
        setListBanDo(listTrees);
        props.selectBanDo(listTrees.filter(x => x.Checked));
    }

    const BuilTree = (list) => {
        let roots = [];
        let parents = list.filter(x => x.ParentId === null);
        for (let i = 0; i < parents.length; i++) {
            parents[i].level = 0;
            parents[i].expanded = true;
            // parents[i].Checked = parents[i].Ma === 'BANDOVETINH' || parents[i].Ma === 'BANDONEN' || parents[i].Ma === 'BDHANHCHINH';
            if (parents[i].Ma === 'BANDONEN') {
                parents[i].CheckedGhiChu = true;
                props.getGhiChu(parents[i]);
            }
            roots.push(parents[i]);
            Tree(list, parents[i], 0, roots);
        }
        // console.log(roots)
        return roots;
    }

    const Tree = (list, parent, level, roots) => {
        let childs = list.filter(x => x.ParentId === parent.Ma);
        if (childs.length > 0) {
            parent.parentExpanded = true;
            parent.isChildren = true;
            for (let i = 0; i < childs.length; i++) {
                childs[i].level = level + 25;
                childs[i].expanded = true;
                // childs[i].Checked = childs[i].Ma === 'GOOGLE' || childs[i].ParentId === 'BANDONEN' || childs[i].ParentId === 'BDHANHCHINH';
                roots.push(childs[i]);
                Tree(list, childs[i], childs[i].level, roots);
            }
        }
    }
    function ClickTree(list, item) {
        if (item.Url === 'BANDONEN') {
            return;
        }
        list.filter(x => x.ParentId === item.Ma).forEach(child => {
            child.Checked = item.Checked;
            child.expanded = true;
            ClickTree(list, child);
        });
    }

    const clickRow = (menu) => {
        menu.Checked = !menu.Checked;
        if (menu.ParentId == null) {
            if (menu.Ma === 'BANDOVETINH') {
                if (menu.Checked) {
                    listBanDos.filter(x => x.ParentId === menu.Ma).forEach(item => {
                        item.Checked = false;
                    })
                    listBanDos.filter(x => x.ParentId === menu.Ma)[0].Checked = true;
                    props.selectBanDo([listBanDos.filter(x => x.ParentId === menu.Ma)[0]])
                }
                else {
                    ClickTree(listBanDos, menu);
                    props.selectBanDo(listBanDos.filter(x => x.ParentId === menu.Ma));
                }
            }
            else {
                ClickTree(listBanDos, menu);
                const list = listBanDos.filter(x => x.ParentId === menu.Ma);
                if (list.length > 0) {
                    props.selectBanDo(list);
                }
                else {
                    props.selectBanDo([menu]);
                }
            }
        }
        else {
            if (menu.ParentId === 'BANDOVETINH') {
                listBanDos.filter(x => x.ParentId === 'BANDOVETINH').forEach(item => {
                    if (item.Ma !== menu.Ma) {
                        item.Checked = false
                    }
                });
                props.selectBanDo([menu]);
            }
            else {
                props.selectBanDo([menu]);
            }
        }

        setListBanDo([...listBanDos]);
    }

    const clickGhiChu = (ghichu) => {
        listBanDos.forEach(item => {
            item.CheckedGhiChu = false;
        });
        ghichu.CheckedGhiChu = true;
        if (ghichu.Checked === false) {
            ghichu.Checked = true;
            props.selectBanDo([ghichu]);
        }
        setListBanDo([...listBanDos]);
        props.getGhiChu(ghichu);
    }
    const clickScolpan = (item) => {
        Expanded(item);

    }
    function Expanded(item) {
        item.parentExpanded = !item.parentExpanded;
        ExpandedBotton(listBanDos, item.Ma, item.parentExpanded)
        setListBanDo([...listBanDos]);
    }
    function ExpandedBotton(list, id, expanded) {
        let childs = list.filter(x => x.ParentId === id);
        for (let child of childs) {
            child.expanded = expanded;
            child.parentExpanded = true;
            ExpandedBotton(list, child.Ma, expanded);
        }
    }

    const adminstyle = {
        height: 'calc(100vh - 150px)'
    }

    const onLen = (item) => {
        // debugger
        if (item.ParentId !== null) {
            const list = listBanDos.filter(x => x.SortOrder < item.SortOrder && x.ParentId === item.ParentId).sort((a, b) => (a.SortOrder < b.SortOrder) ? 1 : -1);
            sortLopBanDo(list, item);
        } else {
            const list = listBanDos.filter(x => x.SortOrder < item.SortOrder && x.ParentId === null).sort((a, b) => (a.SortOrder < b.SortOrder) ? 1 : -1);
            sortLopBanDo(list, item)
        }
    }
    const onXuong = (item) => {
        if (item.ParentId !== null) {
            const list = listBanDos.filter(x => x.SortOrder > item.SortOrder && x.ParentId === item.ParentId).sort((a, b) => (a.SortOrder > b.SortOrder) ? 1 : -1);
            sortLopBanDo(list, item)
        }
        else {
            const list = listBanDos.filter(x => x.SortOrder > item.SortOrder && x.ParentId === null).sort((a, b) => (a.SortOrder > b.SortOrder) ? 1 : -1);
            sortLopBanDo(list, item)
        }
    }

    const sortLopBanDo = (list, item) => {
        if (list.length > 0) {
            const indexOld = listBanDos.findIndex(x => x.Ma === item.Ma);
            const index = listBanDos.findIndex(x => x.Ma === list[0].Ma);
            const sortOrder = listBanDos[index].SortOrder;
            const sortOrderOld = listBanDos[indexOld].SortOrder;
            listBanDos[index].SortOrder = sortOrderOld;
            listBanDos[indexOld].SortOrder = sortOrder;
            props.onSapXep([listBanDos[index], listBanDos[indexOld]]);
            const listSort = listBanDos.sort((a, b) => (a.SortOrder > b.SortOrder) ? 1 : -1);
            let listsSorted = buidSort(listSort);
            setListBanDo([...listsSorted]);
        }
    }

    const buidSort = (list) => {
        let roots = [];
        const parents = list.filter(x => x.ParentId === null);
        parents.forEach(item => {
            roots.push(item);
            treeSort(list, item, roots);
        });
        return roots;
    }

    const treeSort = (list, parent, roots) => {
        const childs = list.filter(x => x.ParentId === parent.Ma);
        if (childs.length > 0) {
            childs.forEach(item => {
                roots.push(item);
                treeSort(list, item, roots);
            });
        }
    }

    return (
        <React.Fragment>
            <div
                className={stylelocal.sidebar}
                style={{
                    transform: `translatex(${xPosition}px)`,
                    width: width,
                }}
            >
                {
                    xPosition === -5 ? <button
                        onClick={() => toggleMenu()}
                        className={stylelocal.togglemenu}
                        style={{
                            transform: `translate(${349}px)`
                        }}
                    >
                        <i className="fas fa-chevron-left" />
                    </button> : ''
                }
                {/* <div className={stylelocal.sidecontent}>{props.children}</div> */}
                <div className={stylelocal.sidecontent}>
                    <div className={stylelocal.mapbando}>
                        <table className="table table-bordered" width="100%">
                            <thead>
                                <tr>
                                    <th className="col-checkbox">
                                    </th>
                                    <th>Bản đồ</th>
                                    <th className={"col-checkbox " + stylelocal.colsapxep} onClick={() => { setSapXep(!isSapXep) }}>
                                        {
                                            isSapXep ? <i className="fas fa-info-circle"></i> : <i className="fas fa-sort"></i>
                                        }
                                    </th>
                                </tr>
                            </thead>
                            <tbody style={props.admin ? adminstyle : null}>
                                {
                                    listBanDos.map((item, key) => {

                                        return (
                                            <tr key={key} className={item.expanded ? '' : stylelocal.rowan}>
                                                <td className="col-checkbox" onClick={() => clickRow(item)}>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" checked={item.Checked} readOnly={true} />
                                                    </div>
                                                </td>
                                                <td className={item.isChildren ? stylelocal.scolpan : ''} onClick={() => clickScolpan(item)}>
                                                    <div style={{ marginLeft: item.level + 'px' }} className={item.ParentId == null ? stylelocal.mngroup : ''}>
                                                        {item.Name}{item.isChildren ? <i className={item.parentExpanded ? 'fas fa-caret-down' : 'fas fa-caret-right'}></i> : ''}
                                                    </div>
                                                </td>
                                                {
                                                    isSapXep === false ? <td className="col-checkbox">
                                                        {
                                                            props.admin ? (item.IsEdit ? <i onClick={() => clickGhiChu(item)} className={'fas fa-edit ' + (item.CheckedGhiChu ? stylelocal.gcactive : stylelocal.gc)}></i> : '') :
                                                                (item.IsGhiChu ? <i onClick={() => clickGhiChu(item)} className={'fas fa-info-circle ' + (item.CheckedGhiChu ? stylelocal.gcactive : stylelocal.gc)}></i> : '')
                                                        }
                                                    </td> : ''
                                                }
                                                {
                                                    isSapXep === true ? <td className={'col-checkbox ' + stylelocal.colsapxep}>
                                                        <div class="btn-group-vertical">
                                                            <button type="button" className="btn-group btn-group-sm btn-info" onClick={() => onLen(item)}><i className="fas fa-sort-up"></i></button>
                                                            <button type="button" className={'btn-group btn-group-sm btn-info ' + stylelocal.xuong} onClick={() => onXuong(item)}><i className="fas fa-sort-down"></i></button>
                                                        </div>
                                                    </td> : ''
                                                }
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
