import axiosClient from './axiosClient';

const DienTichSanXuatApi={
    getAll: (params) =>{
        const url = '/dientichsanxuat/getall';
        return axiosClient.get(url, {params});
    },

    getPaging: (params) => {
        const url='/dientichsanxuat/paging';
        return axiosClient.get(url,{params});
    },

    getThongKe: (params) => {
        const url='/dientichsanxuat/getthongke';
        return axiosClient.get(url,{params});
    },

    getDetail: (id) =>{
        const url = '/dientichsanxuat/getbyid/' + id;
        return axiosClient.get(url);
    },
    getById: (id) =>{
        const url = '/dientichsanxuat/getbyid/' + id;
        return axiosClient.get(url);
    },

    post: (data) =>{
        const url = '/dientichsanxuat/add';
        return axiosClient.post(url, data);
    },

    put: (data) => {
        const url = '/dientichsanxuat/edit';
        return axiosClient.put(url, data);
    },

    delete: (id) =>{
        const url = '/dientichsanxuat/delete/' + id;
        return axiosClient.delete(url);
    }
    
}

export default DienTichSanXuatApi;