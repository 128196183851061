import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import CayTrongApi from '../../api/CayTrongApi';
import SauBenhApi from '../../api/SauBenhApi';
import Select from 'react-select';
import Pagination from 'react-bootstrap-4-pagination';

export default function SauBenh() {
    var apiUrl = process.env.REACT_APP_API_URL.replace('/api', '');
    var noImage = "/uploadfiles/website/no_image-100x100.jpg";
    const [listSauBenhs, setListSauBenh] = useState([]);
    const [listCayTrongs, setListCayTrong] = useState([]);



    const [filter, setFilter] = useState('');
    const [listLoaiSauBenhs, setListLoaiSauBenh] = useState([
        { value: 0, label: 'Tất cả sâu bệnh' },
        { value: 1, label: 'Sâu' },
        { value: 2, label: 'Bệnh' }
    ]);
    var [sauBenhSelect, setSauBenhSelect] = useState({ value: 0, label: 'Tất cả sâu bệnh' });
    var [cayTrongSelect, setCayTrongSelect] = useState({ value: 0, label: 'Tất cả loại cây' });


    const [params, setParams] = useState([{
        pageIndex: 1,
        pageSize: 20,
        filter: '',
        loaiSauBenh: 0,
        cayTrong: 0,
    }]);


    useEffect(() => {
        loadSauBenh();
        loadCayTrong();
    }, []);


    let pageIndex = 1;
    let pageSize = 20;
    const [paginationConfig, setPaging] = useState([{
        totalPages: 1,
        currentPage: 1,
        showMax: 5,
        size: "sm",
        threeDots: true,
        prevNext: true,
        onClick: function (page) {
            pageIndex = page;
            let obj = params[0];
            obj.pageIndex = page;
            let lst = [];
            lst.push(obj)
            setParams(lst);
            loadSauBenh();
        }
    }]);

    const loadCayTrong = async () => {
        const response = await CayTrongApi.getAll();
        let list = [];
        list.push({ value: 0, label: 'Tất cả loại cây' });
        response.forEach(element => {
            list.push({ value: element.Id, label: element.TenCayTrong });
        });
        setListCayTrong(list);
    }

    const loadSauBenh = async () => {
        const res = await SauBenhApi.timKiemSauBenh(params[0]);
        if (res != undefined) {
            setListSauBenh(res.Items);

            let pag = paginationConfig[0];

            let lstPag = [];

            pag.totalPages = 1;
            if (res.Items.length > 0) {
                pag.totalPages = Math.ceil(res.TotalRow / pageSize);
            }
            pag.currentPage = params[0].pageIndex;
            lstPag.push(pag);
            setPaging(lstPag);
        }
    }

    const onChangeFilter = (e) => {
        setFilter(e.target.value);

        let obj = params[0];
        obj.filter = e.target.value;
        obj.pageIndex = 1;
        let lst = [];
        lst.push(obj)
        setParams(lst);
    }

    const Timkiem = () => {
        loadSauBenh();
    }

    const onChangeCayTrong = (event) => {
        setCayTrongSelect(event);
        let obj = params[0];
        obj.cayTrong = event.value;
        obj.pageIndex = 1;
        let lst = [];
        lst.push(obj)
        setParams(lst);
        loadSauBenh();
    }

    const onChangedLoaiSauBenh = (event) => {
        setSauBenhSelect(event);

        let obj = params[0];
        obj.loaiSauBenh = event.value;
        obj.pageIndex = 1;
        let lst = [];
        lst.push(obj)
        setParams(lst);

        loadSauBenh();
    }

    return (
        <div>
            <div className="row">
                <div className="col-md-4">
                    <Select
                        className="select"
                        name="caytrong"
                        placeholder="Chọn loại cây trồng"
                        value={cayTrongSelect}
                        onChange={onChangeCayTrong}
                        options={listCayTrongs}
                        theme={theme => ({
                            ...theme,
                            borderRadius: 0,
                            height: 30,
                            spacing: {
                                controlHeight: 30,
                                menuGutter: 1,
                                baseUnit: 2,
                            }
                        })}
                    />
                </div>
                <div className="col-md-3">
                    <Select
                        className="select"
                        name="saubenh"
                        placeholder="Loại sâu bệnh"
                        value={sauBenhSelect}
                        onChange={onChangedLoaiSauBenh}
                        options={listLoaiSauBenhs}
                        theme={theme => ({
                            ...theme,
                            borderRadius: 0,
                            height: 30,
                            spacing: {
                                controlHeight: 30,
                                menuGutter: 1,
                                baseUnit: 2,
                            }
                        })}
                    />
                </div>
                <div className="col-md-5">
                    <div className="input-group">
                        <input type="text" className="form-control" placeholder="Tìm kiếm" value={filter} onChange={onChangeFilter} />
                        <div className="input-group-append">
                            <button className="btn btn-info btn-sm" type="button" onClick={Timkiem} ><i className='fas fa-search'></i> Tìm kiếm</button>
                        </div>
                    </div>
                </div>
            </div>
            <hr></hr>
            <div className="row">
                <ul className="list-unstyled">
                    {
                        listSauBenhs.map((item, key) => {
                            return (
                                <li className="media lst-sau-benh" key={key}>
                                    <NavLink to={'/public/sau-benh/chi-tiet/' + item.Id} className="a-img">
                                        <img src={apiUrl + (item.HinhAnh == null ? noImage : item.HinhAnh)} alt={item.TenSauBenh} />
                                    </NavLink>
                                    <div className="media-body">
                                        <NavLink to={'/public/sau-benh/chi-tiet/' + item.Id} className="tt-ten">
                                            <h5 className="mt-0 mb-1">{item.TenSauBenh}</h5>
                                        </NavLink>
                                        <div dangerouslySetInnerHTML={{ __html: item.MoTa }}></div>
                                    </div>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
            <div className="col-md-12 text-right">
                <Pagination {...paginationConfig[0]} />
            </div>
        </div>
    )
}
