import axiosClient from './axiosClient';

const DonViCungCapApi =
{
    getAll: (params) =>{
        const url = '/donvicungcap/getall';
        return axiosClient.get(url, {params});
    },

    getPaging: (params)=>
    {
        const url= '/donvicungcap/paging';
        return axiosClient.get(url,{params});
    },

    getThongKe: (params)=>
    {
        const url= '/donvicungcap/thongke';
        return axiosClient.get(url,{params});
    },

    getDetail: (id) =>
    {
        const url= '/donvicungcap/getbyid/' + id;
        return axiosClient.get(url);
    },
    getById: (id) =>
    {
        const url= '/donvicungcap/getbyid/' + id;
        return axiosClient.get(url);
    },
    post: (data) =>{
        const url = '/donvicungcap/add';
        return axiosClient.post(url, data);
    },

    put: (data) => {
        const url = '/donvicungcap/edit';
        return axiosClient.put(url, data);
    },

    delete: (id) =>{
        const url = '/donvicungcap/delete/' + id;
        return axiosClient.delete(url);
    },

    importXls: (data) => {
        const url = '/donvicungcap/importxls';
        return axiosClient.post(url, data);
    }

}

export default DonViCungCapApi;