import React, { useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import PostCategoryApi from '../../api/PostCategoryApi';
import Select from 'react-select';
import Pagination from 'react-bootstrap-4-pagination';
import ThongBao from '../../core/ThongBao';
import { Public } from '../../public/Public';
import { composeCssTransform, set } from 'ol/transform';
import './QuanLyTapTin.css';
import Tree from 'rc-tree';
import '../../../node_modules/rc-tree/assets/index.css'

import FolderApi from '../../api/FolderApi';
import UploadFileApi from '../../api/UploadFile';
import FileApi from '../../api/FileApi';
import axios from 'axios';

export const QuanLyTapTin = (props) => {
    var apiUrl = process.env.REACT_APP_URL;
    const [listPostCategory, setLopBanDo] = useState([]);
    const [show, setShow] = useState(false);
    const handleClose = () => {
        props.closeModal();
        setShow(false);
    };
    const { register, handleSubmit, reset, errors } = useForm();
    const [filter, setFilter] = useState('');
    const [listDanhMucCha, setDanhMucCha] = useState([]);
    const [selectedOption, setSelectedOption] = useState();
    let pageIndex = 1;
    let pageSize = 20;
    const [paginationConfig, setPaging] = useState({
        totalPages: 1,
        currentPage: 1,
        showMax: 5,
        size: "sm",
        threeDots: true,
        prevNext: true,
        onClick: function (page) {
            pageIndex = page;
            loadCategory();
        }
    });

    useEffect(() => {
        if (props.viewModal === true) {
            reset({
                Gid: props.viewModal
            }, [reset])
            loadAllFolder(true);
            setParentItem({});
            setShow(true);
        }
    }, [props.viewModal]);


    var [parentItem, setParentItem] = useState({});
    const [showFolder, setShowFolder] = useState(false);
    const handleCloseFolder = () => setShowFolder(false);
    const handleShowAddFolder = () => {
        reset({
            Id: 0,
            Name: "",
            ParentId: parentItem.key,
            ParentName: parentItem.title
        }, [reset]);
        setShowFolder(true);
    };
    const handleShowEditFolder = () => {
        if (parentItem.key != null) {
            FolderApi.getDetail(parentItem.key).then((response) => {
                reset({
                    Id: response.Id,
                    Name: response.Name,
                    ParentId: parentItem.key,
                    ParentName: parentItem.title
                }, [reset]);
                setShowFolder(true);
            });
        }
    }
    const handleShowDelFolder = () => {
        if (parentItem.key != null) {
            ThongBao.printConfirmationDialog("Bạn có đồng ý xóa dữ liệu đã chọn hay không?", () => {
                let lstId = [];
                lstId.push(parentItem.key);
                FolderApi.delete(lstId).then(() => {
                    loadAllFolder();
                })
            });
        }
    }
    const saveDataFolder = (data) => {
        if (data.Id === "0") {
            data.Status = true;
            FolderApi.post(data).then(() => {
                setShowFolder(false);
                loadAllFolder();
            })
        }
        else {
            FolderApi.put(data).then(() => {
                setShowFolder(false);
                loadAllFolder();
            })
        }
    }
    const loadAllFolder = async (isLoadFile = false) => {
        try {
            const response = await FolderApi.getAll();
            debugger;
            var tree = buildTree(response, null);
            if (tree == null) {
                tree = [];
            }
            setTreeData(tree);
            if (isLoadFile == true) {
                if (tree.length > 0) {
                    setParentItem({ key: tree[0].key });
                    parentItem = { key: tree[0].key };
                    loadPagingUpload();
                }
            }
        } catch (err) {
        }
    };
    const buildTree = (data, parentId) => {
        var lstTemp = data.filter(x => x.ParentId == parentId);
        if (lstTemp.length > 0) {
            var lstKetQua = [];
            lstTemp.forEach(element => {
                var child = buildTree(data, element.Id);
                if (child == null) {
                    lstKetQua.push({ key: element.Id, title: element.Name });
                }
                else {
                    lstKetQua.push({ key: element.Id, title: element.Name, children: child });
                }
            });
            return lstKetQua;
        }
        return null;
    }
    const [treeData, setTreeData] = useState([]);

    const [showUpload, setShowUpload] = useState(false);
    const [fileSelected, setFileSelected] = useState({});
    let pageIndexUpload = 1;
    var [listFile, setListFile] = useState([]);
    const [paginationConfigUpload, setPagingUpload] = useState({
        totalPages: 1,
        currentPage: 1,
        showMax: 5,
        size: "sm",
        threeDots: true,
        prevNext: true,
        onClick: function (page) {
            pageIndexUpload = page;
            loadPagingUpload();
        }
    });
    const loadPagingUpload = async () => {
        if (parentItem.key != null) {
            try {
                const params = {
                    pageIndex: pageIndexUpload,
                    pageSize: pageSize,
                    idFolder: parentItem.key
                }
                const response = await FileApi.getPaging(params);
                paginationConfigUpload.totalPages = Math.ceil(response.TotalRow / pageSize);
                paginationConfigUpload.currentPage = pageIndex;
                paginationConfigUpload.onClick = function (page) {
                    pageIndexUpload = page;
                    loadPagingUpload();
                };
                if (response.Items.length == 0) {

                    paginationConfigUpload.totalPages = 1;
                }
                setPagingUpload(paginationConfigUpload);
                response.Items.forEach(element => {
                    element.Checked = false;
                });
                debugger;
                response.Items.forEach(element => {
                    debugger;
                    let extension = "";
                    let index = element.Name.lastIndexOf(".");
                    if (index > 0) {
                        extension = element.Name.substring(index).toLowerCase();
                    }
                    if (extension == ".jpg" || extension == ".jpge" || extension == ".gif" || extension == ".png" || extension == ".ico" || extension == ".jpg") {
                        element.Icon = element.Path + "/" + element.Name;
                    }
                    else if (extension == ".pdf") {
                        element.Icon = "/uploadfiles/website/file-pdf.png";
                    }
                    else if (extension == ".doc" || extension == ".docx") {
                        element.Icon = "/uploadfiles/website/file-word.png";
                    }
                    else if (extension == ".xls" || extension == ".xlsx") {
                        element.Icon = "/uploadfiles/website/file-excel.png";
                    }
                    else if (extension == ".rar") {
                        element.Icon = "/uploadfiles/website/file-winrar.png";
                    }
                    else {
                        element.Icon = "/uploadfiles/website/file-other.png";
                    }
                });
                setListFile(response.Items);
            } catch (err) {
            }
        }
    }
    const [listFileUpload, setListFileUpload] = useState([]);
    const handleCloseUpload = () => setShowUpload(false);
    const handleShowAddUpload = () => {
        if (parentItem.key != null) {
            reset({

            }, [reset]);
            setShowUpload(true);
        }
    };
    const handleShowDelUpload = () => {
        if (fileSelected.Id != null) {
            ThongBao.printConfirmationDialog("Bạn có đồng ý xóa dữ liệu đã chọn hay không?", () => {
                let lst=[];
                lst.push(fileSelected.Id);
                FileApi.delete(lst).then(() => {
                    loadPagingUpload();
                })
            });
        }
    }
    const onChangeHandlerUpload = event => {
        console.log(event.target.files);
        setListFileUpload(event.target.files);
    }
    const handleChenAnh = () => {
        if (fileSelected.Id != null) {
            props.onFileSelected(fileSelected.Path + "/" + fileSelected.Name);
            handleClose();
        }
    };
    const handleDbClickImage = (item) => {
        const lst = Object.assign([], listFile);
        lst.forEach(element => {
            if (element.Id == item.Id) {
                element.Checked = true;
            }
            else {
                element.Checked = false;
            }
        });
        setListFile(lst);
        setFileSelected(item);
        props.onFileSelected(item.Path + "/" + item.Name);
        handleClose();
    }
    const handleClickImage = (item) => {
        const lst = Object.assign([], listFile);
        lst.forEach(element => {
            if (element.Id == item.Id) {
                element.Checked = true;
            }
            else {
                element.Checked = false;
            }
        });
        setListFile(lst);
        setFileSelected(item);
    }
    const saveDataUpload = (data) => {
        debugger;
        if (listFileUpload.length > 0) {
            let data = new FormData();
            for (let index = 0; index < listFileUpload.length; index++) {
                data.append('file', listFileUpload[index])
            }
            console.log(data);
            UploadFileApi.post(data, parentItem.key).then(() => {
                debugger;
                pageIndexUpload = 1;
                loadPagingUpload();
                setShowUpload(false);
            })
        }
    }

    const onSelect = (selectedKeys, info) => {
        if (info.selectedNodes.length > 0) {
            parentItem = info.selectedNodes[0];
            setParentItem(info.selectedNodes[0]);
            loadPagingUpload();
        }
        else {
            setParentItem({});

        }
    };
    const treeRef = React.useRef();

    const loadCategory = async () => {
        try {
            const params = {
                pageIndex: pageIndex,
                pageSize: pageSize,
                filter: filter
            }
            const response = await PostCategoryApi.getPaging(params);
            response.Items.forEach(item => {
                item.Checked = false;
            });
            paginationConfig.totalPages = Math.ceil(response.TotalRow / pageSize);
            paginationConfig.currentPage = pageIndex;
            setPaging(paginationConfig);
            setLopBanDo(response.Items);
        } catch (err) {
        }
    }

    /* useEffect(() => {
        loadCategory();
        loadDanhMucCha();
    }, []); */

    const timKiem = () => {
        loadCategory();
    }

    function onChangeFilter(e) {
        setFilter(e.target.value)
    }

    const saveData = (data) => {
        if (selectedOption) {
            data.ParentId = selectedOption.value;
        }
        else {
            data.ParentId = null;
        }
        if (data.Id === "0") {
            PostCategoryApi.post(data).then(() => {
                setShow(false);
                loadCategory();
            })
        }
        else {
            PostCategoryApi.put(data).then(() => {
                setShow(false);
                loadCategory();
            })
        }
    }

    const clickRow = (id) => {
        listPostCategory.forEach(item => {
            if (item.Id === id) {
                item.Checked = !item.Checked;
            }
        });
        setLopBanDo([...listPostCategory]);
    }

    const handleShowAdd = () => {
        reset({
            Id: 0,
            Name: ""
        }, [reset]);
        setShow(true)
        setSelectedOption(listDanhMucCha[0]);
    };

    const handleShowEdit = () => {
        let index = listPostCategory.findIndex(x => x.Checked);
        if (index !== -1) {
            PostCategoryApi.getDetail(listPostCategory[index].Id).then((response) => {
                reset({
                    Id: response.Id,
                    Name: response.Name,
                    Alias: response.Alias,
                    Description: response.Description,
                    ParentId: response.ParentId,
                    DisplayOrder: response.DisplayOrder,
                    Image: response.Image,
                    HomeFlag: response.HomeFlag,
                    Status: response.Status,
                    MetaKeyword: response.MetaKeyword,
                    MetaDescription: response.MetaDescription,
                    IconCss: response.IconCss
                }, [reset]);
                var index = listDanhMucCha.findIndex(x => x.value == response.ParentId);
                setSelectedOption(listDanhMucCha[index]);
                setShow(true);
            });
        }
    }

    const handleShowDel = () => {
        ThongBao.printConfirmationDialog("Bạn có đồng ý xóa dữ liệu đã chọn hay không?", () => {
            let lstChecked = listPostCategory.filter(x => x.Checked);
            if (lstChecked.length > 0) {
                let lstId = [];
                lstChecked.forEach(element => {
                    lstId.push(element.Id);
                });
                PostCategoryApi.delete(lstId).then(() => {
                    loadCategory();
                })
            }
        });
    }

    const checkBoHandler = () => {

    }

    function loadDanhMucCha() {
        PostCategoryApi.getAll().then((response) => {
            let list = [];
            list.push({ value: 0, label: 'Chọn danh mục...' });
            response.forEach(item => {
                if (item.ParentId == null) {
                    list.push({ value: item.Id, label: item.Name });
                }
            })
            setDanhMucCha(list);
        })
    }

    const onChangeDanhMucCha = (e) => {
        setSelectedOption(e);
        // props.onChangeHuyenProps(e);
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            loadCategory();
        }
    }

    return (
        <div className="card shadow mb-1">
            <Modal show={show} onHide={handleClose} animation={false} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Quản lý tập tin</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="user quanlytaptin" name="modalQuanLyTapTin" onSubmit={handleSubmit(saveData)}>
                        <div className="row">
                            <div className="col-md-4">
                                <Button variant="btn btn-success btn-sm" size="sm" onClick={handleShowAddFolder}> Thêm</Button>
                                <Button variant="btn btn-primary btn-sm" size="sm" onClick={handleShowEditFolder}> Sửa</Button>
                                <Button variant="btn btn-danger btn-sm" size="sm" onClick={handleShowDelFolder}> Xóa</Button>
                            </div>
                            <div className="col-md-3">
                                <Button variant="btn btn-success btn-sm" size="sm" onClick={handleShowAddUpload}> Tải lên</Button>
                                <Button variant="btn btn-danger btn-sm" size="sm" onClick={handleShowDelUpload}> Xóa ảnh</Button>

                            </div>
                            <div className="col-md-4">
                                Tìm kiêm
                            </div>
                            <div className="col-md-1">
                                <Button variant="btn btn-success btn-sm" size="sm" onClick={handleChenAnh}> Chèn</Button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div class="thu-muc">
                                    <Tree
                                        ref={treeRef}
                                        className="tree-component"
                                        defaultExpandAll
                                        treeData={treeData}
                                        onSelect={onSelect}
                                        height={150}
                                    />
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div class="row hinh-anh">
                                    {
                                        listFile.map((item, key) => {
                                            return (
                                                <div className="col-xs-6 col-md-3" onClick={() => handleClickImage(item)} onDoubleClick={() => handleDbClickImage(item)}>
                                                    <div className={item.Checked ? 'thumbnail-img image-select' : 'thumbnail-img'}  >
                                                        <div class="icon-tap-tin text-center">
                                                            <img src={apiUrl + item.Icon} title={item.Name} />
                                                            <i className="fa " title={item.Name}></i>
                                                        </div>
                                                        <div className="title-taptin-container">
                                                            <span title={item.Name} className="title-taptin-content">{item.Name}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 text-right">
                            <Pagination {...paginationConfigUpload} />
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>


            <Modal name="modalFolder" show={showFolder} onHide={handleCloseFolder} animation={false} size='sm'>
                <Modal.Header closeButton>
                    <Modal.Title>Folder</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="user" onSubmit={handleSubmit(saveDataFolder)}>
                        <input name="Id" hidden={true} ref={register}></input>
                        <div className="form-group row">
                            <div className="col-sm-8">
                                <input name="ParentId" type="text" className="form-control" placeholder="Thư mục cha..." ref={register} hidden='true' />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label">Thư mục cha</label>
                            <div className="col-sm-8">
                                <input name="ParentName" type="text" className="form-control" placeholder="Thư mục cha..." ref={register} disabled='true' />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label">Tên thư mục
                                <span className="text-danger"> *</span>
                            </label>
                            <div className="col-sm-8">
                                <input name="Name" type="text" className="form-control" placeholder="Tên thư mục" ref={register({ required: true })} />
                                {errors.Name && <span className="text-danger">Hãy nhập dữ liệu</span>}
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" size="sm" onClick={handleSubmit(saveDataFolder)}>
                        Lưu</Button>
                    <Button variant="danger" size="sm" onClick={handleCloseFolder}>
                        Hủy</Button>
                </Modal.Footer>
            </Modal>
            <Modal name="modalUpload" show={showUpload} onHide={handleCloseUpload} animation={false} size='sm'>
                <Modal.Header closeButton>
                    <Modal.Title>Upload file</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="user" onSubmit={handleSubmit(saveDataUpload)}>
                        <input type="file" class="form-control" multiple onChange={onChangeHandlerUpload} />
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" size="sm" onClick={handleSubmit(saveDataUpload)}>
                        Lưu</Button>
                    <Button variant="danger" size="sm" onClick={handleCloseUpload}>
                        Hủy</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
