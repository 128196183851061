import React, { useState, useEffect } from 'react'
import './HuongDan.css'
import HuongDanApi from '../../api/HuongDanApi';
import Draggable from 'react-draggable';
import $ from 'jquery';

export const HuongDan = (props) => {
    const [listHuongDans, setListHuongDans] = useState([]);
    const [huongDan, setHuongDan] = useState({});
    var apiUrl = process.env.REACT_APP_URL;

    const handleClose = () => {
        $('#modalhuongdan').modal('hide');
        $('#modalhuongdan').modal('dispose');
        props.handleClose();
    }

    useEffect(() => {
        if (props.show) {
            $('#modalhuongdan').modal('show');
            loadHuongDan();
        }
    }, [props.show]);

    const viewHuongDan = (item) => {
        setHuongDan(item);
    }

    const loadHuongDan = async () => {
        try {
            const params = {
                hienThiCongKhai: true
            }
            const response = await HuongDanApi.getAll(params);
            setListHuongDans(response);
            if (response.length > 0) {
                setHuongDan(response[0]);
                var url = "http://docs.google.com/gview?url=" + new URL(apiUrl + response[0].TapTin) + "&embedded=true";
            }
        } catch (err) {
        }
    }

    return (
        <Draggable handle=".modal-header">
            <div className="modal modal-custom" id="modalhuongdan" tabIndex={-1} data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Hướng dẫn <span>(Tải tập tin hướng dẫn <a target="_blank" style={{color:'blue'}} href="/img/HDSD_SAUBENH_PUBLIC.pdf">tại đây</a>)</span></h5>
                            <button type="button" className="close" onClick={handleClose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {/* <embed src={srcBaoCao} width="100%" height="100%" type="application/pdf" /> */}
                            <div className="row">
                                <div className="col-md-4 col-sm-4 huongdan-danhsach">
                                    {
                                        listHuongDans.map((item, key) => {
                                            return (
                                                <div onClick={() => viewHuongDan(item)} key={key}>{item.TieuDe}</div>
                                            )
                                        })
                                    }
                                </div>
                                <div className="col-md-8 col-sm-8 hd-noi-dubng">
                                    <div className="huongdan-tieude">{huongDan.TieuDe}</div>
                                    <div className="huongdan-noidung" dangerouslySetInnerHTML={{ __html: huongDan.NoiDung }}></div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="modal-footer">
                            <button type="button" className="btn btn-danger btn-sm" onClick={handleClose}><i className="fas fa-sign-out-alt"></i> Đóng</button>
                        </div> */}
                    </div>
                </div>
            </div>
        </Draggable>
    )
}
