import axiosClient from './axiosClient';
const TimKiemApi = {
    getFilter: (params) => {
        const url = '/timkiem/timxa';
        return axiosClient.get(url, {params});
    },
    getFilterByDuAn: (params) => {
        const url = '/timkiem/timxabyduan';
        return axiosClient.get(url, {params});
    },
    getBangDuLieu: () =>{
        const url = '/timkiem/getallbangdulieu';
        return axiosClient.get(url);
    },
    getXaDetail: (params) => {
        const url = '/timkiem/getxadetail';
        return axiosClient.get(url, {params});
    }
}
export default TimKiemApi;