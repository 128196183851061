import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import NumberFormat from 'react-number-format';
import { ModalDienTichSanXuat } from '../modaldientichsanxuat/modaldientichsanxuat';

export const Xa = (props) => {
    const [edit, setEdit] = useState(false);
    const [dataChart, setDataChart] = useState([]);
    const ShowEdit = () => {
        props.showEditThongTin();
    }
    const showInfo = () => {
        setEdit(true);
    }
    const closeModalDienTich = () => {
        setEdit(false);
    }
    useEffect(() => {
        if (props.thongTin.caytrongs) {
            props.thongTin.dienTichCayTrong = 0;
            let labels = [];
            let data = [];
            let backgroundColor = [];
            let color = '#CF5C00';
            props.thongTin.caytrongs.forEach(element => {
                labels.push(element.caytrong);
                data.push(element.dientich);
                props.thongTin.dienTichCayTrong = props.thongTin.dienTichCayTrong + element.dientich;
                switch (element.htsd) {
                    case 'LUA':
                        color = '#FFDB15';
                        break;
                    case 'RAU':
                        color = '#04D010';
                        break;
                }
                backgroundColor.push(color);
            });
            setDataChart({
                labels: labels,
                datasets: [
                    {
                        label: 'Diện tích',
                        data: data,
                        backgroundColor: backgroundColor
                    }
                ]
            })
        }
    }, [props.thongTin])
    return (
        <div className="thong-tin">
            {
                props.thongTin.ten_huyen ? <table className="table table-bordered" width="100%">
                    <thead>
                        <tr>
                            <th colSpan="2">Thông tin xã
                            <button type="button" className="btn btn-info btn-sm suathongtin" onClick={showInfo}><i className='fas fa-info'></i></button>
                                {
                                    props.admin ? <button type="button" className="btn btn-primary btn-sm suathongtin" onClick={ShowEdit}><i className='fas fa-pen'></i></button> : ''
                                }
                            </th>
                        </tr>
                        <tr>
                            <th className="col-name">Tên</th>
                            <th>Thông tin</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="col-name">Huyện</td>
                            <td>{props.thongTin?.ten_huyen}</td>
                        </tr>
                        <tr>
                            <td className="col-name">Xã</td>
                            <td>{props.thongTin?.texxa_vn}</td>
                        </tr>
                        <tr>
                            <td className="col-name">Diện tích(ha)</td>
                            <td>
                                <NumberFormat value={props.thongTin?.dientich} displayType={'text'} thousandSeparator={true} decimalScale={2}></NumberFormat>
                            </td>
                        </tr>
                        <tr>
                            <td className="col-name">Diện tích cây trồng(ha)</td>
                            <td>
                                <NumberFormat value={props.thongTin?.dienTichCayTrong} displayType={'text'} thousandSeparator={true} decimalScale={2}></NumberFormat>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2">
                                <Pie data={dataChart} />
                            </td>
                        </tr>
                        {
                            props?.thongTin?.caytrongs.map((item, key) => {
                                return (
                                    <tr>
                                        <td className="col-name">{item.caytrong}(ha)</td>
                                        <td>
                                            <NumberFormat value={item?.dientich} displayType={'text'} thousandSeparator={true} decimalScale={2}></NumberFormat>
                                        </td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </table> : <h5 className="text-center">Không có thông tin</h5>
            }
            <ModalDienTichSanXuat show={edit} closeModal={closeModalDienTich} ThongTin={props.thongTin}></ModalDienTichSanXuat>
        </div>
    )
};