import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import TrangApi from '../../../api/TrangApi';
import '../../../css/reacttab.css';
import Pagination from 'react-bootstrap-4-pagination';
import '../../../css/apppublic.css';
import Authen from '../../../core/Authen';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Ultility from '../../../core/Ultility'
import { QuanLyTapTin } from '../../../shared/quanlytaptin/QuanLyTapTin';
import ThongBao from '../../../core/ThongBao';

export const Trang = () => {
    const [listTrang, setListTrang] = useState([]);
    const [filter, setFilter] = useState('');
    const [show, setShow] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isXem, setIsXem] = useState(false);
    const [roleAdd, setRoleAdd] = useState(false);
    const [roleEdit, setRoleEdit] = useState(false);
    const [roleDel, setRoleDel] = useState(false);
    const [ckdData, setckdData] = useState('');
    const [showModalImage, setShowModalImage] = useState(false);
    var noImage = "/uploadfiles/website/no_image-100x100.jpg";
    const [srcImage, setSrcImage] = useState(noImage);
    const { handleSubmit, register, reset, errors } = useForm();
    const url = process.env.REACT_APP_URL;
    const [alias, setAlias] = useState("");

    useEffect(() => {
    }, []);

    const [paginationConfig, setPaginationConfig] = useState({
        totalPages: 1,
        currentPage: 1,
        showMax: 5,
        size: 'sm',
        threeDots: true,
        prevNext: true,
        onClick: function (page) {
            if (page != null) {
                pageIndex = page;
                loadTrang();
            }
        }
    });

    var pageIndex = 1, pageSize = 20;

    const loadTrang = async () => {
        try {
            const params = {
                keyword: filter,
                pageIndex: pageIndex,
                pageSize: pageSize
            }
            const res = await TrangApi.getTrang(params);
            res.Items.map((x) => {
                x.Checked = false
            });
            setListTrang(res.Items);

            paginationConfig.totalPages = Math.ceil(res.TotalRow === 0 ? 1 : res.TotalRow / pageSize);
            paginationConfig.currentPage = pageIndex;
            setPaginationConfig(paginationConfig);
            setIsEdit(false);
        }
        catch {
        }

    }

    useEffect(() => {
        loadTrang();
        setRoleAdd(Authen.checkQuyen('TRANG_CREATE'));
        setRoleEdit(Authen.checkQuyen('TRANG_UPDATE'));
        setRoleDel(Authen.checkQuyen('TRANG_DELETE'));
    }, [])

    const setFilt = (e) => {
        setFilter(e.target.value);
    }

    const Timkiem = () => {
        loadTrang();
    }

    const handleClose = () => {
        setShow(false);
        setIsXem(false);
    }
    const handleShow = () => {
        reset({
            id: 0,
            name: "",
            alias: "",
            status: true,
            delete: false,
            // content: "",
            homeflag: true
        }, [reset])
        setIsXem(false);
        setSrcImage(noImage);
        setckdData("");
        setAlias("");
        setShow(true);
    }

    const handleShowEdit = (isXem) => {
        let index = listTrang.findIndex(x => x.Checked);
        if (index !== -1) {
            TrangApi.getDetail(listTrang[index].id).then((response) => {
                reset({
                    id: response.id,
                    name: response?.name,
                    alias: response?.alias,
                    content: response?.content,
                    createddate: response?.createddate,
                    createdby: response?.createdby,
                    updateddate: response?.updateddate,
                    updatedby: response?.updatedby,
                    status: response?.status,
                    delete: response?.delete,
                    metakeyword: response?.metakeyword,
                    metadescription: response?.metadescription,
                    image: response?.image,
                    description: response?.description,
                    homeflag: response?.homeflag
                }, [reset]);
                setSrcImage(response?.image);
                setckdData(response?.content);
                setAlias(response?.alias);
                setIsXem(isXem);
                setIsEdit(true);
                setShow(true);
            });
        }
    }

    const onFileSelected = (event) => {
        setSrcImage(event);
    }

    const rowClick = (id) => {
        listTrang.forEach(x => {
            if (x.id === id) {
                x.Checked = !x.Checked;
            }
        });
        setListTrang([...listTrang]);
        let index = listTrang.findIndex(x => x.Checked);
        if (index !== -1) {
            setIsEdit(true);
        }
    }

    // const handleShowData = () => {
    //     let index = listTrang.findIndex(x => x.Checked);
    //     if (index !== -1) {
    //         TrangApi.getDetail(listTrang[index].id).then((response) => {
    //             reset({
    //                 id: response.id,
    //                 name: response.name,
    //                 alias: response.alias,
    //                 content: response.content,
    //                 createddate: response.createddate,
    //                 createdby: response.createdby,
    //                 updateddate: response.updateddate,
    //                 updatedby: response.updatedby,
    //                 status: response.status,
    //                 delete: response.delete,
    //                 metakeyword: response.metakeyword,
    //                 metadescription: response.metadescription,
    //                 image: response.image,
    //                 description: response.description,
    //                 homeflag: response.homeflag
    //             }, [reset]);
    //             setSrcImage(response.image);
    //             setckdData(response.content);
    //             setAlias(response.alias);
    //             setIsXem(false);
    //             setIsEdit(true);
    //             setShow(true);
    //         });
    //     }
    //     setIsXem(true);
    //     setShow(true);
    // }

    const showViewModalImage = () => {
        setShowModalImage(true);
    }
    const CloseViewModalImage = () => {
        setShowModalImage(false);
    }

    const changeCK = (event, editor) => {
        setckdData(editor.getData());
    }

    const onSubmit = async (data) => {
        try {
            data.metakeyword = '';
            data.metadescription = '';
            data.image = srcImage;
            data.content = ckdData;
            if (data.alias === null || data.alias === '') {
                data.alias = Ultility.MakeSeoTitle(data.name);
            }
            if (data.id=="0") {
                data.id = 1;
                const res = await TrangApi.createTrang(data);
            }
            else {
                const res = await TrangApi.updateTrang(data);
            }
            setShow(false);
            setIsXem(false);
            setIsEdit(false);
            loadTrang();
            reset({}, [reset]);
        }
        catch (ex) {
        }

    }

    const alertDell = () => {
        var lst = listTrang.filter(x => x.Checked === true);
        if (lst.length > 0) {
            ThongBao.printConfirmationDialog("Bạn có đồng ý xóa dữ liệu đã chọn hay không?", () => {
                TrangApi.DeleteTrang(lst[0].id);
                loadTrang();
                setIsEdit(false);
            });
        }
    }

    const onChangeTen = (event) => {
        let str = "";
        str = Ultility.MakeSeoTitle(event.target.value);
        setAlias(str);
    }

    return (
        <div className="card shadow mb-1">
            <div className="card-header py-2">
                <div className="row ml-2">
                    <div className="col-md-4">
                        <h6 className="m-0 font-weight-bold text-primary">Trang</h6>
                    </div>
                    <div className="col-md-4">
                        <div className="input-group">
                            <input type="text" className="form-control" placeholder="Tìm kiếm" value={filter} onChange={setFilt} />
                            <div className="input-group-append">
                                <button className="btn btn-info btn-sm" type="button" onClick={Timkiem} ><i className='fas fa-search'></i> Tìm kiếm</button>
                            </div>
                        </div>
                    </div>
                    <div className="fluid-container col-md-4 text-right">
                        {roleAdd == true ? <button type="button" className="btn btn-success btn-sm ml-1" onClick={handleShow}><i className='fas fa-plus'></i> Thêm</button> : ''}
                        {roleEdit == true ? <button type="button" className="btn btn-info btn-sm ml-1" disabled={!isEdit} onClick={()=>handleShowEdit(false)}><i className='far fa-edit'></i> Sửa</button> : ''}
                        {roleDel == true ? <button type="button" className="btn btn-danger btn-sm ml-1" disabled={!isEdit} onClick={alertDell} ><i className='fas fa-trash-alt'></i> Xóa</button> : ''}
                        <button type="button" className="btn btn-primary btn-sm ml-1 mr-2" disabled={!isEdit} onClick={()=>handleShowEdit(true)}><i className='far fa-eye'></i> Xem</button>
                    </div>
                </div>
            </div>

            <div className="card-body">
                <div>
                    <table className="table table-bordered" width="100%">
                        <thead>
                            <tr className="row text-center NhanCBox">
                                <td className="col-md-1"></td>
                                <td className="col-md-5">Tên trang</td>
                                <td className="col-md-5">Alias</td>
                                <td className="col-md-1">Trạng thái</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                listTrang.map((item, key) => {
                                    return (
                                        <tr className="row SelectRow" key={key} onClick={() => rowClick(item.id)}>
                                            <td className="col-md-1 text-center">
                                                <input type="radio" checked={item.Checked} onChange={e => item.Checked = e.target.value}></input>
                                            </td>
                                            <td className="col-md-5">{item.name}</td>
                                            <td className="col-md-5">{item.alias}</td>
                                            <td className="col-md-1 text-center col-status">
                                                {item.status == true &&
                                                    <span className="label label-success cls-status-label">Kích hoạt</span>
                                                }
                                                {item.status == false &&
                                                    <span className="label label-danger cls-status-label">Khóa</span>
                                                }

                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                    <div className="col-md-12 text-right">
                        <Pagination {...paginationConfig} />
                    </div>
                </div>
            </div>
            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} animation={false} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Trang</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="container-fluid">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-group row" hidden={true}>
                                <div className="col-md-3 col-sm-6 col-xl-3 col-form-label">
                                    <label className="nhanTK">ID <code>*</code></label>
                                </div>
                                <div className="col-md-9 col-sm-6 col-xl-9">
                                    <input className="form-control" name="id" type="Text" ref={register()}></input>
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">
                                    Tên bài viết <code>*</code>
                                </div>
                                <div className="col-md-10 col-sm-6 col-xl-10">
                                    <input className="form-control" name="name" type="Text" ref={register({ required: "Tên bài viết không được trống" })} onChange={onChangeTen}></input>
                                    <span className="eRor">{errors.name && errors.name.message}</span>
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">
                                    Alias
                                    <code> *</code>
                                </div>
                                <div className="col-md-10 col-sm-6 col-xl-10">
                                    <input className="form-control" name="alias" type="Text" value={alias} onChange={e => setAlias(e.target.value)} ref={register(
                                        { required: "Tên alias không được trống" }
                                    )}></input>
                                    <span className="eRor">{errors.alias && errors.alias.message}</span>
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">
                                    Mô tả
                                </div>
                                <div className="col-md-10 col-sm-6 col-xl-10">
                                    <textarea className="form-control" name="description" type="Text" ref={register()}></textarea>
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">
                                    Logo
                                </div>
                                <div className="col-md-3 col-sm-6 col-xl-3">
                                    <div className="thumbnail">
                                        <img name="image" src={url + srcImage} ref={register()} onClick={showViewModalImage} />
                                    </div>

                                </div>
                                <div className="col-md-3 col-sm-6 col-xl-3 mt-2 NhanCBox">
                                    <label ><input type="Checkbox" name="homeflag" ref={register()}></input>  Hiển thị trang chủ</label>
                                </div>
                                <div className="col-md-3 col-sm-6 col-xl-3 mt-2 NhanCBox">
                                    <label ><input type="Checkbox" name="status" ref={register()}></input>  Kích hoạt</label>
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-md-2 col-sm-6 col-xl-2 col-form-label nhanLH">
                                    Nội dung
                                </div>
                                <div className="col-md-10 col-sm-6 col-xl-10 ">
                                    <CKEditor className="ck-editor" editor={ClassicEditor} name="content" data={ckdData} onChange={(event, editor) => setckdData(editor.getData())}></CKEditor>
                                </div>
                            </div>
                        </form>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <a href="#" type="button" className="btn btn-info btn-sm ml-1" onClick={handleSubmit(onSubmit)} hidden={isXem}><i className='fas fa-save'></i> Lưu</a>
                    <a href="#" type="button" className="btn btn-danger btn-sm ml-1" onClick={handleClose} disabled><i className="fas fa-sign-out-alt"></i> Hủy</a>
                </Modal.Footer>
            </Modal>
            <QuanLyTapTin viewModal={showModalImage} closeModal={CloseViewModalImage} onFileSelected={(e) => onFileSelected(e)}></QuanLyTapTin>
        </div>


    );
};
