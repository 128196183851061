import React, { useEffect, useState } from 'react'
import { components } from 'react-select';
import AnhHuongSauBenhApi from '../../api/AnhHuongSauBenhApi';
import { SelectChuGiaiMulti } from './SelectChuGiaiMulti'

export const SelectSauMulti = (props) => {
    const [listDats, setDat] = useState([]);
    const [selectedOption, setSelectedOption] = useState();

    useEffect(() => {
        const loadDat = () => {
            AnhHuongSauBenhApi.getSauBenh().then((response) => {
                let list = [];
                console.log(response)
                response.forEach(item => {      
                        list.push({ value: item.id, label: item.ten, ten: item.ten });
                });
                setDat(list);
                // list.unshift(allOption);
                setSelectedOption([allOption, ...list]);
                setTimeout(() => {
                    props.onChangeSau(list);
                }, 100);
            })
        }
        loadDat();
    }, []);

    const allOption = {
        label: "Tất cả",
        value: 0,
        Ma: 0
    };

    const Option = props => {
        return (
            <div>
                <components.Option {...props}>
                    <input
                        type="checkbox"
                        checked={props.isSelected}
                        onChange={() => null}
                    />{" "}
                    <label>{props.label}</label>
                </components.Option>
            </div>
        );
    };

    const ValueContainer = ({ children, ...props }) => {
        const currentValues = props.getValue();
        let toBeRendered = children;
        if (currentValues.some(val => val.value === allOption.value)) {
            toBeRendered = [[children[0][0]], children[1]];
        }
        return (
            <components.ValueContainer {...props}>
                {toBeRendered}
            </components.ValueContainer>
        );
    };
    const MultiValue = props => {
        let labelToBeDisplayed = `${props.data.label}, `;

        const currentValues = props.getValue();
        if (currentValues.length > 2) {
            if (currentValues[currentValues.length - 1].value === props.data.value) {
                labelToBeDisplayed = `Bạn đã chọn (${currentValues.length})`
            }
            else {
                if (props.data.value === allOption.value) {
                    labelToBeDisplayed = allOption.label;
                }
                else {
                    return '';
                }
            }
        }
        return (
            <components.MultiValue {...props}>
                <span>{labelToBeDisplayed}</span>
            </components.MultiValue>
        );
    };
    const handleChange = (e) => {
        setSelectedOption(e);
        props.onChangeSau(e);

    }
    return (
        <span
            // class="d-inline-block"
            data-toggle="popover"
            data-trigger="focus"
            data-content="Please selecet account(s)"
            className="select"
        >
            <SelectChuGiaiMulti
                options={listDats}
                isMulti
                placeholder="Chọn tên sâu bệnh"
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{
                    Option,
                    MultiValue,
                    ValueContainer
                }}
                theme={theme => ({
                    ...theme,
                    borderRadius: 0,
                    height: 30,
                    spacing: {
                        controlHeight: 30,
                        menuGutter: 1,
                        baseUnit: 2,
                    }
                })}
                onChange={handleChange}
                allowSelectAll={true}
                value={selectedOption}
                styles={{ container: (base) => ({ ...base, zIndex: 500 }) }}
            />
        </span>
    )
}
