import React, { useState,useEffect } from 'react'
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { useHistory } from "react-router-dom";
import { style } from 'openlayers';
import ThongBao from '../../core/ThongBao';
import { Loading } from '../../shared/loading/Loading';
import { Modal, Button } from 'react-bootstrap';

export const DangNhap = (props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);        
        props.closeModal();
    }
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const { register, handleSubmit, errors } = useForm({
        mode: "onBlur"
    });

    useEffect(() => {
        if (props.show) {
            setShow(true);
        }
    }, [props.show]);

    const onSubmit = (data) => {
        setLoading(true);
        axios.post(process.env.REACT_APP_API_URL + '/login', data).then(response => {
            localStorage.setItem(process.env.REACT_APP_TOKEN, response.data.token);
            setLoading(false);
            // history.push("/#");
            history.go(0);
        }, (eror) => {
            if (eror.response) {
                if (eror.response.data === "Bad Request") {
                    ThongBao.printErrorMessage("Tên đăng nhập hoặc mật khẩu không đúng.");
                }
                else {
                    ThongBao.printErrorMessage(eror.response.data);
                }
            }
            setLoading(false);
        });
    }
    return (

        <Modal show={show} onHide={handleClose} animation={false}>
            <Modal.Header closeButton>
                <Modal.Title>ĐĂNG NHẬP HỆ THỐNG</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form className="user" onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group">
                        <input name="userName" type="text" autoFocus={true} className="form-control form-control-user" placeholder="Tên đăng nhập" style={{ ...style.input, borderColor: errors.userName && "red" }} ref={register({ require: true })} />
                        {errors.userName}
                    </div>
                    <div className="form-group">
                        <input name="password" type="password" className="form-control form-control-user" placeholder="Mật khẩu" style={{ ...style.input, borderColor: errors.password && "red" }} ref={register({ require: true })} />
                        {errors.password}
                    </div>
                    <button type="submit" className="btn btn-primary btn-lg btn-block btn-dangnhap" onClick={handleSubmit(onSubmit)}>Đăng nhập</button>
                </form>
            </Modal.Body>
            {/* <Modal.Footer>
                    <Button variant="success" size="sm" onClick={handleSubmit(saveData)}><i className='fas fa-save'></i>&nbsp;Lưu</Button>
                    <Button variant="danger" size="sm" onClick={handleClose}><i className="fas fa-sign-out-alt"></i>&nbsp;Hủy</Button>
                </Modal.Footer> */}
        </Modal>




    )
}
