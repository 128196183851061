import React, { useState, useEffect } from 'react'
import Select from 'react-select';
import Pagination from 'react-bootstrap-4-pagination';
import DonViCungCapApi from '../../../api/DonViCungCapApi';
import PhuongXaApi from '../../../api/PhuongXaApi';
import QuanHuyenApi from '../../../api/QuanHuyenApi';
import ExportExcelApi from '../../../api/ExportExcelApi';


export const ThongKeDonViCungCap = (props) => {
    var apiUrl = process.env.REACT_APP_URL;
    var noImage = "/uploadfiles/website/no_image-100x100.jpg";

    const [listPhuongXas, setListPhuongXa] = useState([]);
    const [listDonViCungCaps, setListDonViCungCaps] = useState([]);
    const [timListQuanHuyen, setTimListQuanHuyen] = useState([]);
    const [timListPhuongXa, setTimListPhuongXa] = useState([]);
    const [timQuanHuyen, setTimQuanHuyen] = useState();
    const [timXaPhuong, setTimXaPhuong] = useState();

    const [search, setSearch] = useState('');

    let pageIndex = 1;
    let pageSize = 20;

    const [paginationConfig, setPaging] = useState({
        totalPages: 1,
        currentPage: 1,
        showMax: 5,
        size: "sm",
        threeDots: true,
        prevNext: true,
        onClick: function (page) {
            pageIndex = page;
            loadDonViCungCap();
        }
    });

    useEffect(() => {
        loadDanhQuanHuyen();
        loadDanhPhuongXa(timQuanHuyen);
        loadDonViCungCap();
    }, []);

    const loadDonViCungCap = async () => {
        const maquanhuyen = timQuanHuyen == undefined ? '' : timQuanHuyen.value;
        const maxaphuong = timXaPhuong == undefined ? '' : timXaPhuong.value;
        try {
            const params = {
                filter: search,
                pageIndex: pageIndex,
                pageSize: pageSize,
                maquanhuyen: maquanhuyen,
                maxaphuong: maxaphuong,
            }
            const response = await DonViCungCapApi.getThongKe(params);
            response.Items.forEach(item => {
                item.Checked = false;
            });
            paginationConfig.totalPages = 1;
            if (response.Items.length > 0) {
                paginationConfig.totalPages = Math.ceil(response.TotalRows / pageSize);
            }
            paginationConfig.currentPage = pageIndex;
            setPaging(paginationConfig);
            setListDonViCungCaps(response.Items);
        } catch (err) {
        }
    }

    const ExportExcel = async () => {
        const maquanhuyen = timQuanHuyen == undefined ? '' : timQuanHuyen.value;
        const maxaphuong = timXaPhuong == undefined ? '' : timXaPhuong.value;
        try {
            const params = {
                filter: search,
                pageIndex: pageIndex,
                pageSize: pageSize,
                maquanhuyen: maquanhuyen,
                maxaphuong: maxaphuong,
            }
            ExportExcelApi.getDonViCungCap(params).then((res) => {
                const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                
                console.log(url)
                a.download = 'download.xlsx';
                a.click();
            }, () => {
                
            });
        } catch (err) {
        }
    }

    const exportExcel = () =>{
        ExportExcel();
    }

    const loadDanhQuanHuyen = () => {
        QuanHuyenApi.getAll().then((response) => {
            let list = [];
            list.push({ value: 0, label: 'Quận huyện...' });
            response.forEach(item => {
                list.push({ value: item.Ma, label: item.Ten });
            })
            setTimListQuanHuyen(list);
            if (props.mahuyen != null) {
                const index = list.findIndex(x => x.value == props.mahuyen);
                if (index != -1) {
                    setTimQuanHuyen(list[index]);
                }
            }
        })
    }

    function loadDanhPhuongXa(quanHuyen) {
        const params = {
            idQuanHuyen: Number(quanHuyen == undefined ? 0 : quanHuyen.value)
        }
        PhuongXaApi.getAll(params).then((response) => {
            let list = [];
            list.push({ value: 0, label: 'Phường xã...' });
            response.forEach(item => {
                list.push({ value: item.Ma, label: item.Ten, mahuyen: item.MaHuyen });
            });
            setListPhuongXa(list);
            if (props.mahuyen != null) {
                let listXas = list.filter(x => x.mahuyen == props.mahuyen);
                listXas.unshift({ value: '', label: 'Tất cả xã', mahuyen: '' });
                setTimListPhuongXa(listXas);
                const index = list.findIndex(x => x.value == props.maxa);
                if (index != -1) {
                    setTimXaPhuong(list[index]);
                }
            }
            else {
                setTimListPhuongXa(list);
            }
        })
    }

    const timKiem = () => {
        loadDonViCungCap();
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            loadDonViCungCap();
        }
    }

    const setFilter = (e) => {
        setSearch(e.target.value)
    }

    // TÌM KIẾM
    const onChangeTimQuanHuyen = (e) => {
        setTimQuanHuyen(e);
        let listXas = listPhuongXas.filter(x => x.mahuyen == e.value);
        listXas.unshift({ value: '', label: 'Tất cả xã', mahuyen: '' });
        setTimXaPhuong({ value: '', label: 'Tất cả xã', mahuyen: '' });
        setTimListPhuongXa(listXas);
    }

    const onChangeTimXaPhuong = (e) => {
        setTimXaPhuong(e);
    }

    return (

        <div className="card shadow mb-1">
            {
                props.showName == true ? <div className="row">
                    <div className="col-md-12">
                        <div className="breadcrumb-container">
                            <a href="/" className="breadcrumb-dis">Trang chủ / </a>
                            <a href="/public/don-vi-cung-cap" className="breadcrumb-active"> Đơn vị cung cấp vật tư nông nghiệp</a>
                            <div className="breadcrumb-line">
                            </div>
                        </div>
                    </div>
                </div> : ''
            }
            <div className="card-header row h-solieu">
                <div className="col-md-3">
                    <Select placeholder="Quận huyện"
                        onChange={onChangeTimQuanHuyen} options={timListQuanHuyen} value={timQuanHuyen} theme={theme => ({
                            ...theme,
                            borderRadius: 0,
                            height: 30,
                            spacing: {
                                controlHeight: 30,
                                menuGutter: 1,
                                baseUnit: 2,
                            }
                        })} />
                </div>
                <div className="col-md-3">
                    <Select placeholder="Phường xã"
                        onChange={onChangeTimXaPhuong} options={timListPhuongXa} value={timXaPhuong} theme={theme => ({
                            ...theme,
                            borderRadius: 0,
                            height: 30,
                            spacing: {
                                controlHeight: 30,
                                menuGutter: 1,
                                baseUnit: 2,
                            }
                        })} />
                </div>
                <div className="col-md-3">
                    <div className="input-group">
                        <input type="text" className="form-control" placeholder="Tìm kiếm" value={search} onChange={setFilter} onKeyPress={handleKeyPress} />
                        <div className="input-group-append">
                            <button className="btn btn-info btn-sm" type="button" onClick={timKiem} ><i className='fas fa-search'></i></button>
                            <button className="btn btn-primary btn-sm" type="button" onClick={exportExcel} ><i className='fas fa-file-excel'></i> Excel</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-body">
                <div id="div-table">
                    <table className="table table-bordered" width="100%">
                        <thead>
                            <tr>
                                <th>Tên</th>
                                <th>Điện thoại</th>
                                <th>Địa chỉ</th>
                                <th>Số đkkd</th>
                                <th>Đơn vị cấp đkkd</th>
                                <th>Ngày cấp đkkd</th>
                                {/* <th>Số CCCM</th>
                                <th>Ngày cấp CCCM</th> */}
                                {/* <th>Số CNDDK</th> */}
                                {/* <th>Ngày cấp CNDDK</th> */}
                                {/* <th>Ngày hết hạn CNDDK</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {
                                listDonViCungCaps.map((item, key) => {
                                    return (
                                        <tr  key={key}>
                                            <td>{item.Ten}</td>
                                            <td>{item.DienThoai}</td>
                                            <td>{item.DiaChi}</td>
                                            <td>{item.SoDangKyKinhDoanh}</td>
                                            <td>{item.DonViCapDangKyKinhDoanh}</td>
                                            <td>{item.NgayCapDangKyKinhDoanh}</td>
                                            {/* <td>{item.SoChungChiChuyenMon}</td>
                                            <td>{item.NgayCapChungChiChuyenMon}</td> */}
                                            {/* <td>{item.SoChungNhanDieuKien}</td> */}
                                            {/* <td>{item.NgayCapChungNhanDieuKien}</td> */}
                                            {/* <td>{item.NgayHetHanChungNhanDieuKien}</td> */}
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <div className="col-md-12 text-right">
                    <Pagination {...paginationConfig} />
                </div>
            </div>
        </div>
    )


}