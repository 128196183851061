import React, { useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import QuanHuyenApi from '../../api/QuanHuyenApi';
import Pagination from 'react-bootstrap-4-pagination';
import BaoCaoApi from '../../api/BaoCaoApi';
import { QuanLyTapTin } from '../../shared/quanlytaptin/QuanLyTapTin';
import ThongBao from '../../core/ThongBao';
import Authen from '../../core/Authen';
import LoaiBaoCaoApi from '../../api/LoaiBaoCaoApi';

export const BaoCao = () => {
    const [listBaoCaos, setBaoCao] = useState([]);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const { register, handleSubmit, reset } = useForm();
    const [filter, setFilter] = useState('');
    const [listQuanHuyens, setQuanHuyen] = useState([]);
    const [selectedOption, setSelectedOption] = useState('0');
    const [showQuanLyTapTin, setShowQuanLyTapTin] = useState(false);
    const [tapTin, setTapTin] = useState(null);
    const [roleAdd, setRoleAdd] = useState(false);
    const [roleEdit, setRoleEdit] = useState(false);
    const [roleDel, setRoleDel] = useState(false);

    const [selectLoaiBaoCao, setLoaiBaoCao] = useState('0');
    const [listLoaiBaoCao, setListLoaiBaoCao] = useState([]);
    const [selectLoaiBaoCao_TimKiem, setLoaiBaoCao_TimKiem] = useState('0');


    let pageIndex = 1;
    let pageSize = 20;
    const [paginationConfig, setPaging] = useState({
        totalPages: 1,
        currentPage: 1,
        showMax: 5,
        size: "sm",
        threeDots: true,
        prevNext: true,
        center: true,
        onClick: function (page) {
            pageIndex = page;
            loadBaoCao();
        }
    });

    const loadBaoCao = async () => {
        try {
            const params = {
                pageIndex: pageIndex,
                pageSize: pageSize,
                filter: filter,
                idQuanHuyen: 0,
                idPhuongXa: 0,
                idLoaiBaoCao: selectLoaiBaoCao_TimKiem.value || 0
            }
            const response = await BaoCaoApi.getPaging(params);
            response.Items.forEach(item => {
                item.Checked = false;
            });
            paginationConfig.totalPages = response.TotalRow == 0 ? 1 : Math.ceil(response.TotalRow / pageSize);
            paginationConfig.currentPage = pageIndex;
            paginationConfig.onClick = function (page) {
                pageIndex = page;
                loadBaoCao();
            }
            setPaging(paginationConfig);
            setBaoCao(response.Items);
        } catch (err) {
        }
    }

    useEffect(() => {
        loadQuanHuyen();
        loadLoaiBaoCao();
        loadBaoCao();
        setRoleAdd(true);
        setRoleEdit(true);
        setRoleDel(true);
        setRoleAdd(Authen.checkQuyen('BAOCAOCHUYENDE_CREATE'));
        setRoleEdit(Authen.checkQuyen('BAOCAOCHUYENDE_UPDATE'));
        setRoleDel(Authen.checkQuyen('BAOCAOCHUYENDE_DELETE'));
    }, []);

    const timKiem = () => {
        loadBaoCao();
    }

    const saveData = (data) => {
        if (data.ThuTu == "") {
            data.ThuTu = 1;
        }
        if (selectedOption.value) {
            data.IdQuanHuyen = selectedOption.value;
        }
        if (selectLoaiBaoCao.value) {
            data.IdLoaiBaoCao = selectLoaiBaoCao.value;
        }
        data.TapTin = tapTin;
        if (data.IdBaoCao === "0") {
            BaoCaoApi.post(data).then(() => {
                setShow(false);
                loadBaoCao();
            })
        }
        else {
            BaoCaoApi.put(data).then(() => {
                setShow(false);
                loadBaoCao();
            })
        }
    }

    const clickRow = (id) => {
        listBaoCaos.forEach(item => {
            if (item.IdBaoCao === id) {
                item.Checked = !item.Checked;
            }
        });
        setBaoCao([...listBaoCaos]);
    }

    const handleShowAdd = () => {
        reset({
            IdBaoCao: 0,
            TenBaoCao: null,
            ThuTu: null,
            TapTin: null,
            Status: true,
            MoTa: null,
            IdQuanHuyen: null
        }, [reset]);
        setTapTin(null);
        setShow(true)
    };

    const handleShowEdit = () => {
        let index = listBaoCaos.findIndex(x => x.Checked);
        if (index !== -1) {
            BaoCaoApi.getDetail(listBaoCaos[index].IdBaoCao).then((response) => {
                reset({
                    IdBaoCao: response.IdBaoCao,
                    TenBaoCao: response.TenBaoCao,
                    TapTin: response.TapTin,
                    ThuTu: response.SortOrder,
                    Status: response.Status,
                    IdQuanHuyen: response.IdQuanHuyen,
                    IdLoaiBaoCao: response.IdLoaiBaoCao,
                    MoTa: response.MoTa
                }, [reset]);
                let index = listQuanHuyens.findIndex(x => x.value == response.IdQuanHuyen);
                setSelectedOption(listQuanHuyens[index]);
                let n = listLoaiBaoCao.findIndex(x => x.value == response.IdLoaiBaoCao);
                setLoaiBaoCao(listLoaiBaoCao[n]);
                setTapTin(response.TapTin);
                setShow(true);
            });
        }
    }

    const handleShowDel = () => {
        let index = listBaoCaos.findIndex(x => x.Checked);
        if (index !== -1) {
            let lstId = [];
            listBaoCaos.filter(x => x.Checked).forEach(element => {
                lstId.push(element.IdBaoCao);
            });
            ThongBao.printConfirmationDialog("Bạn có muốn xóa bản ghi này?", () => {
                BaoCaoApi.delete(lstId).then(() => {
                    ThongBao.printSuccessMessage("Xóa khoảnh đất thành công!");
                    loadBaoCao();
                });
            });
        }
    }

    const checkBoHandler = () => {

    }

    const onChangeQuanHuyen = (event) => {
        setSelectedOption(event);
    }

    function loadQuanHuyen() {
        var user=Authen.getUser();
        QuanHuyenApi.getAllByAuth().then((response) => {
            let list = [];
            list.push({ value: '0', label: 'Chọn huyện' });
            response.forEach(item => {
                list.push({ value: item.Id, label: item.Ten });
            })
            setQuanHuyen(list);
        })
    }

    const openFile = () => {
        setShowQuanLyTapTin(true);
    }

    const onChangeFilter = (e) => {
        setTapTin(e);
    }

    const closeModalQuanLyTapTin = () => {
        setShowQuanLyTapTin(false);
    }

    const huyTapTin = () => {
        setTapTin(null);
    }

    function loadLoaiBaoCao() {
        LoaiBaoCaoApi.getAll().then((response) => {
            let list = [];
            list.push({ value: '0', label: 'Chọn loại báo cáo' });
            response.forEach(item => {
                list.push({ value: item.IdLoaiBaoCao, label: item.Ten });
            })
            setListLoaiBaoCao(list);
        })
    }


    const onChangeLoaiBaoCao = (e) => {
        setLoaiBaoCao(e);
    }

    const onChangeLoaiBaoCao_TimKiem = (e) => {
        setLoaiBaoCao(e.value);
        loadBaoCao(e.value);
    }

    return (
        <div className="card shadow mb-1">
            <div className="card-header py-2 row">
                <div className="col-md-2">
                    <h6 className="m-0 font-weight-bold text-primary">Báo cáo</h6>
                </div>
                <div className="col-md-3">
                    <Select
                        className="select"
                        name="LoaiBaoCao_TimKiem"
                        placeholder="Chọn loại báo cáo"
                        defaultValue={selectLoaiBaoCao_TimKiem}
                        onChange={onChangeLoaiBaoCao_TimKiem}
                        options={listLoaiBaoCao}
                    />
                </div>
                <div className="input-group col-md-3">
                    <input type="text" className="form-control" placeholder="Tìm kiếm" value={filter} onChange={onChangeFilter} />
                    <div className="input-group-append">
                        <button className="btn btn-info" type="button" onClick={timKiem}><i className='fas fa-search'></i> Tìm kiếm</button>
                    </div>
                </div>
                <div className="col-md-4 bd-button text-right">
                    {roleAdd == true ? <Button variant="success" size="sm" onClick={handleShowAdd}><i className='fas fa-plus'></i> Thêm</Button> : ''}
                    {roleEdit == true ? <Button variant="primary" size="sm" onClick={handleShowEdit}><i className='fas fa-edit'></i> Sửa</Button> : ''}
                    {roleDel == true ? <Button variant="danger" size="sm" onClick={handleShowDel}><i className='fas fa-trash-alt'></i> Xóa</Button> : ''}
                </div>
            </div>
            <div className="card-body table-solieu">
                <div className="table-responsive">
                    <table className="table table-bordered" width="100%">
                        <thead>
                            <tr>
                                <th className="col-checkbox"></th>
                                <th>Tên</th>
                                <th>Thứ tự</th>
                                <th>Huyện</th>
                                <th>Tập tin</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                listBaoCaos.map((item, key) => {
                                    return (
                                        <tr onClick={() => clickRow(item.IdBaoCao)} key={key}>
                                            <td className="col-checkbox">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" checked={item.Checked} onChange={checkBoHandler} />
                                                </div>
                                            </td>
                                            <td>{item.TenBaoCao}</td>
                                            <td>{item.ThuTu}</td>
                                            <td>{item.TenQuanHuyen}</td>
                                            <td className="col-taptin">{item.TapTin != null ? (
                                                <a href={process.env.REACT_APP_URL + item.TapTin} target="_bank"><i className="fa fa-file"></i></a>
                                            ) : ''}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <div className="col-md-12 phan-trang">
                    <Pagination {...paginationConfig} />
                </div>
            </div>

            <Modal show={show} onHide={handleClose} animation={false} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Báo cáo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="user" onSubmit={handleSubmit(saveData)}>
                        <input name="IdBaoCao" hidden={true} ref={register}></input>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label">Tên báo cáo <code>*</code></label>
                            <div className="col-sm-10">
                                <input name="TenBaoCao" type="text" className="form-control" placeholder="Tên báo cáo" ref={register} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label">Huyện</label>
                            <div className="col-sm-10">
                                <Select
                                    className="select"
                                    name="IdQuanHuyen"
                                    placeholder="Quận huyện"
                                    value={selectedOption}
                                    onChange={onChangeQuanHuyen}
                                    options={listQuanHuyens}
                                    theme={theme => ({
                                        ...theme,
                                        borderRadius: 0,
                                        height: 30,
                                        spacing: {
                                            controlHeight: 30,
                                            menuGutter: 1,
                                            baseUnit: 2,
                                        }
                                    })}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label">Loại báo cáo <code>*</code></label>
                            <div className="col-sm-6">
                                <Select
                                    className="select"
                                    name="IdLoaiBaoCao"
                                    placeholder="Chọn loại báo cáo"
                                    defaultValue={selectLoaiBaoCao}
                                    onChange={onChangeLoaiBaoCao}
                                    options={listLoaiBaoCao}
                                />
                            </div>
                            <label className="col-sm-2 col-form-label">Thứ tự</label>
                            <div className="col-sm-2">
                                <input name="ThuTu" type="number" className="form-control" placeholder="Thứ tự" ref={register} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label">Tập tin</label>
                            <div className="col-sm-10">
                                {
                                    tapTin === null ? (<button type="button" className="btn btn-info btn-sm" onClick={openFile}>Tập tin đính kèm</button>) :
                                        (<div className="input-group mb-3">
                                            <input type="text" className="form-control" value={tapTin} readOnly />
                                            <div className="input-group-append">
                                                <button className="btn btn-danger" type="button" onClick={huyTapTin}>Hủy</button>
                                            </div>
                                        </div>)
                                }
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label">Mô tả ngắn</label>
                            <div className="col-sm-10">
                                <textarea className="form-control" rows="3" placeholder="Mô tả" name="MoTa" ref={register}></textarea>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label">Kích hoạt</label>
                            <div className="col-md-4">
                                <input name="Status" className="form-check-input" type="Checkbox" ref={register}></input>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" size="sm" onClick={handleSubmit(saveData)}><i className='fas fa-save'></i> Lưu</Button>
                    <Button variant="danger" size="sm" onClick={handleClose}><i className="fas fa-sign-out-alt"></i> Hủy</Button>
                </Modal.Footer>
            </Modal>
            <QuanLyTapTin viewModal={showQuanLyTapTin} onFileSelected={onChangeFilter} closeModal={closeModalQuanLyTapTin}></QuanLyTapTin>
        </div>
    )
}
