import React, { useState, useEffect } from 'react'
import $ from 'jquery';
import Draggable from 'react-draggable';
import TrieuChungApi from '../../api/TrieuChungApi'
import SauBenhApi from '../../api/SauBenhApi'
import { Modal, Button } from 'react-bootstrap';

export const TrieuChungSauBenh = (props) => {
    var apiUrl = process.env.REACT_APP_URL;
    var noImage = "/uploadfiles/website/no_image-100x100.jpg";
    let [listDacDiemTrieuChung, setListDacDiemTrieuChung] = useState([]);
    let [listDaChon, setListDaChon] = useState([]);
    let [listSauBenh, setListSauBenh] = useState([]);
    let [isXemKetQua, setIsXemKetQua] = useState(false);
    let [show, setShow] = useState(false);
    let [dacDiemTrieuChung, setDacDiemTrieuChung] = useState({});
    let theme = {
        borderRadius: 0,
        height: 30,
        spacing: {
            controlHeight: 30,
            menuGutter: 1,
            baseUnit: 2,
        }
    }
    useEffect(() => {
        loadDacDiemTrieuChung(props.timKiem.IdCayTrong, props.timKiem.IdQuanHuyen, props.timKiem.IdXaPhuong, props.timKiem.Thang);
        setListDaChon([]);
    }, [props.timKiem]);

    //#region câu hỏi
    const loadDacDiemTrieuChung = (idCayTrong, idQuanHuyen, idXaPhuong, thang) => {
        var params = {
            pageIndex: 1,
            idCayTrong: idCayTrong,
            idQuanHuyen: idQuanHuyen,
            idXaPhuong: idXaPhuong,
            thang: thang
        }
        TrieuChungApi.getTraCuu(params).then((response) => {
            response.Items.forEach(element => {
                if (element.HinhAnh == null) {
                    element.HinhAnh = '';
                }
                var lst = element.HinhAnh.split(',');
                if (lst.length > 0) {
                    element.HinhAnh_Temp = lst[0];
                }
                else {
                    element.HinhAnh_Temp = '';
                }
            });
            setListDacDiemTrieuChung(response.Items);
        })
    }

    const selectedDacDiemTrieuChung = (item, key) => {
        if (listDaChon == null) {
            listDaChon = [];
        }
        if (listDacDiemTrieuChung[key].ClassCss == "selected-dacdiemtrieuchung") {
            listDacDiemTrieuChung[key].ClassCss = "";
            for (let index = 0; index < listDaChon.length; index++) {
                if (listDaChon[index].Id == item.Id) {
                    listDaChon.splice(index, 1);
                    break;
                }
            }
        }
        else {
            listDacDiemTrieuChung[key].ClassCss = "selected-dacdiemtrieuchung";
            listDaChon.push(item);
        }
        setListDacDiemTrieuChung([...listDacDiemTrieuChung]);
        setListDaChon([...listDaChon]);
    }

    const removeDacDiemTrieuChung = (item, key) => {
        for (let index = 0; index < listDacDiemTrieuChung.length; index++) {
            if (listDacDiemTrieuChung[index].Id == item.Id) {
                listDacDiemTrieuChung[index].ClassCss = "";
            }
        }
        listDaChon.splice(key, 1);
        setListDaChon([...listDaChon]);
        setListDacDiemTrieuChung([...listDacDiemTrieuChung]);
    }
    //#endregion

    const onClickChuanDoanBenh = () => {
        setIsXemKetQua(false);
        $('#modalchuandoansaubenh').modal('show');
    }

    const handleClose = () => {
        $('#modalchuandoansaubenh').modal('hide');
        $('#modalchuandoansaubenh').modal('dispose');
        // props.handleClose();
    }

    const onClickXemKetQua = () => {
        if (listDaChon.length > 0) {
            setIsXemKetQua(true);
            var listBieuHien = [];
            listDaChon.forEach(element => {
                listBieuHien.push(element.IdSauBenh);
            });
            var params = {
                listBieuHien: listBieuHien.toString()
            }
            // TrieuChungApi.getAll().then((response) => {
            SauBenhApi.getByTrieuChung(params).then((response) => {
                setListSauBenh(response);
            })
        }
    }

    const onClickXemChiTiet = (item) => {
        setDacDiemTrieuChung(item);
        setShow(true);
    }

    const handleCloseChiTiet = () => {
        setShow(false);
    }

    return (
        <>
            <div className="form-group row main-content">
                <h4 className="text-center col-md-12">Lựa chọn triệu chứng</h4>
                <div className="form-group row main-content">
                    {
                        listDacDiemTrieuChung.map((item, key) => {
                            return (
                                <div className="col-md-3 trieuchung" key={key}>
                                    <div className={"trieuchung-container " + item.ClassCss}>
                                        <div onClick={() => selectedDacDiemTrieuChung(item, key)}>
                                            <img src={apiUrl + (item.HinhAnh_Temp == null || item.HinhAnh_Temp=='' ? noImage : item.HinhAnh_Temp)} alt={item.TenCayTrong} />
                                            <div className="trieuchung-mota">
                                                {item.MoTa}
                                            </div>
                                        </div>
                                        <span className="trieuchung-xemchitiet" onClick={() => onClickXemChiTiet(item)}>
                                            Xem chi tiết.....
                                        </span>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className="form-group row text-center">
                <button type="button" className="btn btn-success btn-sm btn-chuandoan " onClick={() => onClickChuanDoanBenh()} >
                    Tiến hành chẩn đoán</button>
            </div>

            <Draggable handle=".modal-header">
                <div className="modal modal-custom" id="modalchuandoansaubenh" tabIndex={-1} data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                {isXemKetQua == false &&
                                    <h5 className="modal-title">Triệu chứng đã chọn</h5>
                                }
                                {isXemKetQua == true &&
                                    <h5 className="modal-title">Kết quả chẩn đoán bệnh</h5>
                                }
                                <button type="button" className="close" onClick={handleClose}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {isXemKetQua == false &&
                                    <>
                                        {listDaChon.length == 0 &&
                                            <div className="form-group row">
                                                <label className="col-md-12">Chưa chọn triệu chứng</label>
                                            </div>
                                        }
                                        {
                                            listDaChon.map((item, key) => {
                                                return (
                                                    <>
                                                        <div className="form-group row ketquachuandoan">
                                                            <img className="col-md-3" src={apiUrl + (item.HinhAnh_Temp == null || item.HinhAnh_Temp=='' ? noImage : item.HinhAnh_Temp)} alt={item.TenCayTrong} />
                                                            <div className="col-md-8">
                                                                {item.MoTa}
                                                            </div>
                                                            <div>
                                                                <span className="btn label label-danger cls-status-label" onClick={() => removeDacDiemTrieuChung(item, key)}>Xóa</span>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })
                                        }
                                    </>
                                }
                                {isXemKetQua == true &&
                                    <>
                                        <div className="form-group row">
                                            <h4 className="col-md-12 text-center">Kết quả chẩn đoán.</h4>
                                        </div>
                                        {listSauBenh.map((item, key) => {
                                            return (
                                                <div className="form-group row ketquachuandoan">
                                                    <img className="col-md-3" src={apiUrl + (item.HinhAnh == null || item.HinhAnh=='' ? noImage : item.HinhAnh)} alt={item.TenSauBenh} />
                                                    <div className="col-md-8">
                                                        <div className="font-weight-bold">
                                                            {item.TenSauBenh}
                                                        </div>
                                                        <div>
                                                            {item.MoTa}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        }
                                    </>
                                }
                            </div>
                            <div className="modal-footer">
                                {isXemKetQua == false &&
                                    <button type="button" className="btn btn-success btn-sm" onClick={() => onClickXemKetQua()}> Xem kết quả</button>
                                }
                                {isXemKetQua == true &&
                                    <button type="button" className="btn btn-danger btn-sm" onClick={handleClose}><i className="fas fa-sign-out-alt"></i> Đóng</button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Draggable>

            <Modal show={show} onHide={handleCloseChiTiet} animation={false} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Triệu chứng</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group row">
                        <div className="col-sm-12">
                            {dacDiemTrieuChung.SauBenh}
                        </div>
                        <div className="col-sm-12">
                            {dacDiemTrieuChung.GiaiDoan}
                        </div>
                        <div className="col-sm-12">
                            <img className="trieuchung-hinhanh-chitiet" src={apiUrl + (dacDiemTrieuChung.HinhAnh_Temp == null  ||dacDiemTrieuChung.HinhAnh_Temp==''? noImage : dacDiemTrieuChung.HinhAnh_Temp)} alt={dacDiemTrieuChung.TenSauBenh} />
                        </div>
                        <div className="col-sm-12">
                            {dacDiemTrieuChung.MoTa}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" size="sm" onClick={handleCloseChiTiet}><i className="fas fa-sign-out-alt"></i>&nbsp;Hủy</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
