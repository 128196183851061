import axiosClient from './axiosClient';

const TrangApi = {

    createTrang: (params) => {
        const url = '/Trang/add';
        return axiosClient.post(url, params);

    },

    getDetail: (id) => {
        const url = '/Trang/getbyid/' + id;
        return axiosClient.get(url);
    },

    updateTrang: (params) => {
        const url = '/Trang/edit';
        return axiosClient.put(url, params);
    },

    DeleteTrang: (Id) => {
        const url = '/Trang/delete/' + Id;
        return axiosClient.delete(url);
    },

    getTrang: (params) => {
        const url = '/Trang/paging';
        return axiosClient.get(url, { params });
    },

    getAll: (params) => {
        const url = '/Trang/getall';
        return axiosClient.get(url, { params });
    },

    getByAlias: (params) => {
        const url = '/trang/getbyalias';
        return axiosClient.get(url, { params });
    }

}

export default TrangApi;