const { default: axiosClient } = require("./axiosClient");

const PhuongXaApi = {
    // getPaging: (params) => {
    //     const url = '/tinh/paging';
    //     return axiosClient.get(url, {params});
    // },

    // getDetail: (id) =>{
    //     const url = '/tinh/getbyid/' + id;
    //     return axiosClient.get(url);
    // },

    // post: (data) =>{
    //     const url = '/tinh/add';
    //     return axiosClient.post(url, data);
    // },

    // put: (data) => {
    //     const url = '/tinh/edit';
    //     return axiosClient.put(url, data);
    // },

    // delete: (id) =>{
    //     const url = '/tinh/delete/' + id;
    //     return axiosClient.delete(url);
    // },
    getAll: (params) =>{
        const url = 'phuongxa/getall';
        return axiosClient.get(url);
    },
    getByTinhThanh: (params) =>{
        const url = 'phuongxa/getbytinhthanh';
        return axiosClient.get(url, {params});
    }
}

export default PhuongXaApi;