import React, { useState, useEffect } from 'react'
import LienHeApi from '../../api/LienHeApi';
import GoogleMapReact from 'google-map-react';
import Marker from './Marker';

export const LienHe = () => {

    let pageIndex = 1;
    let pageSize = 20;
    let filter = '';
    const [lienHe, setLienHe] = useState({});
    const [local, setLocal] = useState({
        center: { lat: 0, lng: 0 },
        zoom: 15
    });
    useEffect(() => {
        const loadLienHes = async () => {
            try {
                const params = {
                    pageIndex: pageIndex,
                    pageSize: pageSize,
                    filter: filter
                }
                const response = await LienHeApi.getLienHe(params);
                if (response.Items.length > 0) {
                    setLocal({
                        center: {
                            lat: response.Items[0].Lat,
                            lng: response.Items[0].Lng
                        },
                        zoom: 14
                    });
                    setLienHe(response.Items[0]);
                }
            } catch (err) {
            }
        }
        loadLienHes();
    }, []);

    return (
        <div className="container p-content">
            <div className="row">
                <div className="col-md-12">
                    <div className="breadcrumb-container">
                        <a href="/" className="breadcrumb-dis">Trang chủ / </a>
                        <a href="/public/lien-he" className="breadcrumb-active"> Liên hệ</a>
                        <div className="breadcrumb-line">
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 lh-thong-tin">
                    <div className="title-lienhe"></div>
                    <div>Địa chỉ: {lienHe.Address}</div>
                    <div>Điện thoại: {lienHe.Phone}</div>
                    <div>Email: {lienHe.Email}</div>
                </div>
            </div>
            <div className="lh-phan-hoi">
                <h3>Phản hồi</h3>
                <form>
                    <div className="form-group">
                        <label>Họ và tên</label>
                        <input type="text" className="form-control" placeholder="Họ và tên" />
                    </div>
                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <label>Điện thoại</label>
                            <input type="text" className="form-control" placeholder="Điện thoại" />
                        </div>
                        <div className="form-group col-md-6">
                            <label>Email</label>
                            <input type="text" className="form-control" placeholder="Email" />
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Nội dung</label>
                        <textarea type="text" className="form-control" placeholder="Nội dung" rows={5} defaultValue={""} />
                    </div>
                    <div className="form-group text-right">
                        <button type="button" className="btn btn-success">Gửi phản hồi</button>
                    </div>
                </form>
            </div>
            <div className="row">
                <div className="col-md-12" style={{ height: '400px' }}>
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: 'AIzaSyAk3Eeu6MXm1Y6qil2hO6j89kckY5nnSTo' }}
                        defaultCenter={local.center}
                        center={local.center}
                        defaultZoom={local.zoom}>
                        <Marker
                            key={lienHe.Id}
                            text={lienHe.Name}
                            lat={lienHe.Lat}
                            lng={lienHe.Lng}
                        />
                    </GoogleMapReact>
                </div>
            </div>
        </div>
    )
}
