import React, { useState, useEffect } from 'react'
import CayTrongApi from '../../api/CayTrongApi'
import QuanHuyenApi from '../../api/QuanHuyenApi'
import PhuongXaApi from '../../api/PhuongXaApi'
import Select from 'react-select';
import { TrieuChungSauBenh } from './trieuchungsaubenh'

export const TraCuuSauBenh = () => {
    var apiUrl = process.env.REACT_APP_URL;
    var noImage = "/uploadfiles/website/no_image-100x100.jpg";
    const [listCayTrong, setListCayTrong] = useState([{ value: 0, label: 'Chọn cây trồng...' }]);
    const [currentItem, setCurrentItem] = useState({});

    let [cayTrong, setCayTrong] = useState({});
    let itemQuanHuyen = { value: 0, label: 'Chọn quận huyện.....' };
    let itemXaPhuong = { value: 0, label: 'Chọn xã phường.....' };
    let itemThang = { value: 0, label: 'Chọn tháng.....' };
    let [listQuanHuyen, setListQuanHuyen] = useState([itemQuanHuyen]);
    let [listXaPhuong, setListXaPhuong] = useState([itemXaPhuong]);
    let [listThang, setListThang] = useState([itemThang]);
    const [selectedOptionQuanHuyen, setSelectedOptionQuanHuyen] = useState();
    const [selectedOptionXaPhuong, setSelectedOptionXaPhuong] = useState();
    const [selectedOptionThang, setSelectedOptionThang] = useState();
    const [timKiem, setTimKiem] = useState({});
    let theme = {
        borderRadius: 0,
        height: 30,
        spacing: {
            controlHeight: 30,
            menuGutter: 1,
            baseUnit: 2,
        }
    }
    useEffect(() => {
        setSelectedOptionQuanHuyen(listQuanHuyen[0]);
        setSelectedOptionXaPhuong(listXaPhuong[0]);
        setSelectedOptionThang(listThang[0]);
        loadListCayTrong();
        LoadThang();
        loadQuanHuyen();
    }, []);

    //#region câu hỏi
    const changeCayTrong = (item) => {
        listCayTrong.forEach(element => {
            element.ClassCss = "btn-secondary";
        });
        item.ClassCss = "btn-success";
        setListCayTrong([...listCayTrong]);
        setCurrentItem(item);
        setTimKiem({ IdCayTrong: item.Id, IdQuanHuyen: selectedOptionQuanHuyen.value, IdXaPhuong: selectedOptionXaPhuong.value, Thang: selectedOptionThang.value });
    }
    //#endregion

    const loadListCayTrong = () => {
        CayTrongApi.getAll().then((response) => {
            response.forEach(element => {
                element.ClassCss = "btn-secondary";
            });
            if (response.length > 0) {
                response[0].ClassCss = "btn-success";
                setCayTrong(response[0]);
                setTimKiem({ IdCayTrong: response[0].Id, IdQuanHuyen: 0, IdXaPhuong: 0, Thang: 0 })

            }
            setListCayTrong(response);
        })
    }

    const LoadThang = () => {
        for (let index = 1; index <= 12; index++) {
            listThang.push({ value: index, label: 'Tháng ' + index.toString() })
        }
    }

    const loadQuanHuyen = () => {
        QuanHuyenApi.getAll().then(response => {
            var lst=[];
            lst.push(itemQuanHuyen);
            response.forEach(element => {
                lst.push({ value: element.Ma, label: element.Ten })
            });
            setListQuanHuyen(lst);
            loadXaPhuong(0);
        })
    }

    const loadXaPhuong = (idQuanHuyen) => {
        if (idQuanHuyen == 0) {
            setListXaPhuong([itemXaPhuong]);
        }
        else {
            QuanHuyenApi.getXaByHuyen({ maHuyen: idQuanHuyen }).then(response => {
                var lst = [];
                lst.push(itemXaPhuong);
                response.forEach(element => {
                    lst.push({ value: element.maXa, label: element.tenXa })
                });
                setListXaPhuong(lst);
            })
        }
    }

    const onChangeQuanHuyen = (event) => {
        setSelectedOptionQuanHuyen(event);
        setSelectedOptionXaPhuong(listXaPhuong[0]);
        loadXaPhuong(event.value);
        setTimKiem({ IdCayTrong: cayTrong.Id, IdQuanHuyen: event.value, IdXaPhuong: 0, Thang: selectedOptionThang.value });
    }

    const onChangeXaPhuong = (event) => {
        setSelectedOptionXaPhuong(event);
        setTimKiem({ IdCayTrong: cayTrong.Id, IdQuanHuyen: selectedOptionQuanHuyen.value, IdXaPhuong: event.value, Thang: selectedOptionThang.value });

    }

    const onChangeThang = (event) => {
        setSelectedOptionThang(event);
        setTimKiem({ IdCayTrong: cayTrong.Id, IdQuanHuyen: selectedOptionQuanHuyen.value, IdXaPhuong: selectedOptionXaPhuong.value, Thang: event.value });
    }

    return (
        <div className="fl-builder-content">
                <div className="col-md-12">
                    <div className="breadcrumb-container">
                        <a href="/" className="breadcrumb-dis">Trang chủ / </a>
                        <a href="#" className="breadcrumb-active"> Tra cứu sâu bệnh</a>
                        <div className="breadcrumb-line">
                        </div>
                    </div>
                </div>
                <div className="main-content">
                    <div className="col-md-12">
                        <h4 className="text-center">Hỗ trợ chẩn đoán bệnh dựa trên triệu chứng.</h4>
                    </div>
                    <div className="form-group row">
                        {
                            listCayTrong.map((item, key) => {
                                return (
                                    <div className="col-md-3" key={key}>
                                        <div className="caytrong-container">
                                            <img src={apiUrl + item.HinhAnh} alt={item.TenCayTrong} onClick={() => changeCayTrong(item)} />
                                            <button type="button" className={"btn btn-sm " + item.ClassCss} onClick={() => changeCayTrong(item)}> {item.TenCayTrong}</button>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="form-group row">
                        <div className="col-md-5">
                            <Select className="select" name="quanhuyen" placeholder="Chọn quận huyện" value={selectedOptionQuanHuyen} onChange={onChangeQuanHuyen} options={listQuanHuyen} theme={theme} />
                        </div>
                        <div className="col-md-5">
                            <Select className="select" name="xaphuong" placeholder="Chọn xã phường" value={selectedOptionXaPhuong} onChange={onChangeXaPhuong} options={listXaPhuong} theme={theme} />
                        </div>
                        <div className="col-md-2">
                            <Select className="select" name="thang" placeholder="Chọn tháng" value={selectedOptionThang} onChange={onChangeThang} options={listThang} theme={theme} />
                        </div>
                    </div>
                    <TrieuChungSauBenh timKiem={timKiem}></TrieuChungSauBenh>
                </div>
            </div>
    )
}
