import React, { useState, useEffect } from 'react'
import SlideApi from '../../api/SlideApi'
import Slider from "react-slick";

export const Slide = () => {
    var apiUrl = process.env.REACT_APP_URL;
    const [listSlide, setListSlide] = useState([]);

    const loadSlide = async () => {
        const res = await SlideApi.getAll({ type: 2 });
        setListSlide(res);
    }

    useEffect(() => {
        loadSlide();
    }, []);



    var settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: true,
        arrows: false
    };

    var slide_Style = {
        height: '350px'
    }

    var slideItem_Style = {
        width: '100%',
        height: "350px"
    }
    var textItem_Style = {
        textAlign: "center"
    }

    return (
        <div >
            <Slider {...settings} style={slide_Style}>
                {listSlide.map((item, key) => {
                    return (
                        <div key={key}>
                            <a href="#">
                                <img src={apiUrl + item.image} style={slideItem_Style} />
                            </a>
                        </div>
                    )
                })}
            </Slider>
        </div>

    )
}
