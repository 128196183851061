import React, { useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import PostCategoryApi from '../../../api/PostCategoryApi';
import Select from 'react-select';
import Pagination from 'react-bootstrap-4-pagination';
import ThongBao from '../../../core/ThongBao';
import Authen from '../../../core/Authen';
import Ultility from '../../../core/Ultility';

export const PostCategory = () => {
    const [listPostCategory, setPostCategory] = useState([]);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const { register, handleSubmit, reset, errors } = useForm();
    const [filter, setFilter] = useState('');
    const [listDanhMucCha, setDanhMucCha] = useState([]);
    const [selectedOption, setSelectedOption] = useState();
    const [roleAdd, setRoleAdd] = useState(false);
    const [roleEdit, setRoleEdit] = useState(false);
    const [roleDel, setRoleDel] = useState(false);
    const [alias, setAlias] = useState("");
    const [isEdit, setIsEdit]=useState(false);

    let pageIndex = 1;
    let pageSize = 20;
    const [paginationConfig, setPaging] = useState({
        totalPages: 1,
        currentPage: 1,
        showMax: 5,
        size: "sm",
        threeDots: true,
        prevNext: true,
        onClick: function (page) {
            pageIndex = page;
            loadCategory();
        }
    });

    const loadCategory = async () => {
        try {
            const params = {
                pageIndex: pageIndex,
                pageSize: pageSize,
                filter: filter
            }
            const response = await PostCategoryApi.getPaging(params);
            response.Items.forEach(item => {
                item.Checked = false;
            });
            paginationConfig.totalPages = Math.ceil(response.TotalRow / pageSize);
            paginationConfig.currentPage = pageIndex;
            setPaging(paginationConfig);
            setPostCategory(response.Items);
            setIsEdit(false);
        } catch (err) {
        }
    }

    useEffect(() => {
        loadCategory();
        loadDanhMucCha();
        setRoleAdd(Authen.checkQuyen('DANHMUCBAIVIET_CREATE'));
        setRoleEdit(Authen.checkQuyen('DANHMUCBAIVIET_UPDATE'));
        setRoleDel(Authen.checkQuyen('DANHMUCBAIVIET_DELETE'));
    }, []);

    const timKiem = () => {
        loadCategory();
    }

    function onChangeFilter(e) {
        setFilter(e.target.value)
    }

    const saveData = (data) => {
        data.Alias = alias;
        if (selectedOption) {
            if (selectedOption.value == 0) {
                data.ParentId = null;
            }
            else {
                data.ParentId = selectedOption.value;
            }
        }
        else {
            data.ParentId = null;
        }
        if (data.DisplayOrder != null) {
            data.DisplayOrder = +data.DisplayOrder;
        }
        if (data.Id === "0") {
            data.Id = 0;
            PostCategoryApi.post(data).then(() => {
                setShow(false);
                loadCategory();
            })
        }
        else {
            PostCategoryApi.put(data).then(() => {
                setShow(false);
                loadCategory();
            })
        }

        // if (selectedOption == null || selectedOption.value == 0) {
        //     ThongBao.prinWarningMessage("Hãy nhập danh mục cha.");
        // }
        // else {
        //     data.ParentId = selectedOption.value;
        //     if (data.DisplayOrder != null) {
        //         data.DisplayOrder = +data.DisplayOrder;
        //     }
        //     if (data.Id === "0") {
        //         data.Id = 0;
        //         PostCategoryApi.post(data).then(() => {
        //             setShow(false);
        //             loadCategory();
        //         })
        //     }
        //     else {
        //         PostCategoryApi.put(data).then(() => {
        //             setShow(false);
        //             loadCategory();
        //         })
        //     }
        // }
    }

    const clickRow = (id) => {
        listPostCategory.forEach(item => {
            if (item.Id === id) {
                item.Checked = !item.Checked;
            }
        });
        if(listPostCategory.filter(x=>x.Checked==true).length>0){
            setIsEdit(true);
        }
        else{
            setIsEdit(false);
        }
        setPostCategory([...listPostCategory]);
    }

    const handleShowAdd = () => {
        reset({
            Id: 0,
            Name: "",
            Status: true
        }, [reset]);
        setAlias('');
        setShow(true)
        setSelectedOption(listDanhMucCha[0]);
    };

    const handleShowEdit = () => {
        let index = listPostCategory.findIndex(x => x.Checked);
        if (index !== -1) {
            PostCategoryApi.getDetail(listPostCategory[index].Id).then((response) => {
                reset({
                    Id: response.Id,
                    Name: response.Name,
                    Alias: response.Alias,
                    Description: response.Description,
                    ParentId: response.ParentId,
                    DisplayOrder: response.DisplayOrder,
                    Image: response.Image,
                    HomeFlag: response.HomeFlag,
                    Status: response.Status,
                    MetaKeyword: response.MetaKeyword,
                    MetaDescription: response.MetaDescription,
                    IconCss: response.IconCss
                }, [reset]);
                setAlias(response.Alias);
                var index = listDanhMucCha.findIndex(x => x.value == response.ParentId);
                setSelectedOption(listDanhMucCha[index]);
                setShow(true);
            });
        }
    }

    const handleShowDel = () => {
        ThongBao.printConfirmationDialog("Bạn có đồng ý xóa dữ liệu đã chọn hay không?", () => {
            let lstChecked = listPostCategory.filter(x => x.Checked);
            if (lstChecked.length > 0) {
                let lstId = [];
                lstChecked.forEach(element => {
                    lstId.push(element.Id);
                });
                PostCategoryApi.delete(lstId).then(() => {
                    loadCategory();
                })
            }
        });
    }

    const checkBoHandler = () => {

    }

    function loadDanhMucCha() {
        PostCategoryApi.getAll().then((response) => {
            let list = [];
            list.push({ value: 0, label: 'Chọn danh mục...' });
            response.forEach(item => {
                if (item.ParentId == null) {
                    list.push({ value: item.Id, label: item.Name });
                }
            })
            setDanhMucCha(list);
        })
    }

    const onChangeDanhMucCha = (e) => {
        setSelectedOption(e);
        // props.onChangeHuyenProps(e);
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            loadCategory();
        }
    }

    const onChangeName = (event) => {
        let str = "";
        if (event.target.value != null) {
            str = Ultility.MakeSeoTitle(event.target.value);
        }
        setAlias(str);
    }

    return (
        <div className="card shadow mb-1">
            <div className="card-header py-2 row">
                <div className="col-md-4">
                    <h6 className="m-0 font-weight-bold text-primary">Danh mục bài viết</h6>
                </div>
                <div className="input-group col-md-4">
                    <input type="text" className="form-control" placeholder="Tìm kiếm" value={filter} onChange={onChangeFilter} onKeyPress={handleKeyPress} />
                    <div className="input-group-append">
                        <button className="btn btn-info" type="button" onClick={timKiem}><i className='fas fa-search'></i> Tìm kiếm</button>
                    </div>
                </div>
                <div className="col-md-4 bd-button text-right">
                    {roleAdd == true ? <Button variant="success" size="sm" onClick={handleShowAdd}><i className='fas fa-plus'></i> Thêm</Button> : ''}
                    {roleEdit == true ? <Button variant="primary" size="sm" onClick={handleShowEdit} disabled={!isEdit}><i className='far fa-edit'></i> Sửa</Button> : ''}
                    {roleDel == true ? <Button variant="danger" size="sm" onClick={handleShowDel} disabled={!isEdit}><i className='fas fa-trash-alt'></i> Xóa</Button> : ''}
                </div>
            </div>
            <div className="card-body">
                <div  >
                    <table className="table table-bordered" width="100%">
                        <thead>
                            <tr>
                                <th className="col-checkbox"></th>
                                <th>Tên danh mục</th>
                                <th>Alias</th>
                                <th>Thứ tụ</th>
                                <th>Mô tả</th>
                                <th>Trạng thái</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                listPostCategory.map((item, key) => {
                                    return (
                                        <tr onClick={() => clickRow(item.Id)} key={key}>
                                            <td className="col-checkbox">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" checked={item.Checked} onChange={checkBoHandler} />
                                                </div>
                                            </td>
                                            <td>{item.Name}</td>
                                            <td>{item.Alias}</td>
                                            <td>{item.DisplayOrder}</td>
                                            <td>{item.Description}</td>
                                            <td className="text-center">
                                                {item.Status == true &&
                                                    <span className="label label-success cls-status-label">Kích hoạt</span>
                                                }
                                                {item.Status == false &&
                                                    <span className="label label-danger cls-status-label">Khóa</span>
                                                }
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <div className="col-md-12 text-right">
                    <Pagination {...paginationConfig} />
                </div>
            </div>

            <Modal show={show} onHide={handleClose} animation={false} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Danh mục bài viết</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="user" onSubmit={handleSubmit(saveData)}>
                        <input name="Id" hidden={true} ref={register}></input>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Tên danh mục
                                <span className="text-danger"> *</span>
                            </label>
                            <div className="col-sm-9">
                                <input name="Name" type="text" className="form-control" placeholder="Tên danh mục" ref={register({ required: true })} onChange={onChangeName} />
                                {errors.Name && <span className="text-danger">Hãy nhập dữ liệu</span>}
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Alias
                                <span className="text-danger"> *</span>
                            </label>
                            <div className="col-sm-9">
                                <input name="Alias" type="text" className="form-control" placeholder="Alias" ref={register({ required: true })} value={alias} onChange={(e) => setAlias(e.target.value)} />
                                {errors.Alias && <span className="text-danger">Hãy nhập dữ liệu</span>}
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Thứ tự</label>
                            <div className="col-sm-9">
                                <input name="DisplayOrder" type="number" className="form-control" placeholder="Thứ tự" ref={register} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Danh mục cha <span className="text-danger"> *</span> </label>
                            <div className="col-sm-9">
                                <Select
                                    className="select"
                                    name="ParentId"
                                    placeholder="Danh mục cha"
                                    // defaultValue={selectedOption}
                                    value={selectedOption}
                                    onChange={onChangeDanhMucCha}
                                    options={listDanhMucCha}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Icon css</label>
                            <div className="col-sm-9">
                                <input name="IconCss" type="text" className="form-control" placeholder="Icon css" ref={register} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Mô tả</label>
                            <div className="col-sm-9">
                                <input name="Description" type="text" className="form-control" placeholder="Mô tả" ref={register} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Image</label>
                            <div className="col-sm-9">
                                <input name="Image" type="text" className="form-control" placeholder="Ảnh" ref={register} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label"></label>
                            <div className="col-sm-9">
                                <label className="custom-checkbox checkbox-style" >
                                    <input className="form-check-input" name="Status" type="checkbox" ref={register} />
                                    <span className="helping-el"></span> Kích hoạt
                                </label>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label"></label>
                            <div className="col-sm-9">
                                <label className="custom-checkbox checkbox-style" >
                                    <input className="form-check-input" name="HomeFlag" type="checkbox" ref={register} />
                                    <span className="helping-el"></span> Hiển thị trang chủ
                                </label>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" size="sm" onClick={handleSubmit(saveData)}>
                        Lưu</Button>
                    <Button variant="danger" size="sm" onClick={handleClose}>
                        Hủy</Button>
                </Modal.Footer>
            </Modal>
        </div>

    )
}
