import React, { useEffect, useState } from 'react';
import PostApi from '../../api/PostApi'
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import PostCategoryApi from '../../api/PostCategoryApi';

export const TrangBaiViet = (props) => {

    var apiUrl = process.env.REACT_APP_API_URL.replace('/api', '');
    const [baiViet, setBaiViet] = useState({});
    const [listBaiViet, setListBaiViet] = useState([]);
    const [listBreadcrumb, setListBreadcrumb] = useState([]);

    const loadBaiViet = async (Id) => {
        const resPonse = await PostApi.getDetail(Id);
        setBaiViet(resPonse);
    }

    const loadListBaiViet = async () => {
        const resPonse = await PostApi.getAll();
        setListBaiViet(resPonse);
    }

    const loadBreadcrumb = async (id) => {
        const resPonse = await PostCategoryApi.getBreadcrumb({ id: id });
        setListBreadcrumb(resPonse);
    }

    useEffect(() => {
        var id = props.match.params.IdBaiViet;
        id = id.replace(".html", "");
        loadBreadcrumb(id);
        loadBaiViet(id);
        loadListBaiViet();
    }, [props.match.params.IdBaiViet])

    return (

        <div className="fl-builder-content trang-container">
            <div className="fl-row-content-wrap" >
                <div className="container-fluid container">
                    <div className="col-md-12">
                        <div className="breadcrumb-container">
                            <a href="/" className="breadcrumb-dis">Trang chủ / </a>
                            {listBreadcrumb.map((item, key) => {
                                return (
                                    <a href={'/public'+item.Url} className="breadcrumb-dis" key={key}> {item.Name} /</a>
                                )
                            })}
                            <a href={'/public'+baiViet.Url} className="breadcrumb-active"> {baiViet.Name}</a>
                            <div className="breadcrumb-line">
                            </div>
                        </div>
                    </div>
                    <br></br>
                    <div className="row">
                        <div className="col-sm-9 col-md-9 col-xl-9">
                            <h5 style={{ 'font-weight': 'bold' }}>{baiViet.Name}</h5>
                            <div className="bv-img-hienthibaiviet ">
                                <div className="row">
                                    <div className="col-md-12">
                                        <img src={apiUrl + baiViet.Image}></img>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div dangerouslySetInnerHTML={{ __html: baiViet.Content }}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-3 col-md-3 col-xl-3">
                            <div><h5 style={{ 'font-weight': 'bold' }}>Tin nổi bật</h5></div>
                            {
                                listBaiViet.filter(x => x.HotFlag == true).map((p, key) => {
                                    if (key <= 4) {
                                        return (
                                            <div className="row bv-content-hot" key={key}>
                                                <div className="col-5 col-md-5 col-sm-5 col-xl-5 bv-img-hot">
                                                    <img src={apiUrl + p.Image}></img>
                                                </div>
                                                <div className="col-7 col-md-7 col-sm-7 col-xl-7 bv-text-hot">
                                                    <h6><Link to={{ pathname: '/public'+p.Url, state: { Id: p.Id } }}>{p.Name}</Link></h6>
                                                </div>
                                            </div>
                                        )
                                    }
                                })
                            }
                            <hr></hr>
                            <div><h5 style={{ 'font-weight': 'bold' }}>Tin mới nhất</h5></div>
                            {
                                listBaiViet.filter(x => x.HotFlag == false).map((p, key) => {
                                    if (key <= 4) {
                                        return (
                                            <div className="row bv-content-hot" key={key}>
                                                <div className="col-5 col-md-5 col-sm-5 col-xl-5 bv-img-hot">
                                                    <img src={apiUrl + p.Image}></img>
                                                </div>
                                                <div className="col-7 col-md-7 col-sm-7 col-xl-7 bv-text-hot">
                                                    <h6><Link to={{ pathname:'/public'+ p.Url, state: { Id: p.Id } }}>{p.Name}</Link></h6>
                                                </div>
                                            </div>
                                        )
                                    }
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}