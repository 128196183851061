const { default: axiosClient } = require("./axiosClient");

const NhatKySuDungApi = {
    getPaging: (params) => {
        const url = '/nhatkysudung/paging';
        return axiosClient.get(url, {params});
    },
    delete: (id) =>{
        const url = '/nhatkysudung/delete/' + id;
        return axiosClient.delete(url);
    }
}
  
export default NhatKySuDungApi;