import React, { useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { SelectHuyen } from '../selecthuyen/SelectHuyen';
import { SelectXa } from '../selectxa/SelectXa';
import Select from 'react-select';
import ThongBao from '../../core/ThongBao';
import { Loading } from '../loading/Loading';
import VungDuAnApi from '../../api/VungDuAnApi';
import ChuGiaiApi from '../../api/ChuGiaiApi';

export const ModalVungDuAn = (props) => {
    const [show, setShow] = useState(false);
    const { register, handleSubmit, reset } = useForm();
    const [huyen, setHuyen] = useState({});
    const [xa, setXa] = useState({});
    const [listhtsd, setListhtsd] = useState([]);
    const [htsd, sethtsd] = useState({});
    const [loading, setLoading] = useState(false);

    const handleClose = () => {
        props.handleClose();
        setShow(false);
    };

    useEffect(() => {

        if (props.showModal === true) {
            setShow(true);
            reset({
                id: props.thongTin.id,
                ma_px: props.thongTin.ma_px,
                text_xavn: props.thongTin.text_xavn,
                mahuyen: props.thongTin.mahuyen,
                text_huyen: props.thongTin.text_huyen,
                htsd: props.thongTin.htsd,
                dientich: props.thongTin.dientich
            }, [reset]);
            loadHienTrang();
        }
    }, [props.showModal]);

    const loadHienTrang = async () => {
        const res = await ChuGiaiApi.getVungDuAn();
        let list = [];
        res.forEach(item => {
            if(item.ma != 'SAU'){
                list.push({value: item.ma, label: item.ten});
            }
        })
        if (props.thongTin.htsd != null) {
            const index = list.findIndex(x => x.value == props.thongTin.htsd);
            if (index != -1) {
                sethtsd(list[index]);
            }
        }
        setListhtsd(list);
    }

    const onChangeHuyen = (e) => {
        setHuyen(e);
    }

    const onChangeXa = (e) => {
        setXa(e);
    }

    const onchangeHienTrang = (e) => {
        sethtsd(e);
    }


    const onSubmit = (data) => {
        try {
            data.mahuyen = huyen.value;
            data.text_huyen = huyen.label;
            data.ma_px = xa.value;
            data.text_xavn = xa.label;
            data.geoText = props.thongTin.geoText;
            data.id = props.thongTin.id;
            data.htsd = htsd.value;
            setLoading(true);
            if (data.id != null) {
                VungDuAnApi.update(data).then((res) => {
                    ThongBao.printSuccessMessage('Cập nhật thành công!');
                    handleClose();
                    props.changeMapComplete();
                    setLoading(false);
                }, (err) => {
                    setLoading(false);
                    ThongBao.printErrorMessage(err);
                });
            }
            else {
                VungDuAnApi.add(data).then((res) => {
                    ThongBao.printSuccessMessage('Cập nhật thành công!');
                    handleClose();
                    props.changeMapComplete();
                    setLoading(false);
                }, (err) => {
                    setLoading(false);
                    ThongBao.printErrorMessage(err);
                });
            }
        }
        catch (ex) {
        }

    }

    return (
        <Modal size='md' dialog show={show} onHide={handleClose} backdrop="static" keyboard={false} animation={false}>
            <Modal.Header closeButton>
                <Modal.Title>Hiện trạng sử dụng</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form className="user">
                    <div className="form-group row">
                        <label className="col-sm-3 col-form-label text-right">Huyện</label>
                        <div className="col-sm-9">
                            <SelectHuyen maHuyen={props.thongTin.mahuyen} onChangeHuyenProps={(e) => onChangeHuyen(e)}></SelectHuyen>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-3 col-form-label text-right">Xã</label>
                        <div className="col-sm-9">
                            <SelectXa maXa={props.thongTin.ma_px} maHuyen={huyen.ma} onChangeXaProps={(e) => onChangeXa(e)}></SelectXa>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-3 col-form-label text-right">Hiện trạng</label>
                        <div className="col-sm-9">
                            <Select
                                className="select"
                                name="hientrang"
                                placeholder="Chọn hiện trạng"
                                value={htsd}
                                onChange={onchangeHienTrang}
                                options={listhtsd}
                                theme={theme => ({
                                    ...theme,
                                    borderRadius: 0,
                                    height: 30,
                                    spacing: {
                                        controlHeight: 30,
                                        menuGutter: 1,
                                        baseUnit: 2,
                                    }
                                })}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-3 col-form-label text-right">Diện tích</label>
                        <div className="col-sm-9">
                            <input name="dientich" type="text" className="form-control" placeholder="Diện tích" ref={register} />
                        </div>
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <div className="form-group row">
                    <a href="#" type="button" className="btn btn-info btn-sm ml-1" onClick={handleSubmit(onSubmit)}><i className='fas fa-save'></i> Lưu</a>
                    <a href="#" type="button" className="btn btn-danger btn-sm ml-1" onClick={handleClose} disabled><i className="fas fa-sign-out-alt"></i> Hủy</a>
                </div>
            </Modal.Footer>
            <Loading loading={loading}></Loading>
        </Modal>
    );
};