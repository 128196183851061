import React, { useEffect, useState } from 'react';
import ol from "openlayers";
import "ol/ol.css";
import LopBanDoApi from '../../api/LopBanDoApi';
import BanDoPublicApi from '../../api/BanDoPublicApi';
import CayTrongApi from '../../api/CayTrongApi';
import { Bar } from 'react-chartjs-2';
import Select from 'react-select';
import Chart from 'chart.js';

export const Home = () => {

    var [positionFeature, setPositionFeature] = useState(new ol.Feature());
    var [map, setMap] = useState(new ol.Map());
    var [vectorSelect, setVectorSelect] = useState(new ol.layer.Vector());
    const [timListCayTrong, setTimListCayTrong] = useState([]);
    var [dataReponse, setDataReponse] = useState({});
    const [bieudo, setBieuDo] = useState({});
    const [loaiBieuDo, setLoaiBieuDo] = useState({ value: 1, label: 'Diện tích' });
    const [listLoaiBieuDo, setListLoaiBieuDo] = useState([
        { value: 1, label: 'Diện tích' },
        { value: 2, label: 'Năng suất' },
        { value: 3, label: 'Sản lượng' }
    ]);

    const view = new ol.View({
        center: [11728080.0278, 2357260.9077],
        zoom: 9.4,
        minZoom: 9,
        maxZoom: 11,
        projection: new ol.proj.get('EPSG:3857'),
    });

    const highlightStyle = new ol.style.Style({
        stroke: new ol.style.Stroke({
            color: 'blue',
            width: 2,
        }),
    });

    useEffect(() => {
        positionFeature.setStyle(
            new ol.style.Style({
                image: new ol.style.Circle({
                    radius: 6,
                    fill: new ol.style.Fill({
                        color: '#3399CC',
                    }),
                    stroke: new ol.style.Stroke({
                        color: '#fff',
                        width: 2,
                    }),
                }),
            })
        );
        map = new ol.Map({
            target: "map",
            controls: new ol.control.defaults().extend([
                // new ol.control.ScaleLine({
                //     units: 'metric'
                // }),
                // new ol.control.FullScreen(),
                // new ol.control.ZoomSlider()
            ]),
            layers: [
                // mapBase
                // bingMap
            ],
            view: view
        });
        setPositionFeature(positionFeature);
        map.on('click', function (evt) {
            let coordinate = evt.coordinate;
            if (map.getView().getProjection().getCode() === 'EPSG:3857') {
                coordinate = new ol.proj.transform(evt.coordinate, 'EPSG:3857', 'EPSG:4326');
            }
            loadBieuDo(coordinate[0], coordinate[1]);
        });
        setMap(map);
        loadLopBanDo();
        loadBieuDo();
    }, []);

    const loadLopBanDo = async () => {
        const list = await LopBanDoApi.getAll();
        list.forEach(item => {
            if (item.Ma === 'BDRANHGIOIHUYEN' || item.Ma === 'BDTENHUYEN') {
                let province = new ol.layer.Image({
                    source: new ol.source.ImageWMS({
                        url: item.Url,
                        params: { layers: item.LayerName, version: item.Version },
                        serverType: "geoserver",
                        ratio: item.Ratio,
                        crossOrigin: ''
                    }),
                    zIndex: item.SortOrder
                });
                map.addLayer(province);
            }
        });
    }

    const loadBieuDo = async (x, y) => {
        const params = {
            x: x,
            y: y
        }
        dataReponse = await BanDoPublicApi.getInfoHome(params);
        setDataReponse(dataReponse);
        if (dataReponse.bando.geomjson != null) {
            map.getLayers().forEach(item => {
                if (item != null && item.type === 'VECTOR') {
                    map.removeLayer(item);
                }
            });
            let features = new ol.format.GeoJSON().readFeatures(dataReponse.bando.geomjson);
            if (map.getView().getProjection().getCode() === 'EPSG:3857') {
                features = new ol.format.GeoJSON({ featureProjection: 'EPSG:3857', defaultDataProjection: 'EPSG:4326' }).readFeatures(dataReponse.bando.geomjson);
            }
            vectorSelect = new ol.layer.Vector({
                source: new ol.source.Vector({
                    features: features,
                }),
                style: highlightStyle,
                zIndex: 999
            });
            setVectorSelect(vectorSelect);
            map.addLayer(vectorSelect);
        }
        else {
            map.getLayers().forEach(item => {
                if (item == vectorSelect) {
                    map.removeLayer(vectorSelect);
                }
            });
        }

        let lstCayTrong = [];
        if (timListCayTrong.length == 0) {
            await CayTrongApi.getAll().then((cay) => {
                let list = [];
                list.push({ value: 0, label: 'Chọn cây trồng...' });
                cay.forEach(item => {
                    list.push({ value: item.Id, label: item.TenCayTrong, ma: item.MaCayTrong });
                })
                lstCayTrong = list.map(x => x);
                setTimListCayTrong(list);
            })
        }
        else {
            lstCayTrong = timListCayTrong;
        }
        buildBieuDo(lstCayTrong, 1);
    }

    const buildBieuDo = (lstCayTrong, type = 1) => {
        let datasets = [];
        const endYear = new Date().getFullYear();
        let labels = [];
        for (let i = 0; i < 5; i++) {
            labels.push(endYear - 4 + i);
        }
        lstCayTrong.filter(x => x.value != 0).forEach(item => {
            let data = [];
            labels.forEach(nam => {
                const index = dataReponse.bieudo.findIndex(x => x.MaCayTrong == item.ma && x.Nam == nam);
                let vl = 0;
                switch (type) {
                    case 1:
                        vl = (index == -1 ? 0 : dataReponse.bieudo[index].DienTich);
                        break;
                    case 2:
                        vl = (index == -1 ? 0 : dataReponse.bieudo[index].NangSuat);
                        break;
                    case 3:
                        vl = (index == -1 ? 0 : dataReponse.bieudo[index].SanLuong);
                        break;
                }
                data.push(vl);
            });
            let color = '#CF5C00';
            switch (item.ma) {
                case 'LUA':
                    color = '#FFDB15';
                    break;
                case 'RAU':
                    color = '#04D010';
                    break;
            }
            datasets.push({
                label: item.label,
                data: data,
                backgroundColor: color
            })
        });
        const bd = {
            labels: labels,
            datasets: datasets
        }
        setBieuDo(bd);
    }

    const onChaneLoaiBieuDo = (e) => {
        setLoaiBieuDo(e);
        buildBieuDo(timListCayTrong, e.value);
    }

    return (
        <div className="row home-bt">
            <div className="col-md-6 home-ban-do" id="map"></div>
            <div className="col-md-6 home-bieu-do">
                <div className="row">
                    <div className="col-md-12">
                        <Select placeholder="Biểu đồ"
                            onChange={onChaneLoaiBieuDo} options={listLoaiBieuDo} value={loaiBieuDo} theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                                height: 30,
                                spacing: {
                                    controlHeight: 30,
                                    menuGutter: 1,
                                    baseUnit: 2,
                                }
                            })} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <Bar
                            data={bieudo}
                            height={290}
                            options={{
                                legend: { display: true },
                                title: {
                                    display: true,
                                    text: "Biểu đồ diện tích sản xuất"
                                },
                                hover: {
                                    animationDuration: 0
                                },
                                tooltips: {
                                    callbacks: {
                                            title: function(tooltipItem, data) {
                                            return data['labels'][tooltipItem[0]['index']];
                                          },
                                          label: function(tooltipItem, data) {
                                            return parseInt(data['datasets'][0]['data'][tooltipItem['index']] * 100)/100;
                                          },
                                    },
                                },
                                animation: {
                                    duration: 500,
                                    onComplete: function () {
                                        var chartInstance = this.chart,
                                            ctx = chartInstance.ctx;
                                            ctx.font = Chart.helpers.fontString(8, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
                                        ctx.textAlign = 'center';
                                        ctx.textBaseline = 'bottom';
                                        this.data.datasets.forEach(function (dataset, i) {
                                            var meta = chartInstance.controller.getDatasetMeta(i);
                                            meta.data.forEach(function (bar, index) {
                                                if(dataset.data[index] > 0){
                                                    var data = dataset.data[index];
                                                    data = parseInt(data * 100)/100;                        
                                                    ctx.fillText(data, bar._model.x, bar._model.y - 5);
                                                }
                                            });
                                        });
                                    }
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
