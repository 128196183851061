import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { useHistory } from "react-router-dom";
import { style } from 'openlayers';
import ThongBao from '../../core/ThongBao';
import { Loading } from '../../shared/loading/Loading';
import TaiKhoanApi from '../../api/TaiKhoanApi';


export const DangKy = () => {
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const { register, handleSubmit, errors } = useForm({
        mode: "onBlur"
    });
    const onSubmit = async (data) => {
        setLoading(true);
        try {
            data.Status = true;
            TaiKhoanApi.createUser(data).then((response) => {
                setLoading(false);
                ThongBao.printSuccessMessage("Thành công");
                axios.post(process.env.REACT_APP_API_URL + '/login', { userName: data.UserName, password: data.Password }).then(response => {
                    localStorage.setItem(process.env.REACT_APP_TOKEN, response.data.token);
                    setLoading(false);
                    history.push("/public");
                }, (eror) => {
                    if (eror.response) {
                        if (eror.response.data === "Bad Request") {
                            ThongBao.printErrorMessage("Tên đăng nhập hoặc mật khẩu không đúng.");
                        }
                        else {
                            ThongBao.printErrorMessage(eror.response.data);
                        }
                    }
                    setLoading(false);
                });

            }, (errors) => {
                setLoading(false);
            });
        }
        catch (ex) {
            console.log(ex.message);
        }
    }
    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-xl-6 col-lg-12 col-md-9">
                    <div className="card o-hidden border-0 shadow-lg my-5">
                        <div className="card-body p-0 login">
                            <div className="p-5">
                                <div className="text-center">
                                    <h1 className="h4 text-gray-900 mb-4">ĐĂNG KÝ TÀI KHOẢN</h1>
                                </div>
                                <form className="user" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-group row">
                                        <label className="col-sm-12">Họ tên <code> *</code></label>
                                        <div className="col-sm-12">
                                            <input name="FullName" type="text" className="form-control" placeholder="Họ tên" style={{ ...style.input, borderColor: errors.FullName && "red" }} ref={register({ required: "Họ tên không được để trống" })} />
                                            <span className="eRor">{errors.FullName && errors.FullName.message}</span>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-12">Email <code> *</code></label>
                                        <div className="col-sm-12">
                                            <input name="Email" type="text" className="form-control" placeholder="Email" style={{ ...style.input, borderColor: errors.Email && "red" }} ref={register(
                                                {
                                                    required: "Email không được trống",
                                                    pattern: {
                                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                        message: "Sai định dạng email"
                                                    }
                                                })} />
                                            <span className="eRor">{errors.Email && errors.Email.message}</span>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-12">Địa chỉ</label>
                                        <div className="col-sm-12">
                                            <input name="Address" type="text" className="form-control" placeholder="Địa chỉ" style={{ ...style.input }} ref={register({ require: true })} />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-12">Tên đăng nhập <code> *</code></label>
                                        <div className="col-sm-12">
                                            <input name="UserName" type="text" className="form-control" placeholder="Tên đăng nhập" style={{ ...style.input, borderColor: errors.UserName && "red" }} ref={register({
                                                required: "Tài khoản không được trống",
                                                pattern: {
                                                    value: /^[a-z\d]+$/i,
                                                    message: "Sai định dạng tài khoản"
                                                }, minLength: 3
                                            })} />
                                            <span className="eRor">{errors.UserName && errors.UserName.message}</span>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-12">Mật khẩu <code> *</code></label>
                                        <div className="col-sm-12">
                                            <input name="Password" type="password" className="form-control" placeholder="Mật khẩu" style={{ ...style.input, borderColor: errors.Password && "red" }} ref={register(
                                                { required: "Mật khẩu không được trống", minLength: 3 }
                                            )} />
                                            <span className="eRor">{errors.Password && errors.Password.message}</span>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-12">Nhập lại mật khẩu <code> *</code></label>
                                        <div className="col-sm-12">
                                            <input name="PasswordNhapLai" type="password" className="form-control " placeholder="Mật khẩu" style={{ ...style.input, borderColor: errors.PasswordNhapLai && "red" }} ref={register(
                                                { required: "Mật khẩu không được trống", minLength: 3 }
                                            )} />
                                            <span className="eRor">{errors.PasswordNhapLai && errors.PasswordNhapLai.message}</span>
                                        </div>
                                    </div>
                                    <button type="submit" className="btn btn-primary btn-lg btn-block btn-dangnhap" onClick={handleSubmit(onSubmit)}>Đăng ký tài khoản</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Loading loading={loading}></Loading>
        </div>
    )

}
