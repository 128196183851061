const { default: axiosClient } = require("./axiosClient");

const HoiDapApi = {
    getPaging: (params) => {
        const url = '/hoidap/paging';
        return axiosClient.get(url, {params});
    },
    getPagingPublic: (params) => {
        const url = '/hoidap/pagingpublic';
        return axiosClient.get(url, {params});
    },
    getXemNhieuNhat: (params) => {
        const url = '/hoidap/getxemnhieunhat';
        return axiosClient.get(url, {params});
    },

    getDetail: (id) =>{
        const url = '/hoidap/getbyid/' + id;
        return axiosClient.get(url);
    },

    post: (data) =>{
        const url = '/hoidap/add';
        return axiosClient.post(url, data);
    },

    postPublic: (data) =>{
        const url = '/hoidap/addpublic';
        return axiosClient.post(url, data);
    },

    postCauHoiPublic: (data) =>{
        const url = '/hoidap/addcauhoipublic';
        return axiosClient.post(url, data);
    },

    put: (data) => {
        const url = '/hoidap/edit';
        return axiosClient.put(url, data);
    },

    delete: (id) =>{
        const url = '/hoidap/delete/' + id;
        return axiosClient.delete(url);
    },
    getAll: (params) =>{
        const url = 'hoidap/getall';
        return axiosClient.get(url, {params});
    },
    getByCha: (idcha) => {
        const url = '/hoidap/getbycha/'+idcha;
        return axiosClient.get(url);
    },

    addLike: (data) => {
        const url = '/hoidap/addlike';
        return axiosClient.post(url, data);
    },

    addView: (data) => {
        const url = '/hoidap/addview';
        return axiosClient.post(url, data);
    },

    
}

export default HoiDapApi;