import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import TaiKhoanApi from '../../api/TaiKhoanApi';
import Authen from '../../core/Authen';
import ThongBao from '../../core/ThongBao';
import { Loading } from '../loading/Loading';

export const Header = () => {

    const [nhanVien, setNhanVien] = useState({});
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const { register, handleSubmit, reset } = useForm();
    let history = useHistory();
    var apiUrl = process.env.REACT_APP_API_URL.replace('api', '');

    useEffect(() => {
        setNhanVien(Authen.getUser());
    }, []);

    const logout = () => {
        localStorage.removeItem(process.env.REACT_APP_TOKEN);
        history.push("/login");
        // Authen.logout().then(() => {
        //     localStorage.removeItem(process.env.REACT_APP_TOKEN);
        //     history.push("/api/logout");
        // });
    }
    const doiMatKhau = () => {
        reset({
            UserName: Authen.getUser().UserName,
            Password: '',
            ConfirmPassword: ''
        });
        setShow(true);
    }
    const saveData = (data) => {
        if (data.Password !== data.ConfirmPassword) {
            ThongBao.prinWarningMessage("Mật khẩu nhắc lại không khớp!");
            return;
        }
        setLoading(true);
        TaiKhoanApi.changePass(data).then(() => {
            setShow(false);
            setLoading(false);
            ThongBao.printSuccessMessage("Đổi mật khẩu thành công!");
        }, err => {
            setLoading(false);
        })
    }

    return (
        <nav className="navbar navbar-expand navbar-light bg-white topbar mb-1 static-top shadow">
            <h4 className="ten-pm">PHÂN MỀM SÂU BỆNH HÒA BÌNH</h4>
            <ul className="navbar-nav ml-auto">
                <li className="nav-item dropdown no-arrow">
                    <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span className="mr-1 d-none d-lg-inline text-gray-600 small">{nhanVien.FullName}</span>
                        <img className="img-profile rounded-circle" src={apiUrl + nhanVien.Avatar} />
                    </a>
                    <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                        <a className="dropdown-item" href="#" onClick={doiMatKhau}>
                            <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400" />
                            Đổi mật khẩu
                        </a>
                        <div className="dropdown-divider" />
                        <a className="dropdown-item" href="#" onClick={logout}>
                            <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400" />
                            Đăng xuất
                        </a>
                    </div>
                </li>
            </ul>
            <Modal show={show} onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Đổi mật khẩu</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="user" onSubmit={handleSubmit(saveData)}>
                        <input name="UserName" hidden={true} ref={register}></input>
                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label">Mật khẩu cũ</label>
                            <div className="col-sm-8">
                                <input name="CurrentPassword" type="password" className="form-control" placeholder="Mật khẩu cũ" ref={register} autoFocus />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label">Mật khẩu mới</label>
                            <div className="col-sm-8">
                                <input name="Password" type="password" className="form-control" placeholder="Mật khẩu mới" ref={register} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label">Xác nhận mật khẩu</label>
                            <div className="col-sm-8">
                                <input name="ConfirmPassword" type="password" className="form-control" placeholder="Xác nhận lại mật khẩu" ref={register} />
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" size="sm" onClick={handleSubmit(saveData)}>
                        Lưu</Button>
                    <Button variant="danger" size="sm" onClick={handleClose}>
                        Hủy</Button>
                </Modal.Footer>
                <Loading loading={loading}></Loading>
            </Modal>
        </nav>
    )
}
