import React, { useState, useEffect } from 'react'
import { Modal, Tabs, Tab } from 'react-bootstrap';
import {SauBenh} from '../sauvabenh/sauvabenh';

export const SinhVatGayHai = () => {
    

    return (
         <>
         <SauBenh LoaiSauBenh={true}></SauBenh>
         </>
    )
}

