import React, { useEffect, useLayoutEffect, useState } from 'react'
import ChuGiaiApi from '../../api/ChuGiaiApi';
import stylelocal from './FooterNote.module.css'
import { ChuGiaiHanhChinhHuyen } from './ChuGiaiHanhChinhHuyen';
import { Loading } from '../loading/Loading';
import { ChuGiaiNen } from './ChuGiaiNen';
import { ChuGiaiVungDuAn } from './ChuGiaiVungDuAn';
import { ChuGiaiBaoVeThucVat } from './ChuGiaiBaoVeThucVat';

export const FooterNote = (props) => {

    const [listChuGiais, setListChuGuai] = useState([]);
    const [listBanDos, setListBanDo] = useState([]);
    const [loading, setLoading] = useState(false);

    var height = window.innerHeight - props.height;
    var width = window.innerWidth;
    var minHeight = 250;
    if (width < 768) {
        minHeight = height - 30;
    }
    const [yPosition, setY] = React.useState(height);
    const toggleMenu = () => {
        if (yPosition < height) {
            setY(height);
        } else {
            setY(height - minHeight);
        }
    };
    useEffect(() => {
        setY(height);
    }, []);

    useEffect(() => {
        if (props.show > 0 && yPosition === height) {
            toggleMenu();
        }
    }, [props.show]);

    useEffect(() => {
        if (props.maBanDo != null) {
            loadGhiChu();
        }
    }, [props.maBanDo]);

    const loadGhiChu = async () => {
        const index = listBanDos.findIndex(x => x.MaBanDo === props.maBanDo);
        if (index === -1) {
            setLoading(true);
            switch (props.maBanDo) {
                case 'BDBAOVETHUCVAT':
                    const listBaoVeThucVats = await ChuGiaiApi.getVungDuAn();
                    setListChuGuai(listBaoVeThucVats);
                    listBanDos.push({ MaBanDo: props.maBanDo, listChuGiais: listBaoVeThucVats });
                    setListBanDo([...listBanDos]);
                    break;
                case 'BDCAYLUA':
                    const listCayLuas = await ChuGiaiApi.getVungDuAn();
                    setListChuGuai(listCayLuas);
                    listBanDos.push({ MaBanDo: props.maBanDo, listChuGiais: listCayLuas });
                    setListBanDo([...listBanDos]);
                    break;
                case 'BDCAYRAU':
                    const listCayRaus = await ChuGiaiApi.getVungDuAn();
                    setListChuGuai(listCayRaus);
                    listBanDos.push({ MaBanDo: props.maBanDo, listChuGiais: listCayRaus });
                    setListBanDo([...listBanDos]);
                    break;
                case 'BDCAYCAQ':
                    const listCayAnQuas = await ChuGiaiApi.getVungDuAn();
                    setListChuGuai(listCayAnQuas);
                    listBanDos.push({ MaBanDo: props.maBanDo, listChuGiais: listCayAnQuas });
                    setListBanDo([...listBanDos]);
                    break;
                case 'BDVUNGDUAN':
                    const listVungSuAns = await ChuGiaiApi.getVungDuAn();
                    setListChuGuai(listVungSuAns);
                    listBanDos.push({ MaBanDo: props.maBanDo, listChuGiais: listVungSuAns });
                    setListBanDo([...listBanDos]);
                    break;
                case 'BANDONEN':
                    const listNens = await ChuGiaiApi.getBanDoNen();
                    setListChuGuai(listNens);
                    listBanDos.push({ MaBanDo: props.maBanDo, listChuGiais: listNens });
                    setListBanDo([...listBanDos]);
                    break;
            }
            setLoading(false);
        }
        else {
            setListChuGuai(listBanDos[index].listChuGiais);
        }

    }

    useLayoutEffect(() => {
        function updateSize() {
            height = window.innerHeight - 90;
            width = window.innerWidth;
            setY(height)
        }
        window.addEventListener('resize', updateSize);
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    const clickChuGiai = (list) => {
        props.timKiemGhiChu(list);
    }

    return (
        <React.Fragment>
            <div
                className={stylelocal.footernote}
                style={{
                    transform: `translatey(${yPosition}px)`,
                    height: height - yPosition,
                    border: (height - yPosition) === 0 ? 'none' : ''
                }}
            >
                <button
                    onClick={() => toggleMenu()}
                    className={stylelocal.footermenu}
                    style={{
                        transform: `translate(0px, ${-25}px)`
                    }}
                >
                    {
                        // yPosition < height?<i className="fas fa-chevron-down"></i>:<i className="fas fa-chevron-up" />
                        <span style={{ color: 'white' }}>CHÚ GIẢI</span>
                    }
                </button>
                {
                    height - yPosition > 0 ? <div className={stylelocal.footercontent}>
                        <div className={'row ' + stylelocal.mapghichu}>
                            {
                                props.maBanDo === 'BDBAOVETHUCVAT' ? <ChuGiaiBaoVeThucVat listChuGiais={listChuGiais} clickChuGiai={(e) => clickChuGiai(e)}></ChuGiaiBaoVeThucVat> : ''
                            }
                            {
                                props.maBanDo === 'BDCAYLUA' ? <ChuGiaiVungDuAn listChuGiais={listChuGiais} clickChuGiai={(e) => clickChuGiai(e)}></ChuGiaiVungDuAn> : ''
                            }
                            {
                                props.maBanDo === 'BDCAYRAU' ? <ChuGiaiVungDuAn listChuGiais={listChuGiais} clickChuGiai={(e) => clickChuGiai(e)}></ChuGiaiVungDuAn> : ''
                            }
                            {
                                props.maBanDo === 'BDCAYCAQ' ? <ChuGiaiVungDuAn listChuGiais={listChuGiais} clickChuGiai={(e) => clickChuGiai(e)}></ChuGiaiVungDuAn> : ''
                            }
                            {
                                props.maBanDo === 'BDVUNGDUAN' ? <ChuGiaiVungDuAn listChuGiais={listChuGiais} clickChuGiai={(e) => clickChuGiai(e)}></ChuGiaiVungDuAn> : ''
                            }
                            {
                                props.maBanDo === 'BANDONEN' ? <ChuGiaiNen listChuGiais={listChuGiais} clickChuGiai={(e) => clickChuGiai(e)}></ChuGiaiNen> : ''
                            }
                        </div>
                    </div> : null
                }
            </div>
            <Loading loading={loading}></Loading>
        </React.Fragment>
    )
}
