import axiosClient from './axiosClient';

const VungDuAnApi = {

    update:(params) =>{
        const url = '/vungduan/update';
        return axiosClient.put(url, params);
    },
    add:(params) =>{
        const url = '/vungduan/add';
        return axiosClient.post(url, params);
    },
    delete: (params) =>{
        const url = 'vungduan/delete';
        return axiosClient.delete(url, {params});
    },
    getPaging:(params) =>{
        const url = '/vungduan/getpaging';
        return axiosClient.get(url, {params});
    },
    getPagingBaoVeThucVat:(params) =>{
        const url = '/vungduan/getpagingbvthucvat';
        return axiosClient.get(url, {params});
    },
}

export default VungDuAnApi;