import React, { useEffect } from 'react';
import './App.css';
import './style.css';
import { Login } from './login/Login';
import { BrowserRouter as Router, Switch, Route, Redirect, withRouter } from "react-router-dom";
import { Main } from './main/Main';
import Authen from './core/Authen';
import '../node_modules/bootstrap/dist/js/bootstrap';
import { Public } from './public/Public';
import runFunc from './sb-admin-2';
import { TrangChu } from './trangchu'
import { DefaultPage } from './defaultpage'
import { DangKy } from './public/dangky/dangky';

function App() {
  useEffect(() => {
    runFunc.run();
  }, []);
  function PrivateRoute({ children, ...rest }) {
    return (
      <Route {...rest} render={() => {
        if (Authen.checkAccess()) {
          return children;
        }
        else {
          return (
            <Redirect to="/login"></Redirect>
          )
        }
      }}>
      </Route>
    )
  }

  return (
    <Router>
      <Switch>
        <Route path="/" exact>
          <DefaultPage></DefaultPage>
        </Route>
        <Route path="/public/ban-do-chuyen-de">
          <Public></Public>
        </Route>
        <Route path="/public/ban-do-chuyen-de-mobile">
          <Public></Public>
        </Route>
        <Route path="/public">
          <DefaultPage></DefaultPage>
        </Route>
        <Route path="/main">
          <Main></Main>
        </Route>
        <Route path="/login">
          <Login></Login>
        </Route>
        <Route path="/dangky">
          <DangKy></DangKy>
        </Route>
        <Route path="*">
          <TrangChu></TrangChu>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
