import React, { useState, useEffect } from 'react';
import PostCategoryApi from '../src/api/PostCategoryApi';
import { NavLink, useHistory } from 'react-router-dom';
import LienHeApi from './api/LienHeApi';
import { HuongDan } from './public/huongdan/HuongDan';
import Authen from './core/Authen';
import LuotTruyCapApi from './api/LuotTruyCapApi';

export const Header = (props) => {
    const apiUrl = process.env.REACT_APP_URL;
    const [listPostCategoris, setListPostCategories] = useState([]);
    const [lienHe, setLienHe] = useState({});
    const [showHuongDan, setShowHuongDan] = useState(false);
    const loadPostCategories = async () => {
        const res = await PostCategoryApi.getMenu();
        setListPostCategories(res);
    }
    let history = useHistory();
    const [nhanVien, setNhanVien] = useState({});

    const loadLienHe = async () => {
        const params = {
            keyword: '',
            pageIndex: 1,
            pageSize: 20
        }
        const res = await LienHeApi.getLienHe(params);
        if (res.Items.length > 0) {
            setLienHe(res.Items[0]);
            if (props.changedLienHe) {
                props.changedLienHe(res.Items[0]);
            }
        }
    }

    useEffect(() => {
        const session = sessionStorage.getItem("TRUYCAP");
        if(session == null){
            sessionStorage.setItem("TRUYCAP", 1);
            LuotTruyCapApi.post();
        }
        setNhanVien(Authen.getUser());
        loadPostCategories();
        loadLienHe();
    }, []);

    const logout = () => {
        localStorage.removeItem(process.env.REACT_APP_TOKEN);
        history.push("/public");
        history.go(0);
    }

    const showHuongDanClick = () => {
        setShowHuongDan(true);
    }

    return (
        <nav className="navbar navbar-expand-md fixed-top">
            <div className="container">
                <NavLink to={'/'} className="logo">
                    <img className="logo" src={apiUrl + lienHe.Logo} alt="Trang chủ" />

                </NavLink>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExampleDefault"
                    aria-controls="navbarsExampleDefault" aria-expanded="false" aria-label="Toggle navigation">
                    <i class="fas fa-bars"></i>
                </button>
                <div className="collapse navbar-collapse" id="navbarsExampleDefault">
                    <ul className="navbar-nav mr-auto">
                        {
                            listPostCategoris.map((item, key) => {
                                return (
                                    <div key={key}>
                                        {item.Childs.length == 0 &&
                                            <li className={item.Childs.length > 0 ? 'nav-item dropdown' : 'nav-item'}>
                                                <NavLink to={'/public' + item.Url} className="nav-link">
                                                    {item.Name}
                                                </NavLink>
                                            </li>
                                        }
                                        {item.Childs.length > 0 &&
                                            <li className="nav-item dropdown">
                                                <a className="nav-link link dropdown-toggle" data-toggle="dropdown-submenu" href={item.Url}>{item.Name}</a>
                                                <div className="dropdown-menu">
                                                    {item.Childs.map((child, keyChild) => {
                                                        return (
                                                            <NavLink to={'/public' + child.Url} className="dropdown-item" key={keyChild}>
                                                                {child.Name}
                                                            </NavLink>
                                                        )
                                                    })

                                                    }
                                                </div>
                                            </li>
                                        }
                                    </div>
                                )
                            })
                        }
                        <div>
                            <li className="nav-item" onClick={showHuongDanClick}>
                                <a className="nav-link">Hướng dẫn</a>
                            </li>
                        </div>
                    </ul>
                    {nhanVien.FullName != null &&
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item dropdown no-arrow">
                                <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="fa fa-user-circle" aria-hidden="true"> </i>
                                    <span className="mr-1 d-none d-lg-inline "> {nhanVien.FullName}</span>
                                    {/* <img className="img-profile rounded-circle" src={apiUrl + nhanVien.Avatar} /> */}
                                </a>
                                <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                                    <a className="dropdown-item" href="#" onClick={logout}>
                                        <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400" />
                                        Đăng xuất
                                    </a>
                                </div>
                            </li>
                        </ul>
                    }





                </div>
            </div>
            {
                showHuongDan ? <HuongDan show={showHuongDan} handleClose={() => setShowHuongDan(false)}></HuongDan> : ''
            }
        </nav>
    )
}
