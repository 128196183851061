const { default: axiosClient } = require("./axiosClient");

const ThoiTietApi = {
    getPaging: (params) => {      
        const url = '/thoitiet/paging';
        return axiosClient.get(url, {params});
    },

    getDetail: (id) =>{
        const url = '/thoitiet/getbyid/' + id;
        return axiosClient.get(url);
    },

    post: (data) =>{
        const url = '/thoitiet/add';
        return axiosClient.post(url, data);
    },

    put: (data) => {
        const url = '/thoitiet/edit';
        return axiosClient.put(url, data);
    },

    delete: (id) =>{
        const url = '/thoitiet/delete/' + id;
        return axiosClient.delete(url);
    }   
}

export default ThoiTietApi;